import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Loading } from "@app/components";

const RedirectUrlMiddleware = () => {
    const shouldRender = useRef(true);
    const location = useLocation();
    const navigate = useNavigate();
    const { userProfile } = useSelector((state) => state.authentication);

    useEffect(() => {
        const previousUrl = localStorage.getItem("previous_url");

        if (previousUrl) {
            localStorage.removeItem("previous_url");

            window.location.replace(previousUrl);
        } else {
            shouldRender.current = true;
        }
    }, []);

    useEffect(() => {
        if (userProfile.is_potential_customer && userProfile.is_partner) {
            navigate("/partner", { replace: true });
        } else if (
            userProfile.is_potential_customer &&
            !userProfile.is_talent &&
            !userProfile.is_partner &&
            location.pathname !== "/lobby"
        ) {
            navigate("/lobby", { replace: true });
        } else if (userProfile.is_client && !userProfile.is_potential_customer && location.pathname === "/lobby") {
            navigate("/", { replace: true }); // Redirect to main dashboard if accepted customer tries to access /lobby
        }
    }, [userProfile, location.pathname, navigate]);

    return shouldRender.current ? (
        <Outlet />
    ) : (
        <Loading
            fullScreen
            withoutLabel
        />
    );
};

export default RedirectUrlMiddleware;
