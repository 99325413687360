import React from "react";
import { isNil } from "lodash";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Loading } from "@app/components";
import { safeBackLink } from "@app/helpers";

const ClientProfileMiddleware = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { company, userProfile } = useSelector((state) => state.authentication);
    const profileCompleted = React.useMemo(() => Boolean(company), [company]);

    // Authentication is not ready yet
    if (isNil(userProfile)) {
        return (
            <Loading
                fullScreen
                withoutLabel
            />
        );
    }

    // Profile is completed and current page is `/complete-profile`
    if (profileCompleted && location.pathname === "/complete-profile") {
        navigate(safeBackLink(), { replace: true });

        return null;
    }

    // Profile is NOT completed and current page is NOT `/complete-profile`
    if (!profileCompleted && location.pathname !== "/complete-profile") {
        navigate("complete-profile", { replace: true });

        return null;
    }

    return <Outlet />;
};

export default ClientProfileMiddleware;
