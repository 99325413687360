import React from "react";
import { useSelector } from "react-redux";

import Input from "@app/components/New/molecules/input";
import FormSelect from "@app/components/New/molecules/formSelect";

function prepareCountriesForSelect(countries, valueKey) {
    return countries
        .map((el) => {
            return {
                label: el.nice_name,
                value: el?.[valueKey] || el?.id,
            };
        })
        ?.filter((el) => el.value);
}

const CountriesSelect = ({
    label,
    onChange,
    value = "",
    valueKey = "id",
    required = false,
    menuPortalTarget = null,
    showOptionalLabel = false,
    ...props
}) => {
    const { countries = [] } = useSelector((state) => state.authentication.commonData);

    const hasCountries = React.useMemo(() => countries?.length > 0, [countries]);
    const countriesData = React.useMemo(() => prepareCountriesForSelect(countries, valueKey), [countries, valueKey]);

    const handleChange = React.useCallback((newVal) => onChange?.(newVal), [onChange]);

    const noOptionsMessage = ({ inputValue }) => {
        if (Boolean(inputValue)) return "No matching countries found";

        return "No countries found";
    };

    const renderLabel = () => {
        if (!label) return null;

        if (required) {
            return label;
        }

        return (
            <>
                <span>{label}</span>
                <span> </span>
                {showOptionalLabel && <span className="text-secondary">(Optional)</span>}
            </>
        );
    };

    if (hasCountries) {
        return (
            <FormSelect
                {...props}
                value={value}
                required={required}
                optionsData={countriesData}
                menuPortalTarget={menuPortalTarget}
                noOptionsMessage={noOptionsMessage}
                onChange={(option) => handleChange(option?.value || "")}
            >
                {renderLabel()}
            </FormSelect>
        );
    }

    return (
        <Input
            {...props}
            value={value}
            required={required}
            onChange={(e) => handleChange(e?.target?.value || "")}
        >
            {renderLabel()}
        </Input>
    );
};

export default CountriesSelect;
