import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Collapse } from "@mui/material";

import { TALENT } from "@app/routes";
import { CALENDAR_CONNECT_SUCCESS } from "@app/actions/Talent/Interviews";

const MissingCalendarIntegrationAlert = () => {
    const { action, selectedCalendar } = useSelector((state) => state.talent.interviews);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (action?.isSuccess && action?.actionName === CALENDAR_CONNECT_SUCCESS) {
            handleOpen();
        }
    }, [action?.isSuccess, action?.actionName]);

    return (
        <>
            <Collapse in={open}>
                <Alert
                    sx={{ mt: 2 }}
                    severity="success"
                    onClose={handleClose}
                >
                    Your calendar was successfully integrated.
                </Alert>
            </Collapse>

            {false === selectedCalendar?.is_connected && (
                <Alert
                    sx={{ mt: 2 }}
                    severity="warning"
                >
                    <span>
                        Your calendar is not yet linked to your interviewer account. Please complete the setup to be
                        able to start scheduling.
                    </span>

                    <span> </span>

                    <Link to={TALENT.INTERVIEWS_GUIDE_PATH}>Learn more</Link>
                </Alert>
            )}
        </>
    );
};

export default MissingCalendarIntegrationAlert;
