import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import api from "@app/services/api";
import { MODALS } from "@app/enums";
import { useModal } from "@app/hooks";

export default function RaiseInterestModal({ onSuccess }) {
    const [submitting, setSubmitting] = useState(false);
    const { state, closeModal } = useModal(MODALS.TALENT_RAISE_INTEREST);

    const handleClose = () => {
        closeModal({
            extra: null,
        });

        setSubmitting(false);
    };

    const handleSubmit = () => {
        setSubmitting(true);

        api.post("talent/positions/raise-interest", {
            position_id: state.extra.position.id,
        })
            .then(() => {
                handleClose();

                onSuccess(state.extra.position);
            })
            .catch(() => {});
    };

    return (
        <Modal
            onHide={handleClose}
            show={state?.show || false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Raise Interest</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span>Please confirm you want to raise interest in the position</span>
                <strong> {state?.extra?.position?.name}</strong>
                <span>.</span>
            </Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    onClick={handleClose}
                    disabled={submitting}
                    className="btn-action btn-text btn-transparent"
                >
                    Close
                </button>
                <button
                    type="button"
                    disabled={submitting}
                    onClick={handleSubmit}
                    className={`btn-action btn-text btn-green mr-0 ${submitting ? "disabled" : ""}`}
                >
                    {submitting ? "Submitting..." : "Proceed"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
