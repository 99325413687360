export * from "./colors";
export * from "./dates";
export * from "./errors";
export * from "./forms";
export * from "./geo";
export * from "./navigation";
export * from "./strings";

export const searchGetEndpoint = (data) => {
    const {
        page = 1,
        skills = "",
        perPage = 10,
        sortBy = "4",
        keywords = "",
        statuses = "1",
        companies = "",
        topFilter = "1",
        experience = "",
        joined_date = "",
        last_active = "",
        hourly_rate = "",
        user_belong = "0",
        matched_with = "",
        suggested_for = "",
        availability = "0",
        user_rating = "0,100",
        included_locations = "",
        excluded_locations = "",
        disable_experience = "",
        disable_hourly_rate = "",
        exclude_active_contracts = "0",
    } = data;

    const keyMap = {
        skills: "value",
        statuses: "value",
        companies: "company",
    };

    function convert(key, value) {
        if (value.length !== 0) {
            const keyofObj = keyMap?.[key] || "id";

            if (Array.isArray(value)) {
                return value.map((e) => `${key}[]=${e[keyofObj]}`).join("&");
            }

            return value
                .split(" ")
                .map((e) => `${key}[]=${e}`)
                .join("&");
        }

        return `${key}=`;
    }

    return (
        `admin/talent/search` +
        `?page=${page}` +
        `&sortBy=${sortBy}` +
        `&perPage=${perPage}` +
        `&topFilter=${topFilter}` +
        `&user_belong=${user_belong}` +
        `&user_rating=${user_rating}` +
        `&joined_date=${joined_date}` +
        `&last_active=${last_active}` +
        `&suggested_for=${suggested_for}` +
        `&matched_with=${matched_with}` +
        `&availability=${availability}` +
        `&${convert("skills", skills)}` +
        `&${convert("statuses", statuses)}` +
        `&${convert("companies", companies)}` +
        `&keywords=${encodeURIComponent(keywords)}` +
        `&included_locations=${included_locations}` +
        `&excluded_locations=${excluded_locations}` +
        `&exclude_active_contracts=${exclude_active_contracts}` +
        `&experience=${disable_experience === "1" ? "" : experience}` +
        `&hourly_rate=${disable_hourly_rate === "1" ? "" : hourly_rate}`
    );
};
