import { combineReducers } from "redux";

import account from "@app/reducers/Talent/Account";
import interviews from "@app/reducers/Talent/Interviews";
import invitations from "@app/reducers/Talent/Invitations";
import healthInsurance from "@app/reducers/Talent/HealthInsurance";
import referralsHistory from "@app/reducers/Talent/ReferralsHistory";

export default combineReducers({
    account,
    interviews,
    invitations,
    healthInsurance,
    referralsHistory,
});
