import React from "react";
import Container from "./Container";

import "react-dropdown-tree-select/dist/styles.css";
import "./index.scss";

const transformOption = (option, state) => {
    const children = option?.children || [];
    const countries = option?.countries || [];

    const nestedOptions = [...children, ...countries];

    const isCountry = option.hasOwnProperty("region_id") || false;

    const value = (isCountry ? "country_" : "region_") + option.id;

    return {
        value: value,
        expanded: true,
        label: option?.nicename || option?.name,
        children: nestedOptions?.map((opt) => transformOption(opt, state)),
    };
};

export default React.memo(function RegionCountries({ value, options, onChange }) {
    const currentState = React.useMemo(() => {
        const arrValue = Array.isArray(value) ? value : value?.split(",");

        return arrValue.filter(Boolean);
    }, [value]);

    const data = React.useMemo(
        () => options?.map((opt) => transformOption(opt, currentState)),
        [options, currentState],
    );

    const handleChange = (currentNode, selectedNodes) => onChange?.(selectedNodes, currentNode);

    return (
        <div id="region-select">
            <Container
                data={data}
                className="mdl-demo"
                showPartiallySelected
                onChange={handleChange}
                inlineSearchInput={false}
                texts={{ placeholder: "Select..." }}
            />
        </div>
    );
});
