import api from "@app/services/api";

export const SET_CONTRACT_ATTRIBUTE = "SET_CONTRACT_ATTRIBUTE";
export const setContractAttribute = (key, value) => {
    return {
        key,
        value,
        type: SET_CONTRACT_ATTRIBUTE,
    };
};

export const CLEAR_ACTIONS = "CLEAR_ACTIONS";
export const clearAction = () => ({
    type: CLEAR_ACTIONS,
});
export const CLEAR_CONTRACT_ACTION = "CLEAR_CONTRACT_ACTION";
export const clearContractAction = () => ({
    type: CLEAR_CONTRACT_ACTION,
});

export const CREATE_CONTRACT_BEGIN = "CREATE_CONTRACT_BEGIN";
export const createContractBegin = () => ({
    type: CREATE_CONTRACT_BEGIN,
});

export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const createContractSuccess = (data) => ({
    payload: data,
    type: CREATE_CONTRACT_SUCCESS,
});

export const CREATE_CONTRACT_FAILED = "CREATE_CONTRACT_FAILED";
export const createContractFailed = (error) => ({
    payload: error,
    type: CREATE_CONTRACT_FAILED,
});

export const createContract = (body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createContractBegin());

            await api.post(`admin/contracts`, body).then((response) => {
                dispatch(createContractSuccess(response.data));

                navigate("/admin/contracts/" + response.data.data.id + "?success=true");
            });
        } catch (error) {
            dispatch(createContractFailed(error));
        }
    };
};

export const UPDATE_CONTRACT_BEGIN = "UPDATE_CONTRACT_BEGIN";
export const updateContractBegin = () => ({
    type: UPDATE_CONTRACT_BEGIN,
});

export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
export const updateContractSuccess = (data) => ({
    payload: data,
    type: UPDATE_CONTRACT_SUCCESS,
});

export const UPDATE_CONTRACT_FAILED = "UPDATE_CONTRACT_FAILED";
export const updateContractFailed = (error) => ({
    payload: error,
    type: UPDATE_CONTRACT_FAILED,
});

export const updateContract = (contractId, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updateContractBegin());

            const response = await api.post(`admin/contracts/${contractId}/update`, body);

            dispatch(updateContractSuccess(response.data));

            navigate(-1);
        } catch (error) {
            dispatch(updateContractFailed(error));
        }
    };
};

export const GET_CONTRACT_BEGIN = "GET_CONTRACT_BEGIN";
export const getContractBegin = () => ({
    type: GET_CONTRACT_BEGIN,
});

export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const getContractSuccess = (data) => ({
    payload: data,
    type: GET_CONTRACT_SUCCESS,
});

export const DUPLICATE_CONTRACT_SUCCESS = "DUPLICATE_CONTRACT_SUCCESS";
export const duplicateContractSuccess = (data) => ({
    payload: data,
    type: DUPLICATE_CONTRACT_SUCCESS,
});

export const GET_CONTRACT_FAILED = "GET_CONTRACT_FAILED";
export const getContractFailed = (error) => ({
    payload: error,
    type: GET_CONTRACT_FAILED,
});

export const getContract = (contractId) => {
    return async (dispatch) => {
        try {
            dispatch(getContractBegin());
            const response = await api.get(`admin/contracts/${contractId}`, {
                headers: {},
            });
            dispatch(getContractSuccess(response.data.data));
        } catch (error) {
            dispatch(getContractFailed(error));
        }
    };
};

export const getContractDuplicate = (contractId) => {
    return async (dispatch) => {
        try {
            dispatch(getContractBegin());
            const response = await api.get(`admin/contracts/${contractId}`, {
                headers: {},
            });
            dispatch(duplicateContractSuccess(response.data.data));
        } catch (error) {
            dispatch(getContractFailed(error));
        }
    };
};
