import React from "react";

import FallbackAvatar from "@app/components/FallbackAvatar";

import "./index.scss";

const ScheduleACallBox = ({ user, owner }) => {
    const calendar = owner?.interviewer?.calendar || "";
    const callLink = calendar.replace(/^https:\/\/cal\.com\//, "");

    return (
        <div className="schedule-call-wrapper m-2">
            <div className="owner-details">
                <div>
                    <FallbackAvatar
                        size="md"
                        src={owner?.image}
                        alt={owner?.first_name}
                        sx={{ width: 32, height: 32 }}
                        style={{ marginRight: "7px" }}
                    />
                </div>
                <div>
                    <p className="full-name">{owner?.fullName}</p>
                    <p className="title">{owner?.title}</p>
                </div>
            </div>
            <div>
                <p className="description">Schedule a call with your Account Manager</p>
            </div>
            <div className="mt-2 d-flex align-items-center">
                <i className="icon-phone-blue d-inline-flex"></i>
                <a
                    href="#"
                    data-cal-link={callLink}
                    data-cal-namespace="15min"
                    className="schedule-link px-0"
                    data-cal-config={JSON.stringify({
                        theme: "light",
                        email: user.email,
                        name: user.fullName,
                        layout: "month_view",
                    })}
                >
                    Schedule a Call
                </a>
            </div>
        </div>
    );
};

export default ScheduleACallBox;
