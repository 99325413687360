import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const useFilterActions = (filterData, clearAction) => {
    const dispatch = useDispatch();

    const handleFilterTypes = useCallback(
        (item) => {
            dispatch(filterData(item));
        },
        [dispatch, filterData],
    );

    const handleClearAction = useCallback(() => {
        dispatch(clearAction());
    }, [dispatch, clearAction]);

    return [handleFilterTypes, handleClearAction];
};
