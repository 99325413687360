import { inviteMember } from "@app/actions";

const initialState = {
    inviteMemberBody: {
        role: [],
        email: "",
        groups: [],
    },
};

export default function inviteMemberReducer(state = initialState, action) {
    switch (action.type) {
        case inviteMember.SET_INVITE_MEMBER_ATTRIBUTE:
            return {
                ...state,
                inviteMemberBody: {
                    ...state.inviteMemberBody,
                    [action.key]: action.value,
                },
            };
        case inviteMember.CLEAR_INVITE_MEMBER:
            return {
                ...state,
                inviteMemberBody: {
                    ...initialState.inviteMemberBody,
                },
            };
        default: {
            return state;
        }
    }
}
