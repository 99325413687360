import React from "react";
import RangeCriteriaItem from "./Item";

import "./index.scss";

const RangeCriteriaList = ({ criteria }) => {
    if (!criteria || Array.from(criteria || []).length === 0) return null;

    return (
        <div className="pt-3">
            {criteria?.map((criterion) => (
                <RangeCriteriaItem
                    key={criterion?.id}
                    criteria={criterion}
                />
            ))}
        </div>
    );
};

export default RangeCriteriaList;
