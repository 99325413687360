import Cookies from "js-cookie";
import FeedbackModal from "./FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { TALENT } from "@app/routes";
import { authentication } from "@app/actions";
import Logo from "@app/styles/assets/svg/logo.svg";
import { FEAT_NEW_TALENT_AVAILABILITY_ENABLED } from "@app/config/features";
import { Feature, Authorize, FallbackAvatar, AvailabilityIndicator } from "@app/components";
import { isAdminPortal, isClientPortal, isTalentPortal, redirectExternal } from "@app/helpers";

import { useStyles } from "../style";
import AdminNavbar from "../AdminNavbar";

function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { userProfile } = useSelector((state) => state.authentication);

    const [state, setState] = useState({
        notificationDropdown: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const ref = useRef();
    const isAdmin = Boolean(pathname.includes("/admin"));
    const lightGrayBg = Boolean("/" === pathname || pathname.includes("/admin") || "/partner" === pathname);

    const isImpersonating = () => Cookies.get("impersonate") || false;

    const handleLogoClick = () => {
        if (isTalentPortal()) {
            navigate(TALENT.DASHBOARD_PATH);
        } else {
            if (isAdminPortal()) {
                redirectExternal("admin");
            } else if (isClientPortal()) {
                navigate("/");
            } else {
                navigate("/not-found");
            }
        }
    };

    const handlePlus = (e) => {
        setAnchorEl(e.currentTarget);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (state.notificationDropdown && ref.current && !ref.current.contains(e.target)) {
                setState({ ...state, notificationDropdown: false });
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [state]);

    if (pathname.includes("users/notify")) {
        return null;
    }

    return (
        <header
            id="header-container"
            style={{ position: "fixed" }}
            className="fullwidth dashboard-header not-sticky"
        >
            <div
                id="header"
                style={{ backgroundColor: "#F9FAFC" }}
                className={isAdmin ? "light-gray-backgroud border-bottom" : undefined}
            >
                <div className="container">
                    <div className="clearfix"></div>
                    <div
                        className="left-side"
                        style={{ backgroundColor: "#F9FAFC" }}
                    >
                        <div
                            id="logo"
                            style={{ backgroundColor: isAdmin ? "#F9FAFC" : "#FFFFFF" }}
                        >
                            <img
                                src={Logo}
                                alt="Logo"
                                onClick={handleLogoClick}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        {isAdmin ? (
                            <AdminNavbar />
                        ) : (
                            <nav id="navigation">
                                {window.location.pathname !== "/partner/resources/adevavp" &&
                                    window.location.pathname !== "/partner/resources/partnerpm" && (
                                        <h5 style={{ color: "#1A284D", fontWeight: "normal" }}>{`Hello, ${
                                            userProfile?.first_name || ""
                                        }!`}</h5>
                                    )}
                            </nav>
                        )}
                    </div>

                    <div
                        className="right-side"
                        style={{ backgroundColor: "#F9FAFC" }}
                    >
                        <div className={lightGrayBg ? "header-widget light-gray-backgroud" : "header-widget"}>
                            {userProfile?.is_talent && (
                                <Feature flag={FEAT_NEW_TALENT_AVAILABILITY_ENABLED}>
                                    <AvailabilityIndicator className="me-4" />
                                </Feature>
                            )}

                            {isAdmin && (
                                <div className="add-item dropdown">
                                    <button
                                        type="button"
                                        onClick={handlePlus}
                                    >
                                        <i className="fa fa-plus-circle" />
                                    </button>
                                </div>
                            )}

                            <div
                                className={
                                    state.notificationDropdown
                                        ? "header-notifications user-menu active"
                                        : "header-notifications user-menu"
                                }
                                onClick={() => {
                                    setState({
                                        ...state,
                                        notificationDropdown: !state.notificationDropdown,
                                    });
                                }}
                            >
                                <div className="header-notifications-trigger">
                                    <button>
                                        <div className="user-avatar">
                                            <FallbackAvatar
                                                src={userProfile?.image}
                                                alt={userProfile?.fullName}
                                            />
                                        </div>
                                    </button>
                                </div>
                                <div
                                    ref={ref}
                                    className="header-notifications-dropdown"
                                >
                                    <ul className="user-menu-small-nav">
                                        <Authorize permission="view admin dashboard">
                                            {isTalentPortal() && (
                                                <>
                                                    <li className="user-menu-item mb-0">
                                                        <button
                                                            className="mb-0"
                                                            onClick={() => redirectExternal("admin")}
                                                        >
                                                            Admin Dashboard
                                                        </button>
                                                    </li>
                                                    <hr className="my-3" />
                                                </>
                                            )}

                                            {isAdminPortal() && (
                                                <li className="user-menu-item mb-0">
                                                    <button onClick={() => navigate(TALENT.DASHBOARD_PATH)}>
                                                        Personal Dashboard
                                                    </button>
                                                </li>
                                            )}
                                        </Authorize>

                                        <Authorize role={["Full-time", "Contractor"]}>
                                            {isTalentPortal() && (
                                                <li className="user-menu-item">
                                                    <button onClick={() => redirectExternal("account")}>
                                                        Edit Profile
                                                    </button>
                                                </li>
                                            )}

                                            {isTalentPortal() && (
                                                <li className="user-menu-item">
                                                    <button onClick={() => redirectExternal("settings")}>
                                                        Account Settings
                                                    </button>
                                                </li>
                                            )}
                                        </Authorize>

                                        <Authorize role="Customer">
                                            {!isTalentPortal() && !isAdminPortal() && (
                                                <li className="user-menu-item">
                                                    <button onClick={() => navigate("/account-settings")}>
                                                        Account Settings
                                                    </button>
                                                </li>
                                            )}
                                        </Authorize>

                                        <li className="user-menu-item">
                                            <button onClick={() => dispatch(authentication.logOut())}>Logout</button>
                                        </li>

                                        {isImpersonating() && (
                                            <li className="user-menu-item">
                                                <button onClick={() => dispatch(authentication.leaveImpersonate())}>
                                                    Leave impersonation
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackModal />
            <Popper
                open={open}
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                actions={[
                    {
                        label: "Applicant",
                        icon: "fa fa-user-circle-o",
                        href: "admin/talent/users/create?role=Applicant",
                    },
                    { label: "Prospect", icon: "fa fa-user-o", href: "admin/talent/prospects/create" },
                    { label: "Position", icon: "fa fa-flask", href: "admin/customers/positions/create" },
                ]}
            />
        </header>
    );
}

const Popper = React.memo(({ open, actions, anchorEl, handleClose }) => {
    const classes = useStyles();

    return (
        <Menu
            open={open}
            id="basic-menu"
            anchorEl={anchorEl}
            onClose={() => handleClose()}
            className={classes.menuHeight}
        >
            {actions.map((el) => (
                <MenuItem
                    key={el.label}
                    className={`${classes.optionItem} ${classes.optionItemHeader}`}
                    onClick={() => {
                        handleClose();
                        redirectExternal(el.href);
                    }}
                >
                    <ListItemIcon>
                        <i className={el.icon} />
                    </ListItemIcon>
                    <ListItemText>{el.label}</ListItemText>
                </MenuItem>
            ))}
            <MenuItem
                key="Contract"
                className={`${classes.optionItem} ${classes.optionItemHeader}`}
                onClick={() => {
                    window.location.href = "/admin/contracts/create";
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <i className="fa fa-handshake-o" />
                </ListItemIcon>
                <ListItemText>Contract</ListItemText>
            </MenuItem>
        </Menu>
    );
});

export default Header;
