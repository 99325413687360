import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "@app/components";
import { requestPublicRouteAccess } from "@app/actions/authentication";

const PublicMiddleware = () => {
    const dispatch = useDispatch();
    const [shouldRender, setShouldRender] = useState(false);
    const { isLoading, connectionEstablished } = useSelector((state) => state.authentication);

    useEffect(() => {
        dispatch(requestPublicRouteAccess());
    }, [dispatch]);

    useEffect(() => {
        if (connectionEstablished && !isLoading) {
            setShouldRender(true);
        }
    }, [connectionEstablished, isLoading]);

    return shouldRender ? (
        <Outlet />
    ) : (
        <Loading
            fullScreen
            withoutLabel
        />
    );
};

export default PublicMiddleware;
