import api from "@app/services/api";
export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const RESET_TABLE_ACTIONS = "RESET_TABLE_ACTIONS";
export const resetTableActions = () => ({
    type: RESET_TABLE_ACTIONS,
});

export const SET_SEARCH_KEYWORD_PARTNER = "SET_SEARCH_KEYWORD_PARTNER";
export const setSearchKeyword = (keyword) => {
    return {
        payload: keyword,
        type: SET_SEARCH_KEYWORD_PARTNER,
    };
};

export const SET_PAGINATION = "SET_PAGINATION";
export const setPagination = (key, value) => ({
    key,
    value,
    type: SET_PAGINATION,
});

export const SET_SORT = "SET_SORT";
export const setSort = (orderColumn, asc) => ({
    asc,
    orderColumn,
    type: SET_SORT,
});

export const GET_DASHBOARD_PARTNERS_BEGIN = "GET_DASHBOARD_PARTNERS_BEGIN";
export const getDashboardPartnersBegin = () => ({
    type: GET_DASHBOARD_PARTNERS_BEGIN,
});

export const GET_DASHBOARD_PARTNERS_SUCCESS = "GET_DASHBOARD_PARTNERS_SUCCESS";
export const getDashboardPartnersSuccess = (data) => ({
    payload: data,
    type: GET_DASHBOARD_PARTNERS_SUCCESS,
});

export const GET_DASHBOARD_PARTNERS_FAILED = "GET_DASHBOARD_PARTNERS_FAILED";
export const getDashboardPartnersFailed = (error) => ({
    payload: error,
    type: GET_DASHBOARD_PARTNERS_FAILED,
});

export const getDashboardPartners = () => {
    return async (dispatch) => {
        try {
            dispatch(getDashboardPartnersBegin());
            const response = await api.get(`partner/dashboard`);
            dispatch(getDashboardPartnersSuccess(response.data.data));
        } catch (error) {
            dispatch(getDashboardPartnersFailed(error));
        }
    };
};

export const GET_POSITIONS_BEGIN_PARTNER = "GET_POSITIONS_BEGIN_PARTNER";
export const getPositionsBegin = () => ({
    type: GET_POSITIONS_BEGIN_PARTNER,
});

export const GET_POSITIONS_SUCCESS_PARTNER = "GET_POSITIONS_SUCCESS_PARTNER";
export const getPositionsSuccess = (data) => {
    return {
        payload: data,
        type: GET_POSITIONS_SUCCESS_PARTNER,
    };
};

export const GET_POSITIONS_FAILED_PARTNER = "GET_POSITIONS_FAILED_PARTNER";
export const getPositionsFailed = (error) => ({
    payload: error,
    type: GET_POSITIONS_FAILED_PARTNER,
});

export const getPositions = (page, items, search, orderColumn, direction) => {
    return async (dispatch) => {
        try {
            dispatch(getPositionsBegin());
            const response = await api.get(
                `/partner/positions?page=${page}&num_items=${items}&search=${search}&order_column=${orderColumn}&order_direction=${
                    direction || ""
                }`,
            );
            dispatch(getPositionsSuccess(response.data.data));
        } catch (error) {
            dispatch(getPositionsFailed(error));
        }
    };
};

export const GET_TALENTS_BEGIN = "GET_TALENTS_BEGIN";
export const getTalentsBegin = () => ({
    type: GET_TALENTS_BEGIN,
});

export const GET_TALENTS_SUCCESS = "GET_TALENTS_SUCCESS";
export const getTalentsSuccess = (data) => ({
    payload: data,
    type: GET_TALENTS_SUCCESS,
});

export const GET_TALENTS_FAILED = "GET_TALENTS_FAILED";
export const getTalentsFailed = (error) => ({
    payload: error,
    type: GET_TALENTS_FAILED,
});

export const getTalentsPartner = (status, page, items, search, orderColumn, direction) => {
    return async (dispatch) => {
        try {
            dispatch(getTalentsBegin());
            const response = await api.get(
                `partner/talents?status_name=${status}&page=${page}&num_items=${items}&search=${search}&order_column=${orderColumn}&order_direction=${
                    direction || ""
                }`,
            );
            dispatch(getTalentsSuccess(response.data.data));
        } catch (error) {
            dispatch(getTalentsFailed(error));
        }
    };
};

export const GET_CUSTOMERS_BEGIN = "GET_CUSTOMERS_BEGIN";
export const getCustomersBegin = () => ({
    type: GET_CUSTOMERS_BEGIN,
});

export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const getCustomersSuccess = (data) => ({
    payload: data,
    type: GET_CUSTOMERS_SUCCESS,
});

export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";
export const getCustomersFailed = (error) => ({
    payload: error,
    type: GET_CUSTOMERS_FAILED,
});

export const getCustomers = (status, page, items, search, orderColumn, direction) => {
    return async (dispatch) => {
        try {
            dispatch(getCustomersBegin());
            const response = await api.get(
                `partner/customers?status_name=${status}&page=${page}&num_items=${items}&search=${search}&order_column=${orderColumn}&order_direction=${
                    direction || ""
                }`,
            );
            dispatch(getCustomersSuccess(response.data.data));
        } catch (error) {
            dispatch(getCustomersFailed(error));
        }
    };
};

export const GET_INVOICES_PARTNER_BEGIN = "GET_INVOICES_PARTNER_BEGIN";
export const getInvoicesBegin = () => ({
    type: GET_INVOICES_PARTNER_BEGIN,
});

export const GET_INVOICES_PARTNER_SUCCESS = "GET_INVOICES_PARTNER_SUCCESS";
export const getInvoicesSuccess = (data) => ({
    payload: data,
    type: GET_INVOICES_PARTNER_SUCCESS,
});

export const GET_INVOICES_PARTNER_FAILED = "GET_INVOICES_PARTNER_FAILED";
export const getInvoicesFailed = (error) => ({
    payload: error,
    type: GET_INVOICES_PARTNER_FAILED,
});

export const getInvoicesPartner = (status, page, items, search, orderColumn, direction) => {
    return async (dispatch) => {
        try {
            dispatch(getInvoicesBegin());
            const response = await api.get(
                `partner/invoices?status_name=${status}&page=${page}&num_items=${items}&search=${search}&order_column=${orderColumn}&order_direction=${
                    direction || ""
                }`,
            );
            dispatch(getInvoicesSuccess(response.data.data));
        } catch (error) {
            dispatch(getInvoicesFailed(error));
        }
    };
};

export const INVITE_FRIEND_PARTNER_BEGIN = "INVITE_FRIEND_PARTNER_BEGIN";
export const inviteFriendBegin = () => ({
    type: INVITE_FRIEND_PARTNER_BEGIN,
});

export const INVITE_FRIEND_PARTNER_SUCCESS = "INVITE_FRIEND_PARTNER_SUCCESS";
export const inviteFriendSuccess = (data) => ({
    payload: data,
    type: INVITE_FRIEND_PARTNER_SUCCESS,
});

export const INVITE_FRIEND_PARTNER_FAILED = "INVITE_FRIEND_PARTNER_FAILED";
export const inviteFriendFailed = (error) => ({
    payload: error,
    type: INVITE_FRIEND_PARTNER_FAILED,
});

export const inviteFriendPartner = (body) => {
    return async (dispatch) => {
        try {
            dispatch(inviteFriendBegin());
            const response = await api.post(`referrals/invitations`, body);
            dispatch(inviteFriendSuccess(response.data));
        } catch (error) {
            dispatch(inviteFriendFailed(error));
        }
    };
};

export const INVITE_CUSTOMER_BEGIN = "INVITE_CUSTOMER_BEGIN";
export const inviteCustomerBegin = () => ({
    type: INVITE_CUSTOMER_BEGIN,
});

export const INVITE_CUSTOMER_SUCCESS = "INVITE_CUSTOMER_SUCCESS";
export const inviteCustomerSuccess = (data) => ({
    payload: data,
    type: INVITE_CUSTOMER_SUCCESS,
});

export const INVITE_CUSTOMER_FAILED = "INVITE_CUSTOMER_FAILED";
export const inviteCustomerFailed = (error) => ({
    payload: error,
    type: INVITE_CUSTOMER_FAILED,
});

export const inviteCustomer = (body) => {
    return async (dispatch) => {
        try {
            dispatch(inviteCustomerBegin());
            const response = await api.post(`referrals/invitations`, body);
            dispatch(inviteCustomerSuccess(response.data));
        } catch (error) {
            dispatch(inviteCustomerFailed(error));
        }
    };
};
