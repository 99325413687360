import React from "react";

import Logo from "@app/styles/assets/svg/logo.svg";

import "./index.scss";

const EmailPreview = ({ title = "", fullName = "", emailBody = "", firstName = "", buttonText = "" }) => {
    return (
        <div className="email-preview-wrapper">
            <img
                src={Logo}
                alt="Logo"
                className="logo"
            />
            <div className="mb-3">
                {fullName && title && (
                    <span className="title">
                        {fullName} {title}
                    </span>
                )}
            </div>
            <div>
                <p className="email-body">{emailBody}</p>
            </div>

            <div className="join-button-wrapper">{buttonText && <span className="join-button">{buttonText}</span>}</div>
            <div className="mb-3 greetings-info">
                <p>Regards,</p>
                <p>{firstName}</p>
            </div>
        </div>
    );
};

export default EmailPreview;
