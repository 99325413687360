import React, { useRef } from "react";
import CreatableSelect from "react-select/creatable";

const components = {
    DropdownIndicator: null,
};

const CustomSelect = ({ value, setValue, inputValue, setInputValue }) => {
    const createOption = (label) => ({
        label,
        value: label,
    });
    const reactSelectOnfocus = useRef(false);

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
            case ",":
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue("");
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    return (
        <CreatableSelect
            isMulti
            value={value}
            menuIsOpen={false}
            isClearable={false}
            components={components}
            inputValue={inputValue}
            onKeyDown={handleKeyDown}
            onChange={(newValue) => setValue(newValue)}
            placeholder="Use comma separated email addresses"
            onBlur={() => (reactSelectOnfocus.current = false)}
            onFocus={() => (reactSelectOnfocus.current = true)}
            onInputChange={(newValue, actionMeta) => {
                setInputValue(newValue);
                if (actionMeta.action === "menu-close") {
                    setInputValue(actionMeta.prevInputValue);
                }
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: "5px",
                spacing: {
                    ...theme.spacing,
                    controlHeight: "10px",
                },
                colors: {
                    ...theme.colors,
                    primary50: "198CCA0D",
                    primary25: "#198CCA0D",
                },
            })}
            styles={{
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    color: "#198cca",
                }),
                multiValueRemove: (styles) => ({
                    ...styles,
                    ":hover": {
                        backgroundColor: "transparent",
                    },
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "100px",
                    color: "#198cca !important",
                    backgroundColor: "rgba(25, 140, 202, 0.07)",
                }),
            }}
        />
    );
};

export default CustomSelect;
