import PerksCard from "./Card";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

import api from "@app/services/api";
import { setSideBarTab } from "@app/actions/helper";

import "./index.scss";

async function getPerks() {
    return await api.get("talent/certifications");
}

const LearningAndCertification = () => {
    const [perks, setPerks] = useState([]);
    const dispatch = useDispatch();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const menuParam = searchParams.get("menu");
    const [selectedCategory] = useState(isNaN(parseInt(menuParam)) ? 0 : parseInt(menuParam));

    useEffect(() => {
        dispatch(setSideBarTab("Learning & Certification"));

        getPerks().then(function (response) {
            const sortedPerks = response.data.sort((a, b) => {
                return a.company_name.localeCompare(b.company_name);
            });

            setPerks(sortedPerks);
        });
    }, [dispatch]);

    // const handleMenuClick = (categoryIndex) => {
    //     setSelectedCategory(categoryIndex);
    // };

    return (
        <div
            className="dashboard-content-container"
            style={{ background: "var(--Gray-100, #F9FAFC)" }}
        >
            <div className="container perks-index-page">
                <div className="row wrap mt-2 ml-1">
                    <div className="col-xl-10 offset-md-1">
                        <div className="content-wrapper">
                            <p className="community-header">Learning & Certification</p>
                            <p className="community-subtext mt-2">
                                Get access to training programs and certification to level up your career and build your
                                future with Adeva.
                            </p>
                            <br />
                            <ul className="nav border-bottom">
                                {/*<li className="nav-item pb-1">*/}
                                {/*    <Link*/}
                                {/*        to="#"*/}
                                {/*        onClick={() => handleMenuClick(0)}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}*/}
                                {/*        className={`link ${selectedCategory === 0 ? "active" : ""}`}*/}
                                {/*    >*/}
                                {/*        All benefits*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item ml-2">*/}
                                {/*    <Link*/}
                                {/*        to="#"*/}
                                {/*        onClick={() => handleMenuClick(1)}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}*/}
                                {/*        className={`link ${selectedCategory === 1 ? "active" : ""}`}*/}
                                {/*    >*/}
                                {/*        Health Insurance*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className={`nav-item ml-2`}>*/}
                                {/*  <Link onClick={() => handleMenuClick(2)} to={"#"}*/}
                                {/*        className={`link ${selectedCategory === 2 ? "active" : ""}`}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}>*/}
                                {/*    Certifications*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item ml-2">*/}
                                {/*    <Link*/}
                                {/*        to="#"*/}
                                {/*        onClick={() => handleMenuClick(3)}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}*/}
                                {/*        className={`link ${selectedCategory === 3 ? "active" : ""}`}*/}
                                {/*    >*/}
                                {/*        Education*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className={`nav-item ml-2`}>*/}
                                {/*  <Link onClick={() => handleMenuClick(4)} to={"#"}*/}
                                {/*        className={`link ${selectedCategory === 4 ? "active" : ""}`}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}>*/}
                                {/*    Discounts*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className={`nav-item ml-2`}>*/}
                                {/*  <Link onClick={() => handleMenuClick(5)} to={"#"}*/}
                                {/*        className={`link ${selectedCategory === 5 ? "active" : ""}`}*/}
                                {/*        style={{ padding: "0", paddingTop: "30px" }}>*/}
                                {/*    Transportation*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                            </ul>
                            <br />
                            <br />
                            {[0, 1].includes(selectedCategory) && (
                                <div>
                                    {/*<h4 style={{ color: "#1A284D" }}>*/}
                                    {/*    Get access to training programs and certification to level up your career and*/}
                                    {/*    build your future with Adeva.*/}
                                    {/*</h4>*/}
                                    <div className="panels-container mt-3">
                                        {perks &&
                                            Object.values(perks).map(
                                                (perk, index) =>
                                                    perk.category_id === 1 && (
                                                        <PerksCard
                                                            key={index}
                                                            href={perk.link}
                                                            image={perk.logo}
                                                            subHeader={perk.subtitle}
                                                            subtext={perk.description}
                                                            heading={perk.company_name}
                                                        />
                                                    ),
                                            )}
                                    </div>
                                </div>
                            )}
                            {/*{[0, 3].includes(selectedCategory) && (*/}
                            {/*    <div>*/}
                            {/*        <h4 style={{ color: "#1A284D" }}>Education</h4>*/}
                            {/*        <div className="panels-container mt-3">*/}
                            {/*            {perks &&*/}
                            {/*                Object.values(perks).map(*/}
                            {/*                    (perk, index) =>*/}
                            {/*                        perk.category_id === 3 && (*/}
                            {/*                            <PerksCard*/}
                            {/*                                key={index}*/}
                            {/*                                href={perk.href}*/}
                            {/*                                image={perk.image}*/}
                            {/*                                heading={perk.name}*/}
                            {/*                                subHeader={perk.category}*/}
                            {/*                                subtext={perk.description}*/}
                            {/*                            />*/}
                            {/*                        ),*/}
                            {/*                )}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearningAndCertification;
