import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { Collapse } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { useStyles } from "../style";

function MultiLevel({ item, filterTypes, handleFilterTypes }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const checkedPropLength = item.children.filter((el) => el._selected).length;

    const selectAll = (e) => {
        item.selectAll = e.target.checked;
        item.children.forEach((child) => (child._selected = e.target.checked));
        handleFilterTypes(filterTypes);
    };

    const selectSingleItem = (e) => {
        if (item.selectAll) {
            item.selectAll = false;
        }
        item.children[e.target.id]._selected = !item.children[e.target.id]._selected;
        handleFilterTypes(filterTypes, e);
    };

    return (
        <React.Fragment>
            <MenuItem
                id={item.parent}
                className={classes.filterMenuItem}
                onClick={() => {
                    setOpen((prev) => !prev);
                }}
            >
                {item.hasOwnProperty("selectAll") ? (
                    <>
                        <div style={{ display: "inherit", alignItems: "center" }}>
                            <input
                                type="checkbox"
                                checked={item.selectAll ?? item.selected}
                                style={{ cursor: "pointer", marginRight: "10px" }}
                                onChange={(e) => {
                                    selectAll(e);
                                }}
                            />
                            <label style={{ cursor: "pointer" }}>
                                {item.parent} {checkedPropLength === 0 ? `` : `(${checkedPropLength})`}
                            </label>
                        </div>
                        <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                    </>
                ) : (
                    <div className="display-space-between">
                        <label style={{ cursor: "pointer" }}>
                            {item.parent} {checkedPropLength === 0 ? `` : `(${checkedPropLength})`}
                        </label>
                        <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                    </div>
                )}
            </MenuItem>
            <Collapse
                in={open}
                unmountOnExit
                timeout="auto"
            >
                {item.children.map((child, index) => {
                    return (
                        <MenuItem
                            id={index}
                            key={index}
                            onClick={selectSingleItem}
                            className={classes.childFilterMenuItem}
                        >
                            <input
                                readOnly
                                id={index}
                                type="checkbox"
                                onChange={() => {}}
                                checked={child?._selected || false}
                                style={{ cursor: "pointer", marginRight: "10px" }}
                            />
                            <label
                                id={index}
                                className="cut-text"
                                style={{ cursor: "pointer" }}
                            >
                                {child[item.key]}
                            </label>
                        </MenuItem>
                    );
                })}
            </Collapse>
        </React.Fragment>
    );
}

const Filter = ({ filterTypes, handleFilterTypes, handleClearAction }) => {
    const classes = useStyles();
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopperClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopper((prev) => event.currentTarget !== anchorEl || !prev);
    };

    return (
        <>
            <button
                className="btn-action btn-icon m-0"
                onClick={(event) => {
                    handlePopperClick(event);
                }}
            >
                <i
                    className="icon-filter"
                    style={{ paddingTop: "2px" }}
                ></i>
                Filter
            </button>
            <Menu
                id="basic-menu"
                open={openPopper}
                anchorEl={anchorEl}
                className={classes.menuHeight}
                onClose={() => {
                    setOpenPopper(false);
                }}
            >
                {filterTypes.map((item, idx) => (
                    <MultiLevel
                        key={idx}
                        item={item}
                        filterTypes={filterTypes}
                        handleFilterTypes={handleFilterTypes}
                    />
                ))}
                <div style={{ paddingTop: "8px", textAlign: "right" }}>
                    <button
                        onClick={handleClearAction}
                        className="btn-action btn-text btn-transparent btn-inside-filter"
                    >
                        Clear
                    </button>
                </div>
            </Menu>
        </>
    );
};

export default Filter;
