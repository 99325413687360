const API_HOST = process.env.REACT_APP_API_HOST;
const MAIN_HOST = process.env.REACT_APP_MAIN_HOST;
const GTM_ID = process.env.REACT_APP_GTM_ID;
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_HOST;
const BASIC_AUTH_ENABLED = String(process.env.REACT_APP_BASIC_AUTH_ENABLED)?.toLowerCase() === "true";
const BASIC_AUTH_USERNAME = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const BASIC_AUTH_PASSWORD = process.env.REACT_APP_BASIC_AUTH_PASSWORD;

const properties = {
    GTM_ID,
    API_HOST,
    MAIN_HOST,
    WEBSITE_URL,
    BASIC_AUTH_ENABLED,
    BASIC_AUTH_USERNAME,
    BASIC_AUTH_PASSWORD,
};

export default properties;
