import React from "react";
import Modal from "./modal";

import TalentWorkPreferencesContextProvider from "@app/providers/TalentWorkPreferencesContextProvider";

const WorkPreferencesContextWrapper = (props) => {
    return (
        <TalentWorkPreferencesContextProvider>
            <Modal {...props} />
        </TalentWorkPreferencesContextProvider>
    );
};

export default WorkPreferencesContextWrapper;
