import { healthInsurance } from "@app/actions/Talent";

const initialState = {
    plans: [],
    action: {
        id: null,
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function talentReducer(state = initialState, action) {
    switch (action.type) {
        case healthInsurance.CLEAR_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        }
        case healthInsurance.APPLY_COVERAGE_BEGIN:
        case healthInsurance.GET_PLANS_BEGIN:
        case healthInsurance.SAFETYWING_CHECK_CREDENCIALS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case healthInsurance.APPLY_COVERAGE_FAILED:
        case healthInsurance.GET_PLANS_FAILED:
        case healthInsurance.SAFETYWING_CHECK_CREDENCIALS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case healthInsurance.APPLY_COVERAGE_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case healthInsurance.GET_PLANS_SUCCESS: {
            return {
                ...state,
                plans: action.payload?.data.plans || [],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case healthInsurance.SAFETYWING_CHECK_CREDENCIALS: {
            return {
                ...state,
                safetyWingCredencials: action.payload?.data || "",
            };
        }
        case healthInsurance.DISABLE_PLAN: {
            state.plans.forEach((element, index) => {
                if (element.id === action.payload) {
                    state.plans[index].subscribed = true;
                }
            });
            return {
                ...state,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
