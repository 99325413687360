export const SHOW_MODAL = "SHOW_MODAL";
export const showModal = (show_modal) => {
    return { type: SHOW_MODAL, payload: { show_modal } };
};

export const SET_SIDEBAR_TAB = "SET_SIDEBAR_TAB";
export const setSideBarTab = (tab) => ({
    payload: { tab },
    type: SET_SIDEBAR_TAB,
});

export const SET_NAV_TAB = "SET_NAV_TAB";
export const setNavTab = (tab) => ({
    payload: { tab },
    type: SET_NAV_TAB,
});

export const CONTRACTS_DETAILS_NAV_BAR = "CONTRACTS_DETAILS_NAV_BAR";
export const setContractsNav = (tab) => ({
    payload: { tab },
    type: CONTRACTS_DETAILS_NAV_BAR,
});

export const COMPANY_SETTINGS_NAV_BAR = "COMPANY_SETTINGS_NAV_BAR";
export const setCompanySettingsNav = (tab) => ({
    payload: { tab },
    type: COMPANY_SETTINGS_NAV_BAR,
});

export const ADMIN_NAV_BAR = "ADMIN_NAV_BAR";
export const setAdminNavbar = (tab) => ({
    payload: { tab },
    type: ADMIN_NAV_BAR,
});
