/* eslint-disable promise/no-promise-in-callback */
import React from "react";
import { useDispatch } from "react-redux";

import { ERROR } from "@app/enums";
import api from "@app/services/api";
import { hasPermission, logOutSuccess } from "@app/actions/authentication";

const AxiosInterceptorProvider = ({ children }) => {
    const dispatch = useDispatch();

    const registerRequestInterceptor = () => {
        api?.interceptors?.request?.use(
            (config) => config,
            (error) => Promise.reject(error),
        );
    };

    const requestResponseInterceptor = React.useCallback(() => {
        api?.interceptors?.response?.use(
            (res) => {
                dispatch(hasPermission(true));
                return res;
            },
            (err) => {
                let message = ERROR.DEFAULT_MESSAGE;

                const errors = err?.response?.data?.errors || [];

                message = Array.isArray(errors) ? errors?.[0] : Object.values(errors)?.[0];

                try {
                    // No Logged In user
                    if (err.response.status === 401) {
                        return dispatch(logOutSuccess());
                    }

                    if (err?.response?.data?.showAll) {
                        return Promise.reject(err.response?.data?.errors);
                    }

                    // Unprocessable Entity (Required inputs)
                    if (err.response.status === 422) {
                        const requiredFields = ["first_name", "last_name", "avatar", "name"];

                        for (const keyVal in err.response.data.errors) {
                            if (requiredFields.includes(keyVal)) {
                                message = err.response.data.errors[keyVal];
                                break;
                            }
                        }

                        throw message;
                    }

                    // Unauthorized response
                    if (err.response.status === 403) {
                        return dispatch(hasPermission(false));
                    }

                    throw message;
                } catch (err) {
                    throw message;
                }
            },
        );
    }, [dispatch]);

    React.useEffect(() => {
        registerRequestInterceptor();

        requestResponseInterceptor();
    }, [requestResponseInterceptor]);

    return children;
};

export default AxiosInterceptorProvider;
