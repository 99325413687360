import React from "react";

import "./index.scss";

function Loading({ className, fullScreen = false, withoutLabel = false, message = "Loading..." }) {
    return (
        <div
            style={{ marginTop: "30px" }}
            className={`flex-fill d-flex flex-column justify-content-center align-items-center fade-in ${className || ""} ${
                fullScreen ? "position-fixed top-0 bottom-0 left-0 right-0 vw-100 vh-100" : ""
            }`}
        >
            <div
                role="status"
                className="spinner-border text-primary"
                style={{ width: "2.7rem", height: "2.7rem" }}
            />
            <p
                className="sub-title"
                style={{ fontSize: "12px" }}
            >
                {withoutLabel ? "" : message}
            </p>
        </div>
    );
}

export default Loading;
