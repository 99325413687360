import React from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

const TabNavbar = ({ tabs, activeTab, onTabClick }) => {
    const navigate = useNavigate();

    const handleTabClick = (tab) => {
        const formattedTab = tab.toLowerCase().replace(/\s+/g, "-");
        onTabClick(tab);
        navigate(`?tab=${encodeURIComponent(formattedTab)}`);
    };

    return (
        <div className="tab-navbar">
            <ul
                className="nav"
                style={{ paddingBottom: "9px", borderBottom: "1px solid #E1E3EB" }}
            >
                {tabs.map((tab) => (
                    <li
                        key={tab.key}
                        className="nav-item pb-1"
                    >
                        <a
                            href="#"
                            style={{ padding: "0", paddingTop: "30px" }}
                            className={`link${activeTab === tab.key ? " active" : ""}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleTabClick(tab.key);
                            }}
                        >
                            {tab.key}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TabNavbar;
