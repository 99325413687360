import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { helper } from "@app/actions";

function FeedbackModal() {
    const dispatch = useDispatch();
    const helperState = useSelector((state) => state.helper);

    // TODO SET ACTION ON SUBMIT
    return (
        <Modal
            id="talentFeedbackModal"
            show={helperState.show_modal}
            className="talent-feedback-modal"
            onHide={() => {
                dispatch(helper.showModal(!helperState.show_modal));
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Help Us improve the Platform</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="comment">Questions, bug reports, feedback — we’re here for it all.</label>
                <textarea
                    rows="5"
                    cols="30"
                    id="comment"
                    name="comment"
                    className="form-control"
                    placeholder="Have feedback? We’d love to hear it."
                ></textarea>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn-action btn-text btn-transparent"
                    onClick={() => {
                        dispatch(helper.showModal(!helperState.show_modal));
                    }}
                >
                    Close
                </button>
                <button
                    type="submit"
                    className="btn-action btn-text btn-green mr-0"
                    onClick={() => {
                        dispatch(helper.showModal(!helperState.show_modal));
                    }}
                >
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default FeedbackModal;
