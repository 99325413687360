import React from "react";
import { useSelector } from "react-redux";

function AdminNavbar() {
    const {
        helper: { admin_nav_bar },
        authentication: {
            commonData: { menu },
        },
    } = useSelector((state) => state);

    return (
        <nav
            id="navigation"
            className="d-print-none"
            style={{ marginTop: "15px" }}
        >
            <ul
                id="responsive"
                className="d-print-none"
            >
                {menu.map((el) => {
                    return (
                        <li
                            key={el.name}
                            className={admin_nav_bar === el.name ? "active" : ""}
                        >
                            <a
                                href={el.url}
                                className={el.items && "has-children"}
                            >
                                {el.name}
                            </a>
                            {el.items && (
                                <ul className="dropdown-nav">
                                    {el.items.map((el) => (
                                        <li key={el.name}>
                                            <a href={el.url}>{el.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}

export default AdminNavbar;
