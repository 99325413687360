import React from "react";

export default function Select({
    name,
    value,
    options,
    children,
    required,
    onChange,
    className,
    disabledValue,
    ...props
}) {
    return (
        <div className={`form-group ${className ? className : ""}`}>
            <label
                htmlFor={name}
                className="mb-2"
            >
                {children} {required && <span className="required">*</span>}
            </label>
            <select
                name={name}
                value={value}
                required={!!required}
                className="form-control dropdown-form"
                onChange={(e) => onChange(name, e.target.value)}
                {...props}
            >
                {disabledValue && (
                    <option
                        value=""
                        disabled
                    >
                        Select...
                    </option>
                )}
                {options?.map((el, idx) => (
                    <option
                        key={idx}
                        value={el.value}
                    >
                        {el.label}
                    </option>
                ))}
            </select>
        </div>
    );
}
