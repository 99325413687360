import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";

import "./index.scss";

const CandidateSuitability = ({ recommendations }) => {
    const items = useMemo(() => Object.entries(recommendations ?? {}), [recommendations]);

    if (isNil(items) || isEmpty(items) || Array.isArray(items)) {
        return <div className="candidate-suitability-empty">No suggestions available yet.</div>;
    }

    return (
        <table className="candidate-suitability">
            <thead>
                <tr>
                    <th>Requirement</th>
                    <th>Matching Quality</th>
                    <th>Improvement Suggestion</th>
                </tr>
            </thead>

            <tbody>
                {items?.map(([key, item]) => (
                    <tr key={key}>
                        <td>{item?.requirement_text || "-"}</td>
                        <td>{item?.matching_quality || "-"}</td>
                        <td>{item?.improvement_suggestion || "-"}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default CandidateSuitability;
