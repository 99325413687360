import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState, useEffect } from "react";

import { STATUS } from "@app/enums";
import api from "@app/services/api";
import { updatePosition } from "@app/actions/Admin/Positions";
import { Select, AdvancedEditor, PositionSnippet } from "@app/components";

import "./index.scss";

const MoveAction = ({ state, handleChange, close = false }) => {
    const { statuses } = useSelector((state) => state.admin.positions);

    const filteredStatuses = statuses.filter(
        (status) =>
            !(status.name === "Cancelled" && status.group === "POSITION") &&
            !(status.name === "Declined" && status.group === "POSITION"),
    );

    const [declineOptions, setDeclineOptions] = useState([]);

    // Phases options and remove phase without id
    const phases = React.useMemo(
        () =>
            filteredStatuses
                .map((el) => ({
                    value: el.id,
                    label: el.name,
                }))
                .filter((el) => el.value),
        [filteredStatuses],
    );

    React.useEffect(() => {
        api.get("common-data/decline-reasons?type=position_close").then(({ data }) => {
            const options = data?.data?.map((o) => ({
                value: o.id,
                label: o.name,
            }));

            setDeclineOptions(options);
        });
    }, []);

    return (
        <div className="move">
            {!Boolean(close) && (
                <Select
                    required
                    name="status_id"
                    options={phases}
                    disabled={close}
                    onChange={handleChange}
                    value={state.status_id || ""}
                >
                    Please select the phase you want to move this position to.
                </Select>
            )}

            {Boolean(close) && (
                <Select
                    required
                    name="close_reason_id"
                    onChange={handleChange}
                    options={declineOptions}
                    value={state.close_reason_id}
                    disabledValue={!Boolean(state.close_reason_id)}
                >
                    Please select the reason for closing this position.
                </Select>
            )}

            <div className="mt-3">
                <label className="mb-2">Note</label>
                <AdvancedEditor
                    name="note"
                    value={state.note}
                    onChange={(_, editor) => {
                        handleChange("note", editor.getData());
                    }}
                />
            </div>
        </div>
    );
};

export default React.memo(function MovePositionModal({ position, handleClose, close = false, positionIframe = false }) {
    const dispatch = useDispatch();

    const { filters, statuses } = useSelector((state) => state.admin.positions);

    const closedStatus = useMemo(() => statuses?.find((s) => s.code === STATUS.CODE.POS_CANCELED)?.id, [statuses]);

    const [state, setState] = useState({
        note: "",
        close_reason_id: "",
        status_id: close ? closedStatus : filters.selectedFilter,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            status_id: close ? closedStatus : filters.selectedFilter,
        }));
    }, [close, closedStatus, filters.selectedFilter]);

    const handleStateChange = (name, value) => {
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updatePosition(position.id, state));

        handleClose();
    };

    return (
        <Modal
            size="lg"
            show={true}
            onHide={() => handleClose(true)}
            className={positionIframe ? "position-action-modal" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Are you sure you want to continue with this action?</p>
                </Modal.Title>
            </Modal.Header>

            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <div
                        className="col-md-8 d-flex"
                        style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                    >
                        <PositionSnippet position={position} />
                    </div>

                    <div className="devider mb-3 pb-0" />

                    <MoveAction
                        state={state}
                        close={close}
                        handleChange={handleStateChange}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        onClick={() => handleClose(true)}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className="btn-action btn-text btn-green mr-0"
                    >
                        Proceed
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});
