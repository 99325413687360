import Parser from "./parser";

export default class Query {
    url() {
        return this.get();
    }

    // set the model for the query
    for(model) {
        this.model = model;

        return this;
    }

    /**
     * Set the sorting rules. If multiple rules provided, order is important!
     *
     * It accepts multiple sorting rules, each rule
     * should follow this pattern `{dir}{col}`.
     *
     * `{dir}` can be one of `'-'` for `DESC`, or `'+'` for `ASC`.
     *
     * `{col}` can be any valid column name.
     *
     * @param {String|String[]} value
     * @returns {Query}
     */
    sort(...args) {
        this.sorts = args;

        return this;
    }

    /**
     * Apply the given callback only if the given condition is `true`.
     *
     * @param {Boolean} condition
     * @param {function(Query):Query} callback
     *
     * @returns {Query}
     */
    when(condition, callback) {
        if (Boolean(condition) === true) {
            callback(this);
        }

        return this;
    }

    /**
     * Appends the the given values as params to the pagination response.
     *
     * @param  {String|String[]} values
     * @returns {Query}
     */
    appends(...values) {
        if (!values.length) {
            throw new Error("The `appends()` function takes at least one argument.");
        }

        this.append = values;

        return this;
    }

    parseQuery() {
        if (!this.model) {
            throw new Error("Please call the for() method before adding filters or calling url() / get().");
        }

        return `${this.parser.parse()}`;
    }

    /**
     * Include the given relations to the response.
     *
     * @param  {String|String[]} values
     * @returns {Query}
     */
    includes(...values) {
        if (!values.length) {
            throw new Error("The `includes()` function takes at least one argument.");
        }

        this.include = values;

        return this;
    }

    /**
     * Set the current pagination page.
     *
     * @param {Number} value
     * @returns {Query}
     */
    page(value) {
        if (!Number.isInteger(value)) {
            throw new Error("The page() function takes a single argument of a number");
        }

        this.pageValue = value;

        return this;
    }

    /**
     * Set the pagination page size.
     *
     * @param {Number} value
     * @returns {Query}
     */
    limit(value) {
        if (!Number.isInteger(value)) {
            throw new Error("The limit() function takes a single argument of a number.");
        }

        this.limitValue = value;

        return this;
    }

    /**
     * Define the transformers that should be applied on the response.
     *
     * @param  {String|String[]} values
     * @returns {Query}
     */
    transforms(...values) {
        if (!values.length) {
            throw new Error("The `transforms()` function takes at least one argument.");
        }

        this.transformers = values;

        return this;
    }

    // return the parsed url
    get() {
        // generate the url
        const url = this.base_url ? this.base_url + this.parseQuery() : this.parseQuery();

        // reset the url so the query object can be re-used
        this.reset();

        return url;
    }

    /**
     * Add extra query params that shouldn't be parsed.
     *
     * @param {Object} params
     * @returns {Query}
     */
    params(params) {
        if (params === undefined || params.constructor !== Object) {
            throw new Error("The params() function takes a single argument of an object.");
        }

        this.paramsObj = params;

        return this;
    }

    reset() {
        // Reset the query params
        this.include = [];
        this.transformers = [];
        this.append = [];
        this.sorts = [];
        this.fields = {};
        this.filters = {};
        this.pageValue = null;
        this.limitValue = null;
        this.paramsObj = null;

        // reset the uri
        this.parser.uri = "";
    }

    /**
     * Apply a `where` clause to the given key-value pair.
     *
     * @param {String} key
     * @param {String} value
     * @returns {Query}
     */
    where(key, value) {
        if (key === undefined || value === undefined)
            throw new Error("The where() function takes 2 arguments both of string values.");

        if (Array.isArray(value) || value instanceof Object)
            throw new Error(
                "The second argument to the where() function must be a string. Use whereIn() if you need to pass in an array.",
            );

        this.filters[key] = value;

        return this;
    }

    /**
     * Apply a `whereNull` clause to the given values.
     *
     * @param {String|String[]} value
     * @returns {Query}
     */
    whereNull(...values) {
        if (!values.length) throw new Error("The whereNull() function expects at least 1 argument.");

        const currentValue = this.filters[this.queryParameters.nullableScope] || null;

        if (currentValue)
            throw new Error(
                "You should group all `whereNull()` into a single call and send all values as array instead.",
            );

        this.filters[this.queryParameters.nullableScope] = values.join(",");

        return this;
    }

    /**
     * Apply a `whereNotNull` clause to the given values.
     *
     * @param {String|String[]} value
     * @returns {Query}
     */
    whereNotNull(...values) {
        if (!values.length) throw new Error("The whereNotNull() function expects at least 1 argument.");

        const currentValue = this.filters[this.queryParameters.notNullableScope] || null;

        if (currentValue)
            throw new Error(
                "You should group all `whereNotNull()` into a single call and send all values as array instead.",
            );

        this.filters[this.queryParameters.notNullableScope] = values.join(",");

        return this;
    }

    /**
     * Apply a `whereIn` clause to the given key-value pair.
     *
     * @param {String} key
     * @param {String[]} value
     * @returns {Query}
     */
    whereIn(key, array) {
        if (!key || !array) {
            throw new Error("The whereIn() function takes 2 arguments of (string, array).");
        }

        if ((!key && Array.isArray(key)) || typeof key === "object") {
            throw new Error("The first argument for the whereIn() function must be a string or integer.");
        }

        if (!Array.isArray(array)) {
            throw new Error("The second argument for the whereIn() function must be an array.");
        }

        this.filters[key] = array.join(",");

        return this;
    }

    /**
     * Define which properties should be selected in the response.
     * This also applies to the included relations when passed as
     * an object with the key as the **relation table name** and
     * the value as an array of all columns to be selected.
     *
     * @param  {String|String[]|Object} values
     * @returns {Query}
     */
    select(...values) {
        if (!values.length) {
            throw new Error("The `select()` function takes a single argument of an array.");
        }

        // single entity .fields(['age', 'firstname'])
        if (values[0].constructor === String || Array.isArray(values[0])) {
            this.fields[this.model] = values.join(",");
        }

        // related entities .fields({ posts: ['title', 'content'], user: ['age', 'firstname']} )
        if (values[0].constructor === Object) {
            Object.entries(values[0]).forEach(([key, value]) => {
                this.fields[key] = value.join(",");
            });
        }

        return this;
    }

    constructor(options = {}) {
        // the model to execute the query against
        // set by calling .for(model)
        this.model = null;

        // will use base_url if passed in
        this.base_url = options.base_url || null;

        // default filter names
        this.queryParameters = {
            page: "page",
            sort: "sort",
            limit: "limit",
            fields: "fields",
            append: "append",
            filters: "filter",
            include: "include",
            transforms: "transforms",
            nullableScope: "nullable",
            notNullableScope: "not_nullable",
            ...(options.queryParameters || {}),
        };

        // initialize variables to hold
        // the urls data
        this.include = [];
        this.transformers = [];
        this.append = [];
        this.sorts = [];
        this.fields = {};
        this.filters = {};
        this.pageValue = null;
        this.limitValue = null;
        this.paramsObj = null;

        this.parser = new Parser(this);
    }
}
