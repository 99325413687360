import { contractors } from "@app/actions";

const initialState = {
    contractors: [],
};

export default function contractorsReducer(state = initialState, action) {
    switch (action.type) {
        case contractors.GET_CONTRACTORS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contractors.GET_CONTRACTORS_SUCCESS: {
            return {
                ...state,
                contractors: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case contractors.GET_CONTRACTORS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        default: {
            return state;
        }
    }
}
