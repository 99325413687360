/**
 * Generate a consistent color based on the given name
 * character codes against the available colors.
 *
 * If no `name` was provided, it will return a random color.
 */
export const randomColor = (name = null) => {
    const colors = [
        "#f44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFC107",
        "#FF9800",
        "#FF5722",
    ];

    if (!name) return "#CCC";

    const nameCodes = name?.split("")?.reduce((acc, cur) => {
        acc += cur?.charCodeAt(0);

        return acc;
    }, 0);

    const index = nameCodes % colors?.length;

    return colors[index];
};
