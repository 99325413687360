import { helper } from "@app/actions";

const initialState = {
    nav_tab: "",
    sidebar_tab: "",
    show_modal: false,
    admin_nav_bar: "",
    contracts_details_tab: "Overview",
    company_settings_tab: "Company Details",
};

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case helper.SHOW_MODAL:
            return {
                ...state,
                show_modal: action.payload.show_modal,
            };
        case helper.SET_SIDEBAR_TAB:
            return {
                ...state,
                sidebar_tab: action.payload.tab,
            };
        case helper.COMPANY_SETTINGS_NAV_BAR:
            return {
                ...state,
                company_settings_tab: action.payload.tab,
            };
        case helper.CONTRACTS_DETAILS_NAV_BAR:
            return {
                ...state,
                contracts_details_tab: action.payload.tab,
            };
        case helper.SET_NAV_TAB:
            return {
                ...state,
                nav_tab: action.payload.tab,
            };
        case helper.ADMIN_NAV_BAR:
            return {
                ...state,
                admin_nav_bar: action.payload.tab,
            };
        default: {
            return state;
        }
    }
}
