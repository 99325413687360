import React from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Sync the given state with search params.
 *
 * @param {string} paramKey
 * @param {any} defaultValue
 * @param get
 * @param set
 *
 * @returns {[string,(function(*): void)|*]}
 */
export const useSearchableFilter = (paramKey, defaultValue = "", get = null, set = null) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const param = React.useMemo(() => {
        const val = searchParams.has(paramKey) ? searchParams?.get(paramKey) : defaultValue;

        return get?.(val) || val;
    }, [get, paramKey, searchParams, defaultValue]);

    const setParam = React.useCallback(
        (newValue) => {
            const updatedValue = set?.(newValue) || newValue;

            // Fix multiple calls to `setSearchParams` bugs
            // https://github.com/remix-run/react-router/issues/9757
            const _params = new URLSearchParams(window.location.search);

            _params.set(paramKey, updatedValue);

            setSearchParams(Object.fromEntries(_params), {
                replace: true,
            });
        },
        [set, paramKey, setSearchParams],
    );

    return [param, setParam];
};
