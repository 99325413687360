import React from "react";

export default function NotAuthorized() {
    return (
        <div className="dashboard-content-container">
            <div className="un-authorized">
                <div className="vertical-horizontal-center">
                    <div className="px-2 border-right text-gray-500 text-lg">401</div>
                    <div className="px-2 text-gray-500 text-lg">Unauthorized</div>
                </div>
            </div>
        </div>
    );
}
