export const generateFormdata = (json) => {
    const formData = new FormData();

    Object.entries(json).forEach((el) => {
        const [key, val] = el;
        if (val) {
            if (Array.isArray(val)) {
                for (const element of val) {
                    formData.append(key, element);
                }
                return;
            }
            formData.append(key, val);
        }
    });
    return formData;
};

export const generateFormDataPatch = (body) => {
    let formData = [];
    Object.entries(body).forEach((el) => {
        const [key, val] = el;
        if (val) {
            if (Array.isArray(val)) {
                for (const element of val) {
                    const encodedKey = encodeURIComponent(key);
                    const encodedValue = encodeURIComponent(element);
                    formData.push(encodedKey + "=" + encodedValue);
                }
                return;
            }
        }
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(body[key]);
        formData.push(encodedKey + "=" + encodedValue);
    });
    formData = formData.join("&");
    return formData;
};

export const removeDuplicatesByKey = (array, key) => {
    const keys = array.map((el) => el[key]);
    return array.filter((el, index) => !keys.includes(el[key], index + 1));
};

export const setSelectedProp = (data, checked) => {
    return data.map((el) => ({
        ...el,
        _selected: checked,
    }));
};
