import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = { data: props.data };
    }

    shouldComponentUpdate = (nextProps) => {
        return !isEqual(nextProps.data, this.state.data);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (!isEqual(nextProps.data, this.state.data)) {
            this.setState({ data: nextProps.data });
        }
    };

    render() {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { data, ...rest } = this.props;
        return (
            <DropdownTreeSelect
                data={this.state.data}
                {...rest}
            />
        );
    }
}
