import React, { useState } from "react";

export default function SkillsCol({ max, skills }) {
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow(true);
    };

    return (
        <ul
            style={{ maxWidth: "100%", padding: "5px 0px" }}
            className={show ? "dev-skills small expanded" : "dev-skills small"}
        >
            {skills.map((el, idx) => (
                <li key={idx}>
                    <p>{el.name}</p>
                </li>
            ))}
            {skills.length > max && (
                <li
                    onClick={handleClick}
                    className="show-all-skills"
                >
                    <p>+{skills.length - max}</p>
                </li>
            )}
        </ul>
    );
}
