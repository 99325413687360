import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TALENT } from "@app/routes";

import CalendarIntegrationModal from "../CalendarIntegrationModal";

import "./index.scss";

const CalendarIntegration = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const { calendarLogo, selectedCalendar } = useSelector((state) => state.talent.interviews);

    const navigateToGuide = () => navigate(TALENT.INTERVIEWS_GUIDE_PATH);

    if (selectedCalendar?.is_connected) {
        return null;
    }

    return (
        <div className="calendar-integration-wrapper">
            <button
                onClick={() => setShowModal(true)}
                className="btn-action btn-icon me-2"
            >
                <i className="icon-calendar grey me-2"></i>

                <span className="integrate-text">Integrate with </span>

                <img
                    alt=""
                    width="50"
                    height="100%"
                    className="ms-1"
                    src={calendarLogo}
                />
            </button>

            <button
                title="How to integrate?"
                onClick={navigateToGuide}
                className="btn-action btn-icon m-0 px-2 flex-fill"
            >
                <i className="icon-faq m-0"></i>
            </button>

            {showModal && <CalendarIntegrationModal handleClose={() => setShowModal(false)} />}
        </div>
    );
};

export default CalendarIntegration;
