import axios from "axios";

import properties from "@app/properties";
import { getAuthorizationHeader } from "@app/services/request/helpers";

const instance = axios.create({
    withXSRFToken: true,
    withCredentials: true,
    baseURL: properties.API_HOST,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...getAuthorizationHeader(),
    },
});

export default instance;
