import React from "react";

export default function Pagination({ last_page, currentPage, setPagination }) {
    const MAX_PAGES = 10;
    const pages = () => {
        const pages = [];
        let from = currentPage - Math.floor(MAX_PAGES / 2);
        if (from < 1) {
            from = 1;
        }
        let to = from + MAX_PAGES - 1;
        if (to > last_page) {
            to = last_page;
        }
        while (from <= to) {
            pages.push(from);
            from++;
        }
        return pages;
    };

    const handleEvent = (page) => {
        setPagination(page);
    };

    const nextPage = () => {
        if (last_page === currentPage) {
            return;
        }
        setPagination(currentPage + 1);
        return currentPage + 1;
    };

    const previousPage = () => {
        if (currentPage === 1) {
            return;
        }
        setPagination(currentPage - 1);
        return currentPage - 1;
    };

    return (
        <div>
            <ul className="pagination pb-5 pt-0 mt-0">
                <li
                    id="DataTables_Table_0_previous"
                    className="paginate_button page-item previous"
                >
                    <button
                        className="page-link"
                        onClick={previousPage}
                    >
                        Previous
                    </button>
                </li>
                {pages().map((el) => (
                    <li
                        key={el}
                        className={
                            el === currentPage ? "paginate_button page-item active" : "paginate_button page-item"
                        }
                    >
                        <button
                            className="page-link"
                            onClick={() => handleEvent(el)}
                        >
                            {el}
                        </button>
                    </li>
                ))}
                <li
                    id="DataTables_Table_0_next"
                    className="paginate_button page-item next"
                >
                    <button
                        onClick={nextPage}
                        className="page-link"
                    >
                        Next
                    </button>
                </li>
            </ul>
        </div>
    );
}
