import React from "react";
import shortUUID from "short-uuid";
import { useSelector } from "react-redux";
import AddNewLanguage from "./AddNewLanguage";
import LanguagePreference from "./LanguagePreference";

import { useTalentWorkPreferences } from "@app/hooks";

import "./index.scss";

const NEW_LANG = {
    id: "",
    language: "",
    proficiency: "",
};

function LanguagesPreference() {
    const { state, updateState, updateErrors } = useTalentWorkPreferences();

    const { user_languages } = useSelector((state) => state.talent.account);

    const addLanguage = () => {
        updateState("languages", [...state?.languages, { ...NEW_LANG, id: shortUUID.generate() }]);
    };

    const updateLanguage = (updatedId, data) => {
        updateState(
            "languages",
            state?.languages?.map((l) => {
                if (l.id === updatedId) {
                    return {
                        ...l,
                        ...data,
                    };
                }

                return l;
            }),
        );
    };

    const removeLanguage = (deletedId) => {
        state?.languages?.forEach((l, i) => {
            updateErrors(`languages.${i}.language`, null);
            updateErrors(`languages.${i}.proficiency`, null);
        });

        updateState(
            "languages",
            state?.languages?.filter((pref) => pref.id !== deletedId),
        );
    };

    React.useEffect(() => {
        const defaultLanguages = user_languages?.map((lang) => ({
            id: lang.id,
            language: lang?.language_id,
            proficiency: lang?.language_proficiency_id,
        }));

        updateState("languages", defaultLanguages);
    }, [updateState, user_languages]);

    return (
        <div className="language-preference">
            {state?.languages?.map((lang, i) => (
                <LanguagePreference
                    index={i}
                    key={lang.id}
                    preference={lang}
                    onUpdate={updateLanguage}
                    onRemove={removeLanguage}
                />
            ))}

            <AddNewLanguage onClick={addLanguage} />
        </div>
    );
}

export default LanguagesPreference;
