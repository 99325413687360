import api from "@app/services/api";
import { formatDate } from "@app/helpers";

export const FILTER_DATA_INVOICES = "FILTER_DATA_INVOICES";

export const TIMELOGS_CLEAR_UP = "TIMELOGS_CLEAR_UP";
export const clearUpTimelogs = () => ({
    type: TIMELOGS_CLEAR_UP,
});

export const TIMELOGS_CLEAR_ACTION = "TIMELOGS_CLEAR_ACTION";
export const clearAction = () => ({
    type: TIMELOGS_CLEAR_ACTION,
});

export const FILTER_DATA_TIMELOGS = "FILTER_DATA_TIMELOGS";
export const filterDataTimelogs = (data) => {
    return {
        payload: data,
        type: FILTER_DATA_TIMELOGS,
    };
};

export const TIMELOGS_SEARCH_DATA = "TIMELOGS_SEARCH_DATA";
export const timelogsSearchData = (keyword) => {
    return {
        keyword,
        type: TIMELOGS_SEARCH_DATA,
    };
};

export const CONTRACTOR_TIMELOGS_SEARCH_DATA = "CONTRACTOR_TIMELOGS_SEARCH_DATA";
export const contractorTimelogsSearchData = (keyword) => {
    return {
        keyword,
        type: CONTRACTOR_TIMELOGS_SEARCH_DATA,
    };
};

export const TIMELOGS_SET_DATE_TO_FILTER = "TIMELOGS_SET_DATE_TO_FILTER";
export const setDateToFilter = (dateToFilter) => {
    return {
        payload: dateToFilter,
        type: TIMELOGS_SET_DATE_TO_FILTER,
    };
};

export const SET_SEARCH_KEYS_TIMELOGS = "SET_SEARCH_KEYS_TIMELOGS";
export const setSearchKeys = (keys) => ({
    payload: keys,
    type: SET_SEARCH_KEYS_TIMELOGS,
});

export const GET_TIMELOGS_BEGIN = "GET_TIMELOGS_BEGIN";
export const getTimelogsBegin = () => ({
    type: GET_TIMELOGS_BEGIN,
});

export const GET_TIMELOGS_SUCCESS = "GET_TIMELOGS_SUCCESS";
export const getTimelogsSuccess = (data) => ({
    payload: data,
    type: GET_TIMELOGS_SUCCESS,
});

export const GET_TIMELOGS_FAILED = "GET_TIMELOGS_FAILED";
export const getTimelogsFailed = (error) => ({
    payload: error,
    type: GET_TIMELOGS_FAILED,
});

export const GET_TIMELOGS = "GET_TIMELOGS";
export const getTimelogs = (clientId, selection) => {
    return async (dispatch) => {
        try {
            dispatch(getTimelogsBegin());
            const response = await api.get(
                `clients/${clientId}/time-logs?start_date=${formatDate(selection[0].startDate)}&end_date=${formatDate(
                    selection[0].endDate,
                )}`,
            );
            dispatch(getTimelogsSuccess(response.data));
        } catch (error) {
            dispatch(getTimelogsFailed(error));
        }
    };
};

export const GET_TIMELOGS_FOR_USER_BEGIN = "GET_TIMELOGS_FOR_USER_BEGIN";
export const getTimelogsForUserBegin = () => ({
    type: GET_TIMELOGS_FOR_USER_BEGIN,
});

export const GET_TIMELOGS_FOR_USER_SUCCESS = "GET_TIMELOGS_FOR_USER_SUCCESS";
export const getTimelogsForUserSuccess = (data) => ({
    payload: data,
    type: GET_TIMELOGS_FOR_USER_SUCCESS,
});

export const GET_TIMELOGS_FOR_USER_FAILED = "GET_TIMELOGS_FOR_USER_FAILED";
export const getTimelogsForUserFailed = (error) => ({
    payload: error,
    type: GET_TIMELOGS_FOR_USER_FAILED,
});

export const GET_TIMELOGS_FOR_USER = "GET_TIMELOGS_FOR_USER";
export const getTimelogsForUser = (clientId, userId, selection) => {
    return async (dispatch) => {
        try {
            dispatch(getTimelogsForUserBegin());
            const response = await api.get(
                `clients/${clientId}/users/${userId}/time-logs?start_date=${formatDate(
                    selection[0].startDate,
                )}&end_date=${formatDate(selection[0].endDate)}`,
            );
            dispatch(getTimelogsForUserSuccess(response.data));
        } catch (error) {
            dispatch(getTimelogsForUserFailed(error));
        }
    };
};
