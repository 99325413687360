import { ERROR } from "@app/enums";
import * as account from "@app/actions/Talent/Account";

const initialState = {
    location: null,
    user_languages: [],
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case account.CLEAR_ERRORS: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        }

        /**
         * BEGIN ACTIONS
         */
        case account.GET_ACCOUNT_PREFERENCES_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }

        /**
         * FAILED ACTIONS
         */
        case account.SET_ACCOUNT_PREFERENCES_FAILED:
        case account.GET_ACCOUNT_PREFERENCES_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    message: "",
                    isLoading: false,
                    isSuccess: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : ERROR.DEFAULT_MESSAGE,
                },
            };
        }

        /**
         * SUCCESS ACTIONS
         */
        case account.SET_ACCOUNT_PREFERENCES_SUCCESS:
        case account.GET_ACCOUNT_PREFERENCES_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }

        /**
         * DEFAULT ACTION
         */
        default: {
            return state;
        }
    }
}
