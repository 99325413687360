import Select from "react-select";
import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNoteTypes, getRatingPerNote } from "@app/actions/Talent/Interviews";

import "./index.scss";

const SelectNoteType = () => {
    const dispatch = useDispatch();
    const { interview, noteTypes } = useSelector((state) => state.talent.interviews);

    const transformNoteToOption = ([id, type]) => ({
        value: id,
        label: type,
    });

    const noteOptions = useMemo(
        () => Object.entries(noteTypes)?.map((note) => transformNoteToOption(note)),
        [noteTypes],
    );

    const handleChange = ({ value }) => dispatch(getRatingPerNote(interview?.applicant_id, value));

    useEffect(() => {
        dispatch(
            getNoteTypes({
                interviewerId: interview?.interviewer_id,
            }),
        );
    }, [dispatch, interview?.interviewer_id]);

    return (
        <div className="select-note-type">
            <label className="label">Interview Type</label>

            <div className="select-wrapper">
                <Select
                    isSearchable
                    options={noteOptions}
                    onChange={handleChange}
                    placeholder="What you are interviewing for?"
                />
            </div>
        </div>
    );
};

export default SelectNoteType;
