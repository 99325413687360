import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { FEAT_NEW_TALENT_AVAILABILITY_ENABLED } from "@app/config/features";
import { Header, Feature, Sidebar, AvailabilityModal } from "@app/components";

const LayoutMiddleware = () => {
    const { is_talent = false } = useSelector((state) => state.authentication.userProfile);

    return (
        <div className="dashboard-container">
            <Header />

            <Sidebar />

            <Outlet />

            {is_talent && (
                <Feature flag={FEAT_NEW_TALENT_AVAILABILITY_ENABLED}>
                    <AvailabilityModal />
                </Feature>
            )}
        </div>
    );
};

export default LayoutMiddleware;
