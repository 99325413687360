import sortBy from "lodash/sortBy";
import { Modal } from "react-bootstrap";
import { TextareaAutosize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState, useEffect } from "react";

import { moveToPhase, declineMatch, addMatchNote, getEmailTemplates } from "@app/actions/Admin/Matches";
import {
    WithLoader,
    FormSelect,
    AlertComponent,
    ContractorCard,
    AdvancedEditor,
    PositionSnippet,
} from "@app/components";

import "./index.scss";

const MAX_CHAR_COUNT = 5000;

const MoveAction = ({
    state,
    matchId,
    handleChange,
    sendEmailClient,
    sendEmailCandidate,
    setSendEmailClient,
    setSendEmailCandidate,
}) => {
    const dispatch = useDispatch();
    const { userProfile } = useSelector((state) => state.authentication);
    const { filters, movePositionData } = useSelector((state) => state.admin.matches);

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const checkIsOnboarded = ["bY43L03VrX", "rvXpGb9y8G"].includes(state.status_id);

    const phases = useMemo(
        () =>
            sortBy(
                filters
                    .map((el) => ({
                        label: el.name,
                        value: el.hash,
                    }))
                    .filter((el) => el.value),
                "order",
            ),
        [filters],
    );

    const renderClientEmailPrev = () => {
        if (sendEmailClient)
            return (
                <div className="mt-3">
                    <label>Client Email Preview</label>
                    {!movePositionData.accountManager && (
                        <div className="mb-1">
                            <AlertComponent
                                width={100}
                                infoMessage={` There’s no account manager specified for this client, so email can not be sent automatically.`}
                            />
                        </div>
                    )}
                    {!movePositionData.hiringManager && (
                        <div className="mb-1">
                            <AlertComponent
                                width={100}
                                infoMessage={`There’s no hiring manager specified for this position, so email can not be sent automatically.
            `}
                            />
                        </div>
                    )}
                    {movePositionData?.client && (
                        <div
                            className="email-content"
                            dangerouslySetInnerHTML={{ __html: movePositionData?.client }}
                        />
                    )}
                </div>
            );
    };

    useEffect(() => {
        if (movePositionData) {
            const users = movePositionData?.users?.map((el) => {
                return {
                    value: el.id,
                    label: el.full_name,
                };
            });

            setUsers(users);
        }
    }, [movePositionData]);

    useEffect(() => {
        if (matchId && state?.status_id && state?.user_id) {
            setIsLoading(true);

            dispatch(getEmailTemplates(matchId, state.status_id, state.user_id)).finally(() => setIsLoading(false));
        }
    }, [dispatch, matchId, state.status_id, state.user_id]);

    return (
        <div className="move">
            <FormSelect
                required
                name="status_id"
                optionsData={phases}
                value={state.status_id}
                placeholder="Select..."
                onChange={(e) => handleChange("status_id", e?.value || e?.target?.value)}
            >
                Please select the phase you want to move this candidate to.
            </FormSelect>

            <WithLoader isLoading={isLoading}>
                {movePositionData && (
                    <>
                        <label
                            className="d-flex align-center"
                            style={{ cursor: "pointer", maxWidth: "200px" }}
                        >
                            <input
                                type="checkbox"
                                checked={sendEmailCandidate}
                                style={{ cursor: "pointer", marginRight: "10px" }}
                                onChange={() => setSendEmailCandidate(!sendEmailCandidate)}
                            />
                            Send email to candidate
                        </label>

                        {/* If the admin selects Phase: “Contract Preps“ or “Onboarded“ show the section below*/}
                        {checkIsOnboarded && (
                            <label
                                className="d-flex align-center"
                                style={{ cursor: "pointer", maxWidth: "200px" }}
                            >
                                <input
                                    type="checkbox"
                                    checked={sendEmailClient}
                                    style={{ cursor: "pointer", marginRight: "10px" }}
                                    onChange={() => setSendEmailClient(!sendEmailClient)}
                                />
                                Send email to client
                            </label>
                        )}

                        {sendEmailCandidate && (
                            <div className="mt-2">
                                {/* Show the dropdown only if Admin has role "Super Admin" */}
                                {/* ************************** */}
                                {movePositionData.is_super_admin && (
                                    <div className="form-group">
                                        <label
                                            className="mb-2"
                                            htmlFor="user_id"
                                        >
                                            Send candidate email on behalf of:
                                        </label>
                                        <select
                                            name="user_id"
                                            value={state.user_id}
                                            className="form-control dropdown-form"
                                            onChange={(e) => handleChange("user_id", e.target.value)}
                                        >
                                            <option value={userProfile.id}>Myself</option>
                                            {users?.map((el, idx) => (
                                                <option
                                                    key={idx}
                                                    value={el.value}
                                                >
                                                    {el.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {/* ************************** */}
                                <div className="mt-4">
                                    <label>Candidate Email Preview</label>
                                    <div
                                        className="email-content"
                                        dangerouslySetInnerHTML={{ __html: movePositionData?.candidate }}
                                    />
                                </div>
                            </div>
                        )}

                        {/* If the admin selects Phase: “Contract Preps“ or “Onboarded“ show the section below*/}
                        {checkIsOnboarded && renderClientEmailPrev()}
                    </>
                )}
            </WithLoader>

            <div className="mt-3">
                <label className="mb-2">Note</label>

                <AdvancedEditor
                    name="note"
                    value={state.note}
                    onChange={(_, editor) => handleChange("note", editor.getData())}
                />
            </div>
        </div>
    );
};

const DeclineAction = ({ state, handleChange, declineReasons }) => {
    return (
        <div className="decline">
            <div className="mb-3">
                <AlertComponent
                    width={100}
                    infoMessage={` Proceeding will mark this candidate as unqualified for the selected position. They will be considered as failed at the current stage.`}
                />
            </div>

            <FormSelect
                required
                name="reason_id"
                value={state.reason_id}
                placeholder="Select..."
                optionsData={declineReasons}
                onChange={(e) => handleChange("reason_id", e?.value || e?.target?.value)}
            >
                Reason for declining
            </FormSelect>

            <label
                className="d-flex align-center"
                style={{ cursor: "pointer", maxWidth: "100px" }}
            >
                <input
                    type="checkbox"
                    checked={state?.send_email}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    onChange={() => handleChange("send_email", !state?.send_email)}
                />
                Send Email
            </label>

            {state?.send_email && (
                <div className="mt-2">
                    <label>Email Content</label>
                    <i>
                        Greeting and salutation are automatically added to the email, don't add them to the content
                        below.
                    </i>
                    <TextareaAutosize
                        minRows={1}
                        className="mt-2"
                        name="email_content"
                        maxLength={MAX_CHAR_COUNT}
                        value={state.email_content}
                        onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                        }}
                    />
                </div>
            )}
            <div className="mt-2">
                <label className="mb-2">Note</label>
                <AdvancedEditor
                    name="note"
                    value={state.content}
                    onChange={(e, editor) => {
                        handleChange("note", editor.getData());
                    }}
                />
            </div>
        </div>
    );
};

export default function MoveMatchModal({ match, onClose, decline, addNote, onSubmit }) {
    const dispatch = useDispatch();

    const { id } = useSelector((state) => state.authentication.userProfile);
    const { decline_reasons } = useSelector((state) => state.authentication.commonData);
    const { filters, paginationMatches } = useSelector((state) => state.admin.matches);

    const [addNoteState] = useState("");
    const [sendEmailClient, setSendEmailClient] = useState(false);
    const [sendEmailCandidate, setSendEmailCandidate] = useState(false);

    const [moveState, setMoveState] = useState({
        note: "",
        user_id: id,
        status_id: "",
    });

    const [declineState, setDeclineState] = useState({
        note: "",
        reason_id: "",
        send_email: false,
        email_content: "",
    });

    const submitMove = () => {
        const data = {
            ...moveState,
            match_id: match?.id,
            send_email_to_client: sendEmailClient ? "1" : "0",
            user_id: sendEmailCandidate ? moveState?.user_id : "",
            send_email_to_candidate: sendEmailCandidate ? "1" : "0",
        };

        return dispatch(moveToPhase(match.id, data));
    };

    const submitDecline = () => {
        const data = { ...declineState, send_email: declineState?.send_email ? "1" : "0" };

        if (!declineState?.send_email) {
            delete data.email_content;
        }

        return dispatch(declineMatch(match.id, data));
    };

    const submitAddNote = () => {
        const data = {
            note: addNoteState,
            match_id: match?.id,
        };

        return dispatch(addMatchNote(match.id, data));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (decline) {
            await submitDecline();
        } else if (addNote) {
            await submitAddNote();
        } else {
            await submitMove();
        }

        onClose();

        onSubmit?.(match?.id);
    };

    const handleMoveState = (key, value) => {
        setMoveState((prev) => ({ ...prev, [key]: value }));
    };

    const handleChangeByKey = (key, value) => {
        setDeclineState((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        const selectedFilterIndex = filters?.findIndex((el) => el?.name === paginationMatches?.selectedFilter);

        const selectedFilterValue =
            filters.length - 1 === selectedFilterIndex ? 0 : filters[selectedFilterIndex + 1]?.hash;

        handleMoveState("status_id", selectedFilterValue);
    }, [filters, paginationMatches?.selectedFilter]);

    useEffect(() => {
        if (declineState?.reason_id) {
            const declineEmailContent = decline_reasons
                ?.filter((el) => el.value === parseInt(declineState?.reason_id))
                ?.at(0);

            setDeclineState((prev) => ({
                ...prev,
                email_content: declineEmailContent?.email_content?.replace("[position_name]", match?.position?.name),
            }));
        }
    }, [declineState?.reason_id, decline_reasons, match?.position?.name]);

    return (
        <Modal
            size="lg"
            show={true}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Are you sure you want to continue with this action?</p>
                </Modal.Title>
            </Modal.Header>

            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <div className="row">
                        <ContractorCard
                            contractor={match.user}
                            className="col-12 col-md-6"
                        />

                        <PositionSnippet
                            position={match.position}
                            className="col-12 col-md-6"
                        />
                    </div>

                    {match?.user?.preferred_communication && (
                        <label className="py-2">
                            <span>Preferred communication channel: </span>

                            <span
                                style={{ fontSize: "12px" }}
                                className="preferred-communication"
                            >
                                {match.user.preferred_communication}
                            </span>
                        </label>
                    )}

                    <div className="devider mb-3 pb-0" />

                    {decline ? (
                        <DeclineAction
                            state={declineState}
                            declineReasons={decline_reasons}
                            handleChange={handleChangeByKey}
                        />
                    ) : (
                        <MoveAction
                            state={moveState}
                            matchId={match?.id}
                            sendEmailClient={sendEmailClient}
                            sendEmailCandidate={sendEmailCandidate}
                            setSendEmailClient={setSendEmailClient}
                            setSendEmailCandidate={setSendEmailCandidate}
                            handleChange={(key, value) => handleMoveState(key, value)}
                        />
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className="btn-action btn-text btn-green mr-0"
                    >
                        Proceed
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
