import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Badge, Avatar } from "@mui/material";
import Flags from "country-flag-icons/react/3x2";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { mainHostUrl } from "@app/helpers";
import FallbackAvatar from "@app/components/FallbackAvatar";
import PieChart from "@app/pages/client/Dashboard/PieChart";
import checkMark from "@app/styles/assets/img/assets/white_checkmark_green_bg.svg";

import { useStyles } from "../style";

import "./index.scss";

const statusClasses = {
    New: "tag-text primary",
    Accepted: "tag-text active",
    Closed: "tag-text archived",
    Incomplete: "tag-text gray",
    "On-Hold": "tag-text yellow",
    "On Hold": "tag-text yellow",
    Declined: "tag-text declined",
    Rejected: "tag-text declined",
    "Initial Call": "tag-text purple",
    "Tech Interview": "tag-text mint",
    "Initial Call Scheduled": "tag-text pink-ghost",
    "Tech Interview Scheduled": "tag-text pale-blue",
};

const ContractorCard = ({ contractor, showSkills, className = "" }) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleCopy = async (text) => {
        try {
            await navigator?.clipboard?.writeText?.(text);
            setOpenTooltip(true);
            setTimeout(() => setOpenTooltip(false), 1000);
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    if (!contractor) return null;

    const Flag = Flags[contractor?.contact?.country_code];

    return (
        <div className={`contractor-card ${className}`}>
            <div
                className="align-center"
                style={{ marginTop: "5px" }}
            >
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    style={contractor?.adeva_score?.totalScore ? { marginRight: "15px" } : { marginRight: "10px" }}
                    badgeContent={
                        contractor?.profile_ready && (
                            <SmallAvatar
                                src={checkMark}
                                alt="Small CheckMark"
                            />
                        )
                    }
                >
                    <HtmlTooltip
                        title={
                            contractor?.adeva_score?.totalScore && (
                                <div className="card-hover">
                                    <div className="total-points">
                                        <span style={{ fontWeight: 600 }}>Total Score</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="dev-total-points">
                                                {contractor?.adeva_score?.totalScore}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-option border-bottom">
                                        <div className="card-subtitle align-items-baseline">
                                            <span className="cultural-fit-title">Cultural Fit</span>
                                            <div className="d-flex align-items-baseline">
                                                <span className="dev-partial-points">
                                                    {contractor?.adeva_score?.hrScore}
                                                </span>{" "}
                                                <span className="dev-max-points">/100</span>
                                            </div>
                                        </div>
                                        <LinearProgress
                                            variant="determinate"
                                            className={classes.progresBarColor}
                                            value={parseInt(contractor?.adeva_score?.hrScore)}
                                        />
                                    </div>
                                    <div className="card-option">
                                        <div className="card-subtitle align-items-baseline">
                                            <span className="tech-score-title">Tech Score</span>
                                            <div className="d-flex align-items-baseline">
                                                <span className="dev-partial-points">
                                                    {contractor?.adeva_score?.techScore}
                                                </span>
                                                <span className="dev-max-points">/100</span>
                                            </div>
                                        </div>
                                        <LinearProgress
                                            variant="determinate"
                                            value={parseInt(contractor?.adeva_score?.techScore)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            badgeContent={
                                contractor?.adeva_score?.totalScore ? (
                                    <PieChart
                                        AvatarChart
                                        colors={["#198CCA", "#FDB436", "#ADB4C7"]}
                                        totalScore={Number(contractor?.adeva_score?.totalScore)}
                                        data={[
                                            {
                                                name: "totalTechScorePercent",
                                                value: Number(contractor?.adeva_score?.techPercentage),
                                            },
                                            {
                                                name: "totalHrScorePercent",
                                                value: Number(contractor?.adeva_score?.hrPercentage),
                                            },
                                            {
                                                name: "totalRemainingScore",
                                                value: 100 - Number(contractor?.adeva_score?.totalScore),
                                            },
                                        ]}
                                    />
                                ) : (
                                    ""
                                )
                            }
                        >
                            <FallbackAvatar
                                alt={contractor?.first_name}
                                src={contractor?.custom_avatar_thumbnail}
                            />
                        </Badge>
                    </HtmlTooltip>
                </Badge>
            </div>

            <div>
                <div className="d-flex align-center">
                    <a
                        className="main-text"
                        style={{ display: "inline", cursor: "pointer" }}
                        href={mainHostUrl(`admin/talent/applicants/${contractor?.id}`)}
                    >
                        {contractor?.full_name ?? contractor?.fullName}
                    </a>

                    {contractor?.contact?.country_code ? (
                        <Tippy content={contractor?.contact?.country}>
                            <span>
                                <Flag className="country-flag ml-1" />
                            </span>
                        </Tippy>
                    ) : (
                        ""
                    )}
                </div>
                <Tooltip
                    open={openTooltip}
                    title="Email copied"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={() => setOpenTooltip(false)}
                    onOpen={() => handleCopy(contractor?.email)}
                >
                    <p
                        className="second-text"
                        onClick={() => handleCopy(contractor?.email)}
                        style={{ fontSize: "12px", cursor: "pointer", lineHeight: "20px", marginBottom: "3px" }}
                    >
                        {contractor?.email}
                    </p>
                </Tooltip>
                <div className="d-flex align-center">
                    {Boolean(contractor?.current_status) && (
                        <p
                            style={{ fontSize: "12px", marginRight: "4px", lineHeight: "16px" }}
                            className={
                                contractor?.is_declined
                                    ? "tag-text declined"
                                    : statusClasses[contractor?.current_status] ?? "tag-text fallback"
                            }
                        >
                            {contractor?.is_declined ? "Declined" : contractor?.current_status}
                        </p>
                    )}
                    {Boolean(contractor?.is_prospect) && (
                        <p
                            className={statusClasses["Incomplete"] ?? "tag-text fallback"}
                            style={{ fontSize: "12px", marginRight: "4px", lineHeight: "16px" }}
                        >
                            Prospect
                        </p>
                    )}
                    <div className="badge-compact">
                        <FallbackAvatar
                            size="xs"
                            style={{ marginRight: "5px" }}
                            sx={{ width: 13, height: 13 }}
                            alt={contractor?.owner?.first_name}
                            src={contractor?.owner?.custom_avatar_thumbnail}
                        />
                        {contractor?.owner?.first_name} {contractor?.owner?.last_name?.charAt(0)}.
                    </div>
                </div>

                {showSkills && contractor?.skills && contractor?.skills.length !== 0 && (
                    <ul
                        style={{ marginTop: "1px" }}
                        className={show ? "dev-skills small expanded" : "dev-skills small"}
                    >
                        {contractor?.skills.map((el, idx) => (
                            <li key={idx}>
                                <p>{typeof el === "object" ? el?.skillName : el}</p>
                            </li>
                        ))}
                        {contractor?.skills.length > 4 && (
                            <li
                                className="show-all-skills"
                                onClick={(e) => handleClick(e)}
                            >
                                <p>+{contractor?.skills.length - 4}</p>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 13,
    height: 13,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
    },
}));

export default ContractorCard;
