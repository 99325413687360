import Fuse from "fuse.js";

import { invoicesAndTimeoff } from "@app/actions";

const initialState = {
    data: [],
    filteredData: [],
    searchKeyword: "",
    filterStatuses: [],
    invoiceAttachments: null,
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    fuseOptions: {
        keys: [],
        threshold: 0,
    },
    action: {
        id: null,
        error: null,
        message: "",
        isSuccess: null,
        isLoading: false,
        actionName: null,
    },
};

const setSelctedVal = (data, checked) => {
    return data.map((el) => ({
        ...el,
        _selected: checked,
    }));
};

const sortData = (filteredData, key) => {
    return filteredData.sort((a, b) => new Date(b[key]) - new Date(a[key]));
};

const filterData = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child[el.key]);
        if (el.key === "date") {
            if (dataToFilter.length === 0) return;
            if (dataToFilter.includes("Past") && dataToFilter.includes("Upcoming")) return;
            if (dataToFilter.includes("Upcoming"))
                result = result.filter((item) => new Date(item.end_date) > new Date());
            if (dataToFilter.includes("Past")) result = result.filter((item) => new Date(item.end_date) < new Date());
        }
        if (el.key === "status") {
            if (dataToFilter.length === 0) return;
            result = result.filter((item) => dataToFilter.includes(item[el.key]));
        }
    });
    return result;
};

const defaultSelectedVal = (_reMap) => {
    const defaultSelected = _reMap
        .filter((el) => ["Pending"].includes(el.status))
        .map((el) => ({ ...el, _selected: true }));
    return defaultSelected.concat(_reMap.filter((el) => !["Pending"].includes(el.status)));
};

export default function invoicesAndTimeOffReducer(state = initialState, action) {
    switch (action.type) {
        case invoicesAndTimeoff.CLEAR_ACTION: {
            const _checkedFalse = setSelctedVal(state.data, false);
            return {
                ...state,
                data: [..._checkedFalse],
                filteredData: [..._checkedFalse],
                action: {
                    ...initialState.action,
                },
            };
        }
        case invoicesAndTimeoff.SET_SEARCH_KEYS_INVOICES_AND_TIMEOFF: {
            return {
                ...state,
                fuseOptions: {
                    ...state.fuseOptions,
                    keys: action.payload,
                },
            };
        }
        case invoicesAndTimeoff.INVOICES_TIMEOFF_SEARCH_DATA: {
            const filteredData = filterData(state.filterStatuses, state.data, state.filteredData);
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...filteredData],
            };
        }
        case invoicesAndTimeoff.FILTER_DATA_INVOICES: {
            const filteredData = filterData(action.payload, state.data, state.filteredData);
            return {
                ...state,
                filteredData: filteredData,
                filterStatuses: action.payload,
                fuseSearch: new Fuse(filteredData, state.fuseOptions),
            };
        }
        case invoicesAndTimeoff.SET_DATA_TO_RENDER_INVOICES_AND_TIMEOFF: {
            return {
                ...state,
                data: action.payload,
                action: initialState.action,
                filteredData: action.payload,
            };
        }
        case invoicesAndTimeoff.GET_INVOICES_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICES_SUCCESS: {
            const _reMap = setSelctedVal(action.payload.data.invoices, false);
            return {
                ...state,
                data: [..._reMap],
                filteredData: [..._reMap],
                fuseSearch: new Fuse(action.payload.data.invoices, state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICES_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_SUCCESS: {
            const _reMap = defaultSelectedVal(
                sortData(setSelctedVal(action.payload.data["time-offs"], false), "requested_date"),
            );
            const filteredData = filterData(
                [
                    {
                        key: "status",
                        selectAll: false,
                        parent: "Status",
                        children: [..._reMap],
                    },
                    {
                        key: "date",
                        parent: "Date",
                        selectAll: false,
                        children: [
                            { date: "Upcoming", _selected: false },
                            { date: "Past", _selected: false },
                        ],
                    },
                ],
                _reMap,
            );
            return {
                ...state,
                data: [..._reMap],
                filteredData: [...filteredData],
                fuseSearch: new Fuse([..._reMap], state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_PDF_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    id: action.payload,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_PDF_SUCCESS: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isSuccess: true,
                    isLoading: false,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_PDF_FAILED: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_ATTACHMENTS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_ATTACHMENTS_SUCCESS: {
            return {
                ...state,
                invoiceAttachments: action.payload.data,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_INVOICE_ATTACHMENTS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_BY_USER_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_BY_USER_SUCCESS: {
            const sortedData = sortData(action.payload.data["time-offs"], "requested_date");
            return {
                ...state,
                data: sortedData,
                filteredData: sortedData,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case invoicesAndTimeoff.GET_TIME_OFF_BY_USER_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        default: {
            return state;
        }
    }
}
