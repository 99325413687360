import { authentication } from "@app/actions";

const initialState = {
    error: null,
    company: null,
    permissions: [],
    isLoading: true,
    commonData: null,
    isLoggedIn: false,
    userProfile: null,
    currentRole: null,
    hasPermission: false,
    connectionEstablished: false,
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case authentication.PUBLIC_ROUTE_REQUESTED:
            return {
                ...state,
                isLoading: false,
                connectionEstablished: true,
            };
        case authentication.ESTABLISH_CONNECTION_BEGIN:
            return {
                ...state,
                isLoading: true,
            };

        case authentication.ESTABLISH_CONNECTION_SUCCESS:
            return {
                ...state,
                connectionEstablished: true,
                isLoggedIn: action?.payload?.data?.user_logged_in || false,
            };

        case authentication.ESTABLISH_CONNECTION_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                error: action.payload,
                connectionEstablished: true,
            };
        case authentication.GET_COMMON_DATA_SUCCESS:
            return {
                ...state,
                commonData: action.payload.data,
            };
        case authentication.GET_COMMON_DATA_FAILED:
            return {
                ...initialState,
                error: action.payload,
            };
        case authentication.CLEAR_ACTION:
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        case authentication.FETCH_USER_BEGIN:
            return {
                ...state,
                isLoading: true,
            };
        case authentication.FETCH_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                userProfile: action.payload,
                company: action.payload?.companies[0] || null,
                permissions: action.payload?.companies[0]?.allowed_sections || [],
            };
        case authentication.FETCH_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                error: action?.payload?.error || "Unknown error",
            };
        case authentication.UPDATE_USER_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case authentication.UPDATE_USER_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case authentication.UPDATE_USER_FAILED:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case authentication.LOGOUT_BEGIN:
            return {
                ...state,
                isLoading: true,
            };
        case authentication.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
            };
        case authentication.LOGOUT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case authentication.SET_COMPANY:
            return {
                ...state,
                company: action.payload,
            };
        case authentication.CURRENT_ROLE_SWITCHED:
            return {
                ...state,
                currentRole: action.payload,
            };
        case authentication.SET_PERMISSION:
            return {
                ...state,
                hasPermission: action.payload,
            };
        default: {
            return state;
        }
    }
}
