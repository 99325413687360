import api from "@app/services/api";
import { generateFormdata, generateFormDataPatch } from "@app/helpers";
import { getTimeOff } from "@app/actions/Client/InvoicesAndTimeOff/invoicesAndTimeOff";

export const SET_DATA_TO_RENDER = "SET_DATA_TO_RENDER";
export const setDataToRender = (dataToRender) => {
    return {
        payload: dataToRender,
        type: SET_DATA_TO_RENDER,
    };
};

export const CONTRACTS_SORT_DATA = "CONTRACTS_SORT_DATA";
export const sortDataByDate = () => ({
    type: CONTRACTS_SORT_DATA,
});

export const CONTRACTS_SEARCH_DATA = "CONTRACTS_SEARCH_DATA";
export const contractsSearchData = (keyword) => {
    return {
        keyword,
        type: CONTRACTS_SEARCH_DATA,
    };
};

export const SET_SEARCH_KEYS = "SET_SEARCH_KEYS";
export const setSearchKeys = (keys) => {
    return {
        payload: keys,
        type: SET_SEARCH_KEYS,
    };
};

export const SET_DATE_TO_FILTER = "SET_DATE_TO_FILTER";
export const setDateToFilter = (dateToFilter) => {
    return {
        payload: dateToFilter,
        type: SET_DATE_TO_FILTER,
    };
};

export const SET_ACTIVITY = "SET_ACTIVITY";
export const setActivity = (activity) => {
    return {
        payload: activity,
        type: SET_ACTIVITY,
    };
};

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});

export const GROUP_EDIT_SUCCESS = "GROUP_EDIT_SUCCESS";
export const groupEditSuccess = (data) => ({
    payload: data,
    type: GROUP_EDIT_SUCCESS,
});

export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS";
export const groupDeleteSuccess = (data) => ({
    payload: data,
    type: GROUP_DELETE_SUCCESS,
});

export const FILTER_DATA_CONTRACTS = "FILTER_DATA_CONTRACTS";
export const filterDataContracts = (item) => {
    return {
        payload: item,
        type: FILTER_DATA_CONTRACTS,
    };
};

export const SET_EQUIPMENT_ATTRIBUTE = "SET_EQUIPMENT_ATTRIBUTE";
export const setEquipmentAttribute = (key, value) => {
    return {
        key,
        value,
        type: SET_EQUIPMENT_ATTRIBUTE,
    };
};

//=========>>>>API-EndPoints<<<==========

export const GET_CONTRACT_DETAILS_BEGIN = "GET_CONTRACT_DETAILS_BEGIN";
export const getContractDetailsBegin = () => ({
    type: GET_CONTRACT_DETAILS_BEGIN,
});

export const GET_CONTRACT_DETAILS_SUCCESS = "GET_CONTRACT_DETAILS_SUCCESS";
export const getContractDetailsSuccess = (data) => ({
    payload: data,
    type: GET_CONTRACT_DETAILS_SUCCESS,
});

export const GET_CONTRACT_DETAILS_FAILED = "GET_CONTRACT_DETAILS_FAILED";
export const getContractDetailsFailed = (error) => ({
    payload: error,
    type: GET_CONTRACT_DETAILS_FAILED,
});

export const getContractDetails = (clientId, contractId) => {
    return async (dispatch) => {
        try {
            dispatch(getContractDetailsBegin());
            const response = await api.get(`clients/${clientId}/contracts/${contractId}`);
            dispatch(getContractDetailsSuccess(response.data));
            dispatch(sortDataByDate());
        } catch (error) {
            dispatch(getContractDetailsFailed(error));
        }
    };
};

export const TIME_OFF_BEGIN = "TIME_OFF_BEGIN";
export const timeOffBegin = () => ({
    type: TIME_OFF_BEGIN,
});

export const TIME_OFF_SUCCESS = "TIME_OFF_SUCCESS";
export const timeOffSuccess = (data) => ({
    payload: data,
    type: TIME_OFF_SUCCESS,
});

export const TIME_OFF_FAILED = "TIME_OFF_FAILED";
export const timeOffFailed = (error) => ({
    payload: error,
    type: TIME_OFF_FAILED,
});

export const timeOff = (clientId, timeOffId, body, contractId) => {
    return async (dispatch) => {
        try {
            dispatch(timeOffBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/time-offs/${timeOffId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(timeOffSuccess(response.data));
            contractId ? dispatch(getContractDetails(clientId, contractId)) : dispatch(getTimeOff(clientId));
        } catch (error) {
            dispatch(timeOffFailed(error));
        }
    };
};

export const GET_DOCUMENTS_BEGIN = "GET_DOCUMENTS_BEGIN";
export const getDocumentsBegin = () => ({
    type: GET_DOCUMENTS_BEGIN,
});

export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const getDocumentsSuccess = (data) => ({
    payload: data,
    type: GET_DOCUMENTS_SUCCESS,
});

export const GET_DOCUMENTS_FAILED = "GET_DOCUMENTS_FAILED";
export const getDocumentsFailed = (error) => ({
    payload: error,
    type: GET_DOCUMENTS_FAILED,
});

export const getDocuments = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getDocumentsBegin());
            const response = await api.get(`clients/${clientId}/documents`);
            dispatch(getDocumentsSuccess(response.data));
            dispatch(setDataToRender(response.data.data.documents.data));
            // dispatch(sortDataByDate());
        } catch (error) {
            dispatch(getDocumentsFailed(error));
        }
    };
};

export const UPDATE_DOCUMENT_NAME_BEGIN = "UPDATE_DOCUMENT_NAME_BEGIN";
export const updateDocumenNameBegin = () => ({
    type: UPDATE_DOCUMENT_NAME_BEGIN,
});

export const UPDATE_DOCUMENT_NAME_SUCCESS = "UPDATE_DOCUMENT_NAME_SUCCESS";
export const updateDocumenNameSuccess = (data) => ({
    payload: data,
    type: UPDATE_DOCUMENT_NAME_SUCCESS,
});

export const UPDATE_DOCUMENT_NAME_FAILED = "UPDATE_DOCUMENT_NAME_FAILED";
export const updateDocumenNameFailed = (error) => ({
    payload: error,
    type: UPDATE_DOCUMENT_NAME_FAILED,
});

export const updateDocumenName = (clientId, documentId, body) => {
    return async (dispatch) => {
        try {
            dispatch(updateDocumenNameBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/documents/${documentId}`, formData, {
                headers: {},
            });
            dispatch(updateDocumenNameSuccess(response.data));
        } catch (error) {
            dispatch(updateDocumenNameFailed(error));
        }
    };
};

export const GET_CONTRACTS_BEGIN = "GET_CONTRACTS_BEGIN";
export const getContractsBegin = () => ({
    type: GET_CONTRACTS_BEGIN,
});

export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const getContractsSuccess = (data) => ({
    payload: data,
    type: GET_CONTRACTS_SUCCESS,
});

export const GET_CONTRACTS_FAILED = "GET_CONTRACTS_FAILED";
export const getContractsFailed = (error) => ({
    payload: error,
    type: GET_CONTRACTS_FAILED,
});

export const getContracts = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getContractsBegin());
            const response = await api.get(`clients/${clientId}/contracts`);
            dispatch(getContractsSuccess(response.data));
        } catch (error) {
            dispatch(getContractsFailed(error));
        }
    };
};

export const GET_EQUIPMENT_BEGIN = "GET_EQUIPMENT_BEGIN";
export const getEquipmentBegin = () => ({
    type: GET_EQUIPMENT_BEGIN,
});

export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS";
export const getEquipmentSuccess = (data) => ({
    payload: data,
    type: GET_EQUIPMENT_SUCCESS,
});

export const GET_EQUIPMENT_FAILED = "GET_EQUIPMENT_FAILED";
export const getEquipmentFailed = (error) => ({
    payload: error,
    type: GET_EQUIPMENT_FAILED,
});

export const getEquipment = (clientId, equipmentId) => {
    return async (dispatch) => {
        try {
            dispatch(getEquipmentBegin());
            const response = await api.get(`clients/${clientId}/equipment/${equipmentId}`, {
                headers: {},
            });
            dispatch(getEquipmentSuccess(response.data));
        } catch (error) {
            dispatch(getEquipmentFailed(error));
        }
    };
};

export const DELETE_EQUIPMENT_BEGIN = "DELETE_EQUIPMENT_BEGIN";
export const deleteEquipmentBegin = () => ({
    type: DELETE_EQUIPMENT_BEGIN,
});

export const DELETE_EQUIPMENT_SUCCESS = "DELETE_EQUIPMENT_SUCCESS";
export const deleteEquipmentSuccess = (data) => ({
    payload: data,
    type: DELETE_EQUIPMENT_SUCCESS,
});

export const DELETE_EQUIPMENT_FAILED = "DELETE_EQUIPMENT_FAILED";
export const deleteEquipmentFailed = (error) => ({
    payload: error,
    type: DELETE_EQUIPMENT_FAILED,
});

export const deleteEquipment = (clientId, contractId, equipmentId) => {
    return async (dispatch) => {
        try {
            dispatch(deleteEquipmentBegin());
            const response = await api.delete(`clients/${clientId}/equipment/${equipmentId}`, {
                headers: {},
            });
            dispatch(deleteEquipmentSuccess(response.data));
            dispatch(getContractDetails(clientId, contractId));
        } catch (error) {
            dispatch(deleteEquipmentFailed(error));
        }
    };
};

export const ADD_EQUIPMENT_BEGIN = "ADD_EQUIPMENT_BEGIN";
export const addEquipmentBegin = () => ({
    type: ADD_EQUIPMENT_BEGIN,
});

export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS";
export const addEquipmentSuccess = (data) => ({
    payload: data,
    type: ADD_EQUIPMENT_SUCCESS,
});

export const ADD_EQUIPMENT_FAILED = "ADD_EQUIPMENT_FAILED";
export const addEquipmentFailed = (error) => ({
    payload: error,
    type: ADD_EQUIPMENT_FAILED,
});

export const addEquipment = (clientId, contractId, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(addEquipmentBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/contracts/${contractId}/equipment`, formData, {
                headers: {},
            });
            dispatch(addEquipmentSuccess(response.data));
            navigate(-1);
        } catch (error) {
            dispatch(addEquipmentFailed(error));
        }
    };
};

export const EDIT_EQUIPMENT_BEGIN = "EDIT_EQUIPMENT_BEGIN";
export const editEquipmentBegin = () => ({
    type: EDIT_EQUIPMENT_BEGIN,
});

export const EDIT_EQUIPMENT_SUCCESS = "EDIT_EQUIPMENT_SUCCESS";
export const editEquipmentSuccess = (data) => ({
    payload: data,
    type: EDIT_EQUIPMENT_SUCCESS,
});

export const EDIT_EQUIPMENT_FAILED = "EDIT_EQUIPMENT_FAILED";
export const editEquipmentFailed = (error) => ({
    payload: error,
    type: EDIT_EQUIPMENT_FAILED,
});

export const editEquipment = (clientId, contractId, equipmentId, body, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(editEquipmentBegin());
            const formData = generateFormDataPatch(body);
            const response = await api.patch(
                `clients/${clientId}/contracts/${contractId}/equipment/${equipmentId}`,
                formData,
                {
                    body: body,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                },
            );
            dispatch(editEquipmentSuccess(response.data));
            navigate(-1);
        } catch (error) {
            dispatch(editEquipmentFailed(error));
        }
    };
};

export const CHANGE_EQUIPMENT_STATUS_BEGIN = "CHANGE_EQUIPMENT_STATUS_BEGIN";
export const changeEquipmentStatusBegin = () => ({
    type: CHANGE_EQUIPMENT_STATUS_BEGIN,
});

export const CHANGE_EQUIPMENT_STATUS_SUCCESS = "CHANGE_EQUIPMENT_STATUS_SUCCESS";
export const changeEquipmentStatusSuccess = (data) => ({
    payload: data,
    type: CHANGE_EQUIPMENT_STATUS_SUCCESS,
});

export const CHANGE_EQUIPMENT_STATUS_FAILED = "CHANGE_EQUIPMENT_STATUS_FAILED";
export const changeEquipmentStatusFailed = (error) => ({
    payload: error,
    type: CHANGE_EQUIPMENT_STATUS_FAILED,
});

export const changeEquipmentStatus = (clientId, contractId, equipmentId, body) => {
    return async (dispatch) => {
        try {
            dispatch(changeEquipmentStatusBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/equipment/${equipmentId}`, formData, {
                headers: {},
            });
            dispatch(changeEquipmentStatusSuccess(response.data));
            dispatch(getContractDetails(clientId, contractId));
        } catch (error) {
            dispatch(changeEquipmentStatusFailed(error));
        }
    };
};

export const SUBMIT_FEEDBACK_BEGIN = "SUBMIT_FEEDBACK_BEGIN";
export const submitFeedbackBegin = () => ({
    type: SUBMIT_FEEDBACK_BEGIN,
});

export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const submitFeedbackSuccess = (data) => ({
    payload: data,
    type: SUBMIT_FEEDBACK_SUCCESS,
});

export const SUBMIT_FEEDBACK_FAILED = "SUBMIT_FEEDBACK_FAILED";
export const submitFeedbackFailed = (error) => ({
    payload: error,
    type: SUBMIT_FEEDBACK_FAILED,
});

export const submitFeedback = (clientId, contractId) => {
    return async (dispatch) => {
        try {
            dispatch(submitFeedbackBegin());
            const response = await api.get(`clients/${clientId}/contracts/${contractId}`);
            dispatch(submitFeedbackSuccess(response.data));
            dispatch(sortDataByDate());
        } catch (error) {
            dispatch(submitFeedbackFailed(error));
        }
    };
};
