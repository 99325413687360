import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { SmartLink } from "@app/components";

import "./index.scss";

const SidebarItem = ({ item, onClick }) => {
    const { sidebar_tab } = useSelector((state) => state.helper);
    const [open, setOpen] = useState(false);

    const toggleOpenState = () => setOpen((p) => !p);

    const handleItemClick = (e) => {
        if (item?.children) {
            e.preventDefault();
            return toggleOpenState();
        }

        // Toggle the sidebar on mobile screen
        onClick?.(e.target.name);

        return true;
    };

    const SidebarItemElement = item?.children ? "button" : SmartLink;
    const SidebarItemProps = item?.children ? {} : { linkText: item.display, leadingIconClassName: item.icon };

    useEffect(() => {
        setOpen(item?.children?.map((i) => i.display)?.includes(sidebar_tab) || sidebar_tab === item.display);
    }, [item?.children, item.display, sidebar_tab]);

    return (
        <li
            className={`${sidebar_tab === item.display ? "active" : ""} ${item?.children ? "parent-item" : ""} ${open ? "open" : ""}`}
            style={{
                marginTop: item?.hasMarginTop ? "40px" : "0",
                borderTop: item?.hasBorderTop ? "1px solid #E1E3EB" : "none",
            }}
        >
            {item?.children && <i className="icon-arrow-right-s dropdown-icon"></i>}

            <SidebarItemElement
                to={item.target}
                name={item.display}
                onClick={handleItemClick}
                className={`sidebar-item-link button-container ${item.renderTitleFirst ? "justify-content-between pr-0 flex-row-reverse" : ""}`}
                {...SidebarItemProps}
            >
                {item?.icon && <i className={item?.icon}></i>}
                <span>{item?.display}</span>
            </SidebarItemElement>

            {item?.children && (
                <ul>
                    {item?.children?.map((child) => (
                        <SidebarItem
                            item={child}
                            key={child?.key}
                            onClick={onClick}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default SidebarItem;
