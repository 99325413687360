import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SupportedCalendars } from "@app/config";

import CalendarIntegrationModal from "../CalendarIntegrationModal";

import "./index.scss";

const Token = () => {
    const [showModal, setShowModal] = useState(false);
    const { selectedCalendar } = useSelector((state) => state.talent.interviews);

    if (
        !selectedCalendar ||
        !selectedCalendar?.is_connected ||
        SupportedCalendars.includes(selectedCalendar?.integration) === false
    ) {
        return null;
    }

    return (
        <div className="token">
            <span className="label">Token</span>

            <span className="secret">{selectedCalendar?.token}</span>

            <span className="separator">|</span>

            <button
                className="edit"
                onClick={() => setShowModal(true)}
            >
                <i
                    title="Edit"
                    className="fa fa-pencil"
                ></i>
            </button>

            {showModal && <CalendarIntegrationModal handleClose={() => setShowModal(false)} />}
        </div>
    );
};

export default Token;
