import React from "react";

export default function NotFound() {
    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content-inner generic-table mt-4 d-flex justify-content-center">
                <div className="text-center">
                    <h1 className="mb-3">Oops!</h1>

                    <p>It seems like you're lost. We can't find that page.</p>
                </div>
            </div>
        </div>
    );
}
