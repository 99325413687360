import React from "react";
import { useSelector } from "react-redux";

import { Loading } from "@app/components";
import TimerImage from "@app/styles/assets/svg/timer.svg";

import "./index.scss";

const UnsupportedIntegration = () => {
    const { isLoading } = useSelector((state) => state.talent.interviews.action);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="p-5 m-5 text-center">
            <img
                width={120}
                height="auto"
                src={TimerImage}
                alt="Timer icon"
            />
            <h4 className="mt-2 mb-3">Stay Tuned!</h4>
            <p>We'are working on improving the hiring experience for you 💪.</p>
        </div>
    );
};

export default UnsupportedIntegration;
