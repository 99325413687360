import api from "@app/services/api";

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const RESET_TABLE_ACTIONS = "RESET_TABLE_ACTIONS";
export const resetTableActions = () => ({
    type: RESET_TABLE_ACTIONS,
});

export const SET_SEARCH_KEYWORD_REFERRALS = "SET_SEARCH_KEYWORD_REFERRALS";
export const setSearchKeyword = (keyword) => {
    return {
        payload: keyword,
        type: SET_SEARCH_KEYWORD_REFERRALS,
    };
};

export const SET_PAGINATION = "SET_PAGINATION";
export const setPagination = (key, value) => ({
    key,
    value,
    type: SET_PAGINATION,
});

export const SET_SORT = "SET_SORT";
export const setSort = (orderColumn, orderDirection) => ({
    orderColumn,
    orderDirection,
    type: SET_SORT,
});

export const GET_REFERRALS_HISTORY_BEGIN = "GET_REFERRALS_HISTORY_BEGIN";
export const getReferralsHistoryBegin = () => ({
    type: GET_REFERRALS_HISTORY_BEGIN,
});

export const GET_REFERRALS_HISTORY_SUCCESS = "GET_REFERRALS_HISTORY_SUCCESS";
export const getReferralsHistorySuccess = (data) => ({
    payload: data,
    type: GET_REFERRALS_HISTORY_SUCCESS,
});

export const GET_REFERRALS_HISTORY_FAILED = "GET_REFERRALS_HISTORY_FAILED";
export const getReferralsHistoryFailed = (error) => ({
    payload: error,
    type: GET_REFERRALS_HISTORY_FAILED,
});

export const getReferralsHistory = (page, items, search, orderColumn, orderDirection) => {
    return async (dispatch) => {
        try {
            dispatch(getReferralsHistoryBegin());

            const params = new URLSearchParams({
                page: page,
                search: search,
                num_items: items,
            });

            if (orderColumn) {
                params.append("order_column", orderColumn);
            }

            if (orderDirection) {
                params.append("order_direction", orderDirection);
            }

            const response = await api.get(`referrals/history?${params.toString()}`);

            dispatch(getReferralsHistorySuccess(response.data?.data || []));
        } catch (error) {
            dispatch(getReferralsHistoryFailed(error));
        }
    };
};
