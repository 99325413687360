import { useRef, useState, useCallback } from "react";

export const useDatagridActions = (actionsByStatus) => {
    const [popUpToggle, setPopUpToggle] = useState(false);
    const anchorEl = useRef(null);
    const actions = useRef(null);
    const tableRow = useRef(null);

    const handlePopUpToggle = useCallback(
        (currentTarget, status, row) => {
            anchorEl.current = currentTarget;
            actions.current = actionsByStatus[status];
            tableRow.current = row;
            setPopUpToggle((prev) => currentTarget !== anchorEl.current || !prev);
        },
        [actionsByStatus],
    );

    return [handlePopUpToggle, setPopUpToggle, anchorEl, actions.current, tableRow.current, popUpToggle];
};
