import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import "./index.scss";

const ShareReferralSection = ({
    title,
    tooltip,
    buttons,
    inputRef,
    titleDescription,
    isClientTabOpened,
    isTalentTabOpened,
    handleCopyButtonClick,
    handleSocialButtonClick,
}) => {
    const { referral_link_client, referral_link_talent } = useSelector((state) => state.authentication.userProfile);
    const [defaultValue, setDefaultValue] = useState("");

    useEffect(() => {
        setDefaultValue(isClientTabOpened ? referral_link_client : isTalentTabOpened ? referral_link_talent : "");
    }, [isClientTabOpened, isTalentTabOpened, referral_link_client, referral_link_talent]);

    const tabContent = isClientTabOpened
        ? "Discover the power of global talent with Adeva. Sign up today and explore the world's leading independent talent network, ready to engage in your mission-critical initiatives and make a significant impact. Don't let talent scarcity hold you back."
        : isTalentTabOpened
          ? "Join Adeva, a leading independent talent network, and set yourself up for success by working with top companies. Elevate your career by being part of mission-critical projects and making an impactful contribution to the community."
          : "";

    return (
        <div className="share-referral-section">
            <div>
                <div className="title-wrapper mb-1">
                    <p className="mb-2">{title}</p>
                </div>

                <div className="share-wrapper mb-3">
                    <input
                        type="hidden"
                        ref={inputRef}
                        value={defaultValue}
                    />
                    <div className="link-field">{defaultValue}</div>
                    <button
                        type="button"
                        className="copy-btn"
                        onClick={handleCopyButtonClick}
                    >
                        {tooltip}
                    </button>
                </div>
            </div>
            <div className="d-block">
                <div>
                    {titleDescription && (
                        <p
                            className="mb-2"
                            style={{ fontWeight: 600, color: "#546285" }}
                        >
                            {titleDescription}
                        </p>
                    )}
                </div>
                <div className="social-buttons-wrapper">
                    {buttons.map((button, index) => (
                        <React.Fragment key={index}>
                            <button
                                type="button"
                                onClick={() => handleSocialButtonClick(button.text, defaultValue, tabContent)}
                                style={{
                                    width: button.text === "Email" ? "92px" : " ",
                                }}
                            >
                                {button.icon && (
                                    <i
                                        className={button.icon}
                                        style={{
                                            width: button.size.width,
                                            height: button.size.height,
                                            marginRight: button.text === "Email" ? "5px" : "0",
                                        }}
                                    ></i>
                                )}
                                {button.text && index === 0 && button.text}
                            </button>
                            {index === 0 && (
                                <span
                                    style={{
                                        margin: "0 5px",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                    }}
                                >
                                    or
                                </span>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ShareReferralSection;
