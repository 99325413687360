import React from "react";
import RadioCriteriaItem from "./Item";

const RadioCriteriaList = ({ criteria }) => {
    if (!criteria || Array.from(criteria || []).length === 0) return null;

    return (
        <div className="pt-3">
            {criteria
                ?.filter?.((criterion) => Boolean(criterion?.scorable) && Boolean(criterion?.value))
                ?.map((criterion) => (
                    <RadioCriteriaItem
                        key={criterion?.id}
                        criteria={criterion}
                    />
                ))}
        </div>
    );
};

export default RadioCriteriaList;
