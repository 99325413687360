import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminMenu = ({ onClick }) => {
    const navigate = useNavigate();
    const { sidebar_tab } = useSelector((state) => state.helper);
    const { permissions } = useSelector((state) => state.authentication);

    const shouldRender = () => permissions.includes("company-details");

    return (
        shouldRender() && (
            <ul data-submenu-title="Admin">
                <li className={sidebar_tab === "Company Settings" ? "active" : undefined}>
                    <a
                        name="Company Settings"
                        className="sidebar-item-link"
                        onClick={(event) => {
                            onClick(event.target.name);
                            navigate("/company-settings");
                        }}
                    >
                        <i className="icon-settings"></i>
                        <span>Company Settings</span>
                    </a>
                </li>
            </ul>
        )
    );
};

export default AdminMenu;
