import React, { useMemo } from "react";

const FilterWidget = React.memo(({ data, statuses, filterType, allFilterTypes, ordered = false, handleFilterType }) => {
    const orderedItems = useMemo(() => {
        const items = [...(filterType?.children || [])];

        if (!ordered) return items;

        items.sort((a, b) => a?.order - b?.order);

        return items;
    }, [ordered, filterType?.children]);

    const selectSingleItem = (e) => {
        filterType.selectAll = false;
        orderedItems[e.target.id]._selected = !orderedItems[e.target.id]._selected;
        handleFilterType(allFilterTypes, orderedItems[e.target.id]);
    };

    const checkPropertyLength = (status) => {
        if (Array.isArray(data)) return data?.filter((el) => el.status === status).length;

        return data[status] || 0;
    };

    const emptyStateStatus = () => {
        filterType.children.forEach((el) => {
            if (statuses.includes(el.status)) {
                statuses.splice(statuses.indexOf(el.status), 1);
            }
        });
        return statuses;
    };

    return (
        <div className="filter-widget-form responsive-btn">
            {orderedItems.map((el, index) => {
                return (
                    <div
                        key={index}
                        className={el._selected ? "widget active" : "widget"}
                    >
                        <p className="text-capitalize">{el?.display || el[filterType.key]}</p>
                        {filterType.key === "status" && <p className="tag-number">{checkPropertyLength(el.status)}</p>}
                        <button
                            id={index}
                            type="button"
                            onClick={selectSingleItem}
                            className="full-click-button"
                        />
                    </div>
                );
            })}
            {emptyStateStatus().map((el, idx) => {
                return (
                    <div
                        key={idx}
                        className="widget"
                    >
                        <p className="empty-state-text">{el}</p>
                    </div>
                );
            })}
        </div>
    );
});

export default FilterWidget;
