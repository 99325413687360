import Fuse from "fuse.js";

import { timelogs } from "@app/actions";
// import shortUUID from "short-uuid";

const initialState = {
    data: [],
    user: {},
    filteredData: [],
    dateToFilter: [],
    searchKeyword: "",
    contractorTimelogsData: [],
    contractorTimelogsFilteredData: [],
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    fuseOptions: {
        keys: [],
        threshold: 0,
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

const filterData = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child[el.key]);
        if (el.key === "status") {
            if (dataToFilter.length === 0) return;
            result = result.filter((item) => dataToFilter.includes(item[el.key]));
        }
    });
    return result;
};

export default function timelogsReduces(state = initialState, action) {
    switch (action.type) {
        case timelogs.TIMELOGS_CLEAR_UP: {
            return {
                ...initialState,
            };
        }
        case timelogs.TIMELOGS_CLEAR_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        }
        case timelogs.SET_SEARCH_KEYS_TIMELOGS: {
            return {
                ...state,
                fuseOptions: {
                    ...state.fuseOptions,
                    keys: action.payload,
                },
            };
        }
        case timelogs.TIMELOGS_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.data],
            };
        }
        case timelogs.CONTRACTOR_TIMELOGS_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                contractorTimelogsFilteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.contractorTimelogsData],
            };
        }
        case timelogs.FILTER_DATA_INVOICES: {
            return {
                ...state,
                filteredData: filterData(action.payload, state.data, state.filteredData),
            };
        }
        case timelogs.TIMELOGS_SET_DATE_TO_FILTER: {
            return {
                ...state,
                dateToFilter: action.payload,
            };
        }
        case timelogs.GET_TIMELOGS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case timelogs.GET_TIMELOGS_SUCCESS: {
            return {
                ...state,
                data: action.payload.data["time-logs"],
                filteredData: action.payload.data["time-logs"],
                fuseSearch: new Fuse(action.payload.data["time-logs"], state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case timelogs.GET_TIMELOGS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case timelogs.GET_TIMELOGS_FOR_USER_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case timelogs.GET_TIMELOGS_FOR_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload.data.user,
                contractorTimelogsData: action.payload.data["time-logs"],
                contractorTimelogsFilteredData: action.payload.data["time-logs"],
                fuseSearch: new Fuse(action.payload.data["time-logs"], state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case timelogs.GET_TIMELOGS_FOR_USER_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
