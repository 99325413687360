import React from "react";

import "./index.scss";

const ActionsMenu = ({ items, onClick }) => {
    return (
        <ul className="dropdown-menu">
            {items.map((item, index) => (
                <li key={index}>
                    <a
                        onClick={() => onClick(item)}
                        className="dropdown-item bulk-actions-decline"
                    >
                        {item.label}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default ActionsMenu;
