import React from "react";

import { ReferralStatus as ReferralStatusEnum } from "@app/enums";

const statusClasses = {
    [ReferralStatusEnum.EARNING]: "tag-text active",
    [ReferralStatusEnum.INVITED]: "tag-text primary",
    [ReferralStatusEnum.ACCEPTED]: "tag-text primary",
    [ReferralStatusEnum.DECLINED]: "tag-text declined",
    [ReferralStatusEnum.SIGNED_UP]: "tag-text primary",
};

const statusLabel = {
    [ReferralStatusEnum.EARNING]: "Earning",
    [ReferralStatusEnum.INVITED]: "Invited",
    [ReferralStatusEnum.ACCEPTED]: "Accepted",
    [ReferralStatusEnum.DECLINED]: "Declined",
    [ReferralStatusEnum.SIGNED_UP]: "Signed Up",
};

const ReferralStatus = ({ status, ...props }) => {
    return status ? (
        <p
            {...props}
            className={statusClasses?.[status] || ""}
        >
            {statusLabel?.[status] || status}
        </p>
    ) : null;
};

export default ReferralStatus;
