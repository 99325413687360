import React from "react";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";

// import FormSelect from "@app/components/New/molecules/formSelect";
import { useGeoLocation, useTalentWorkPreferences } from "@app/hooks";
import { RadioInput, StatesSelect, CountriesSelect } from "@app/components";

// const LOCATION_RADIUS = [
//   {
//     value: "km_10",
//     label: <span className="option-dark">10 KM</span>,
//   },
//   {
//     value: "km_20",
//     label: <span className="option-dark">20 KM</span>,
//   },
//   {
//     value: "km_30",
//     label: <span className="option-dark">30 KM</span>,
//   },
// ];

const LocationPreference = () => {
    const { getCurrentCountryByGPS } = useGeoLocation();
    const { state, errors, updateState, updateErrors } = useTalentWorkPreferences();
    const { contact, work_locations } = useSelector((state) => state.talent.account);

    const [isLoading, setIsLoading] = React.useState(true);

    const handleStateChange = React.useCallback(
        (value) => {
            updateErrors("location.state", null);

            updateState("location.state", value);
        },
        [updateErrors, updateState],
    );

    const handleCountryChange = React.useCallback(
        (value) => {
            updateErrors("location.country", null);

            updateState("location.country", value);
        },
        [updateErrors, updateState],
    );

    const handleOnSiteChange = React.useCallback(
        (value) => {
            updateErrors("location.onSite", null);

            updateState("location.onSite", value);
        },
        [updateErrors, updateState],
    );

    // const handleWorkLocationsChange = React.useCallback(
    //   (value) => {
    //     updateErrors("location.workLocations", null);

    //     updateState("location.workLocations", value);
    //   },
    //   [updateErrors, updateState]
    // );

    // const handleWorkLocationRadiusChange = React.useCallback(
    //   (value) => {
    //     updateErrors("location.workLocationRadius", null);

    //     updateState("location.workLocationRadius", value);
    //   },
    //   [updateErrors, updateState]
    // );

    const detectCountry = React.useCallback(
        (country, state) => {
            setIsLoading(true);

            if (country || state) {
                handleStateChange(state);
                handleCountryChange(country);

                setIsLoading(false);

                return;
            }

            // Otherwise, try to get the country code from the GeoLocation
            getCurrentCountryByGPS()
                .then((res) => {
                    const { countryName, adminName1 = "", adminName2 = "" } = res;

                    handleStateChange(adminName1 || adminName2);

                    handleCountryChange(countryName);
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
        },
        [getCurrentCountryByGPS, handleCountryChange, handleStateChange],
    );

    React.useEffect(() => {
        detectCountry(contact?.country, contact?.state);
    }, [detectCountry, contact?.country, contact?.state]);

    React.useEffect(() => {
        handleOnSiteChange(work_locations?.on_site);
    }, [handleOnSiteChange, work_locations?.on_site]);

    return (
        <div className="location-preference">
            <div className="preference">
                <label className="preference-label">What country are you located in?</label>

                <CountriesSelect
                    valueKey="nice_name"
                    disabled={isLoading}
                    error={errors?.["location.country"]}
                    value={state?.location?.country || ""}
                    placeholder={isLoading ? "Detecting your current location..." : ""}
                    onChange={(newVal) => {
                        handleStateChange("");
                        handleCountryChange(newVal);
                    }}
                />
            </div>

            <div className="preference">
                <StatesSelect
                    hideWhenEmpty
                    label="State"
                    valueKey="name"
                    disabled={isLoading}
                    value={state?.location?.state}
                    country={state?.location?.country}
                    error={errors?.["location.state"]}
                    onChange={(newVal) => handleStateChange(newVal)}
                    placeholder={isLoading ? "Detecting your current location..." : ""}
                />
            </div>

            <div className="preference">
                <label className="preference-label">Are you able to work onsite at the client's office?</label>

                <div
                    style={{ rowGap: 16 }}
                    className="d-flex flex-column"
                >
                    <RadioInput
                        onChange={() => handleOnSiteChange(false)}
                        checked={state?.location?.onSite === false}
                        label="No, I’m available only for remote work"
                    />

                    <RadioInput
                        onChange={() => handleOnSiteChange(true)}
                        checked={state?.location?.onSite === true}
                        label="Yes, I’m available for both onsite and remote work"
                    />
                </div>
            </div>

            {state?.location?.onSite === true && (
                <>
                    {/* <div className="preference mb-0">
            <div className="d-flex">
              <div className="flex-fill me-3">
                <label className="preference-label">I can work in</label>

                <Input
                  value={state?.location?.workLocations}
                  onChange={(e) => handleWorkLocationsChange(e.target.value)}
                />
              </div>

              <div style={{ width: 130 }}>
                <label className="preference-label">Extended to</label>

                <FormSelect
                  menuPortalTarget={document.body}
                  optionsData={LOCATION_RADIUS}
                  value={state?.location?.workLocationRadius}
                  onChange={(newVal) => handleWorkLocationRadiusChange(newVal.value)}
                />
              </div>
            </div>
          </div> */}

                    <Alert
                        severity="info"
                        sx={{ color: "#1A284D", lineHeight: "130%", fontFamily: "Inter, sans-serif" }}
                    >
                        You will be contacted for both remote projects and projects that take place onsite at a client's
                        office. Depending on the client, some remote work may be possible even for onsite roles.
                    </Alert>
                </>
            )}
        </div>
    );
};

export default LocationPreference;
