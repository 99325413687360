import api from "@app/services/api";
import QueryBuilder from "@app/services/query";

const refreshProspectsState = () => {
    return async (dispatch) => {
        dispatch(getProspectsStatuses());

        dispatch(getProspects());
    };
};

const performSearch = async (dispatch, field, keyword, filters, sort, pagination) => {
    const params = QueryBuilder.for("users")
        .includes(["latestProspectStatus", "owner", "analytics.invitedBy"])
        .select({
            statuses: ["id", "name"],
        })
        .when(keyword, (q) => q.where(field, keyword))
        .where("latestProspectStatus.status_id", filters?.selectedFilter)
        .limit(pagination?.pageSize)
        .page(pagination?.page)
        .sort(`${sort?.direction}${sort?.column}`)
        .get();

    const response = await api.get(`admin/prospects${params}`);

    return response.data;
};

export const SET_ADMIN_PROSPECTS_PAGINATION = "SET_ADMIN_PROSPECTS_PAGINATION";
export const setPagination = (payload) => ({
    payload,
    type: SET_ADMIN_PROSPECTS_PAGINATION,
});

export const SET_ADMIN_PROSPECTS_FILTERS = "SET_ADMIN_PROSPECTS_FILTERS";
export const setFilters = (key, value) => {
    return {
        key,
        value,
        type: SET_ADMIN_PROSPECTS_FILTERS,
    };
};

export const SET_ADMIN_PROSPECTS_SORTING = "SET_ADMIN_PROSPECTS_SORTING";
export const setSorting = (column, direction) => ({
    column,
    direction,
    type: SET_ADMIN_PROSPECTS_SORTING,
});

export const CLEAR_ADMIN_PROSPECTS_ACTION = "CLEAR_ADMIN_PROSPECTS_ACTION";
export const clearAction = () => ({
    type: CLEAR_ADMIN_PROSPECTS_ACTION,
});

export const CLEAR_ADMIN_PROSPECTS_STATE = "CLEAR_ADMIN_PROSPECTS_STATE";
export const clearState = () => ({
    type: CLEAR_ADMIN_PROSPECTS_STATE,
});

export const GET_ADMIN_PROSPECTS_STATUSES_BEGIN = "GET_ADMIN_PROSPECTS_STATUSES_BEGIN";
export const getProspectsStatusesBegin = () => ({
    type: GET_ADMIN_PROSPECTS_STATUSES_BEGIN,
});

export const GET_ADMIN_PROSPECTS_STATUSES = "GET_ADMIN_PROSPECTS_STATUSES";
export const getProspectsStatuses = () => {
    return async (dispatch) => {
        try {
            dispatch(getProspectsStatusesBegin());

            const response = await api.get(`admin/statuses/prospects`);

            dispatch(getProspectsStatusesSuccess(response.data.data));
        } catch (error) {
            dispatch(getProspectsStatusesFailed(error));
        }
    };
};

export const GET_ADMIN_PROSPECTS_STATUSES_SUCCESS = "GET_ADMIN_PROSPECTS_STATUSES_SUCCESS";
export const getProspectsStatusesSuccess = (data) => ({
    payload: data,
    type: GET_ADMIN_PROSPECTS_STATUSES_SUCCESS,
});

export const GET_ADMIN_PROSPECTS_STATUSES_FAILED = "GET_ADMIN_PROSPECTS_STATUSES_FAILED";
export const getProspectsStatusesFailed = (error) => ({
    payload: error,
    type: GET_ADMIN_PROSPECTS_STATUSES_FAILED,
});
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_ADMIN_PROSPECTS_BEGIN = "GET_ADMIN_PROSPECTS_BEGIN";
export const getProspectsBegin = () => ({
    type: GET_ADMIN_PROSPECTS_BEGIN,
});

export const GET_ADMIN_PROSPECTS = "GET_ADMIN_PROSPECTS";
export const getProspects = () => {
    return async (dispatch, getState) => {
        const { sort, filters, pagination } = getState()?.admin?.prospects;

        try {
            dispatch(getProspectsBegin());

            let prospectsData = await performSearch(
                dispatch,
                "search_by_name",
                filters?.search,
                filters,
                sort,
                pagination,
            );

            if (!prospectsData || prospectsData.data.length === 0) {
                prospectsData = await performSearch(
                    dispatch,
                    "search_by_invited_by",
                    filters?.search,
                    filters,
                    sort,
                    pagination,
                );
            }

            dispatch(getProspectsSuccess(prospectsData));
        } catch (error) {
            dispatch(getProspectsFailed(error));
        }
    };
};

export const GET_ADMIN_PROSPECTS_SUCCESS = "GET_ADMIN_PROSPECTS_SUCCESS";
export const getProspectsSuccess = (data) => ({
    payload: data,
    type: GET_ADMIN_PROSPECTS_SUCCESS,
});

export const GET_ADMIN_PROSPECTS_FAILED = "GET_ADMIN_PROSPECTS_FAILED";
export const getProspectsFailed = (error) => ({
    payload: error,
    type: GET_ADMIN_PROSPECTS_FAILED,
});

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const DELETE_ADMIN_PROSPECTS_BEGIN = "DELETE_ADMIN_PROSPECTS_BEGIN";
export const deleteProspectBegin = () => ({
    type: DELETE_ADMIN_PROSPECTS_BEGIN,
});

export const DELETE_ADMIN_PROSPECTS = "DELETE_ADMIN_PROSPECTS";
export const deleteProspect = (prospectId) => {
    return async (dispatch) => {
        try {
            dispatch(deleteProspectBegin());

            const response = await api.delete(`admin/prospects/${prospectId}`);

            dispatch(deleteProspectSuccess(response.data));

            dispatch(refreshProspectsState());
        } catch (error) {
            dispatch(deleteProspectFailed(error));
        }
    };
};

export const DELETE_ADMIN_PROSPECTS_SUCCESS = "DELETE_ADMIN_PROSPECTS_SUCCESS";
export const deleteProspectSuccess = (data) => ({
    payload: data,
    type: DELETE_ADMIN_PROSPECTS_SUCCESS,
});

export const DELETE_ADMIN_PROSPECTS_FAILED = "DELETE_ADMIN_PROSPECTS_FAILED";
export const deleteProspectFailed = (error) => ({
    payload: error,
    type: DELETE_ADMIN_PROSPECTS_FAILED,
});
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const UPDATE_ADMIN_PROSPECT_BEGIN = "UPDATE_ADMIN_PROSPECT_BEGIN";
export const updateProspectBegin = () => ({
    type: UPDATE_ADMIN_PROSPECT_BEGIN,
});

export const UPDATE_ADMIN_PROSPECT_SUCCESS = "UPDATE_ADMIN_PROSPECT_SUCCESS";
export const updateProspectSuccess = (data) => ({
    payload: data,
    type: UPDATE_ADMIN_PROSPECT_SUCCESS,
});

export const UPDATE_ADMIN_PROSPECT_FAILED = "UPDATE_ADMIN_PROSPECT_FAILED";
export const updateProspectFailed = (error) => ({
    payload: error,
    type: UPDATE_ADMIN_PROSPECT_FAILED,
});

export const UPDATE_ADMIN_PROSPECT = "UPDATE_ADMIN_PROSPECT";
export const updateProspect = (prospectId, data) => {
    return async (dispatch) => {
        try {
            dispatch(updateProspectBegin());

            const response = await api.patch(`admin/prospects/${prospectId}`, data);

            dispatch(updateProspectSuccess(response.data));

            dispatch(refreshProspectsState());
        } catch (error) {
            dispatch(updateProspectFailed(error));
        }
    };
};
