import React from "react";
import { Link } from "react-router-dom";

const SmartLink = ({ to, linkText, iconClassName, leadingIconClassName, ...rest }) => {
    const isAbsoluteUrl = /^http(s)?:\/\//.test(to);

    const linkContent = (
        <>
            {leadingIconClassName && <i className={leadingIconClassName} />}
            {linkText}
            {iconClassName && <i className={iconClassName} />}
        </>
    );

    return isAbsoluteUrl ? (
        <a
            href={to}
            {...rest}
        >
            {linkContent}
        </a>
    ) : (
        <Link
            to={to}
            {...rest}
        >
            {linkContent}
        </Link>
    );
};

export default SmartLink;
