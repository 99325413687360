import React from "react";

import { ModalsContext } from "@app/contexts";

const ModalsContextProvider = ({ children }) => {
    const [modals, setModals] = React.useState([]);

    const _findModal = React.useCallback((modalId) => modals?.find((m) => String(m?.id) === String(modalId)), [modals]);

    const _updateModal = React.useCallback(
        (modalId, newState) => {
            const targetModal = _findModal(modalId);

            if (!targetModal) return;

            setModals((prev) => [
                ...prev?.filter((m) => String(m?.id) !== String(modalId)),
                {
                    ...targetModal,
                    ...newState,
                },
            ]);
        },
        [_findModal],
    );

    const registerModal = (modalId) => {
        // Model already registered before
        if (_findModal(modalId)) return;

        setModals((prev) => [
            ...prev?.filter((m) => String(m?.id) !== String(modalId)),
            {
                id: modalId,
                show: false,
            },
        ]);
    };

    const unregisterModal = (modalId) => {
        const targetModal = _findModal(modalId);

        if (!targetModal) return;

        setModals((prev) => [...prev?.filter((m) => String(m?.id) !== String(modalId))]);
    };

    const openModal = (modalId, payload = null) => {
        _updateModal(modalId, {
            show: true,
            ...(payload || {}),
        });
    };

    const closeModal = (modalId, payload = null) => {
        _updateModal(modalId, {
            show: false,
            ...(payload || {}),
        });
    };

    const modalState = React.useCallback((modalId) => _findModal(modalId), [_findModal]);

    const value = { openModal, modalState, closeModal, registerModal, unregisterModal };

    return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
};

export default ModalsContextProvider;
