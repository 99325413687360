import React from "react";
import { Avatar } from "@mui/material";
import upperCase from "lodash/upperCase";

import { randomColor } from "@app/helpers";

const sizeMap = {
    xs: 6,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
};

const FallbackAvatar = ({ src, alt, sx = {}, size = "md", ...props }) => {
    const _randomColor = React.useMemo(() => randomColor(alt), [alt]);

    const isValidSrc = Boolean(src) && src?.length > 0;

    return (
        <Avatar
            alt={upperCase(alt ?? "")}
            src={isValidSrc ? src : "#"}
            sx={{
                fontWeight: "bold",
                fontFamily: "Inter",
                fontSize: sizeMap[size],
                bgcolor: `${_randomColor}`,
                ...sx,
            }}
            {...props}
        />
    );
};

export default FallbackAvatar;
