import api from "@app/services/api";
import { download } from "@app/helpers";

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const INVOICES_TIMEOFF_SEARCH_DATA = "INVOICES_TIMEOFF_SEARCH_DATA";
export const invoicesAndTimeOffSearchData = (keyword) => {
    return {
        keyword,
        type: INVOICES_TIMEOFF_SEARCH_DATA,
    };
};

export const SET_SEARCH_KEYS_INVOICES_AND_TIMEOFF = "SET_SEARCH_KEYS_INVOICES_AND_TIMEOFF";
export const setSearchKeys = (keys) => {
    return {
        payload: keys,
        type: SET_SEARCH_KEYS_INVOICES_AND_TIMEOFF,
    };
};

export const FILTER_DATA_INVOICES = "FILTER_DATA_INVOICES";
export const filterDataInvoices = (data) => {
    return {
        payload: data,
        type: FILTER_DATA_INVOICES,
    };
};

export const SET_DATA_TO_RENDER_INVOICES_AND_TIMEOFF = "SET_DATA_TO_RENDER_INVOICES_AND_TIMEOFF";
export const setDataToRender = (data) => {
    return {
        payload: data,
        type: SET_DATA_TO_RENDER_INVOICES_AND_TIMEOFF,
    };
};

export const GET_INVOICES_BEGIN = "GET_INVOICES_BEGIN";
export const getInvoicesBegin = () => ({
    type: GET_INVOICES_BEGIN,
});

export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const getInvoicesSuccess = (data) => ({
    payload: data,
    type: GET_INVOICES_SUCCESS,
});

export const GET_INVOICES_FAILED = "GET_INVOICES_FAILED";
export const getInvoicesFailed = (error) => ({
    payload: error,
    type: GET_INVOICES_FAILED,
});

export const getInvoices = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getInvoicesBegin());
            const response = await api.get(`clients/${clientId}/invoices`);
            dispatch(getInvoicesSuccess(response.data));
        } catch (error) {
            dispatch(getInvoicesFailed(error));
        }
    };
};

export const GET_INVOICE_PDF_BEGIN = "GET_INVOICE_PDF_BEGIN";
export const getInvoicePdfBegin = (id) => ({
    payload: id,
    type: GET_INVOICE_PDF_BEGIN,
});

export const GET_INVOICE_PDF_SUCCESS = "GET_INVOICE_PDF_SUCCESS";
export const getInvoicePdfSuccess = () => ({
    type: GET_INVOICE_PDF_SUCCESS,
});

export const GET_INVOICE_PDF_FAILED = "GET_INVOICE_PDF_FAILED";
export const getInvoicePdfFailed = (error) => ({
    payload: error,
    type: GET_INVOICE_PDF_FAILED,
});

export const getInvoicePdf = (clientId, invoiceId) => {
    return async (dispatch) => {
        try {
            dispatch(getInvoicePdfBegin(invoiceId));
            const response = await api.get(`clients/${clientId}/invoices/${invoiceId}/pdf-download`);
            download(`data:application/pdf;base64, ${response.data.data.content}`, "invoice.pdf");
            dispatch(getInvoicePdfSuccess());
        } catch (error) {
            dispatch(getInvoicePdfFailed(error));
        }
    };
};

export const GET_INVOICE_ATTACHMENTS_BEGIN = "GET_INVOICE_ATTACHMENTS_BEGIN";
export const getInvoiceAttachmentsBegin = () => ({
    type: GET_INVOICE_ATTACHMENTS_BEGIN,
});

export const GET_INVOICE_ATTACHMENTS_SUCCESS = "GET_INVOICE_ATTACHMENTS_SUCCESS";
export const getInvoiceAttachmentsSuccess = (data) => ({
    payload: data,
    type: GET_INVOICE_ATTACHMENTS_SUCCESS,
});

export const GET_INVOICE_ATTACHMENTS_FAILED = "GET_INVOICE_ATTACHMENTS_FAILED";
export const getInvoiceAttachmentsFailed = (error) => ({
    payload: error,
    type: GET_INVOICE_ATTACHMENTS_FAILED,
});

export const getInvoiceAttachments = (clientId, invoiceId) => {
    return async (dispatch) => {
        try {
            dispatch(getInvoiceAttachmentsBegin());
            const response = await api.get(`clients/${clientId}/invoices/${invoiceId}/attachments`);
            dispatch(getInvoiceAttachmentsSuccess(response.data));
        } catch (error) {
            dispatch(getInvoiceAttachmentsFailed(error));
        }
    };
};

export const GET_TIME_OFF_BEGIN = "GET_TIME_OFF_BEGIN";
export const getTimeOffBegin = () => ({
    type: GET_TIME_OFF_BEGIN,
});

export const GET_TIME_OFF_SUCCESS = "GET_TIME_OFF_SUCCESS";
export const getTimeOffSuccess = (data) => ({
    payload: data,
    type: GET_TIME_OFF_SUCCESS,
});

export const GET_TIME_OFF_FAILED = "GET_TIME_OFF_FAILED";
export const getTimeOffFailed = (error) => ({
    payload: error,
    type: GET_TIME_OFF_FAILED,
});

export const getTimeOff = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getTimeOffBegin());
            const response = await api.get(`clients/${clientId}/time-offs`);
            dispatch(getTimeOffSuccess(response.data));
        } catch (error) {
            dispatch(getTimeOffFailed(error));
        }
    };
};

export const GET_TIME_OFF_BY_USER_BEGIN = "GET_TIME_OFF_BY_USER_BEGIN";
export const getTimeOffByUserBegin = () => ({
    type: GET_TIME_OFF_BY_USER_BEGIN,
});

export const GET_TIME_OFF_BY_USER_SUCCESS = "GET_TIME_OFF_BY_USER_SUCCESS";
export const getTimeOffByUserSuccess = (data) => ({
    payload: data,
    type: GET_TIME_OFF_BY_USER_SUCCESS,
});

export const GET_TIME_OFF_BY_USER_FAILED = "GET_TIME_OFF_BY_USER_FAILED";
export const getTimeOffByUserFailed = (error) => ({
    payload: error,
    type: GET_TIME_OFF_BY_USER_FAILED,
});

export const getTimeOffByUser = (clientId, userId) => {
    return async (dispatch) => {
        try {
            dispatch(getTimeOffByUserBegin());
            const response = await api.get(`clients/${clientId}/users/${userId}/time-offs`);
            dispatch(getTimeOffByUserSuccess(response.data));
        } catch (error) {
            dispatch(getTimeOffByUserFailed(error));
        }
    };
};
