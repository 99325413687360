import { combineReducers } from "redux";

import matches from "@app/reducers/Admin/Matches";
import positions from "@app/reducers/Admin/Positions";
import prospects from "@app/reducers/Admin/Prospects";
import search from "@app/reducers/Admin/Search/search";

export default combineReducers({
    search,
    matches,
    positions,
    prospects,
});
