import React from "react";
import AddIcon from "@mui/icons-material/Add";

import "./index.scss";

function AddNewLanguage({ onClick }) {
    return (
        <div
            onClick={onClick}
            className="preference add-new"
        >
            <span>
                <AddIcon
                    sx={{
                        fontSize: 16,
                        color: "#096AB0",
                    }}
                />
            </span>

            <p className="option-dark">Add another language</p>
        </div>
    );
}

export default AddNewLanguage;
