import axios from "axios";

const CACHE_KEY = "adeva_geo_location_cache";

const fallbackResponse = {
    error: true,
    adminName1: null, // State
    adminName2: null, // City
    countryCode: null,
};

export default class GeoLocationService {
    // Discard any error and fallback to the default location
    static handleError() {
        return fallbackResponse;
    }

    static cacheResponse(response) {
        const value = JSON.stringify(response.data);

        localStorage.setItem(CACHE_KEY, value);
    }

    static readCache() {
        const data = localStorage.getItem(CACHE_KEY);

        if (data) {
            return JSON.parse(data);
        }

        return null;
    }

    static handleResponse(response) {
        if (response.status === 200) {
            GeoLocationService.cacheResponse(response);

            return response.data;
        }

        return fallbackResponse;
    }

    // @See https://www.geonames.org/export/web-services.html#countrycode
    static getCountryFromLatLong(lat, long) {
        const cachedData = GeoLocationService.readCache();

        if (cachedData) return new Promise((resolve) => resolve(cachedData));

        const baseUrl = process.env.REACT_APP_GEONAMES_URL;

        const username = process.env.REACT_APP_GEONAMES_USERNAME;

        const endpoint = "countrySubdivisionJSON";

        const urlPath = `${endpoint}?username=${username}&lat=${lat}&lng=${long}`;

        const url = baseUrl + urlPath;

        return axios.get(url).then(GeoLocationService.handleResponse).catch(GeoLocationService.handleError);
    }
}
