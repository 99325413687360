import React from "react";
import { Alert } from "@mui/material";

import AlertImage from "@app/styles/assets/img/assets/mail-icon.svg";

export default React.memo(function AlertComponent({
    width,
    infoMessage,
    clearAction,
    icon = true,
    errorMessage,
    successMessage,
    warningMessage,
    messageCentered = false,
}) {
    return (
        <div className={`${width === 100 ? "col-xl-12" : "col-xl-6"} ${messageCentered ? "text-message" : ""}`}>
            {infoMessage && (
                <Alert severity="info">
                    <p dangerouslySetInnerHTML={{ __html: infoMessage }} />
                </Alert>
            )}

            {warningMessage && (
                <Alert severity="warning">
                    <p dangerouslySetInnerHTML={{ __html: warningMessage }} />
                </Alert>
            )}

            {successMessage && icon === true && (
                <Alert
                    onClose={clearAction}
                    className="alert-success"
                >
                    <p dangerouslySetInnerHTML={{ __html: successMessage }} />
                </Alert>
            )}

            {successMessage && icon === false && (
                <Alert
                    icon={false}
                    onClose={clearAction}
                    className="alert-success d-flex align-items-center"
                    style={{ border: "1px solid #24B76829", font: "normal normal normal 14px/17px Inter" }}
                >
                    <img
                        alt=""
                        src={AlertImage}
                        style={{ width: "15px", height: "16px", marginRight: "10px", verticalAlign: "sub" }}
                    />

                    <p dangerouslySetInnerHTML={{ __html: successMessage }} />
                </Alert>
            )}

            {errorMessage && (
                <Alert
                    severity="error"
                    onClose={clearAction}
                    className="alert-error mt-0"
                >
                    <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </Alert>
            )}
        </div>
    );
});
