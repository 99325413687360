// Escape RegExp special characters (e.g. `.`, `^`, ...etc)
export const escapeForRegex = (str) => str?.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");

export const startCase = (str = "") => {
    return str?.replaceAll(/-|_/g, " ")?.trim();
};

export const customNoOptionsMessage = (condition = false, message = null) => {
    const defaultMessage = "No options";
    const customMessage = message || defaultMessage;

    return Boolean(condition) ? defaultMessage : customMessage;
};
