import React, { useState, useEffect } from "react";

import { useWindowResize } from "@app/hooks";

import "./index.scss";

const CardWithToggleContent = ({
    icon,
    title,
    subTitle,
    buttonText,
    description,
    toggleBtnText,
    onButtonClick,
    defaultExpanded,
    titleDescription,
    additionalContent,
}) => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleToggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };

    const handleButtonClick = () => {
        onButtonClick?.();
    };

    useEffect(() => {
        setIsContentVisible(defaultExpanded);
    }, [defaultExpanded]);

    useWindowResize(() => {
        setWindowWidth(window.innerWidth);
    }, []);

    return (
        <div className="card-with-toggle-content">
            <div className="card-wrapper mb-3">
                <div>
                    <p className="title">{title}</p>
                    {titleDescription && <p className="title-description">{titleDescription}</p>}
                </div>

                <div className="d-flex align-items-center">
                    {icon && <i className={`icon d-block ${icon}`}></i>}

                    <p style={{ fontWeight: 600 }}>{subTitle}</p>

                    {windowWidth > 576 && subTitle && buttonText && (
                        <button
                            onClick={handleButtonClick}
                            className="referrals-btn btn-action btn-text btn-green mr-0"
                        >
                            {buttonText}
                        </button>
                    )}
                </div>
            </div>
            <div>
                <p className="description">{description}</p>
            </div>
            <div className="toggle-wrapper">
                <a
                    className="toggle-btn"
                    onClick={handleToggleContent}
                >
                    {toggleBtnText}

                    {isContentVisible && <i className="icon-arrow"></i>}
                </a>
                {windowWidth <= 576 && subTitle && buttonText && (
                    <button
                        onClick={handleButtonClick}
                        className="referrals-btn btn-action btn-text btn-green mr-0"
                    >
                        {buttonText}
                    </button>
                )}
            </div>

            {isContentVisible && (
                <div>
                    <div className="additional-content">
                        <div className="description">{additionalContent}</div>
                        <div className="additional-info">
                            <p className="title mb-1">Additional information</p>
                            <p className="description">
                                Have a question? Reach out to{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="contact"
                                    href="mailto:support@adevait.com"
                                >
                                    support@adevait.com
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CardWithToggleContent;
