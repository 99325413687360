import React from "react";

export default React.memo(function SearchBar({ onSearch, className, searchKeyword, ...props }) {
    return (
        <div className={`input-search ${className || ""}`}>
            <i className="icon-search"></i>
            {searchKeyword !== undefined ? (
                <input
                    id="search"
                    type="text"
                    placeholder="Search"
                    value={searchKeyword}
                    onChange={(event) => {
                        onSearch(event.target.value);
                    }}
                    {...props}
                />
            ) : (
                <input
                    id="search"
                    type="text"
                    placeholder="Search"
                    onChange={(event) => {
                        onSearch(event.target.value);
                    }}
                    {...props}
                />
            )}
        </div>
    );
});
