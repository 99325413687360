export async function handleErrors(error) {
    if (error.response) {
        const { data, status } = error.response;
        let message = "Unknown error";

        if (status === 401) {
            message = "Authentication failed";
        }

        if (data && data.errors && data.errors.length > 0) {
            message = data.errors[0];
        }

        throw new Error(message);
    } else if (error.request) {
        throw new Error("No response received from server");
    } else {
        throw new Error(error.message);
    }
}
