import qs from "qs";

export default class Parser {
    prepend() {
        return this.uri === "" ? "?" : "&";
    }

    constructor(query) {
        this.query = query;
        this.uri = "";
    }

    params() {
        if (this.query.paramsObj === null) {
            return;
        }

        this.uri += this.prepend() + qs.stringify(this.query.paramsObj, { encode: false });
    }

    sorts() {
        if (!this.query.sorts.length > 0) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.sort}=${this.query.sorts}`;
    }

    page() {
        if (this.query.pageValue === null) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.page}=${this.query.pageValue}`;
    }

    append() {
        if (!this.query.append.length > 0) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.append}=${this.query.append}`;
    }

    limit() {
        if (this.query.limitValue === null) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.limit}=${this.query.limitValue}`;
    }

    /**
     * Parsers
     */
    include() {
        if (!this.query.include.length > 0) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.include}=${this.query.include}`;
    }

    transform() {
        if (!this.query.transformers.length > 0) {
            return;
        }

        this.uri += `${this.prepend() + this.query.queryParameters.transforms}=${this.query.transformers}`;
    }

    // parse the final query string
    parse() {
        this.include();
        this.transform();
        this.append();
        this.fields();
        this.filters();
        this.sorts();
        this.page();
        this.limit();
        this.params();

        return this.uri;
    }

    fields() {
        if (!Object.keys(this.query.fields).length > 0) {
            return;
        }

        const fields = { [`${this.query.queryParameters.fields}`]: this.query.fields };
        this.uri += this.prepend() + qs.stringify(fields, { encode: false });
    }

    filters() {
        if (!Object.keys(this.query.filters).length > 0) {
            return;
        }

        const filters = { [this.query.queryParameters.filters]: this.query.filters };
        this.uri += this.prepend() + qs.stringify(filters, { encode: false });
    }
}
