import { invitations } from "@app/actions";

const initialState = {
    data: null,

    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function invitationsReducer(state = initialState, action) {
    switch (action.type) {
        case invitations.CLEAR_ACTION:
            return initialState;
        case invitations.TALENT_INVITE_CUSTOMER_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case invitations.TALENT_INVITE_CUSTOMER_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case invitations.TALENT_INVITE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
