import shortUUID from "short-uuid";

import { partner } from "@app/actions";

const initialState = {
    data: null,
    talentsData: null,
    searchKeyword: "",
    invoicesData: null,
    positionsData: null,
    customersData: null,
    sort: {
        orderColumn: "",
        direction: null,
    },
    paginationPartner: {
        page: 1,
        pageSize: 10,
        selectedFilter: "",
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

const reMapData = (data) => {
    return data.map((el) => {
        return {
            ...el,
            uid: shortUUID.generate(),
        };
    });
};

export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case partner.CLEAR_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                },
            };
        }
        case partner.RESET_TABLE_ACTIONS: {
            return {
                ...initialState,
            };
        }
        case partner.SET_SEARCH_KEYWORD_PARTNER: {
            return {
                ...state,
                searchKeyword: action.payload,
                paginationPartner: {
                    ...state.paginationPartner,
                    page: action.payload === "" ? 1 : "",
                },
            };
        }
        case partner.SET_PAGINATION: {
            return {
                ...state,
                paginationPartner: {
                    ...state.paginationPartner,
                    [action.key]: action.value,
                },
            };
        }
        case partner.SET_SORT: {
            return {
                ...state,
                sort: {
                    direction: action.asc,
                    orderColumn: action.orderColumn,
                },
            };
        }
        case partner.GET_CUSTOMERS_BEGIN:
        case partner.INVITE_CUSTOMER_BEGIN:
        case partner.INVITE_FRIEND_PARTNER_BEGIN:
        case partner.GET_INVOICES_PARTNER_BEGIN:
        case partner.GET_TALENTS_BEGIN:
        case partner.GET_POSITIONS_BEGIN_PARTNER:
        case partner.GET_DASHBOARD_PARTNERS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case partner.GET_CUSTOMERS_FAILED:
        case partner.INVITE_CUSTOMER_FAILED:
        case partner.INVITE_FRIEND_PARTNER_FAILED:
        case partner.GET_INVOICES_PARTNER_FAILED:
        case partner.GET_TALENTS_FAILED:
        case partner.GET_POSITIONS_FAILED_PARTNER:
        case partner.GET_DASHBOARD_PARTNERS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case partner.GET_DASHBOARD_PARTNERS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case partner.GET_POSITIONS_SUCCESS_PARTNER: {
            return {
                ...state,
                positionsData: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case partner.GET_TALENTS_SUCCESS: {
            return {
                ...state,
                filters: action.payload.filters.sort((a, b) => a.order - b.order),
                talentsData: { ...action.payload, data: reMapData(action.payload.data) },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case partner.GET_INVOICES_PARTNER_SUCCESS: {
            return {
                ...state,
                filters: action.payload.filters,
                invoicesData: { ...action.payload, data: reMapData(action.payload.data) },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case partner.INVITE_CUSTOMER_SUCCESS:
        case partner.INVITE_FRIEND_PARTNER_SUCCESS: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case partner.GET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                filters: action.payload.filters.sort((a, b) => a.order - b.order),
                customersData: { ...action.payload, data: reMapData(action.payload.data) },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
