import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet-async";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import api from "@app/services/api";
import properties from "@app/properties";
import { Loading, MentionItem } from "@app/components";

import "./index.scss";

const MENTION_MARKER = "@";

const CKEditor_URL = `${properties.MAIN_HOST}/vendor/ckeditor/ckeditor.js`;

/**
 * Fetch the matching users for the given `queryText`.
 */
function fetchUsersForMention(queryText) {
    return new Promise((resolve) => {
        api.get(`/admin/users/mentions?q=${queryText}`)
            .then(({ data }) => {
                const matchingUsers =
                    data?.users?.map((u) => ({
                        ...u,
                        uid: u.id, // Used as a reference to the mentioned user
                        id: `${MENTION_MARKER}${u.fullName}`, // Determine how the mentioned user will be displayed
                    })) || [];

                // No matching users found
                if (matchingUsers.length === 0) {
                    matchingUsers.push({
                        id: -1,
                        emptyState: true,
                    });
                }

                resolve(matchingUsers);
            })
            .catch(() => {
                resolve([]);
            });
    });
}

/**
 * Control how the user should be rendered in the autocomplete dropdown.
 */
function customMentionRenderer(item) {
    const itemElement = document.createElement("span");
    itemElement.classList.add("mention-list-item");
    itemElement.id = `mention-list-item-id-${item.id}`;

    if (item?.emptyState) {
        itemElement.insertAdjacentHTML("beforeend", '<p class="empty-list">No matching users found</p>');
    } else {
        ReactDOM.render(<MentionItem item={item} />, itemElement);
    }

    return itemElement;
}

function MentionCustomization(editor) {
    // The upcast converter will convert <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for("upcast").elementToAttribute({
        converterPriority: "high",
        view: {
            name: "span",
            classes: "mention",
            key: "data-mention",
            attributes: {
                "data-mention-id": true,
            },
        },
        model: {
            key: "mention",
            value: (viewItem) => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object, use the toMentionAttribute helper method:
                const mentionAttribute = editor.plugins.get("Mention").toMentionAttribute(viewItem, {
                    // Add any other properties that you need.

                    // Restore the uid from the data attribute
                    uid: viewItem?.getAttribute("data-mention-id"),
                });

                return mentionAttribute;
            },
        },
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for("downcast").attributeToElement({
        model: "mention",
        converterPriority: "high",
        view: (modelAttributeValue, { writer }) => {
            // Do not convert empty attributes (lack of value means no mention).
            if (!modelAttributeValue) {
                return;
            }

            return writer.createAttributeElement(
                "span",
                {
                    class: "mention",
                    "data-mention": modelAttributeValue?.id,
                    "data-mention-id": modelAttributeValue?.uid,
                },
                {
                    // Make mention attribute to be wrapped by other attribute elements.
                    priority: 20,
                    // Prevent merging mentions together.
                    id: modelAttributeValue.uid,
                },
            );
        },
    });
}

const EditorConfig = {
    extraPlugins: [MentionCustomization],
    mention: {
        feeds: [
            {
                minimumCharacters: 0,
                marker: MENTION_MARKER,
                feed: fetchUsersForMention,
                itemRenderer: customMentionRenderer,
            },
        ],
    },
    fontSize: {
        options: [
            {
                model: "12px",
                title: "Small",
            },
            {
                model: "14px",
                title: "Normal",
            },
            {
                title: "Big",
                model: "16px",
            },
            {
                title: "Huge",
                model: "18px",
            },
        ],
    },
};

const EmptyEditor = () => {
    return (
        <div className="advanced-editor-empty">
            <Loading message="Preparing editor..." />
        </div>
    );
};

const AdvancedEditor = ({ value, ...props }) => {
    const [isEditorLoaded, setIsScriptLoaded] = React.useState(Boolean(window?.ClassicEditor));

    const handleChangeClientState = (newState, addedTags) => {
        if (addedTags?.scriptTags) {
            const foundScript = addedTags.scriptTags?.find(({ src }) => src === CKEditor_URL);

            if (foundScript) {
                foundScript.addEventListener("load", () => setIsScriptLoaded(true), { once: true });
            }
        }
    };

    return (
        <>
            {!isEditorLoaded && (
                <Helmet onChangeClientState={handleChangeClientState}>
                    <script
                        async
                        defer
                        src={CKEditor_URL}
                        type="text/javascript"
                    />
                </Helmet>
            )}

            {isEditorLoaded ? (
                <CKEditor
                    data={value}
                    config={EditorConfig}
                    editor={window.ClassicEditor}
                    {...props}
                />
            ) : (
                <EmptyEditor />
            )}
        </>
    );
};

export default AdvancedEditor;
