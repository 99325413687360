import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { RadioInput } from "@app/components";
import HubspotAPI from "@app/services/HubspotAPI";
import { getCookie } from "@app/services/CookieUtilis";
import { talentInviteCustomer } from "@app/actions/Talent/Invitations";
import { RefereeType, ReferralInvitationType, ReferralInvitationReason } from "@app/enums";

import Label from "../../New/atoms/label";
import Input from "../../New/molecules/input";

import "./index.scss";

export default function DirectIntroductionModal({ programId, ipAddress, handleClose, setSuccessMessage }) {
    const [isRadioButtonChecked, setIsRadioButtonChecked] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(null);
    const { userProfile } = useSelector((state) => state.authentication);

    const handleRadioButtonChange = (value) => {
        setIsRadioButtonChecked(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitting(true);
        const contactPersonValue = document.getElementById("contact_person").value;
        const [firstName, ...remaining] = contactPersonValue.split(" ");
        const lastName = remaining.length > 0 ? remaining.join(" ") : "";

        const formData = {
            invitations: [
                {
                    last_name: lastName,
                    program_id: programId,
                    first_name: firstName,
                    direct_introduction_modal: true,
                    referee_type: RefereeType.CLIENT,
                    email: document.getElementById("email").value,
                    invitation_type: ReferralInvitationType.PROGRAM,
                    invitation_reason: ReferralInvitationReason.APPLY,
                    referee_details: {
                        note: document.getElementById("note").value,
                        company_name: document.getElementById("company_name").value,
                        introductory_call_preference: isRadioButtonChecked ? "Yes" : "No",
                        relationship: document.getElementById("relationship_select").value,
                    },
                },
            ],
        };

        try {
            const dispatchResponse = await dispatch(talentInviteCustomer(formData));

            if (!dispatchResponse.data.data?.message?.error) {
                const portalId = process.env.REACT_APP_DIRECT_INTRODUCTION_PORTAL_ID;
                const formId = process.env.REACT_APP_DIRECT_INTRODUCTION_FORM_ID;

                const hubspotutkCookie = getCookie("hubspotutk");
                const form = new HubspotAPI(portalId, formId, ipAddress, hubspotutkCookie);

                form.setFields([
                    { name: "firstname", value: formData.invitations[0].first_name },
                    { name: "lastname", value: formData.invitations[0].last_name },
                    { name: "email", value: formData.invitations[0].email },
                    {
                        objectTypeId: "company",
                        value: formData.invitations[0].referee_details.note,
                        name: "describe_why_you_re_referring_this_client_to_us__how_do_you_think_we_can_help_them_",
                    },
                    {
                        name: "company",
                        value: formData.invitations[0].referee_details.company_name,
                    },
                    {
                        objectTypeId: "company",
                        name: "how_do_you_know_the_client_",
                        value: formData.invitations[0].referee_details.relationship,
                    },
                    {
                        objectTypeId: "company",
                        name: "would_you_like_to_be_part_of_an_introductory_call_with_the_client_",
                        value: formData.invitations[0].referee_details.introductory_call_preference,
                    },
                    {
                        name: "referred_by",
                        objectTypeId: "company",
                        value: userProfile.email,
                    },
                ]);

                const response = await form.submit();
                setSuccessMessage(response.data.inlineMessage);
                setSubmitting(false);
            }
            handleClose();
        } catch (error) {
            setErrors(error);
            setSubmitting(false);
        }
    };
    return (
        <>
            <Helmet>
                <script
                    async
                    defer
                    id="hs-script-loader"
                    type="text/javascript"
                    src={`//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}.js`}
                ></script>
            </Helmet>
            <Modal
                size="lg"
                show={true}
                onHide={handleClose}
                id="DirectIntroductionModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p
                            className="modal-title"
                            style={{
                                fontSize: "24px",
                                color: "#1A284D",
                                lineHeight: "36px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Client Referral - Direct Introduction
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <form
                    name="formSubmit"
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        {/* Workaround: Ignore a specific non-Hubspot form from being collected */}
                        <label
                            htmlFor="cc-num"
                            className="d-none"
                        ></label>
                        <input
                            required=""
                            id="cc-num"
                            name="cc-num"
                            className="d-none"
                            value="HubspotCollectedFormsWorkaround"
                        />
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500 }}
                                label="How do you know the client?"
                            />
                            <select
                                id="relationship_select"
                                style={{ fontWeight: 500 }}
                                className="form-control dropdown-form"
                            >
                                <option>We've worked together or have a professional relationship</option>
                                <option>I know of the client or briefly interacted with them</option>
                                <option>Personal relationship/friend</option>
                            </select>
                        </div>

                        <Label
                            style={{ fontWeight: 500 }}
                            label="Would you like to be part of an introductory call with the client?"
                        />
                        <div className="d-flex mb-4">
                            <RadioInput
                                inline
                                label="Yes"
                                checked={isRadioButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleRadioButtonChange(true)}
                            />

                            <RadioInput
                                inline
                                label="No"
                                checked={!isRadioButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleRadioButtonChange(false)}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                label="Company"
                                elementName="content"
                                style={{ fontWeight: 500 }}
                            />
                            <Input
                                type="text"
                                id="company_name"
                                name="company_name"
                                error={errors && errors["invitations.0.referee_details.company_name"]}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                label="Contact Person"
                                style={{ fontWeight: 500 }}
                            />
                            <Input
                                type="text"
                                id="contact_person"
                                error={errors && errors["invitations.0.first_name"]}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                label="Email Address"
                                elementName="content"
                                style={{ fontWeight: 500 }}
                            />
                            <Input
                                id="email"
                                type="text"
                                name="email"
                                error={errors && errors["invitations.0.email"]}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500 }}
                                label="Describe why you're referring this client to us. How do you think we can help them?"
                            />
                            <textarea
                                rows="3"
                                id="note"
                                cols="30"
                                name="note"
                                style={{ resize: "none" }}
                                className={`form-control ${errors && errors["invitations.0.referee_details.note"] ? "border-error" : ""}`}
                            ></textarea>
                            {errors && errors["invitations.0.referee_details.note"] && (
                                <div className="helper-text">
                                    <small className="message error-message">
                                        {errors["invitations.0.referee_details.note"]}
                                    </small>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="btn-action btn-text btn-transparent"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            disabled={submitting}
                            className="btn-action btn-blue mr-0"
                        >
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
