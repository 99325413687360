import MainMenu from "./MainMenu";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const transformAdminMenu = (menu) => {
    return menu?.map((item) => ({
        icon: "",
        key: item.name,
        display: item.name,
        target: item.url || null,
        children: item?.items ? transformAdminMenu(item.items) : null,
    }));
};

function AdminSidebar({ toggleResponsiveMenu }) {
    const { menu } = useSelector((state) => state.authentication.commonData);

    const sidebarItems = useMemo(() => transformAdminMenu(menu), [menu]);

    return (
        <MainMenu
            items={sidebarItems}
            onClick={toggleResponsiveMenu}
        />
    );
}

export default AdminSidebar;
