import React from "react";
import isNil from "lodash/isNil";
import { useSelector } from "react-redux";

import Input from "@app/components/Input";
import { useTalentWorkPreferences } from "@app/hooks";
import FormSelect from "@app/components/New/molecules/formSelect";

const RATE_TYPES = [
    {
        value: "hourly",
        label: <span className="option-dark">Hourly</span>,
    },
    {
        value: "daily",
        label: <span className="option-dark">Daily</span>,
    },
];

const RatePreference = () => {
    const { state, updateState } = useTalentWorkPreferences();
    const { preferences } = useSelector((state) => state.talent.account);

    const handleChange = React.useCallback((key, value) => updateState(`rate.${key}`, value), [updateState]);

    React.useEffect(() => {
        handleChange("amount", preferences?.hourly_rate ?? preferences?.daily_rate ?? "");
    }, [handleChange, preferences?.daily_rate, preferences?.hourly_rate]);

    React.useEffect(() => {
        let type = null;

        if (!isNil(preferences?.hourly_rate)) type = "hourly";

        if (!isNil(preferences?.daily_rate)) type = "daily";

        handleChange("type", type || "daily");
    }, [handleChange, preferences?.daily_rate, preferences?.hourly_rate]);

    return (
        <div className="rate-preference preference mb-0">
            <label className="preference-label">What’s your expected rate?</label>
            <div className="d-flex">
                <div className="flex-fill me-3">
                    <Input
                        min={0}
                        icon="$"
                        type="number"
                        className="mb-0"
                        value={state?.rate?.amount || ""}
                        onChange={(e) => handleChange("amount", e.target.value)}
                    />
                </div>

                <div style={{ width: 130 }}>
                    <FormSelect
                        optionsData={RATE_TYPES}
                        value={state?.rate?.type || ""}
                        onChange={(newVal) => handleChange("type", newVal.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RatePreference;
