import React from "react";
import { useSelector } from "react-redux";

import { Loading } from "@app/components";

const InterviewerNotRegistered = () => {
    const { isLoading } = useSelector((state) => state.talent.interviews.action);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="p-5 m-5 text-center">
            <h4 className="mb-4">You're not registered as interviewer yet</h4>
            <p>If you're interested in joining Adeva's technical interviewers team, please contact our admins.</p>
        </div>
    );
};

export default InterviewerNotRegistered;
