import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";

import api from "@app/services/api";
import { RadioInput } from "@app/components";
import HubspotAPI from "@app/services/HubspotAPI";
import { getCookie } from "@app/services/CookieUtilis";

import Label from "../../New/atoms/label";
import Input from "../../New/molecules/input";

import "./index.scss";

async function getUserCompanies() {
    return await api.get("user-companies");
}

export default function SpotlightOpportunityModal({ ipAddress, handleClose, setSuccessMessage }) {
    const [companies, setCompanies] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isRadioButtonChecked, setIsRadioButtonChecked] = useState(true);
    const [isOpportunityButtonChecked, setIsOpportunityButtonChecked] = useState(true);
    const { userProfile } = useSelector((state) => state.authentication);

    useEffect(() => {
        getUserCompanies().then((result) => {
            setCompanies(result.data?.data || []);
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        const opportunity = document.getElementById("opportunity").value;
        const company = document.getElementById("company").value;
        const opportunityDescription = document.getElementById("opportunity_description").value;
        const firstName = document.getElementById("first_name").value;
        const lastName = document.getElementById("last_name").value;
        const contactEmail = document.getElementById("contact_email").value;

        try {
            const portalId = process.env.REACT_APP_SPOTLIGHT_PORTAL_ID;
            const formId = process.env.REACT_APP_SPOTLIGHT_FORM_ID;
            const hubspotutkCookie = getCookie("hubspotutk");
            const form = new HubspotAPI(portalId, formId, ipAddress, hubspotutkCookie);

            form.setFields([
                {
                    value: opportunity,
                    objectTypeId: "company",
                    name: "select_an_option_that_best_describes_the_opportunity_or_challenge",
                },
                {
                    value: company,
                    objectTypeId: "company",
                    name: "what_company_is_this_opportunity_linked_to_",
                },
                {
                    objectTypeId: "company",
                    value: opportunityDescription,
                    name: "tell_us_more_about_this_opportunity",
                },
                {
                    objectTypeId: "company",
                    value: isRadioButtonChecked ? "Yes" : "No",
                    name: "is_the_client_aware_that_you_re_reaching_out_to_adeva_",
                },
                {
                    objectTypeId: "company",
                    value: isOpportunityButtonChecked ? "Yes" : "No",
                    name: "have_you_discussed_this_opportunity_with_the_client_",
                },
                { value: firstName, name: "firstname" },
                { value: lastName, name: "lastname" },
                { name: "email", value: contactEmail },
                {
                    name: "spotlighted_by",
                    objectTypeId: "company",
                    value: userProfile.email,
                },
            ]);

            const response = await form.submit();

            setSuccessMessage(response.data.inlineMessage);
            setSubmitting(false);
            handleClose();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const hubspotErrors = error.response.data.errors;
                const errorMessages = {};
                hubspotErrors.forEach((error) => {
                    const fieldNameMatch = error.message.match(/'([^']+)'/);
                    if (fieldNameMatch && fieldNameMatch.length > 1) {
                        const fieldName = fieldNameMatch[1];
                        errorMessages[fieldName] = error.message;
                    }
                });
                setErrors(errorMessages);
            }
            setSubmitting(false);
        }
    };

    const handleRadioButtonChange = (value) => {
        setIsRadioButtonChecked(value);
    };

    const handleOpportunityRadioButton = (value) => {
        setIsOpportunityButtonChecked(value);
    };

    return (
        <>
            <Helmet>
                <script
                    async
                    defer
                    id="hs-script-loader"
                    type="text/javascript"
                    src={`//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}.js`}
                ></script>
            </Helmet>
            <Modal
                size="lg"
                show={true}
                onHide={handleClose}
                id="SpotlightOpportunityModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p
                            className="modal-title"
                            style={{
                                fontSize: "24px",
                                color: "#1A284D",
                                lineHeight: "36px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Spotlight Expansion Opportunity
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <form
                    name="formSubmit"
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        {/* Workaround: Ignore a specific non-Hubspot form from being collected */}
                        <label
                            htmlFor="cc-num"
                            className="d-none"
                        ></label>
                        <input
                            required=""
                            id="cc-num"
                            name="cc-num"
                            className="d-none"
                            value="HubspotCollectedFormsWorkaround"
                        />
                        <div className="form-group">
                            <p
                                className="mb-3"
                                style={{
                                    color: "#343F5C",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                }}
                            >
                                Great! You've found a possible opportunity to get more from your client engagement and
                                Adeva experience. Any information you share is private and limited. The client will not
                                be informed that you've reached out to us.
                            </p>
                            <p
                                className="mb-4"
                                style={{
                                    color: "#343F5C",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                }}
                            >
                                Remember, working with Adeva isn't limited to existing engagements and terms. Flag any
                                new opportunities, and we'll gladly look into them.
                            </p>
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500 }}
                                label="Select an option that best describes the opportunity or challenge"
                            />
                            <select
                                id="opportunity"
                                style={{ fontWeight: 500 }}
                                className="form-control dropdown-form"
                            >
                                <option>Client can’t fill open positions internally</option>
                                <option>Client is actively hiring contractors for various positions</option>
                                <option>Client is actively working with other vendors for various positions</option>
                                <option>We have an ambitious roadmap and limited capacity in the team</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500 }}
                                label="What company is this opportunity linked to?"
                            />
                            <select
                                id="company"
                                style={{ fontWeight: 500 }}
                                className="form-control dropdown-form"
                            >
                                {companies.map((company) => (
                                    <option
                                        key={company.id}
                                        value={company.name}
                                    >
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500 }}
                                label="Tell us more about this opportunity"
                            />
                            <textarea
                                rows="3"
                                cols="30"
                                style={{ resize: "none" }}
                                id="opportunity_description"
                                className={
                                    errors && errors["fields.0-2/tell_us_more_about_this_opportunity"]
                                        ? "border-error"
                                        : ""
                                }
                            ></textarea>
                            {errors && (
                                <div>
                                    {errors["fields.0-2/tell_us_more_about_this_opportunity"] && (
                                        <div className="helper-text">
                                            <small className="message error-message">
                                                {errors["fields.0-2/tell_us_more_about_this_opportunity"]
                                                    ? "This filed is required."
                                                    : ""}
                                            </small>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <Label
                            elementName="content"
                            style={{ fontWeight: 500 }}
                            label="Is the client aware that you’re reaching out to Adeva?"
                        />
                        <div className="d-flex mb-4">
                            <RadioInput
                                inline
                                label="Yes"
                                checked={isRadioButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleRadioButtonChange(true)}
                            />

                            <RadioInput
                                inline
                                label="No"
                                checked={!isRadioButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleRadioButtonChange(false)}
                            />
                        </div>

                        <Label
                            elementName="content"
                            style={{ fontWeight: 500 }}
                            label="Have you discussed this opportunity with the client?"
                        />
                        <div className="d-flex mb-4">
                            <RadioInput
                                inline
                                label="Yes"
                                checked={isOpportunityButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleOpportunityRadioButton(true)}
                            />

                            <RadioInput
                                inline
                                label="No"
                                checked={!isOpportunityButtonChecked}
                                additionalStyle={{ minWidth: "85px" }}
                                onChange={() => handleOpportunityRadioButton(false)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                elementName="content"
                                style={{ fontWeight: 500, paddingBottom: "17px" }}
                                label="Who is your point of contact on the client side?"
                            />
                            <div className="d-flex">
                                <div
                                    className="mr-1"
                                    style={{ width: "100%" }}
                                >
                                    <Label
                                        label="First Name"
                                        elementName="content"
                                        style={{ fontWeight: 500 }}
                                    />
                                    <Input
                                        type="text"
                                        id="first_name"
                                        error={errors["fields.firstname"] ? "This field is required." : ""}
                                    />
                                </div>
                                <div
                                    className="ml-1"
                                    style={{ width: "100%" }}
                                >
                                    <Label
                                        label="Last Name"
                                        elementName="content"
                                        style={{ fontWeight: 500 }}
                                    />
                                    <Input
                                        type="text"
                                        id="last_name"
                                        error={errors["fields.lastname"] ? "This field is required." : ""}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <Label
                                label="Email Address"
                                elementName="content"
                                style={{ fontWeight: 500 }}
                            />
                            <Input
                                type="text"
                                name="email"
                                id="contact_email"
                                error={errors["fields.email"] ? "This field is required." : ""}
                            />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="btn-action btn-text btn-transparent"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            disabled={submitting}
                            className="btn-action btn-blue mr-0"
                        >
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
