import React from "react";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import IntlTelInput from "react-intl-tel-input";

import { Input } from "@app/components";
import { useGeoLocation, useTalentWorkPreferences } from "@app/hooks";

import "./index.scss";

const VALIDATION_ERRORS = {
    4: "Invalid number",
    1: "Invalid country code",
    3: "Phone number is too long",
    2: "Phone number is too short",
    0: "Please provide valid number",
};

const ContactPreference = () => {
    const { getCurrentCountryByGPS } = useGeoLocation();
    const { state, errors, updateState, updateErrors } = useTalentWorkPreferences();
    const countries = useSelector((state) => state.authentication.commonData.countries);
    const { contact } = useSelector((state) => state.talent.account);

    const [isValidPhone, setIsValidPhone] = React.useState(true);

    const countryCode = React.useMemo(
        () => countries?.find((c) => c?.nice_name?.toLowerCase() === contact?.country?.toLowerCase())?.iso || null,
        [countries, contact?.country],
    );

    const shouldRenderRegularInput = React.useMemo(
        () => !state?.contact?.countryCode && !contact?.mobile?.startsWith("+"),
        [contact?.mobile, state?.contact?.countryCode],
    );

    const handlePhoneChange = React.useCallback((value) => updateState("contact.phone", value), [updateState]);

    const handleCountryChange = React.useCallback(
        (countryIsoCode) => updateState("contact.countryCode", countryIsoCode?.toLowerCase() || ""),
        [updateState],
    );

    const detectCountryCode = React.useCallback(
        (phone, code) => {
            // Only try to detect the country if the phone
            // number doesn't have the country dial code
            if (phone?.startsWith("+")) {
                return;
            }

            // If a code is provided, use it to set the country code
            if (code) {
                handleCountryChange(code);

                return;
            }

            // Otherwise, try to get the country code from the GeoLocation
            getCurrentCountryByGPS()
                .then((res) => handleCountryChange(res?.countryCode))
                .catch(() => {});
        },
        [getCurrentCountryByGPS, handleCountryChange],
    );

    const onPhoneNumberChange = (isValid, _, countryInfo, fullNumber) => {
        handlePhoneChange(fullNumber);

        const valid = fullNumber?.length > 0 ? isValid : true;

        const dialCodePrefix = countryInfo?.dialCode ? `+${countryInfo?.dialCode}` : null;

        const number = fullNumber?.startsWith(dialCodePrefix) ? fullNumber : `${dialCodePrefix}${fullNumber}`;

        setIsValidPhone(valid);

        updateErrors(
            "contact.phone",
            valid ? null : VALIDATION_ERRORS?.[window?.intlTelInputUtils?.getValidationError?.(number)],
        );
    };

    React.useEffect(() => {
        handlePhoneChange(contact?.mobile);
    }, [handlePhoneChange, contact?.mobile]);

    React.useEffect(() => {
        detectCountryCode(contact?.mobile, countryCode);
    }, [detectCountryCode, contact?.mobile, countryCode]);

    return (
        <div className="contact-preference preference mb-0">
            <label className="preference-label">Phone Number</label>

            {shouldRenderRegularInput ? (
                <div className="d-flex">
                    <div className="empty-country-dropdown"></div>

                    <div className="flex-fill">
                        <Input
                            className="mb-0"
                            value={state?.contact?.phone || ""}
                            onChange={(e) => handlePhoneChange(e.target.value)}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <IntlTelInput
                        autoHideDialCode={false}
                        onPhoneNumberChange={onPhoneNumberChange}
                        defaultValue={state?.contact?.phone || ""}
                        defaultCountry={state?.contact?.countryCode || ""}
                        containerClassName={`intl-tel-input ${isValidPhone ? "" : "invalid-number"}`}
                    />

                    {!isValidPhone && (
                        <small className="d-block text-danger mt-3">
                            {errors?.["contact.phone"] || "Invalid number"}
                        </small>
                    )}
                    <Alert
                        severity="info"
                        sx={{
                            color: "#1A284D",
                            marginTop: "24px",
                            lineHeight: "130%",
                            fontFamily: "Inter, sans-serif",
                        }}
                    >
                        Your phone number will be used only for essential communications. We assure you, there will be
                        no general notifications or unnecessary disturbances.
                    </Alert>
                </>
            )}
        </div>
    );
};

export default ContactPreference;
