import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { startCase } from "@app/helpers";
import { STATUS, PAGINATION } from "@app/enums";
import { setSorting, fetchStatuses, getInterviews, setPagination } from "@app/actions/Talent/Interviews";
import { Token, Filters, DateTime, ApplicantName, DataGridTable, CalendarIntegration } from "@app/components";

const InterviewsList = () => {
    const dispatch = useDispatch();
    const { filters, sorting, interviews, pagination, selectedCalendar } = useSelector(
        (state) => state.talent.interviews,
    );

    const tableColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            flex: 0.2,
            minWidth: 250,
            sortable: false,
            field: "applicant",
            headerName: "Candidate",
            renderCell: (params) => <ApplicantName interview={params?.row} />,
        },
        {
            flex: 0.1,
            sortable: false,
            headerName: "Role",
            field: "prefered_position",
            renderCell: (params) => (
                <span className="text-capitalize">{params?.row?.applicant?.preferences?.prefered_position || "-"}</span>
            ),
        },
        {
            flex: 0.1,
            sortable: false,
            headerName: "Experience",
            field: "experience_length",
            valueGetter: (params) => {
                const experience = params?.row?.applicant?.analytics?.experience_length || null;

                if (experience) {
                    return `${experience} Years`;
                }

                return "-";
            },
        },
        {
            width: 80,
            field: "resume",
            sortable: false,
            headerName: "Resume",
            renderCell: (params) => {
                const cvMedia = params?.row?.applicant?.media?.find((m) => m?.collection_name === "cvs") || null;

                const cvUrl = cvMedia?.original_url || cvMedia?.preview_url || null;

                return cvUrl ? (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={cvUrl || "#"}
                        className="btn-link"
                    >
                        View
                    </a>
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            flex: 0.2,
            minWidth: 250,
            sortable: true,
            field: "start_at",
            headerName: "Date/Time",
            renderCell: (params) => <DateTime interview={params?.row} />,
        },
        {
            minWidth: 100,
            field: "status",
            sortable: false,
            headerName: "Status",
            renderCell: (params) => <p className={STATUS.STATUS_CLASS[params.value]}>{startCase(params.value)}</p>,
        },
        {
            flex: 0.2,
            minWidth: 160,
            headerName: "",
            align: "right",
            sortable: false,
            field: "actions",
            headerAlign: "right",
            renderCell: (params) => {
                const { status, editable } = params?.row;

                const isPast = status === STATUS.INTERVIEW_STATUS.PAST;
                const isCancelled = status === STATUS.INTERVIEW_STATUS.CANCELLED;
                const isCompleted = status === STATUS.INTERVIEW_STATUS.COMPLETED;
                const hasLink = !!params?.row?.meeting_url || false;

                if (isCancelled) {
                    return null;
                }

                return (
                    <>
                        {hasLink && !isCompleted && !isPast && (
                            <a
                                target="_blank"
                                className="btn-link"
                                rel="noopener noreferrer"
                                href={params?.row?.meeting_url}
                            >
                                Start Call
                            </a>
                        )}

                        {editable && (
                            <Link
                                className="btn-link"
                                to={`${params?.row?.id}`}
                            >
                                {isCompleted ? "Update" : "Submit"}
                            </Link>
                        )}
                    </>
                );
            },
        },
    ];

    const changePage = (_page) => {
        dispatch(
            setPagination({
                page: _page,
            }),
        );
    };

    const changePageSize = (_pageSize) => {
        dispatch(
            setPagination({
                pageSize: _pageSize,
            }),
        );
    };

    const handleSortModelChange = React.useCallback(
        (sortModel) => {
            const model = sortModel[0];

            // No change in sorting options
            if (model?.field === sorting?.field && model?.sort === sorting?.sort) return;

            dispatch(setSorting(model));
        },
        [dispatch, sorting?.field, sorting?.sort],
    );

    useEffect(() => {
        if (selectedCalendar?.id) {
            dispatch(
                fetchStatuses({
                    interviewerId: selectedCalendar?.id,
                }),
            );
        }
    }, [dispatch, selectedCalendar?.id]);

    useEffect(() => {
        if (selectedCalendar?.id) {
            dispatch(
                getInterviews({
                    sortBy: sorting?.field,
                    sortDir: sorting?.sort,
                    status: filters?.status,
                    page: pagination?.page + 1,
                    pageSize: pagination?.pageSize,
                    interviewerId: selectedCalendar?.id,
                }),
            );
        }
    }, [
        dispatch,
        filters?.status,
        pagination?.page,
        pagination?.pageSize,
        selectedCalendar?.id,
        sorting?.field,
        sorting?.sort,
    ]);

    return (
        <>
            <div className="py-3 d-flex justify-content-between flex-wrap-reverse">
                <Filters />

                <Token />

                <CalendarIntegration />
            </div>

            <div className="table-container">
                <DataGridTable
                    data={interviews}
                    sortingMode="server"
                    sortModel={[sorting]}
                    paginationMode="server"
                    page={pagination?.page}
                    tableColumns={tableColumns}
                    rowCount={pagination?.total}
                    sortingOrder={["desc", "asc"]}
                    pageSize={pagination?.pageSize}
                    onSortModelChange={handleSortModelChange}
                    onPageChange={(newPage) => changePage(newPage)}
                    onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
                    hideFooterPagination={pagination?.total <= PAGINATION.PAGINATE_PER_PAGE}
                />
            </div>
        </>
    );
};

export default InterviewsList;
