import React from "react";
import { format } from "date-fns";

import "./index.scss";

const NoteRecord = ({ type, author, content, timestamp }) => {
    return (
        <div className="note-record">
            <div className="header">
                <div className="details">
                    <h5 className="type">{type ?? "Note"}</h5>
                    <p className="author">{author ?? "System"}</p>
                </div>

                <p className="timestamp">
                    {format(new Date(timestamp), "MMM dd, yyyy")}
                    {" at "}
                    {format(new Date(timestamp), "hh:mm a")}
                </p>
            </div>

            <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content || "" }}
            />
        </div>
    );
};

export default NoteRecord;
