import React from "react";
import { useSelector } from "react-redux";

const RangeCriteriaItem = ({ criteria, isChild = false }) => {
    const { rating } = useSelector((state) => state.talent.interviews);

    const hasChildren = () => criteria?.children && criteria?.children?.length > 0;

    return (
        <div
            style={{ margin: "0.5rem 0", alignItems: "center" }}
            className={`criteria-group d-flex justify-content-between ${hasChildren() ? "flex-column" : ""} ${
                isChild ? "child" : ""
            }`}
        >
            <div className={hasChildren() ? `w-100` : ""}>
                <label className="criteria-label">{criteria?.criteria}</label>
                {criteria?.description && <p>{criteria?.description}</p>}
            </div>

            {hasChildren() && (
                <ul className="ps-4 mb-2 w-100">
                    {criteria?.children?.map((child) => (
                        <li key={child?.id}>
                            <RangeCriteriaItem
                                isChild
                                criteria={child}
                            />
                        </li>
                    ))}
                </ul>
            )}

            <div className="rate">
                {criteria?.values?.map((value, key) => (
                    <span key={key}>
                        <input
                            type="radio"
                            value={value}
                            name={`rate[${criteria?.id}]`}
                            id={`star${key + 1}-${criteria?.id}`}
                            defaultChecked={rating?.currentRating?.[criteria["id"]] === value}
                        />

                        <label
                            className="rating-number"
                            title={`${value} Points`}
                            htmlFor={`star${key + 1}-${criteria?.id}`}
                        >
                            {value}
                        </label>
                    </span>
                ))}
            </div>
        </div>
    );
};

export default RangeCriteriaItem;
