import React from "react";
import set from "lodash/fp/set";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";

import { TalentWorkPreferences } from "@app/contexts";

const TalentWorkPreferencesContextProvider = ({ children }) => {
    const [errors, setErrors] = React.useState({});

    const [state, setState] = React.useState({});

    const hasError = React.useMemo(() => Object.values(errors).filter(Boolean).length > 0, [errors]);

    const updateState = React.useCallback((path, value) => {
        setState((prev) => set(path, value, prev));
    }, []);

    const resetState = React.useCallback(() => {
        setState({});
    }, []);

    const updateErrors = React.useCallback((path, value) => {
        let parsedValue = value;

        if (Array.isArray(value)) {
            parsedValue = value?.[0];
        }

        if (isObject(value)) {
            parsedValue = Object.values(value)?.[0];
        }

        setErrors((prev) => {
            if (isNil(parsedValue)) {
                delete prev[path];

                return { ...prev };
            } else {
                return {
                    ...prev,
                    [path]: parsedValue,
                };
            }
        });
    }, []);

    const hasSegmentError = React.useCallback(
        (segment) => {
            return Object.keys(errors).some((e) => e?.startsWith(segment));
        },
        [errors],
    );

    const clearErrors = React.useCallback(() => {
        setErrors({});
    }, []);

    const value = {
        state,
        errors,
        hasError,
        resetState,
        updateState,
        clearErrors,
        updateErrors,
        hasSegmentError,
    };

    return <TalentWorkPreferences.Provider value={value}>{children}</TalentWorkPreferences.Provider>;
};

export default TalentWorkPreferencesContextProvider;
