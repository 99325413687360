import React from "react";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

import NoteRecord from "../NoteRecord";

import "./index.scss";

const NoteList = ({ notes }) => {
    if (isNil(notes) || isEmpty(notes)) {
        return <p className="notes-list-empty">No activity recorded yet.</p>;
    }
    return (
        <div className="notes-list">
            {notes?.map((note) => (
                <NoteRecord
                    key={note.id}
                    content={note.note}
                    author={note.owner_name}
                    type={note.formatted_type}
                    timestamp={note.created_at_timestamp}
                />
            ))}
        </div>
    );
};

export default NoteList;
