import isNil from "lodash/isNil";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { useTalentWorkPreferences } from "@app/hooks";
import FormSelect from "@app/components/New/molecules/formSelect";
import { RadioInput, HelperText, ButtonDatePicker } from "@app/components";

const AVAILABILITIES = [
    {
        value: "part-time",
        label: (
            <span>
                <span className="option-dark">Part-Time </span>
                <span className="option-light">(less than 20 hours weekly)</span>
            </span>
        ),
    },
    {
        value: "full-time",
        label: (
            <span>
                <span className="option-dark">Full-Time </span>
                <span className="option-light">(40 hours weekly)</span>
            </span>
        ),
    },
    {
        value: "flexible",
        label: (
            <span>
                <span className="option-dark">Flexible</span>
            </span>
        ),
    },
];

const AVAILABLE_AT = {
    MONTH: "1_month",
    SIX_MONTHS: "6_months",
    THREE_MONTHS: "3_months",
};

const AvailabilityPreference = () => {
    const { state, errors, updateState, updateErrors } = useTalentWorkPreferences();
    const { user_availability } = useSelector((state) => state.talent.account);

    const isCustomAvailableAtDate = useMemo(
        () => !Object.values(AVAILABLE_AT).includes(state?.availability?.available_at),
        [state?.availability?.available_at],
    );

    const handleIsAvailableChange = (newValue) => {
        updateErrors("availability.type", null);
        updateErrors("availability.available_at", null);

        updateState("availability.isAvailable", newValue);
    };

    const handleAvailabilityTypeChange = (newValue) => {
        updateErrors("availability.type", null);
        updateErrors("availability.available_at", null);

        updateState("availability.type", newValue);
    };

    const handleAvailableInChange = React.useCallback(
        (newValue) => {
            updateErrors("availability.type", null);
            updateErrors("availability.available_at", null);

            updateState("availability.available_at", newValue);
        },
        [updateErrors, updateState],
    );

    React.useEffect(() => {
        if (!isNil(user_availability?.type)) {
            updateState("availability.isAvailable", user_availability?.type !== "unavailable");
        }

        updateState("availability.type", user_availability?.type);
    }, [updateState, user_availability?.type]);

    React.useEffect(() => {
        if (!isNil(user_availability?.available_at)) {
            handleAvailableInChange(new Date(user_availability?.available_at));
        }
    }, [handleAvailableInChange, user_availability?.available_at]);

    return (
        <div className="availability-preference">
            <div className="preference">
                <label className="preference-label">Are you available to receive job opportunities?</label>

                <div className="d-flex">
                    <RadioInput
                        inline
                        label="Yes"
                        additionalStyle={{ minWidth: "110px" }}
                        onChange={() => handleIsAvailableChange(true)}
                        checked={state?.availability?.isAvailable === true}
                    />

                    <RadioInput
                        inline
                        label="No"
                        additionalStyle={{ minWidth: "110px" }}
                        onChange={() => handleIsAvailableChange(false)}
                        checked={state?.availability?.isAvailable === false}
                    />
                </div>
            </div>

            {state?.availability?.isAvailable === true && (
                <div className="preference">
                    <label className="preference-label">What is your availability?</label>

                    <FormSelect
                        required
                        optionsData={AVAILABILITIES}
                        value={state?.availability?.type}
                        error={errors?.["availability.type"]}
                        onChange={(option) => handleAvailabilityTypeChange(option.value)}
                    />
                </div>
            )}

            {state?.availability?.isAvailable === false && (
                <div className="preference">
                    <label className="preference-label">When will you be available again?</label>

                    <div className="d-block d-md-flex justify-content-between flex-wrap">
                        <div className="mb-3 mb-md-0">
                            <RadioInput
                                label="In 1 month"
                                className="mr-sm-0"
                                additionalStyle={{ minWidth: "200px" }}
                                onChange={() => handleAvailableInChange(AVAILABLE_AT.MONTH)}
                                checked={state?.availability?.available_at === AVAILABLE_AT.MONTH}
                            />
                        </div>

                        <div className="mb-3 mb-md-0">
                            <RadioInput
                                label="In 3 months"
                                className="mr-sm-0"
                                additionalStyle={{ minWidth: "200px" }}
                                onChange={() => handleAvailableInChange(AVAILABLE_AT.THREE_MONTHS)}
                                checked={state?.availability?.available_at === AVAILABLE_AT.THREE_MONTHS}
                            />
                        </div>

                        <div className="mb-3 mb-md-0">
                            <RadioInput
                                label="In 6 months"
                                className="mr-sm-0"
                                additionalStyle={{ minWidth: "200px" }}
                                onChange={() => handleAvailableInChange(AVAILABLE_AT.SIX_MONTHS)}
                                checked={state?.availability?.available_at === AVAILABLE_AT.SIX_MONTHS}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <ButtonDatePicker
                            disablePast
                            onChange={handleAvailableInChange}
                            value={isCustomAvailableAtDate ? state?.availability?.available_at : null}
                        />
                    </div>

                    <HelperText
                        variant="error"
                        text={errors?.["availability.available_at"]}
                    />
                </div>
            )}
        </div>
    );
};

export default AvailabilityPreference;
