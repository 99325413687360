import api from "@app/services/api";

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const TALENT_INVITE_CUSTOMER_BEGIN = "TALENT_INVITE_CUSTOMER_BEGIN";
export const talentInviteCustomerBegin = () => ({
    type: TALENT_INVITE_CUSTOMER_BEGIN,
});

export const TALENT_INVITE_CUSTOMER_SUCCESS = "TALENT_INVITE_CUSTOMER_SUCCESS";
export const talentInviteCustomerSuccess = (data) => ({
    payload: data,
    type: TALENT_INVITE_CUSTOMER_SUCCESS,
});

export const TALENT_INVITE_CUSTOMER_FAILED = "TALENT_INVITE_CUSTOMER_FAILED";
export const talentInviteCustomerFailed = (error) => ({
    payload: error,
    type: TALENT_INVITE_CUSTOMER_FAILED,
});

export const talentInviteCustomer = (body) => {
    return async (dispatch) => {
        try {
            dispatch(talentInviteCustomerBegin());
            const response = await api.post(`referrals/invitations`, body);
            dispatch(talentInviteCustomerSuccess(response.data));
            return response;
        } catch (error) {
            dispatch(talentInviteCustomerFailed(error));
            throw error;
        }
    };
};
