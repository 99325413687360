import React from "react";
import { useSelector } from "react-redux";

import { SupportedCalendars } from "@app/config";
import { InterviewsList, UnsupportedIntegration, MissingCalendarIntegrationAlert } from "@app/components";

import "./index.scss";

const InterviewerIntegration = () => {
    const { selectedCalendar } = useSelector((state) => state.talent.interviews);

    if (SupportedCalendars.includes(selectedCalendar?.integration) === false) {
        return <UnsupportedIntegration />;
    }

    return (
        <>
            <MissingCalendarIntegrationAlert />

            <InterviewsList />
        </>
    );
};

export default InterviewerIntegration;
