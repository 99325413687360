import React from "react";
import { useSelector } from "react-redux";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

const BooleanCriteriaItem = ({ criteria }) => {
    const { rating } = useSelector((state) => state.talent.interviews);

    return (
        <FormGroup>
            <FormControlLabel
                value="1"
                label={criteria?.criteria}
                name={`rate[${criteria?.id}]`}
                control={<Checkbox size="small" />}
                defaultChecked={rating?.currentRating?.[criteria?.id] === 1}
            />
        </FormGroup>
    );
};

export default BooleanCriteriaItem;
