import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Divider, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { setCompany } from "@app/actions/authentication";

import { useStyles } from "../../style";

const CheckableMenuItem = ({ el, checked, setAnchorEl }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const styles = useMemo(() => (checked ? { paddingLeft: "0px" } : {}), [checked]);

    return (
        <>
            <MenuItem
                className={classes.optionItem}
                onClick={() => {
                    dispatch(setCompany(el));
                    setAnchorEl(null);
                }}
            >
                {checked && (
                    <ListItemIcon style={{ minWidth: "22px" }}>
                        <i
                            className="icon-checkmark"
                            style={{ width: "22px", height: "25px", marginRight: "13px" }}
                        />
                    </ListItemIcon>
                )}

                <ListItemText
                    inset
                    style={styles}
                    className={classes.listItem}
                >
                    {el.name}
                </ListItemText>
            </MenuItem>

            <Divider style={{ color: "#EBECF2" }} />
        </>
    );
};

const CompanySwitch = () => {
    const classes = useStyles();
    const { company, userProfile } = useSelector((state) => state.authentication);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <button
                className="icon-dropdown-form"
                style={open ? { background: "#fff" } : undefined}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <i className="icon-company" />
                <p>{company?.name}</p>
                <i
                    style={{ marginLeft: "auto" }}
                    className={open ? "icon-drop-up" : "icon-drop-down"}
                />
            </button>

            <Menu
                open={open}
                anchorEl={anchorEl}
                className={classes.dropdownMenu}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {userProfile?.companies.map((el) => (
                    <CheckableMenuItem
                        el={el}
                        key={el.id}
                        setAnchorEl={setAnchorEl}
                        checked={company?.id === el.id}
                    />
                ))}
            </Menu>
        </>
    );
};

export default CompanySwitch;
