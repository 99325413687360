import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import EmailPreview from "@app/components/EmailPreview";
import { talentInviteCustomer } from "@app/actions/Talent/Invitations";
import { RefereeType, ReferralInvitationType, ReferralInvitationReason } from "@app/enums";

import Label from "../../New/atoms/label";
import Input from "../../New/molecules/input";

import "./index.scss";

export default function EmailInviteModal({ activeTab, handleClose }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(null);
    const [emailBody, setEmailBody] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [refereeEmail, setRefereeEmail] = useState("");
    const { fullName, first_name } = useSelector((state) => state.authentication.userProfile);

    const handleEmailBodyChange = (event) => {
        setEmailBody(event.target.value);
    };

    const handleRefereeEmailChange = (event) => {
        setRefereeEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitting(true);

        const refereeTypeMap = {
            "Client Programs": RefereeType.CLIENT,
            "Talent Programs": RefereeType.TALENT,
        };

        const formData = {
            invitations: [
                {
                    email: refereeEmail,
                    emailContent: emailBody,
                    referee_type: refereeTypeMap[activeTab],
                    invitation_type: ReferralInvitationType.EMAIL,
                    invitation_reason: ReferralInvitationReason.APPLY,
                },
            ],
        };

        dispatch(talentInviteCustomer(formData))
            .then(() => {
                setSubmitting(false);
                handleClose();
            })
            .catch((error) => {
                if (error) {
                    setErrors(error);
                    setSubmitting(false);
                } else {
                    setSubmitting(true);
                    handleClose();
                }
            });
    };

    return (
        <Modal
            size="lg"
            show={true}
            onHide={handleClose}
            id="EmailInviteModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p
                        className="modal-title"
                        style={{
                            fontSize: "24px",
                            color: "#1A284D",
                            lineHeight: "36px",
                            fontFamily: "Poppins",
                        }}
                    >
                        Email Invite
                    </p>
                </Modal.Title>
            </Modal.Header>

            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    {/* Workaround: Ignore a specific non-Hubspot form from being collected */}
                    <label
                        htmlFor="cc-num"
                        className="d-none"
                    ></label>
                    <input
                        required=""
                        id="cc-num"
                        name="cc-num"
                        className="d-none"
                        value="HubspotCollectedFormsWorkaround"
                    />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="form-group">
                                <Label
                                    label="Referee Email"
                                    elementName="content"
                                    style={{ fontWeight: 500 }}
                                />
                                <Input
                                    type="text"
                                    name="email"
                                    error={errors}
                                    value={refereeEmail}
                                    onChange={handleRefereeEmailChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-xl-12 mb-4">
                            <Label
                                label="Email Body"
                                elementName="content"
                                style={{ fontWeight: 500 }}
                            />
                            <textarea
                                rows="3"
                                cols="30"
                                value={emailBody}
                                name="emailBodyTextarea"
                                style={{ resize: "none" }}
                                onChange={handleEmailBodyChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <div className="row mb-4">
                                <div className="col-xl-12">
                                    <Label
                                        label="Email Preview"
                                        elementName="content"
                                        style={{ fontWeight: 500 }}
                                    />
                                    <EmailPreview
                                        fullName={fullName}
                                        emailBody={emailBody}
                                        firstName={first_name}
                                        buttonText="Click here to join Adeva"
                                        title="is inviting you to join Adeva"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="btn-action btn-blue mr-0"
                    >
                        Send Email
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
