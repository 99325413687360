import Fuse from "fuse.js";
import shortUUID from "short-uuid";

import { userManagement } from "@app/actions";
import { setSelectedProp } from "@app/helpers";

const initialState = {
    data: [],
    clientRoles: [],
    filteredData: [],
    dataToEdit: null,
    dashboardData: [],
    searchKeyword: "",
    companyDetails: [],
    resendEmail: false,
    removeAccess: false,
    DESCSortByDate: true,
    fuseSearch: new Fuse([]),
    fuseOptions: {
        threshold: 0,
        keys: ["name", "email", "client_roles.name", "groups.name", "last_login"],
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

const reMapData = (data) => {
    return data.map((el) => {
        return {
            ...el,
            uid: shortUUID.generate(),
            groups: el.client_roles[0].groups,
        };
    });
};

const sortData = (filteredData, DESCSortByDate) => {
    const dataWithoutDate = filteredData.filter((el) => el.last_login === "");
    const dataWithDate = filteredData.filter((el) => el.last_login !== "");
    if (DESCSortByDate) {
        return dataWithDate
            .slice()
            .sort((a, b) => new Date(b.last_login) - new Date(a.last_login))
            .concat(dataWithoutDate);
    }
    return dataWithDate
        .slice()
        .sort((a, b) => new Date(a.last_login) - new Date(b.last_login))
        .concat(dataWithoutDate);
};

const filterData = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child.id);
        if (dataToFilter.length === 0) return;
        if (el.parent === "Groups") {
            result = result.filter((item) =>
                dataToFilter.some((element) => item.groups.map((group) => group.id).includes(element)),
            );
        }
        if (el.parent === "Roles") {
            result = result.filter((item) => dataToFilter.includes(item.client_roles[0].id));
        }
    });
    return result;
};

export default function userManagementReducer(state = initialState, action) {
    switch (action.type) {
        case userManagement.USER_MANAGEMENT_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.data],
            };
        }
        case userManagement.RESEND_EMAIL: {
            return {
                ...state,
                resendEmail: !state.resendEmail,
            };
        }
        case userManagement.REMOVE_ACCESS: {
            return {
                ...state,
                removeAccess: !state.removeAccess,
            };
        }
        case userManagement.DATA_TO_EDIT: {
            return {
                ...state,
                dataToEdit: action.selectedRow,
            };
        }
        case userManagement.CLEAR_ACTION: {
            const resetVal = setSelectedProp(state.clientRoles, false);
            return {
                ...state,
                filteredData: state.data,
                clientRoles: [...resetVal],
                action: {
                    ...initialState.action,
                },
            };
        }
        case userManagement.SORT_DATA: {
            return {
                ...state,
                DESCSortByDate: !state.DESCSortByDate,
                filteredData: sortData(state.filteredData, state.DESCSortByDate),
            };
        }
        case userManagement.FILTER_DATA_USER_MANAGEMENT: {
            return {
                ...state,
                filteredData: filterData(action.payload, state.data),
            };
        }
        case userManagement.GET_CLIENT_ROLES_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_CLIENT_ROLES_SUCCESS: {
            return {
                ...state,
                clientRoles: setSelectedProp(action.payload.data.client_roles.data, false),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_CLIENT_ROLES_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_USERS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_USERS_SUCCESS: {
            const reMapedUserData = reMapData(action.payload.data.company_users.data);
            return {
                ...state,
                data: reMapedUserData,
                filteredData: reMapedUserData,
                fuseSearch: new Fuse(reMapedUserData, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_USERS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.REMOVE_USER_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.REMOVE_USER_SUCCESS: {
            const filtered_data = state.data.filter((el) => el.id !== action.payload.data.removed_id);
            return {
                ...state,
                data: [...filtered_data],
                filteredData: [...filtered_data],
                fuseSearch: new Fuse(filtered_data, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.REMOVE_USER_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.REMOVE_INVITATION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.REMOVE_INVITATION_SUCCESS: {
            const filtered_invitation = state.data.filter((el) => el.email !== action.payload.data.removed_email);
            return {
                ...state,
                data: [...filtered_invitation],
                filteredData: [...filtered_invitation],
                fuseSearch: new Fuse([...filtered_invitation], initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.REMOVE_INVITATION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.INVITE_USER_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.INVITE_USER_SUCCESS: {
            const reMapedData = reMapData(action.payload.data.company_users.data);
            return {
                ...state,
                data: reMapedData,
                filteredData: reMapedData,
                fuseSearch: new Fuse(reMapedData, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.INVITE_USER_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.UPDATE_CLIENT_ROLE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.UPDATE_CLIENT_ROLE_SUCCESS: {
            const reMaped = reMapData(action.payload.data.company_users.data);
            return {
                ...state,
                data: reMaped,
                filteredData: reMaped,
                fuseSearch: new Fuse(reMaped, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.UPDATE_CLIENT_ROLE_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.UPDATE_GROUP_FOR_CLIENT_ROLE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.UPDATE_GROUP_FOR_CLIENT_ROLE_SUCCESS: {
            const reMaped = reMapData(action.payload.data.company_users.data);
            return {
                ...state,
                data: reMaped,
                filteredData: sortData(reMaped, true),
                fuseSearch: new Fuse(reMaped, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.UPDATE_GROUP_FOR_CLIENT_ROLE_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.RESEND_INVITATION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.RESEND_INVITATION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.RESEND_INVITATION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.ACCEPT_INVITATION_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.ACCEPT_INVITATION_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case userManagement.ACCEPT_INVITATION_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.GET_DASHBOARD_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboardData: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_DASHBOARD_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.GET_COMPANY_DETAILS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_COMPANY_DETAILS_SUCCESS: {
            return {
                ...state,
                companyDetails: action.payload,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case userManagement.GET_COMPANY_DETAILS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.UPDATE_COMPANY_DETAILS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.UPDATE_COMPANY_DETAILS_SUCCESS: {
            return {
                ...state,
                companyDetails: action.payload,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: "Company Details were successfully updated!",
                },
            };
        }
        case userManagement.UPDATE_COMPANY_DETAILS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case userManagement.SET_COMPANY_DETAILS_ATTRIBUTE: {
            return {
                ...state,
                companyDetails: {
                    ...state.companyDetails,
                    company: {
                        ...state.companyDetails.company,
                        [action.key]: action.value,
                    },
                },
            };
        }
        case userManagement.REMOVE_GROUP_FOR_CLIENT_ROLE_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case userManagement.REMOVE_GROUP_FOR_CLIENT_ROLE_SUCCESS: {
            const reMaped = reMapData(action.payload.company_users.data);
            return {
                ...state,
                data: reMaped,
                filteredData: sortData(reMaped, true),
                fuseSearch: new Fuse(reMaped, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.message,
                },
            };
        }
        case userManagement.REMOVE_GROUP_FOR_CLIENT_ROLE_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        default: {
            return state;
        }
    }
}
