export const INTERVIEW_STATUS = {
    PAST: "past",
    UPCOMING: "upcoming",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
    IN_PROGRESS: "in_progress",
};

export const STATUS_CLASS = {
    upcoming: "px-2 text-capitalize tag-text primary",
    completed: "px-2 text-capitalize tag-text active",
    in_progress: "px-2 text-capitalize tag-text yellow",
    cancelled: "px-2 text-capitalize tag-text declined",
    past: "px-2 text-capitalize tag-text bg-secondary text-white",
};

export const COMPANY_STATUS = {
    PENDING: "Pending",
    REJECTED: "Rejected",
};

export const MEETING_STATUS = {
    NO_SHOW: "NO_SHOW",
    CANCELED: "CANCELED",
    SCHEDULED: "SCHEDULED",
    COMPLETED: "COMPLETED",
    UNSCHEDULED: "UNSCHEDULED",
    RESCHEDULED: "RESCHEDULED",
};

export const POSITION_STATUS = {
    IN_PROGRESS: 6,
};

export const PROSPECT_STATUS = {
    NEW: 68,
};

export const CODE = {
    // PAYMENTS
    PAID: "PAID",
    APP_NEW: "APP_NEW",
    // TIME_OFF
    PENDING: "PENDING",
    EXPIRED: "EXPIRED",
    // EQUIPMENT
    EQI_SENT: "EQI_SENT",
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
    // POSITION
    POS_DRAFT: "POS_DRAFT",
    INTERVIEW: "INTERVIEW",
    APP_CLOSED: "APP_CLOSED",

    POS_ONHOLD: "POS_ONHOLD",
    POS_FILLED: "POS_FILLED",
    APP_ON_HOLD: "APP_ON_HOLD",
    POS_ONGOING: "POS_ONGOING",

    // CONTRACT
    SHORTLISTED: "SHORTLISTED",
    APP_ACCEPTED: "APP_ACCEPTED",
    EQI_RECEIVED: "EQI_RECEIVED",
    EQI_RETURNED: "EQI_RETURNED",
    POS_CANCELED: "POS_CANCELED",
    POS_DECLINED: "POS_DECLINED",
    POS_PUBLISHED: "POS_PUBLISHED",
    CON_ONBOARDED: "CON_ONBOARDED",
    APP_INCOMPLETE: "APP_INCOMPLETE",
    CONTRACT_PREPS: "CONTRACT_PREPS",

    // COMPANY
    COMPANY_PENDING: "COMPANY_PENDING",
    RAISED_INTEREST: "RAISED_INTEREST",
    APP_INITIAL_CALL: "APP_INITIAL_CALL",

    EQI_REQUEST_BACK: "EQI_REQUEST_BACK",

    COMPANY_ACCEPTED: "COMPANY_ACCEPTED",
    COMPANY_REJECTED: "COMPANY_REJECTED",
    APP_TECH_CALL_SCH: "APP_TECH_CALL_SCH",

    POS_CLIENT_REVIEW: "POS_CLIENT_REVIEW",
    APP_TECH_INTERVIEW: "APP_TECH_INTERVIEW",
    POS_TO_BE_REVIEWED: "POS_TO_BE_REVIEWED",
    POS_CONTRACT_PREPS: "POS_CONTRACT_PREPS",
    APP_TECH_ASSIGNMENT: "APP_TECH_ASSIGNMENT",
    APP_INITIAL_CALL_SCH: "APP_INITIAL_CALL_SCH",
    INTRODUCED_TO_CLIENT: "INTRODUCED_TO_CLIENT",
    AVAILABILITY_REQUESTED: "AVAILABILITY_REQUESTED",
};

export const GROUP = {
    COMPANY: "COMPANY",
    CONTRACT: "CONTRACT",
    PAYMENTS: "PAYMENTS",
    POSITION: "POSITION",
    PROSPECT: "PROSPECT",
    TIME_OFF: "TIME_OFF",
    APPLICANT: "APPLICANT",
    EQUIPMENT: "EQUIPMENT",
};
