import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";

import App from "@app/App";
import store from "@app/store";
import Error from "@app/components/Error";
import { configureSentry } from "@app/config/sentry";
import ModalsContextProvider from "@app/providers/ModalsContextProvider";
import AxiosInterceptorProvider from "@app/providers/AxiosInterceptorProvider";

import "tippy.js/dist/tippy.css";
import "react-date-range/dist/styles.css";
import "react-intl-tel-input/dist/main.css";
import "font-awesome/css/font-awesome.min.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "@app/styles/sass/app.scss";

configureSentry();

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<Error />}>
            <Provider store={store}>
                <AxiosInterceptorProvider>
                    <BrowserRouter>
                        <HelmetProvider>
                            <ModalsContextProvider>
                                <App />
                            </ModalsContextProvider>
                        </HelmetProvider>
                    </BrowserRouter>
                </AxiosInterceptorProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
