import React from "react";

const ResponsiveMenuButton = ({ collapsed, text = "Menu", toggleCollapsed }) => {
    return (
        <button
            onClick={() => toggleCollapsed(!collapsed)}
            className={collapsed ? "dashboard-responsive-nav-trigger" : "dashboard-responsive-nav-trigger active"}
        >
            <span className={collapsed ? "hamburger hamburger--collapse" : "hamburger hamburger--collapse is-active"}>
                <span className="hamburger-box">
                    <span className="hamburger-inner" />
                </span>
            </span>
            <span className="trigger-title">{text}</span>
        </button>
    );
};

export default ResponsiveMenuButton;
