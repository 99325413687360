import React from "react";

import { InterviewNote } from "@app/components";
import { RangeCriteriaList } from "@app/components/Criteria/Range";
import { RadioCriteriaList } from "@app/components/Criteria/Radio";
import { BooleanCriteriaList } from "@app/components/Criteria/Boolean";

const RatingWrapper = ({ rating, handleNoteChange }) => {
    if (!rating) {
        return (
            <p className="empty-note-type-message">
                Please select the interview type to display the corresponding scorecard
            </p>
        );
    }

    return (
        <>
            <RangeCriteriaList criteria={rating?.scoreData} />

            <RadioCriteriaList criteria={rating?.rangeRadioCriteria} />

            <BooleanCriteriaList criteria={rating?.booleanCriteria} />

            <InterviewNote onChange={handleNoteChange} />
        </>
    );
};

export default RatingWrapper;
