import thunk from "redux-thunk";
import { compose, createStore, applyMiddleware } from "redux";

import rootReducer from "@app/reducers";

const composeEnhancers =
    process.env.NODE_ENV === "production" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
