import moment from "moment";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { uniq, isEmpty, snakeCase } from "lodash";
import React, { useState, useContext } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import properties from "@app/properties";
import { SkillsContext } from "@app/contexts";
import { USER_AVAILABILITY } from "@app/enums";
import { FallbackAvatar } from "@app/components";
import { mainHostUrl, escapeForRegex } from "@app/helpers";
import PieChart from "@app/pages/client/Dashboard/PieChart";

import { useStyles } from "../style";

import "./index.scss";

const filterMatchingElements = (elements, keywords) => {
    if (isEmpty(keywords)) return [];

    return elements?.filter((el) => {
        let hasMatch = false;

        for (const keyword of keywords) {
            // Escape regex special characters
            const regex = new RegExp(`${escapeForRegex(keyword)}`, "gi");

            if (regex.test(el)) {
                hasMatch = true;

                break;
            }
        }

        return hasMatch;
    });
};

const getMatchingSegment = (hit, search) => {
    if (hit.length <= MIN_LEN_TO_TRUNCATE) {
        return hit;
    }

    const escapedSearchQuery = escapeForRegex(search?.join(" "))?.split(" ");

    const searchQuery = escapedSearchQuery?.join("|"); // Match any of the keywords

    const matchingRegex = new RegExp(
        `((\\b[^\\s]+\\b)\\s?\\S?\\s?){0,${WORDS_TO_MATCH_BEFORE_AND_AFTER}}(${searchQuery})(\\s?\\S?\\s?(\\b[^\\s]+\\b)){0,${WORDS_TO_MATCH_BEFORE_AND_AFTER}}`,
        "i",
    );

    return hit.match(matchingRegex)?.[0];
};

const MIN_LEN_TO_TRUNCATE = 100;
const WORDS_TO_MATCH_BEFORE_AND_AFTER = 3;

function MatchingHit({ hit, search }) {
    const matchingParagraph = getMatchingSegment(hit, search);

    if (isEmpty(matchingParagraph)) {
        return null;
    }

    return (
        <div className="matching-hit">
            <p>{matchingParagraph}</p>
        </div>
    );
}

function MatchedHits({ data }) {
    const { keywords } = useSelector((state) => state.admin.search);

    const searchQuery = React.useMemo(
        () =>
            keywords
                ?.join(" ")
                ?.replace(/\s(not|and|or)\s/gi, " ") // Remove boolean operators [`AND`, `OR`, `NOT`]
                ?.replace(/\s(&|\+|\|)\s/gi, " ") // Remove boolean operators [`&`, `+`, `|`]
                ?.replace(/-|_/gi, " ") // Remove dashes and underscores to match more strings as possible
                ?.replace(/(\s|^)(!|~|-)\w+/gi, " ") // Remove negated keywords alongside the negation operator [`!`, `-`, `~`]
                ?.replace(/[()]+/gi, "") // Remove parenthesis [`(`, `)`]
                ?.split(" ")
                ?.filter(Boolean) || [],
        [keywords],
    );

    const emails = React.useMemo(
        () => filterMatchingElements(uniq(data?.emails)?.filter((e) => e !== data?.primary_email) || [], searchQuery),
        [data?.emails, data?.primary_email, searchQuery],
    );

    const titles = React.useMemo(
        () => filterMatchingElements(uniq(data?.experience_titles) || [], searchQuery),
        [data?.experience_titles, searchQuery],
    );

    const companies = React.useMemo(
        () => filterMatchingElements(uniq(data?.experience_companies) || [], searchQuery),
        [data?.experience_companies, searchQuery],
    );

    const summaries = React.useMemo(
        () => filterMatchingElements(uniq(data?.experience_summaries) || [], searchQuery),
        [data?.experience_summaries, searchQuery],
    );

    return (
        <>
            {emails?.map((email, index) => (
                <MatchingHit
                    hit={email}
                    search={searchQuery}
                    key={`matching_email_${index}`}
                />
            ))}

            {titles?.map((title, index) => (
                <MatchingHit
                    hit={title}
                    search={searchQuery}
                    key={`matching_title_${index}`}
                />
            ))}

            {companies?.map((company, index) => (
                <MatchingHit
                    hit={company}
                    search={searchQuery}
                    key={`matching_title_${index}`}
                />
            ))}

            {summaries?.map((summary, index) => (
                <MatchingHit
                    hit={summary}
                    search={searchQuery}
                    key={`matching_title_${index}`}
                />
            ))}
        </>
    );
}

const UserMainInfo = React.memo(function UserMainInfo({
    data,
    hide,
    email,
    title,
    referrer,
    userAvailability,
    customMemberSince,
    customLastLoginAt,
}) {
    return (
        <ul>
            {email ? <li>{email}</li> : ""}
            {title ? <li>{title}</li> : ""}
            {data.public === 1 ? (
                <li className="green">Public Profile</li>
            ) : (
                <li className="blueish">Private Profile </li>
            )}
            {hide ? <li style={{ color: "#F1553F" }}>Hidden</li> : ""}

            {userAvailability ? (
                userAvailability.type === USER_AVAILABILITY.UNAVAILABLE ? (
                    <li className="red">
                        Not available{" "}
                        {userAvailability?.available_at &&
                            ` before ${new Date(userAvailability.available_at).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}`}
                    </li>
                ) : (
                    <li className="green">
                        Available {USER_AVAILABILITY.MAP[snakeCase(userAvailability.type)] || userAvailability.type}
                    </li>
                )
            ) : (
                ""
            )}
            {customMemberSince ? <li>{`Member since ${customMemberSince}`}</li> : ""}
            {customLastLoginAt ? <li>{`Last login ${customLastLoginAt}`}</li> : ""}
            {referrer ? <li>{referrer}</li> : ""}
        </ul>
    );
});

const UserSkills = ({ primaryIds, secondaryIds, experienceIds }) => {
    const skills = useContext(SkillsContext);
    const location = useLocation();

    //Filter skills based on IDs
    const filterSkillsByIds = (ids) => {
        const idArray = Array.isArray(ids) ? ids : Object.values(ids) || [];
        return skills?.filter((s) => idArray.includes(s?.id)) || [];
    };

    //Get skills for each category
    const primarySkills = React.useMemo(() => filterSkillsByIds(primaryIds), [skills, primaryIds]);
    const secondarySkills = React.useMemo(() => filterSkillsByIds(secondaryIds), [skills, secondaryIds]);
    const experienceSkills = React.useMemo(() => filterSkillsByIds(experienceIds), [skills, experienceIds]);

    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    const skillParam = getQueryParam("skills");

    //Check if experience skills should be displayed based on search
    const filteredExperienceSkills = experienceSkills.filter((skill) => skill.id.toString() === skillParam);

    return (
        <div className="d-flex flex-column">
            <ul className="dev-skills mt-2">
                {primarySkills?.map((el) => (
                    <li key={el.id}>
                        <p>{el?.name || el?.label}</p>
                    </li>
                ))}

                {secondarySkills?.map((el) => (
                    <li key={el.id}>
                        <p className="transparent">{el?.name || el?.label}</p>
                    </li>
                ))}
            </ul>
            {filteredExperienceSkills.length > 0 && (
                <ul className="dev-skills">
                    {filteredExperienceSkills.map((el) => (
                        <li key={el.id}>
                            <p className="transparent">{el?.name || el?.label}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const Score = React.memo(function Score({ hrScore, techScore, totalScore }) {
    const classes = useStyles();
    const { config } = useSelector((state) => state.admin.search);

    const totalHrScorePercent = React.useMemo(
        () => (config.hrScoreWeight * hrScore) / 100,
        [config.hrScoreWeight, hrScore],
    );
    const totalTechScorePercent = React.useMemo(
        () => (config.technicalScoreWeight * techScore) / 100,
        [config.technicalScoreWeight, techScore],
    );

    return (
        <HtmlTooltip
            placement="bottom-start"
            title={
                <div className="card-hover">
                    <div className="total-points">
                        <span style={{ fontWeight: 600 }}>Total Score</span>
                        <div className="d-flex align-items-baseline">
                            <span className="dev-total-points">{totalScore}</span>{" "}
                            <span className="dev-max-points">/100</span>
                        </div>
                    </div>
                    <div className="card-option border-bottom">
                        <div className="card-subtitle align-items-baseline">
                            <span className="cultural-fit-title">Cultural Fit</span>
                            <div className="d-flex align-items-baseline">
                                <span className="dev-partial-points">{hrScore}</span>{" "}
                                <span className="dev-max-points">/100</span>
                            </div>
                        </div>
                        <LinearProgress
                            variant="determinate"
                            value={parseInt(hrScore)}
                            className={classes.progresBarColor}
                        />
                    </div>
                    <div className="card-option">
                        <div className="card-subtitle align-items-baseline">
                            <span className="tech-score-title">Tech Score</span>
                            <div className="d-flex align-items-baseline">
                                <span className="dev-partial-points">{techScore}</span>
                                <span className="dev-max-points">/100</span>
                            </div>
                        </div>
                        <LinearProgress
                            variant="determinate"
                            value={parseInt(techScore)}
                        />
                    </div>
                </div>
            }
        >
            <div className="score">
                {totalScore !== 0 && (
                    <PieChart
                        CardViewChart
                        totalScore={totalScore}
                        colors={["#198CCA", "#FDB436", "#ADB4C7"]}
                        data={[
                            {
                                value: totalTechScorePercent,
                                name: "totalTechScorePercent",
                            },
                            {
                                value: totalHrScorePercent,
                                name: "totalHrScorePercent",
                            },
                            {
                                value: 100 - totalScore,
                                name: "totalRemainingScore",
                            },
                        ]}
                    />
                )}
            </div>
        </HtmlTooltip>
    );
});

const Footer = React.memo(({ slug, onClick, hourly_rate, socialAccounts, experienceYears, active_contracts_num }) => {
    return (
        <div className="footer">
            <div className="info-wrapper">
                {Boolean(experienceYears) && parseInt(experienceYears) > 0 && (
                    <div className="dev-info-box">
                        <BootstrapTooltip title="Experience">
                            <p>
                                <i className="icon-experience"></i>
                                {parseInt(experienceYears)} Years
                            </p>
                        </BootstrapTooltip>
                    </div>
                )}
                {Boolean(hourly_rate) && (
                    <div className="dev-info-box">
                        <BootstrapTooltip title="Rate">
                            <p>
                                <i className="icon-dollar active"></i>
                                {parseInt(hourly_rate)}.00
                            </p>
                        </BootstrapTooltip>
                    </div>
                )}
                {active_contracts_num > 0 && (
                    <div className="dev-info-box">
                        <BootstrapTooltip title="Active contracts">
                            <p>
                                <i className="icon-positions active"></i>
                                {active_contracts_num}
                            </p>
                        </BootstrapTooltip>
                    </div>
                )}
                {socialAccounts?.length > 0 && (
                    <div className="dev-info-box social-media">
                        <p className="social-icons">
                            {socialAccounts.map((el) => (
                                <a
                                    key={el.id}
                                    href={el.account}
                                >
                                    <i className={`${el.socialAccountIcon} mx-2`}></i>
                                </a>
                            ))}
                        </p>
                    </div>
                )}
            </div>
            <div className="actions-wrapper">
                <button
                    className="btn-action btn-icon btn-green"
                    onClick={() => window.open(mainHostUrl(`resume/${slug}`, "_blank"))}
                >
                    View Profile
                </button>
                <button
                    onClick={onClick}
                    className="btn-action btn-icon datagrid-btn-action"
                >
                    <i
                        className="icon-more"
                        style={{ margin: "0px" }}
                    />
                </button>
            </div>
        </div>
    );
});

const Popper = React.memo(({ open, actions, anchorEl, handleClose }) => {
    const classes = useStyles();
    const handleAction = (action) => {
        action();
        handleClose();
    };

    return (
        <Menu
            open={open}
            id="basic-menu"
            anchorEl={anchorEl}
            onClose={() => handleClose()}
            className={classes.menuHeight}
        >
            {actions?.map((el, idx) => {
                return el.action ? (
                    <MenuItem
                        id={idx}
                        key={idx}
                        className={classes.menuItem}
                        onClick={() => handleAction(el.action)}
                    >
                        {el.label}
                    </MenuItem>
                ) : (
                    <li
                        key={idx}
                        className={classes.menuItem}
                    >
                        <Link
                            href={el.href}
                            target="_blank"
                            underline="hover"
                        >
                            {el.label}
                        </Link>
                    </li>
                );
            })}
        </Menu>
    );
});

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        arrow
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export default function UserCardView({ data, checked, handleUserCheckbox }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="card-view">
            <div className="card-view-wrapper">
                <div className="content declined">
                    {data?.is_declined && <span className="declined"></span>}
                    <div
                        style={{ height: "80px" }}
                        className="avatar-wrap d-flex align-items-center"
                    >
                        <input
                            type="checkbox"
                            className="mr-1"
                            checked={checked}
                            onChange={() => handleUserCheckbox(data?.id, data)}
                        />
                        <FallbackAvatar
                            size="lg"
                            src={data?.avatar}
                            alt={data?.full_name}
                            sx={{
                                width: 80,
                                height: 80,
                            }}
                        />
                    </div>

                    <div className="content-wrap flex-fill">
                        <div className="title-wrapper">
                            <div>
                                <a
                                    className="title"
                                    href={`${properties.MAIN_HOST}admin/talent/applicants/${data.id}`}
                                >
                                    {data?.full_name}
                                </a>
                                <span>{data?.country}</span>
                                <UserMainInfo
                                    data={data}
                                    hide={data.hide}
                                    title={data.title}
                                    email={data?.primary_email}
                                    userAvailability={data.availability}
                                    referrer={data?.referrer_full_name ?? data?.prospect_invited_by}
                                    customLastLoginAt={
                                        data?.last_login_at ? moment.unix(data?.last_login_at).fromNow() : null
                                    }
                                    customMemberSince={
                                        data?.member_since ? moment.unix(data?.member_since).format("MMM Y") : null
                                    }
                                />
                            </div>
                            <div className="score-wrap-title">
                                <Score
                                    hrScore={data.hr_score}
                                    totalScore={data.adeva_score}
                                    techScore={data.technical_score}
                                />
                            </div>
                        </div>
                        <p
                            className="description"
                            style={{ color: "#343f5c", lineHeight: "1.7" }}
                        >
                            {data?.overview}
                        </p>

                        <UserSkills
                            primaryIds={data?.primary_skills || []}
                            secondaryIds={data?.secondary_skills || []}
                            experienceIds={data?.experience_skills || []}
                        />

                        <div className="hits-wrap">
                            <MatchedHits data={data} />
                        </div>
                    </div>
                </div>

                <div className="score-wrap">
                    <Score
                        hrScore={data.hr_score}
                        totalScore={data.adeva_score}
                        techScore={data.technical_score}
                    />
                </div>
            </div>

            <Footer
                slug={data.slug}
                onClick={handleClick}
                socialAccounts={data?.social_accounts}
                experienceYears={data?.experience_years}
                hourly_rate={data?.preferred_hourly_rate}
                active_contracts_num={data?.active_contracts_count}
            />
            <Popper
                open={open}
                anchorEl={anchorEl}
                actions={data.actions}
                handleClose={handleClose}
            />
        </div>
    );
}
