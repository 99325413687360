import React from "react";
import { useSelector } from "react-redux";
import difference from "lodash/difference";
import isUndefined from "lodash/isUndefined";
import intersection from "lodash/intersection";

/**
 * Define an authorization gate for the current user.
 *
 * @param {string|array} role Provide roles to check.
 * @param {string|array} permission Provide permissions to check.
 * @param {"OR"|"AND"} (roleOperator = "OR") Define if all/some roles must be checked. If "AND" is provided, all provided roles must be satisfied, otherwise only one role is sufficient to pass.
 * @param {"OR"|"AND"} (permissionOperator = "OR") Define if all/some permissions must be checked. If "AND" is provided, all provided permissions must be satisfied, otherwise only one permission is sufficient to pass.
 * @param children Any valid JSX syntax to render if the authorization checks pass.
 *
 * @returns {JSX.Element|null}
 */
export default function Authorize({ role, children, permission, roleOperator = "OR", permissionOperator = "OR" }) {
    const { roles = [], permissions = [] } = useSelector((state) => state?.authentication?.userProfile);

    const hasRole = React.useMemo(() => {
        // No role provided, skip roles check
        if (isUndefined(role)) return true;

        const wrappedRoles = Array.isArray(role) ? role : [role];

        // User only needs one of the provided roles to be authorized
        if (roleOperator === "OR") {
            return intersection(roles, wrappedRoles)?.length > 0 || false;
        }

        // User needs ALL of the provided roles to be authorized
        return difference(wrappedRoles, roles)?.length === 0 || false;
    }, [role, roleOperator, roles]);

    const hasPermission = React.useMemo(() => {
        // No permission provided, skip permissions check
        if (isUndefined(permission)) return true;

        const wrappedPermissions = Array.isArray(permission) ? permission : [permission];

        // User only needs one of the provided permissions to be authorized
        if (permissionOperator === "OR") {
            return intersection(permissions, wrappedPermissions)?.length > 0 || false;
        }

        // User needs ALL of the provided permissions to be authorized
        return difference(wrappedPermissions, permissions)?.length === 0 || false;
    }, [permission, permissionOperator, permissions]);

    // No role or permission provided to check
    if (!role && !permission) {
        return null;
    }

    // User doesn't have the required role or permission
    if (!hasRole || !hasPermission) {
        return null;
    }

    return children;
}
