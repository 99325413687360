import React from "react";
import { isEmpty } from "lodash";

export default function FilterWidgetServerSide({ filters, onClick, className, selectedFilter }) {
    if (!filters || isEmpty(filters)) {
        return null;
    }

    return (
        <div className={`filter-widget-form ${className}`}>
            {filters?.map((el, index) => {
                return (
                    <div
                        key={index}
                        className={el.name === selectedFilter ? "widget active" : "widget"}
                    >
                        {Number(el?.number_of_items) > 0 ? (
                            <>
                                <p className="text-capitalize">{el.name === "Active" ? "Accepted" : el.name}</p>
                                <p className="tag-number">{el.number_of_items}</p>
                                <button
                                    id={index}
                                    type="button"
                                    onClick={() => onClick(el)}
                                    className="full-click-button"
                                />
                            </>
                        ) : (
                            <p className="empty-state-text">{el.name === "Active" ? "Accepted" : el.name}</p>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
