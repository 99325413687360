export const NOT_FOUND_PATH = "/talent/not-found";
export const INTERVIEWS_PATH = "/talent/interviews";
export const INTERVIEWS_GUIDE_PATH = "/talent/interviews/guide";
export const HEALTH_INSURANCE_PATH = "/talent/health-insurance";
export const TALENT_PROGRAMS = "/talent/community/programs";
export const TALENT_LEARNING = "/talent/community/learning";
export const TALENT_PERKS = "/talent/community/perks";
export const DASHBOARD_PATH = "/talent/overview";
export const REFERRALS = "/talent/referrals";
export const POSITIONS = "/talent/positions";
