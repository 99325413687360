import React from "react";

export default function Label({ label, required, elementName, ...props }) {
    return (
        <label
            htmlFor={elementName}
            {...props}
        >
            {label} {required && <span className="required">*</span>}
        </label>
    );
}
