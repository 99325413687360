import React from "react";

import api from "@app/services/api";
import Input from "@app/components/New/molecules/input";
import FormSelect from "@app/components/New/molecules/formSelect";

function prepareStatesForSelect(states, valueKey) {
    if (!states) return [];

    return states
        ?.map((el) => {
            return {
                label: el.name,
                value: el?.[valueKey] || el?.id,
            };
        })
        ?.filter((el) => el.value);
}

const StatesSelect = ({
    label,
    country,
    onChange,
    value = "",
    valueKey = "id",
    required = false,
    isLoading = false,
    isDisabled = false,
    hideWhenEmpty = true,
    menuPortalTarget = null,
    showOptionalLabel = false,
    ...props
}) => {
    const [states, setStates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const hasStates = React.useMemo(() => states?.length > 0, [states]);
    const statesData = React.useMemo(() => prepareStatesForSelect(states, valueKey), [states, valueKey]);

    const handleChange = React.useCallback((newVal) => onChange?.(newVal), [onChange]);

    const noOptionsMessage = ({ inputValue }) => {
        if (!Boolean(country)) return "Please select a country";

        if (Boolean(inputValue)) return "No matching states found";

        return "No states found";
    };

    const renderLabel = () => {
        if (!label) return null;

        if (required || !showOptionalLabel) {
            return <div style={{ marginBottom: hasStates ? "12px" : "0" }}>{label}</div>;
        }

        return (
            <>
                <span>{label}</span>
                <span> </span>
                {showOptionalLabel && <span className="text-secondary">(Optional)</span>}
            </>
        );
    };

    const getCountryStates = (countryId) => {
        if (!countryId) {
            setStates([]);

            return;
        }

        setLoading(true);

        api.get(`/common-data/${countryId}/states`)
            .then((response) => setStates(response?.data?.data || []))
            .catch(() => setStates([]))
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        getCountryStates(country);
    }, [country]);

    if (hideWhenEmpty && !hasStates) {
        return null;
    }

    if (hasStates) {
        return (
            <FormSelect
                {...props}
                value={value}
                required={required}
                optionsData={statesData}
                isLoading={loading || isLoading}
                isDisabled={loading || isDisabled}
                menuPortalTarget={menuPortalTarget}
                noOptionsMessage={noOptionsMessage}
                onChange={(option) => handleChange(option?.value || "")}
            >
                {renderLabel()}
            </FormSelect>
        );
    }

    return (
        <Input
            {...props}
            value={value}
            required={required}
            onChange={(e) => handleChange(e?.target?.value || "")}
        >
            {renderLabel()}
        </Input>
    );
};

export default StatesSelect;
