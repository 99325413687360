import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { WithLoader } from "@app/components";
import { setSideBarTab } from "@app/actions/helper";

import "./index.scss";

export default function Page({ children, title = null, target = null, isLoading = false, description = null }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (target) {
            dispatch(setSideBarTab(target));
        }
    }, [dispatch, target]);

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content-inner generic-table mt-4">
                <div className="container page-wrapper">
                    <div className="row wrap">
                        <div className="page-header col-xl-12">
                            {title && <h3 className="page-title">{title}</h3>}

                            {description && <span className="page-description">{description}</span>}
                        </div>

                        <WithLoader isLoading={isLoading}>{children}</WithLoader>
                    </div>
                </div>
            </div>
        </div>
    );
}
