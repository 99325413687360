import React from "react";

import "./index.scss";

const CardRow = ({ heading, children, subheading, muted = false }) => {
    return (
        <li className={`card-row list-group-item ${muted ? "muted" : ""}`}>
            <div className="card-wrapper row">
                <div className="left-panel col-xl-5">
                    <div className="text-left mb-4 mb-xl-0">
                        {Boolean(heading) && <label className="heading-label">{heading}</label>}

                        {Boolean(subheading) && <p className="subheading-label">{subheading}</p>}
                    </div>
                </div>

                <div className="right-panel col-xl-7">{children}</div>
            </div>
        </li>
    );
};

export default CardRow;
