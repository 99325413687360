import Fuse from "fuse.js";

import { groups } from "@app/actions";
import { setSelectedProp } from "@app/helpers";

const initialState = {
    data: [],
    filteredData: [],
    dataToEdit: null,
    searchKeyword: "",
    editPopper: false,
    createPopper: false,
    deletePopper: false,
    DESCSortByDate: true,
    archivePopper: false,
    fuseSearch: new Fuse([]),
    fuseOptions: {
        threshold: 0,
        keys: ["name", "description", "created_at"],
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

const sortData = (filteredData, DESCSortByDate) => {
    const dataWithoutDate = filteredData.filter((el) => el.created_at === "");
    const dataWithDate = filteredData.filter((el) => el.created_at !== "");
    if (DESCSortByDate) {
        return dataWithDate
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .concat(dataWithoutDate);
    }
    return dataWithDate
        .slice()
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .concat(dataWithoutDate);
};

const filterData = (selectedStatus, groupsData) => {
    return groupsData.filter((el) => {
        if (selectedStatus === "0") {
            return el.archived;
        }
        return !el.archived;
    });
};

export default function groupsReducer(state = initialState, action) {
    switch (action.type) {
        case groups.GROUP_SEARCH_DATA:
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.data],
            };
        case groups.DELETE_POPPER:
            return {
                ...state,
                deletePopper: !state.deletePopper,
            };
        case groups.EDIT_POPPER:
            return {
                ...state,
                editPopper: !state.editPopper,
            };
        case groups.ARCHIVE_POPPER:
            return {
                ...state,
                archivePopper: !state.archivePopper,
            };
        case groups.CREATE_POPPER:
            return {
                ...state,
                createPopper: !state.createPopper,
            };
        case groups.DATA_TO_EDIT:
            return {
                ...state,
                dataToEdit: action.selectedRow,
            };
        case groups.CLEAR_ACTION:
            return {
                ...state,
                filteredData: setSelectedProp(state.data, false),
                action: {
                    ...initialState.action,
                },
            };
        case groups.SORT_DATA_GROUP:
            return {
                ...state,
                DESCSortByDate: !state.DESCSortByDate,
                filteredData: sortData(state.filteredData, state.DESCSortByDate),
            };
        case groups.FILTER_DATA_GROUPS:
            return {
                ...state,
                filteredData: filterData(action.payload, state.data),
            };
        case groups.GET_GROUPS_BEGIN:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case groups.GET_GROUPS_SUCCESS:
            return {
                ...state,
                data: action.payload.data.groups.data,
                filteredData: setSelectedProp(action.payload.data.groups.data, false),
                fuseSearch: new Fuse(action.payload.data.groups.data, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        case groups.GET_GROUPS_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case groups.REMOVE_GROUP_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case groups.REMOVE_GROUP_SUCCESS:
            const filtered_data = state.data.filter((el) => el.id !== action.payload.data.removed_id);
            return {
                ...state,
                data: [...filtered_data],
                filteredData: [...filtered_data],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case groups.REMOVE_GROUP_FAILED:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case groups.ARCHIVE_GROUP_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case groups.ARCHIVE_GROUP_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case groups.ARCHIVE_GROUP_FAILED:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case groups.UPDATE_GROUP_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case groups.UPDATE_GROUP_SUCCESS:
            const foundIndex = state.data.findIndex((el) => el.id === action.payload.data.group.id);
            const editedGroupData = state.data;
            editedGroupData[foundIndex] = action.payload.data.group;
            return {
                ...state,
                data: [...editedGroupData],
                filteredData: [...editedGroupData],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case groups.UPDATE_GROUP_FAILED:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case groups.ADD_GROUP_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case groups.ADD_GROUP_SUCCESS:
            const extandedData = [...state.data];
            extandedData.push(action.payload.data.group);
            return {
                ...state,
                data: [...extandedData],
                filteredData: [...extandedData],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case groups.ADD_GROUP_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };

        default: {
            return state;
        }
    }
}
