import properties from "@app/properties";

export const mainHostUrl = (postfix) => {
    return properties.MAIN_HOST + postfix;
};

export const websiteUrl = (postfix) => {
    return properties.WEBSITE_URL + postfix;
};

export const redirectToWebsite = (path = "") => {
    window.location.href = websiteUrl(path);
};

export const redirectExternal = (path = "") => {
    window.location.href = mainHostUrl(path);
};

export const isTalentPortal = () => {
    return window.location.pathname.startsWith("/talent");
};

export const isAdminPortal = () => {
    return window.location.pathname.startsWith("/admin");
};

export const isPartnerPortal = () => {
    return window.location.pathname.startsWith("/partner");
};

export const isClientPortal = () => {
    return !isAdminPortal() && !isTalentPortal() && !isPartnerPortal();
};

export const safeBackLink = () => {
    // Redirect back if the last history was coming from the app
    // Otherwise, redirect to the home page
    // 2 => Current page is counted and also the new tab/window is counted (2 mean that the user entered the page directly)

    return window.history.length > 2 ? -1 : "/";
};

export const getQueryParam = (key) => {
    const queryParams = new URLSearchParams(window.location.search);

    return queryParams.get(key);
};

export const scrollTop = () => {
    try {
        window.scrollTo({ top: 0, behavior: "smooth" });
        document.querySelector(".dashboard-content-container").scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {}
};

export const download = (url, filename) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
};
