import React from "react";

import { Loading } from "@app/components";

const WithLoader = React.memo(({ children, isLoading, className }) => {
    if (isLoading) return <Loading className={className} />;

    if (!children) return null;

    return typeof children === "function" ? children() : children;
});

export default WithLoader;
