import { lazy } from "react";

const InviteFriend = lazy(() => import("@app/pages/partner/Talents/InviteFriend"));
const PartnerPositions = lazy(() => import("@app/pages/partner/Positions"));
const IntroduceCompany = lazy(() => import("@app/pages/partner/IntroduceCompany"));
const Resources = lazy(() => import("@app/pages/partner/Resources"));
const PartnersDashboard = lazy(() => import("@app/pages/partner/Dashboard"));
const Invoices = lazy(() => import("./client/Invoices"));
const TimeOff = lazy(() => import("./client/TimeOff"));
const HealthInsurance = lazy(() => import("./talent/HealthInsurance"));
const Overview = lazy(() => import("./talent/Overview"));
const Documents = lazy(() => import("./client/Documents"));
const Dashboard = lazy(() => import("./client/Dashboard"));
const Contracts = lazy(() => import("./client/Contracts"));
const ContractorTimeOff = lazy(() => import("./client/TimeOff/ContractorTimeOff"));
const CompanySettings = lazy(() => import("./client/CompanySettings"));
const AccountSettings = lazy(() => import("./client/AccountSettings"));
const InviteUsers = lazy(() => import("./client/CompanySettings/UserManagement/InviteUsers"));
const ContractsDetailes = lazy(() => import("./client/Contracts/ContractsDetails"));
const PositionDetails = lazy(() => import("./client/Positions/PositionDetails"));
const PostJob = lazy(() => import("./client/Positions/PostJob"));
const Candidates = lazy(() => import("./client/Positions/PositionDetails/Candidates"));
const Positions = lazy(() => import("./client/Positions"));
const AddEquipment = lazy(() => import("./client/AddEquipment"));
const Referrals = lazy(() => import("./client/Referrals"));
const Timelogs = lazy(() => import("./client/Timelogs"));
const ContractorTimelogs = lazy(() => import("./client/Timelogs/ContractorTimelogs"));
const AcceptInvitation = lazy(() => import("./client/AcceptInvitation"));
const Interviews = lazy(() => import("./talent/Interviews"));
const InterviewDetails = lazy(() => import("./talent/InterviewDetails"));
const Search = lazy(() => import("./admin/Search"));
const InterviewerGuide = lazy(() => import("./talent/InterviewerGuide"));
const Matches = lazy(() => import("./admin/Matches"));
const PartnerTalents = lazy(() => import("./partner/Talents"));
const PartnerInvoices = lazy(() => import("./partner/Invoices"));
const PartnerCustomers = lazy(() => import("./partner/Customers"));
const PartnerGetInTouch = lazy(() => import("@app/pages/partner/Resources/GetInTouch"));
const PartnerBenefits = lazy(() => import("@app/pages/partner/Resources/Benefits"));
const SubmitForm = lazy(() => import("@app/pages/client/Contracts/ContractsDetails/Tabs/Feedback/SubmitForm"));
const ContractsCreate = lazy(() => import("@app/components/pages/client/Contracts/Form/create"));
const SuggestedCandidates = lazy(() => import("./admin/SuggestedCandidates"));
const AdminPositions = lazy(() => import("@app/pages/admin/Positions"));
const Prospects = lazy(() => import("@app/pages/admin/Prospect"));
const PartnerProgramTrainingGuide = lazy(() => import("@app/pages/partner/Resources/PartnerProgramTrainingGuide"));
const PartnerProgramMessaging = lazy(() => import("@app/pages/partner/Resources/PartnerProgramMessaging"));
const MovePositionPhaseActions = lazy(() => import("@app/pages/admin/Position/Phase"));
const AdminShowMatchDetails = lazy(() => import("@app/pages/admin/Matches/Show"));
const CommunityProgramsIndex = lazy(() => import("@app/pages/talent/CommunityPrograms"));
const CommunityProgramsDetail = lazy(() => import("@app/pages/talent/CommunityProgram"));
const CommunityProgramsDetailForm = lazy(() => import("@app/pages/talent/CommunityProgramForm"));
const PerksAndBenefits = lazy(() => import("@app/pages/talent/PerksAndBenefits"));
const PerksAndBenefitsDetail = lazy(() => import("@app/pages/talent/PerksAndBenefitsDetail"));
const TalentReferrals = lazy(() => import("@app/pages/talent/Referrals"));
const Lobby = lazy(() => import("./client/Lobby"));

const TalentPositions = lazy(() => import("@app/pages/talent/Positions"));

export {
    Lobby,
    Search,
    TimeOff,
    PostJob,
    Matches,
    Invoices,
    Overview,
    Timelogs,
    Documents,
    Dashboard,
    Contracts,
    Positions,
    Referrals,
    Resources,
    Prospects,
    Candidates,
    Interviews,
    SubmitForm,
    InviteUsers,
    AddEquipment,
    InviteFriend,
    PartnerTalents,
    AdminPositions,
    TalentPositions,
    HealthInsurance,
    CompanySettings,
    AccountSettings,
    PositionDetails,
    PartnerBenefits,
    PartnerInvoices,
    ContractsCreate,
    TalentReferrals,
    AcceptInvitation,
    InterviewDetails,
    InterviewerGuide,
    PartnerPositions,
    IntroduceCompany,
    PartnerCustomers,
    PerksAndBenefits,
    ContractorTimeOff,
    ContractsDetailes,
    PartnersDashboard,
    PartnerGetInTouch,
    ContractorTimelogs,
    SuggestedCandidates,
    AdminShowMatchDetails,
    CommunityProgramsIndex,
    PerksAndBenefitsDetail,
    PartnerProgramMessaging,
    CommunityProgramsDetail,
    MovePositionPhaseActions,
    PartnerProgramTrainingGuide,
    CommunityProgramsDetailForm,
};
