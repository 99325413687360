export const MODALS = {
    TALENT_RAISE_INTEREST: "talent-raise-interest",
    TALENT_AVAILABILITY: "talent-availability-modal",
    TALENT_WORK_PREFERENCES: "talent-work-preferences",
};

export const TALENT_AVAILABILITY_MODAL = {
    RATE: "rate",
    CONTACT: "contact",
    LOCATION: "location",
    LANGUAGES: "languages",
    AVAILABILITY: "availability",
};
