import React from "react";
import { format } from "date-fns";

import { mainHostUrl } from "@app/helpers";
import FallbackAvatar from "@app/components/FallbackAvatar";

import "./index.scss";

const PositionInfo = ({ position }) => {
    const formattedDate = React.useMemo(
        () => (position?.due_date ? format(new Date(position?.due_date), "MMM dd, yyyy") : ""),
        [position?.due_date],
    );

    return (
        <ul style={{ wordBreak: "break-word" }}>
            <li className="dark-gray">
                <a
                    href={
                        position?.company?.owner?.id
                            ? mainHostUrl(`admin/customers/companies/${position?.company?.owner?.id}`)
                            : "#"
                    }
                >
                    {position?.company?.name}
                </a>
            </li>
            <li>| {position?.location}</li>
            <li>· {formattedDate}</li>
        </ul>
    );
};

export default function PositionSnippet({ position, className = "" }) {
    if (!position) return <div className={`position-snippet-empty ${className}`}>-</div>;

    return (
        <div className={`position-snippet ${className}`}>
            <div className="info-wrap">
                <PositionInfo position={position} />
            </div>

            <a
                className="main-text"
                href={mainHostUrl(`admin/customers/positions/${position?.id}`)}
            >
                {position?.name}
            </a>

            <div className="d-flex mt-1">
                <div className="badge-compact">
                    <FallbackAvatar
                        size="xs"
                        style={{ marginRight: "5px" }}
                        sx={{ width: 13, height: 13 }}
                        alt={position?.internal_owner?.first_name}
                        src={position?.internal_owner?.custom_avatar_thumbnail}
                    />
                    {position?.internal_owner?.first_name} {position?.internal_owner?.last_name?.charAt(0)}.
                </div>
            </div>
        </div>
    );
}
