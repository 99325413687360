import { Modal } from "react-bootstrap";
import React, { useMemo, useState, useEffect } from "react";

import api from "@app/services/api";

import Label from "../New/atoms/label";
import Input from "../New/molecules/input";
import FormSelect from "../New/molecules/formSelect";

function prepareTemplatesForSelect(templates) {
    if (templates === undefined || templates.length === 0) {
        return [];
    }

    return templates
        .map((el) => {
            return {
                value: el.id,
                label: el.name,
            };
        })
        .filter((el) => el.value);
}

async function getTemplatesForUser() {
    return await api.get("admin/email-templates/authenticated-user");
}

export default function UserBulkNotifyModal({ onNotify, handleClose, selectedUserIds }) {
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [, setSelectedUsers] = useState([]);
    const [subject, setSubject] = useState("");
    const [emailType, setEmailType] = useState(0);
    const [content, setContent] = useState("");
    const [createResponseMessage] = useState("");
    const templatesData = useMemo(() => prepareTemplatesForSelect(emailTemplates), [emailTemplates]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await dispatchSubmitForNotification();
        setLoading(false);
        handleClose();
    };

    useEffect(() => {
        getTemplatesForUser().then((result) => setEmailTemplates(result.data.data));
    }, []);

    const handleOnChangeTemplate = (ev) => {
        emailTemplates.forEach(function (row) {
            if (row.id === ev.value) {
                setEmailType(row.id);
                setSubject(row.subject);
                setContent(row.body);
            }
        });
    };

    const dispatchSubmitForNotification = async () => {
        try {
            const userIds = selectedUserIds.map((user) => user.user.id);

            const response = await api.post("admin/talent/bulk-notification", {
                body: content,
                subject: subject,
                user_ids: userIds,
                email_type: emailType,
            });

            setContent("");
            setSubject("");

            if (response?.data?.status === "success") {
                onNotify("success", response.data.data.message);
            }
            setSelectedUsers([]);
        } catch (error) {
            if (error) {
                onNotify("error", error);
            } else {
                onNotify("error", error || "An error occurred while processing your request.");
            }
        }
    };

    return (
        <Modal
            size="xl"
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Notify Users</p>
                </Modal.Title>
            </Modal.Header>

            {createResponseMessage !== "" && (
                <div className="card my-3 mx-2">
                    <div
                        style={{ borderRadius: "10px" }}
                        className="card-body alert-warning"
                    >
                        <h5 className="card-title m-0">{createResponseMessage}</h5>
                    </div>
                </div>
            )}

            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <span style={{ fontWeight: "600" }}>
                        <span
                            style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                padding: "2px 7px",
                                borderRadius: "5px",
                                backgroundColor: "#ebecf2",
                            }}
                        >
                            {selectedUserIds.length}
                        </span>{" "}
                        Selected Users
                    </span>
                    <div className="row mt-3">
                        <div className="col-xl-5">
                            <div className="form-group mb-3">
                                <FormSelect
                                    optionsData={templatesData}
                                    onChange={handleOnChangeTemplate}
                                    placeholder="Select a template..."
                                >
                                    Select Template
                                </FormSelect>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="form-group">
                                <Input
                                    value={subject}
                                    required={true}
                                    onChange={(event) => setSubject(event.target.value)}
                                >
                                    Subject
                                </Input>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <Label
                                required={true}
                                label="Content"
                                elementName="content"
                            />
                            <textarea
                                id=""
                                name=""
                                cols="30"
                                rows="10"
                                value={content}
                                onChange={(event) => setContent(event.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="btn-action btn-text btn-green mr-0"
                    >
                        {loading ? "Sending..." : "Submit"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
