export const getGPSCoordinates = () => {
    return new Promise((resolve, reject) => {
        try {
            window?.navigator?.geolocation?.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                {
                    timeout: 5000,
                    maximumAge: Infinity, // 1 Day
                    enableHighAccuracy: true,
                },
            );
        } catch (error) {
            reject(error);
        }
    });
};
