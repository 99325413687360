/**
 * TODO: MOVE THE `reducers` DIRECTORY UNDER `store`
 */
import helper from "./helper";
import { combineReducers } from "redux";
import partner from "./Partner/PartnerReducer";
import timelogs from "./Client/Timelogs/timelogs";
import contract from "./Admin/Contracts/contracts";
import groups from "./Client/CompanySettings/groups";
import contracts from "./Client/Contracts/contracts";
import positions from "./Client/Positions/positions";
import contractors from "./Admin/Contractors/contractors";
import inviteMember from "./Client/CompanySettings/inviteMembers";
import userManagement from "./Client/CompanySettings/userManagement";
import invoicesAndTimeOff from "./Client/InvoicesAndTimeOff/invoicesAndTimeOff";

import AuthReducer from "@app/reducers/Auth";
import AdminReducer from "@app/reducers/Admin";
import TalentReducer from "@app/reducers/Talent";

const reducers = {
    helper,
    groups,
    partner,
    timelogs,
    contract,
    contracts,
    positions,
    contractors,
    inviteMember,
    userManagement,
    invoicesAndTimeOff,
    admin: AdminReducer,
    talent: TalentReducer,
    authentication: AuthReducer,
};

export default combineReducers(reducers);
