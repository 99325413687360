import api from "@app/services/api";

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const APPLY_COVERAGE_BEGIN = "APPLY_COVERAGE_BEGIN";
export const applyCoverageBegin = () => ({
    type: APPLY_COVERAGE_BEGIN,
});

export const APPLY_COVERAGE_SUCCESS = "APPLY_COVERAGE_SUCCESS";
export const applyCoverageSuccess = (data) => ({
    payload: data,
    type: APPLY_COVERAGE_SUCCESS,
});

export const APPLY_COVERAGE_FAILED = "APPLY_COVERAGE_FAILED";
export const applyCoverageFailed = (error) => ({
    payload: error,
    type: APPLY_COVERAGE_FAILED,
});

export const DISABLE_PLAN = "DISABLE_PLAN";
export const disablePlan = (id) => ({
    payload: id,
    type: DISABLE_PLAN,
});

export const APPLY_COVERAGE = "APPLY_COVERAGE";
export const applyCoverage = (planId) => {
    return async (dispatch) => {
        try {
            dispatch(applyCoverageBegin());
            const response = await api.post(`/talent/benefits/safety-wing/add-member/${planId}`);
            dispatch(applyCoverageSuccess(response.data));
            dispatch(disablePlan(planId));
        } catch (error) {
            dispatch(applyCoverageFailed(error));
        }
    };
};

export const SAFETYWING_CHECK_CREDENCIALS_BEGIN = "SAFETYWING_CHECK_CREDENCIALS_BEGIN";
export const safetyWingCheckCredencialsBegin = () => ({
    type: SAFETYWING_CHECK_CREDENCIALS,
});

export const SAFETYWING_CHECK_CREDENCIALS_SUCCESS = "SAFETYWING_CHECK_CREDENCIALS_SUCCESS";
export const safetyWingCheckCredencialsSuccess = (data) => ({
    payload: data,
    type: SAFETYWING_CHECK_CREDENCIALS_SUCCESS,
});

export const SAFETYWING_CHECK_CREDENCIALS_FAILED = "SAFETYWING_CHECK_CREDENCIALS_FAILED";
export const safetyWingCheckCredencialsFailed = (error) => ({
    payload: error,
    type: SAFETYWING_CHECK_CREDENCIALS_FAILED,
});

export const SAFETYWING_CHECK_CREDENCIALS = "SAFETYWING_CHECK_CREDENCIALS";
export const safetyWingCheckCredencials = () => {
    return async (dispatch) => {
        try {
            dispatch(safetyWingCheckCredencialsBegin());
            const response = await api.get(`talent/benefits/safety-wing/check-credentials`);
            dispatch(safetyWingCheckCredencialsSuccess(response.data));
        } catch (error) {
            dispatch(safetyWingCheckCredencialsFailed(error));
        }
    };
};

export const GET_PLANS_BEGIN = "GET_PLANS_BEGIN";
export const getPlansBegin = () => ({
    type: GET_PLANS_BEGIN,
});

export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const getPlansSuccess = (data) => ({
    payload: data,
    type: GET_PLANS_SUCCESS,
});

export const GET_PLANS_FAILED = "GET_PLANS_FAILED";
export const getPlansFailed = (error) => ({
    payload: error,
    type: GET_PLANS_FAILED,
});

export const GET_PLANS = "GET_PLANS";
export const getPlans = () => {
    return async (dispatch) => {
        try {
            dispatch(getPlansBegin());
            const response = await api.get(`/talent/benefits/safety-wing/get-plans`);
            dispatch(getPlansSuccess(response.data));
        } catch (error) {
            dispatch(getPlansFailed(error));
        }
    };
};
