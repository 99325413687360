import properties from "@app/properties";

export const getAuthorizationHeader = () => {
    if (properties?.BASIC_AUTH_ENABLED) {
        const username = properties?.BASIC_AUTH_USERNAME;
        const password = properties?.BASIC_AUTH_PASSWORD;

        return {
            Authorization: "Basic " + btoa(username + ":" + password),
        };
    }

    return {};
};
