import React from "react";
import format from "date-fns/format";
import Calendar from "react-calendar";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { isPast, isToday, isFuture, isSameYear, isSameMonth } from "date-fns";

import { formatDateFns } from "@app/helpers";

import "./index.scss";

const CustomDatePickerButton = (props) => {
    const { id, label, onClick, disabled } = props;

    const hasCustomDate = Boolean(label);

    return (
        <Button
            id={id}
            onClick={onClick}
            variant="outlined"
            disabled={disabled}
            sx={{
                width: "100%",
                justifyContent: "flex-start",
                backgroundColor: hasCustomDate ? "#198cca14" : "",
                borderColor: hasCustomDate ? "#198cca80!important" : "",
            }}
        >
            <i
                className="icon-calendar"
                style={{ width: 24, height: 20, marginRight: 8, backgroundSize: 20 }}
            />

            <span
                style={{
                    fontWeight: "500",
                    lineHeight: "17px",
                    fontFamily: "'Inter'",
                    textTransform: "none",
                    letterSpacing: "normal",
                    color: hasCustomDate ? "#0e448c" : "#1a284d",
                }}
            >
                {label || "Choose a custom date"}
            </span>
        </Button>
    );
};

const ButtonDatePicker = ({ disablePast = false, disableToday = false, disableFuture = false, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleChange = (newVal) => {
        /**
         * Convert the date from the user's timezone into a UTC date.
         * @type {Date}
         */
        const val = new Date(Date.UTC(newVal.getFullYear(), newVal.getMonth(), newVal.getDate(), 0, 0, 0, 0));

        props?.onChange?.(val);
    };

    const handleClick = (event) => {
        if (props?.isDisabled) return;

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "date-picker-popover" : undefined;

    const isDayDisabled = (date) => {
        if (disableToday && disablePast && disableFuture) {
            return true;
        }

        if (disableToday && isToday(date)) {
            return true;
        }

        if (disablePast && isPast(date) && !isToday(date)) {
            return true;
        }

        return disableFuture && isFuture(date) && !isToday(date);
    };

    const isMonthDisabled = (date) => {
        if (disableToday && disablePast && disableFuture) {
            return true;
        }

        if (disablePast && isPast(date) && !isSameMonth(new Date(), date)) {
            return true;
        }

        return disableFuture && isFuture(date) && !isSameMonth(new Date(), date);
    };

    const isYearDisabled = (date) => {
        if (disableToday && disablePast && disableFuture) {
            return true;
        }

        if (disablePast && isPast(date) && !isSameYear(new Date(), date)) {
            return true;
        }

        return disableFuture && isFuture(date) && !isSameYear(new Date(), date);
    };

    const isDisabled = ({ date, view }) => {
        if (view === "month") {
            return isDayDisabled(date);
        }

        if (view === "year") {
            return isMonthDisabled(date);
        }

        if (view === "decade") {
            return isYearDisabled(date);
        }

        return false;
    };

    return (
        <>
            <CustomDatePickerButton
                onClick={handleClick}
                label={props.value ? formatDateFns(props.value, "dd MMM, y") : props.value}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <Calendar
                    showLeadingZeros
                    autoFocus={false}
                    maxDetail="month"
                    minDetail="decade"
                    defaultView="month"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    showNeighboringMonth={true}
                    formatMonth={(locale, date) => format(date, "MMM")}
                    formatShortWeekday={(locale, date) => format(date, "EEEEE")}
                    {...props}
                    onChange={handleChange}
                    onClickDay={handleClose}
                    tileDisabled={isDisabled}
                />
            </Popover>
        </>
    );
};

export default ButtonDatePicker;
