import React from "react";
import MainMenu from "./MainMenu";

const SidebarLinks = [
    {
        key: "lobby",
        target: "/lobby",
        display: "Dashboard",
        icon: "icon-overview",
    },
];

function PotentialCustomer({ toggleResponsiveMenu }) {
    return (
        <MainMenu
            items={SidebarLinks}
            onClick={toggleResponsiveMenu}
        />
    );
}

export default PotentialCustomer;
