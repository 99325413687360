import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material";

const RadioCriteriaItem = ({ criteria }) => {
    const { rating } = useSelector((state) => state.talent.interviews);

    const values = useMemo(() => Object.entries(JSON.parse(criteria?.value)) || [], [criteria]);

    return (
        <div className="criteria-group">
            <label className="criteria-label">{criteria?.criteria}</label>

            <FormControl>
                <RadioGroup defaultValue={rating?.currentRating?.[criteria["id"]]}>
                    {values?.map(([key, value]) => (
                        <FormControlLabel
                            key={key}
                            value={key}
                            label={value}
                            name={`rate[${criteria?.id}]`}
                            control={<Radio size="small" />}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default RadioCriteriaItem;
