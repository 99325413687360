import React from "react";
import { useSelector } from "react-redux";

import { useTalentWorkPreferences } from "@app/hooks";
import FormSelect from "@app/components/New/molecules/formSelect";

const mapLanguage = (language, selectedValues = []) => ({
    value: language.id,
    label: <span className="option-dark">{language.name}</span>,
    isDisabled: Boolean(selectedValues?.find((val) => String(val.language) === String(language.id)) ?? false),
});

const mapProficiency = (proficiency) => ({
    value: proficiency.id,
    label: <span className="option-dark">{proficiency.name}</span>,
});

function LanguagePreference({ index, onUpdate, onRemove, preference }) {
    const { state, errors, updateErrors } = useTalentWorkPreferences();

    const { languages, proficiencies } = useSelector((state) => state.authentication.commonData);

    const [language, setLanguage] = React.useState(preference.language);
    const [proficiency, setProficiency] = React.useState(preference.proficiency);

    const languagesOptions = React.useMemo(
        () => languages?.map((l) => mapLanguage(l, state?.languages)),
        [languages, state?.languages],
    );
    const proficienciesOptions = React.useMemo(() => proficiencies?.map(mapProficiency), [proficiencies]);

    const updateLanguage = (newLang) => {
        updateErrors(`languages.${index}.language`, null);

        const newVal = newLang.value;

        setLanguage(newVal);

        onUpdate(preference.id, { language: newVal });
    };

    const updateProficiency = (newLevel) => {
        updateErrors(`languages.${index}.proficiency`, null);

        const newVal = newLevel.value;

        setProficiency(newVal);

        onUpdate(preference.id, { proficiency: newVal });
    };

    return (
        <div className="preference">
            <div className="d-flex align-items-start">
                <div className="w-50 flex-fill me-3">
                    <FormSelect
                        value={language}
                        className="mb-0"
                        placeholder="Language"
                        onChange={updateLanguage}
                        optionsData={languagesOptions}
                        error={errors?.[`languages.${index}.language`]}
                    />
                </div>

                <div className="w-50 flex-fill me-3">
                    <FormSelect
                        value={proficiency}
                        placeholder="Proficiency"
                        onChange={updateProficiency}
                        optionsData={proficienciesOptions}
                        error={errors?.[`languages.${index}.proficiency`]}
                    />
                </div>

                <button
                    className="btn-close mt-2 p-1"
                    onClick={() => onRemove(preference.id)}
                ></button>
            </div>
        </div>
    );
}

export default LanguagePreference;
