import axios from "axios";

export default class HubspotAPI {
    formId;
    region;
    fields;
    portalId;
    ipAddress;
    hubspotutkCookie;

    setFields(fields) {
        this.fields = fields;

        return this;
    }

    constructor(portalId, formId, ipAddress, hubspotutkCookie, region = "na1") {
        this.region = region;
        this.formId = formId;
        this.portalId = portalId;
        this.ipAddress = ipAddress;
        this.fields = [];
        this.hubspotutkCookie = hubspotutkCookie;
    }

    submit() {
        return axios.post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`,
            {
                region: this.region,
                formId: this.formId,
                fields: this.fields,
                portalId: this.portalId,
                context: {
                    ipAddress: this.ipAddress,
                    "hutk": this.hubspotutkCookie,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    }
}
