import shortUUID from "short-uuid";

import { matches } from "@app/actions";
import { getQueryParam } from "@app/helpers";

const initialState = {
    data: [],
    filters: [],
    positionStatuses: [],
    movePositionData: null,
    searchKeyword: getQueryParam("keyword") || "",
    sort: {
        orderColumn: "",
        direction: null,
    },
    current: {
        notes: [],
        data: null,
        recommendations: [],
    },
    action: {
        error: null,
        message: "",
        isLoading: true,
        actionName: null,
        isSuccess: false,
    },
    paginationMatches: {
        page: 1,
        pageSize: 10,
        positionStatusesFilter: [],
        selectedFilter: "Shortlisted",
    },
};

const reMapData = (data) => {
    return data.map((el) => {
        return {
            ...el,
            uid: shortUUID.generate(),
        };
    });
};

export default function matchesReducer(state = initialState, action) {
    switch (action.type) {
        case matches.CLEAR_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                },
            };
        }
        case matches.CLEAR_STATE: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
            };
        }
        case matches.CLEAR_MATCH_DETAILS_STATE: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
                current: {
                    ...initialState.current,
                },
            };
        }
        case matches.SET_SEARCH_KEYWORD: {
            return {
                ...state,
                searchKeyword: action.payload,
                action: {
                    ...state.action,
                    actionName: action.type,
                },
                paginationMatches: {
                    ...state.paginationMatches,
                    page: action.payload === "" ? 1 : state?.paginationMatches?.page,
                },
            };
        }
        case matches.SET_PAGINATION_MATCHES: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
                paginationMatches: {
                    ...state.paginationMatches,
                    [action.key]: action.value,
                },
            };
        }
        case matches.SET_SORT_MATCHES: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
                sort: {
                    direction: action.asc,
                    orderColumn: action.orderColumn,
                },
            };
        }

        case matches.MOVE_TO_PHASE_BEGIN:
        case matches.GET_EMAIL_TEMPLATES_BEGIN:
        case matches.DELETE_MATCH_BEGIN:
        case matches.REOPEN_MATCH_BEGIN:
        case matches.DECLINE_MATCH_BEGIN:
        case matches.BULK_DECLINE_MATCH_BEGIN:
        case matches.GET_MATCHES_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case matches.MOVE_TO_PHASE_FAILED:
        case matches.GET_EMAIL_TEMPLATES_FAILED:
        case matches.DELETE_MATCH_FAILED:
        case matches.REOPEN_MATCH_FAILED:
        case matches.DECLINE_MATCH_FAILED:
        case matches.BULK_DECLINE_MATCH_FAILED:
        case matches.ADD_MATCH_NOTE_FAILED:
        case matches.GET_MATCHES_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case matches.MOVE_TO_PHASE_SUCCESS:
        case matches.DELETE_MATCH_SUCCESS:
        case matches.REOPEN_MATCH_SUCCESS:
        case matches.ADD_NOTE_MATCH_SUCCESS:
        case matches.BULK_DECLINE_MATCH_SUCCESS:
        case matches.DECLINE_MATCH_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.message,
                },
            };
        }
        case matches.GET_MATCHES_SUCCESS: {
            const reMapedData = reMapData(action.payload.data);
            return {
                ...state,
                filters: action.payload.filters,
                data: {
                    ...action.payload,
                    data: reMapedData,
                },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case matches.GET_EMAIL_TEMPLATES_SUCCESS: {
            return {
                ...state,
                movePositionData: action.payload.data,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case matches.SET_ADMIN_POSITION_STATUSES_FILTER: {
            const positionStatusesFilter = action.payload?.[0];

            let selectedStatuses = [];

            if (positionStatusesFilter.selectAll === false) {
                selectedStatuses = positionStatusesFilter?.children?.filter((status) => status._selected);
            }

            const userStatusesFilter = action.payload?.[1];

            let userStatuses = [];

            if (userStatusesFilter.selectAll === false) {
                userStatuses = userStatusesFilter?.children?.filter((status) => status._selected);
            }

            return {
                ...state,
                paginationMatches: {
                    ...state.paginationMatches,
                    userStatusesFilter: userStatuses?.map((status) => status?.id),
                    positionStatusesFilter: selectedStatuses?.map((status) => status?.id),
                },
            };
        }
        case matches.SET_ADMIN_POSITION_STATUSES_FILTER_DATA: {
            return {
                ...state,
                positionStatuses: action.payload,
            };
        }
        case matches.CLEAR_ADMIN_POSITION_STATUSES_FILTER: {
            return {
                ...state,
                paginationMatches: {
                    ...state.paginationMatches,
                    positionStatusesFilter: [],
                },
                positionStatuses: state?.positionStatuses?.map((s) => {
                    s._selected = false;

                    return s;
                }),
            };
        }
        case matches.GET_MATCHES_STATUSES_SUCCESS: {
            return {
                ...state,
                filters: action.payload ?? [],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }

        case matches.GET_MATCH_DETAILS_BEGIN:
        case matches.UPDATE_MATCH_DETAILS_BEGIN: {
            return {
                ...state,
                action: { ...state.action, isLoading: true, isSuccess: false, actionName: action.type },
            };
        }

        case matches.GET_MATCH_DETAILS_FAILED:
        case matches.UPDATE_MATCH_DETAILS_FAILED: {
            return {
                ...state,
                action: { ...state.action, isLoading: false, error: action.payload ? action.payload : "Unknown error" },
            };
        }

        case matches.GET_MATCH_DETAILS_SUCCESS: {
            return {
                ...state,
                action: { ...state.action, isSuccess: true, isLoading: false, actionName: action.type },
                current: {
                    ...state.current,
                    data: action.payload,
                },
            };
        }

        case matches.UPDATE_MATCH_DETAILS_SUCCESS: {
            return {
                ...state,
                action: { ...state.action, isLoading: true, isSuccess: true, actionName: action.type },
            };
        }

        // MATCH_NOTES
        case matches.GET_MATCH_NOTES_BEGIN:
        case matches.GET_MATCH_NOTES_FAILED:
        case matches.GET_MATCH_RECOMMENDATIONS_BEGIN:
        case matches.GET_MATCH_RECOMMENDATIONS_FAILED: {
            return {
                ...state,
                action: { ...state.action, isSuccess: false, actionName: action.type },
            };
        }

        case matches.GET_MATCH_NOTES_SUCCESS: {
            return {
                ...state,
                action: { ...state.action, isLoading: false, isSuccess: false, actionName: action.type },
                current: {
                    ...state.current,
                    notes: action.payload,
                },
            };
        }

        case matches.GET_MATCH_RECOMMENDATIONS_SUCCESS: {
            return {
                ...state,
                action: { ...state.action, isLoading: false, isSuccess: false, actionName: action.type },
                current: {
                    ...state.current,
                    recommendations: action.payload,
                },
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
