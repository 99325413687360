import React from "react";
import uniq from "lodash/uniq";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { MODALS, TALENT_AVAILABILITY_MODAL } from "@app/enums";
import { useModal, useTalentWorkPreferences } from "@app/hooks";
import { clearErrors, setAccountPreferences, getAccountPreferences } from "@app/actions/Talent/Account";

import RatePreference from "../RatePreference";
import ContactPreference from "../ContactPreference";
import LocationPreference from "../LocationPreference";
import LanguagesPreference from "../LanguagesPreference";
import AvailabilityPreference from "../AvailabilityPreference";

import "./index.scss";

const PREFERENCES = [
    {
        title: "Availability",
        render: () => <AvailabilityPreference />,
        key: TALENT_AVAILABILITY_MODAL.AVAILABILITY,
    },
    {
        title: "Location and Workplace",
        render: () => <LocationPreference />,
        key: TALENT_AVAILABILITY_MODAL.LOCATION,
    },
    {
        title: "Languages",
        render: () => <LanguagesPreference />,
        key: TALENT_AVAILABILITY_MODAL.LANGUAGES,
    },
    {
        title: "Rate",
        render: () => <RatePreference />,
        key: TALENT_AVAILABILITY_MODAL.RATE,
    },
    {
        title: "Contact Info",
        render: () => <ContactPreference />,
        key: TALENT_AVAILABILITY_MODAL.CONTACT,
    },
];

const WorkPreferencesModal = ({ onClose, modalId = MODALS.TALENT_WORK_PREFERENCES }) => {
    const dispatch = useDispatch();
    const { state, closeModal } = useModal(modalId);
    const {
        hasSegmentError,
        state: contextData,
        hasError: hasContextError,
        resetState: resetContextData,
        clearErrors: clearContextErrors,
        updateErrors: updateContextErrors,
    } = useTalentWorkPreferences();

    const timeoutRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [expanded, setExpanded] = React.useState(state?.target ? state?.target : "");

    const onExpandToggle = (id) => {
        setExpanded((prev) => (prev === id ? "" : id));
    };

    const onHide = React.useCallback(() => {
        if (isSubmitting) return;

        dispatch(clearErrors());

        closeModal({
            target: null,
        });

        onClose?.();

        resetContextData();

        clearContextErrors();
    }, [clearContextErrors, closeModal, dispatch, isSubmitting, onClose, resetContextData]);

    const onSubmit = () => {
        if (hasContextError) return;

        setIsSubmitting(true);

        dispatch(clearErrors());

        clearContextErrors?.();

        dispatch(setAccountPreferences(contextData))
            .then(() => {
                dispatch(getAccountPreferences());

                onHide();
            })
            .catch((e) => {
                Object.entries(e || {})?.forEach(([path, error]) => {
                    updateContextErrors(path, error);
                });
            })
            .finally(() => setIsSubmitting(false));
    };

    const scrollToSection = (sectionId, timeout = 250) => {
        // Scroll to the initial view if case the viewport
        // height was smaller than the modal height
        timeoutRef.current = setTimeout(() => {
            if (sectionId) {
                document
                    .getElementById(`preference_${sectionId}`)
                    ?.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);

                    timeoutRef.current = null;
                }
            }
        }, timeout);
    };

    // Set the initial section to be opened
    React.useEffect(() => {
        setExpanded(state?.target ? state?.target : "");

        scrollToSection(state?.target);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [state?.target]);

    // Expand sections with errors
    React.useEffect(() => {
        const sectionsWithErrors = PREFERENCES?.filter((pref) => hasSegmentError(pref?.key))?.map((pref) => pref.key);

        if (sectionsWithErrors?.length) {
            setExpanded(uniq(sectionsWithErrors));

            scrollToSection(sectionsWithErrors?.[0]);
        }
    }, [hasSegmentError]);

    return (
        <Modal
            scrollable
            onHide={onHide}
            id="WorkPreferencesModal"
            show={state?.show || false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Work Preferences</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {PREFERENCES?.map((preference) => (
                    <Accordion
                        elevation={0}
                        disableGutters
                        key={preference.key}
                        className="accordion-wrapper"
                        id={`preference_${preference.key}`}
                        onChange={() => onExpandToggle(preference.key)}
                        expanded={
                            Array.isArray(expanded) ? expanded.includes(preference.key) : expanded === preference.key
                        }
                    >
                        <AccordionSummary
                            className="accordion-summary"
                            expandIcon={<ExpandMoreIcon htmlColor="#ADB4C7" />}
                        >
                            {preference.title}
                        </AccordionSummary>

                        <AccordionDetails className="accordion-details">{preference.render()}</AccordionDetails>
                    </Accordion>
                ))}
            </Modal.Body>

            <Modal.Footer>
                <button
                    onClick={onHide}
                    disabled={isSubmitting}
                    className="btn-action btn-text btn-transparent"
                >
                    Close
                </button>

                <button
                    onClick={onSubmit}
                    disabled={!!hasContextError || isSubmitting}
                    className="btn-action btn-text btn-green mr-0"
                >
                    {isSubmitting ? "Saving..." : "Submit"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default WorkPreferencesModal;
