import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "@app/components";
import { mainHostUrl } from "@app/helpers";
import { getCommonData, fetchUserProfile, establishConnection } from "@app/actions/authentication";
const AuthenticationMiddleware = () => {
    const dispatch = useDispatch();
    const [shouldRender, setShouldRender] = useState(false);
    const { isLoggedIn, connectionEstablished } = useSelector((state) => state.authentication);

    const redirectToLogin = () => {
        const loginUrl = mainHostUrl("login");
        const currentUrl = window.location.href;
        const redirectUrl = new URL(loginUrl);

        // If the current URL has query parameters, append them to the redirect URL
        if (currentUrl.includes("?")) {
            const params = new URLSearchParams(window.location.search);
            params.forEach((value, key) => {
                redirectUrl.searchParams.append(key, value);
            });
        }

        if (currentUrl.includes("open-availability-modal")) {
            localStorage.setItem("previous_url", currentUrl);
        }

        // Add any additional query parameters you want here
        // Example: redirectUrl.searchParams.append('open-availability-modal', '1');

        window.location.replace(redirectUrl.toString());
    };

    useEffect(() => {
        dispatch(establishConnection());
    }, [dispatch]);

    useEffect(() => {
        if (connectionEstablished && !isLoggedIn) {
            redirectToLogin();
        } else if (connectionEstablished && isLoggedIn) {
            Promise.all([dispatch(fetchUserProfile()), dispatch(getCommonData())]).then(() => setShouldRender(true));
        }
    }, [dispatch, connectionEstablished, isLoggedIn]);

    return shouldRender ? (
        <Outlet />
    ) : (
        <Loading
            fullScreen
            withoutLabel
        />
    );
};

export default AuthenticationMiddleware;
