import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Input, Select } from "@app/components";
import { createMatch } from "@app/actions/Admin/Search/search";

const CreateMatchModal = React.memo(function CreateMatchModal({ matchUser, handleClose }) {
    const disptach = useDispatch();
    const { rates, currencies, open_positions } = useSelector((state) => state.authentication.commonData);

    const currenciesReMap = currencies?.map((el) => ({
        label: el.code,
        value: el.code,
    }));

    const ratesReMap = rates?.map((el) => ({ value: el.id, label: el.value }));

    const [state, setState] = useState({
        rate: "",
        position: "",
        currency: "",
        compensationType: "",
    });

    const handleSubmit = () => {
        disptach(createMatch(matchUser.id, state));
        handleClose();
    };

    const handleChangeByKey = (key, value) => {
        setState((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <Modal
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Open positions</p>
                </Modal.Title>
            </Modal.Header>
            <form
                name="CreateAMatch"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <Select
                        required
                        disabledValue
                        name="position"
                        value={state.position}
                        options={open_positions}
                        onChange={handleChangeByKey}
                    >
                        Positions
                    </Select>
                    <p
                        className="mt-3 mb-4"
                        style={{ color: "#546285", fontSize: "16px", fontWeight: "600" }}
                    >
                        Contractor Details
                    </p>
                    <div className="d-flex align-items">
                        <Select
                            required
                            disabledValue
                            className="mr-2"
                            options={ratesReMap}
                            name="compensationType"
                            onChange={handleChangeByKey}
                            value={state.compensationType}
                        >
                            Compensation Type
                        </Select>
                        <Select
                            required
                            disabledValue
                            name="currency"
                            className="mr-2"
                            value={state.currency}
                            options={currenciesReMap}
                            onChange={handleChangeByKey}
                        >
                            Currency
                        </Select>
                        <Input
                            required
                            name="rate"
                            value={state.rate}
                            style={{ maxWidth: "150px" }}
                            onChange={(e) => handleChangeByKey(e.target.name, e.target.value)}
                        >
                            Rate
                        </Input>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className="btn-action btn-text btn-green mr-0"
                    >
                        Save
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});

export default CreateMatchModal;
