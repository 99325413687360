import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const AreYouSureModal = React.memo(function AreYouSureModal({
    title,
    danger,
    btnText,
    bodyText,
    handleClose,
    handleAction,
    showButtons = true,
    btnCancelText = "Cancel",
}) {
    return (
        <Modal
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">{title}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">{bodyText}</p>
            </Modal.Body>
            {showButtons && (
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        {btnCancelText}
                    </button>
                    <button
                        onClick={() => {
                            handleAction();
                        }}
                        className={
                            danger ? "btn-action btn-text btn-danger mr-0" : "btn-action btn-text btn-green mr-0"
                        }
                    >
                        {btnText}
                    </button>
                </Modal.Footer>
            )}
        </Modal>
    );
});

export default AreYouSureModal;
