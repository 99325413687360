import React from "react";

import EmptyIcon from "@app/styles/assets/svg/icons/no_data.svg";

import "./index.scss";

export default function EmptyState({ message = null }) {
    return (
        <div className="empty-state-wrapper">
            <img
                alt=""
                src={EmptyIcon}
                className="icon"
            />

            <p className="message">{message || ""}</p>
        </div>
    );
}
