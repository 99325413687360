import Fuse from "fuse.js";
import shortUUID from "short-uuid";

import { contracts } from "@app/actions";

const initialState = {
    data: [],
    contracts: [],
    filteredData: [],
    dateToFilter: [],
    documentsData: [],
    searchKeyword: "",
    viewActivity: null,
    DESCSortByDate: true,
    contractDetailsData: [],
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    fuseOptions: {
        keys: [],
        threshold: 0,
    },
    equipment: {
        type: "",
        item_name: "",
        status_id: "",
        issue_date: "",
        serial_number: "",
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

const sortData = (filteredData, DESCSortByDate) => {
    if (DESCSortByDate) {
        return filteredData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    return filteredData.slice().sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });
};

const filterByDate = (data, dateTofilter) => {
    return data.filter((el) => {
        const date = new Date(el.date);
        return date >= dateTofilter[0].startDate && date <= dateTofilter[0].endDate;
    });
};

const setId = (data) => {
    return data.map((el) => {
        return {
            ...el,
            id: shortUUID.generate(),
        };
    });
};

const setSelctedVal = (data, checked) => {
    return data.map((el) => ({
        ...el,
        _selected: checked,
        contractor: { ...el.contractor, _selected: checked },
    }));
};

const filterData = (checkedData, data) => {
    let result = data;
    checkedData.forEach((el) => {
        const dataToFilter = el.children.filter((child) => child._selected).map((child) => child[el.key]);
        if (dataToFilter.length === 0) return;
        if (el.parent === "Position") {
            result = result.filter((item) => dataToFilter.includes(item.contractor[el.key]));
        }
        if (el.parent === "Status") {
            result = result.filter((item) => dataToFilter.includes(item[el.key]));
        }
    });
    return result;
};

export default function contractsReducer(state = initialState, action) {
    switch (action.type) {
        case contracts.SET_DATA_TO_RENDER: {
            let updatedData = action.payload;
            if (action.payload.length > 0) {
                if (!action.payload[0].hasOwnProperty("id")) {
                    updatedData = setId(action.payload);
                }
            }
            return {
                ...state,
                data: updatedData,
                filteredData: updatedData,
                fuseSearch: new Fuse(updatedData, state.fuseOptions),
            };
        }
        case contracts.CONTRACTS_SORT_DATA: {
            return {
                ...state,
                DESCSortByDate: !state.DESCSortByDate,
                filteredData: sortData(state.filteredData, state.DESCSortByDate),
            };
        }
        case contracts.SET_DATE_TO_FILTER: {
            const filteredDate = filterByDate(state.data, action.payload);
            return {
                ...state,
                dateToFilter: action.payload,
                filteredData: [...filteredDate],
            };
        }
        case contracts.CONTRACTS_SEARCH_DATA: {
            return {
                ...state,
                searchKeyword: action.keyword,
                filteredData:
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.data],
            };
        }
        case contracts.SET_ACTIVITY: {
            return {
                ...state,
                viewActivity: action.payload,
            };
        }
        case contracts.CLEAR_ACTION: {
            const _checkedFalse = setSelctedVal(state.data, false);
            return {
                ...state,
                filteredData: [..._checkedFalse],
                action: {
                    ...initialState.action,
                },
                equipment: {
                    ...initialState.equipment,
                },
            };
        }

        case contracts.CLEAR_MESSAGE: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        }
        case contracts.SET_SEARCH_KEYS: {
            return {
                ...state,
                fuseOptions: {
                    ...state.fuseOptions,
                    keys: action.payload,
                },
            };
        }
        case contracts.GROUP_EDIT_SUCCESS: {
            const foundIndex = state.contractDetailsData.groups.findIndex(
                (el) => el.id === action.payload.data.group.id,
            );
            const editedGroupData = state.contractDetailsData.groups;
            editedGroupData[foundIndex] = action.payload.data.group;
            return {
                ...state,
                contractDetailsData: {
                    ...state.contractDetailsData,
                    groups: [...editedGroupData],
                },
            };
        }
        case contracts.GROUP_DELETE_SUCCESS: {
            const filtered_data = state.contractDetailsData.groups.filter(
                (el) => el.id !== action.payload.data.removed_id,
            );
            return {
                ...state,
                contractDetailsData: {
                    ...state.contractDetailsData,
                    groups: [...filtered_data],
                },
            };
        }
        case contracts.FILTER_DATA_CONTRACTS: {
            return {
                ...state,
                filteredData: filterData(action.payload, state.data),
            };
        }
        // ====>>API CALLS<<====
        case contracts.GET_CONTRACT_DETAILS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contracts.GET_CONTRACT_DETAILS_SUCCESS: {
            return {
                ...state,
                contractDetailsData: action.payload.data.contract,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case contracts.GET_CONTRACT_DETAILS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.TIME_OFF_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contracts.TIME_OFF_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case contracts.TIME_OFF_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.GET_DOCUMENTS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contracts.GET_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                documentsData: action.payload.data.documents.data,
                fuseSearch: new Fuse(action.payload.data.documents.data, initialState.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case contracts.GET_DOCUMENTS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.UPDATE_DOCUMENT_NAME_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contracts.UPDATE_DOCUMENT_NAME_SUCCESS: {
            const editedIndex = state.documentsData.findIndex((el) => el.id === action.payload.data.document.id);
            const editedData = [...state.documentsData];
            editedData[editedIndex] = action.payload.data.document;
            return {
                ...state,
                filteredData: [...editedData],
                documentsData: [...editedData],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case contracts.UPDATE_DOCUMENT_NAME_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.GET_CONTRACTS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }
        case contracts.GET_CONTRACTS_SUCCESS: {
            const _reMap = setSelctedVal(action.payload.data.contracts, false).sort(
                (a, b) => new Date(b.start_date) - new Date(a.start_date),
            );
            return {
                ...state,
                data: [..._reMap],
                contracts: [..._reMap],
                filteredData: [..._reMap],
                fuseSearch: new Fuse(action.payload.data.contracts, state.fuseOptions),
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case contracts.GET_CONTRACTS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.SET_EQUIPMENT_ATTRIBUTE: {
            return {
                ...state,
                equipment: {
                    ...state.equipment,
                    [action.key]: action.value,
                },
            };
        }
        case contracts.GET_EQUIPMENT_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case contracts.GET_EQUIPMENT_SUCCESS: {
            const { equipment_item } = action.payload.data;
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                },
                equipment: {
                    type: equipment_item.type,
                    item_name: equipment_item.item_name,
                    status_id: equipment_item.status_id,
                    issue_date: equipment_item.issue_date,
                    serial_number: equipment_item.serial_number,
                },
            };
        }
        case contracts.GET_EQUIPMENT_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.ADD_EQUIPMENT_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case contracts.ADD_EQUIPMENT_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case contracts.ADD_EQUIPMENT_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.EDIT_EQUIPMENT_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case contracts.EDIT_EQUIPMENT_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case contracts.EDIT_EQUIPMENT_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.DELETE_EQUIPMENT_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case contracts.DELETE_EQUIPMENT_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case contracts.DELETE_EQUIPMENT_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case contracts.CHANGE_EQUIPMENT_STATUS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case contracts.CHANGE_EQUIPMENT_STATUS_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        }
        case contracts.CHANGE_EQUIPMENT_STATUS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        default: {
            return state;
        }
    }
}
