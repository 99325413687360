import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AUTH } from "@app/enums";
import { TALENT } from "@app/routes";
import { currentRoleSwitched } from "@app/actions/authentication";

const RoleSwitch = ({ onClick }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userProfile, currentRole } = useSelector((state) => state.authentication);

    const shouldRender = () => Boolean(userProfile?.show_toggle || false);

    const handleRoleSwitch = (event) => {
        const newRole = event?.currentTarget?.name || null;

        onClick(newRole);

        dispatch(currentRoleSwitched(newRole));

        if (newRole === AUTH.ROLE.TALENT) {
            navigate(TALENT.DASHBOARD_PATH);
        } else if (newRole === AUTH.ROLE.CLIENT) {
            navigate("/");
        }
    };

    return (
        shouldRender() && (
            <ul
                data-submenu-title="Switch Role"
                style={{ marginTop: "45px", marginBottom: "0px" }}
            >
                <div className="d-flex">
                    <li className={currentRole === AUTH.ROLE.TALENT ? "active" : undefined}>
                        <a
                            name={AUTH.ROLE.TALENT}
                            onClick={handleRoleSwitch}
                            className="sidebar-item-link"
                        >
                            <i className="icon-talent-developers"></i>
                            <span>Talent</span>
                        </a>
                    </li>
                    <li className={currentRole === AUTH.ROLE.CLIENT ? "active" : undefined}>
                        <a
                            name={AUTH.ROLE.CLIENT}
                            onClick={handleRoleSwitch}
                            className="sidebar-item-link"
                        >
                            <i className="icon-company"></i>
                            <span>Company</span>
                        </a>
                    </li>
                </div>
            </ul>
        )
    );
};

export default RoleSwitch;
