import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { STATUS } from "@app/enums";
import { startCase } from "@app/helpers";
import { FilterWidget } from "@app/components";
import { setFilters } from "@app/actions/Talent/Interviews";

const orderMap = {
    past: 3,
    upcoming: 1,
    completed: 4,
    cancelled: 5,
    in_progress: 2,
};

const transformStatus = ([status], selectedStatus) => ({
    status: status,
    display: startCase(status),
    order: orderMap[status] || 0,
    _selected: selectedStatus === status,
});

const Filters = () => {
    const dispatch = useDispatch();
    const { filters, statuses } = useSelector((state) => state.talent.interviews);

    const filterTypes = React.useMemo(
        () => ({
            key: "status",
            selectAll: false,
            children: Object.entries(statuses).map((status) => transformStatus(status, filters?.status)),
        }),
        [statuses, filters?.status],
    );

    const updateStatusFilter = (_, selectedFilter) => {
        const newFilter = selectedFilter?.status || STATUS.INTERVIEW_STATUS.UPCOMING;
        const currentFilter = filters?.status;

        // Avoid sending new requests when the status filter isn't changed
        if (newFilter !== currentFilter) {
            dispatch(
                setFilters({
                    status: newFilter,
                }),
            );
        }
    };

    return (
        <FilterWidget
            ordered
            statuses={[]}
            data={statuses}
            filterType={filterTypes}
            handleFilterType={updateStatusFilter}
        />
    );
};

export default Filters;
