import React from "react";
import Select, { createFilter } from "react-select";

import Label from "../atoms/label";
import HelperText from "../../HelperText";

export default function FormSelect({
    name,
    value,
    error,
    children,
    onChange,
    required,
    optionsData,
    placeholder,
    styles = {},
    disabled = false,
    ...props
}) {
    const customStyles = {
        multiValue: (base) => ({
            ...base,
        }),
        menu: (base) => ({
            ...base,
            zIndex: 1001,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 1055,
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: 4,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: 4,
        }),
        placeholder: (base) => ({
            ...base,
            color: "#ADB4C7",
        }),
        valueContainer: (base) => ({
            ...base,
            padding: "0px 12px",
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
        }),
        control: (base, state) => ({
            ...base,
            height: 42,
            minHeight: 42,
            backgroundColor: state.isDisabled ? "#E9ECEF" : "#FFFFFF",
            boxShadow: state.isFocused ? "0 2px 10px rgba(26,40,77,.08)" : 0,
            borderColor: state.isFocused ? "#ADB4C7" : error ? "#f1553f" : "#E1E3EB", // Updates the borderColor based on the presence of errors
            "&:hover": {
                borderColor: state.isFocused ? "#ADB4C7" : error ? "#f1553f" : "#E1E3EB", // Updates the borderColor based on the presence of errors
            },
        }),
        ...styles,
    };

    const valueSelected = value &&
        optionsData.length > 0 && {
            value: value,
            label: optionsData.find((option) => option.value === value)?.label,
        };

    return (
        <div className={`form-group ${error ? "position-relative error" : ""}`}>
            {children && (
                <Label
                    label={children}
                    elementName={name}
                    required={required}
                />
            )}

            <Select
                name={name}
                onChange={onChange}
                styles={customStyles}
                options={optionsData}
                value={valueSelected}
                isDisabled={disabled}
                placeholder={placeholder}
                filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                components={{
                    IndicatorSeparator: () => null,
                }}
                {...props}
            />

            <HelperText
                text={error}
                variant="error"
            />
        </div>
    );
}
