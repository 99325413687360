import React from "react";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import { Link, Menu, MenuItem } from "@mui/material";

import { PAGINATION } from "@app/enums";
import { useStyles } from "@app/components/style";
import NoData from "@app/styles/assets/svg/icons/no_data.svg";

import "./index.scss";

const NoRowsOverlay = () => {
    const classes = useStyles();

    return (
        <Stack className={classes.noRowsDataGrid}>
            <img
                src={NoData}
                alt="No Data"
            />
            <p>No Data Avaliable</p>
        </Stack>
    );
};

function DataGridTable({
    data,
    actions,
    anchorEl,
    popUpToggle,
    onCellClick,
    tableColumns,
    handleAction,
    handlePopUpToggle,
    disablePagination = false,
    ...props
}) {
    const classes = useStyles();
    const [rowColumn, setRowColumn] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(PAGINATION.PAGINATE_PER_PAGE);

    const handleRowClick = (e) => {
        setRowColumn(e.row);
    };

    const TablePopper = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                className={classes.menuHeight}
                open={popUpToggle ? popUpToggle : false}
                onClose={() => handlePopUpToggle(null, null, null)}
            >
                {actions?.map((el, idx) => {
                    if (typeof el === "object") {
                        return el.hasOwnProperty("href") ? (
                            <li
                                key={idx}
                                className={classes.menuItem}
                            >
                                <Link
                                    target="_blank"
                                    underline="hover"
                                    style={{ width: "100%", display: "block" }}
                                    href={el.href.replace("[id]", rowColumn.unhashed_id)}
                                >
                                    {el.display}
                                </Link>
                            </li>
                        ) : (
                            <MenuItem
                                key={idx}
                                id={el.display}
                                className={classes.menuItem}
                                onClick={(event) => handleAction(event.target.id)}
                            >
                                {el.display}
                            </MenuItem>
                        );
                    }
                    return (
                        <MenuItem
                            id={el}
                            key={idx}
                            className={classes.menuItem}
                            onClick={(event) => handleAction(event.target.id)}
                        >
                            {el}
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    };

    return (
        <div className="datagrid">
            <TablePopper />

            <DataGrid
                autoHeight
                rows={data}
                page={page}
                rowHeight={80}
                disableColumnMenu
                columns={tableColumns}
                disableSelectionOnClick
                className={classes.root}
                onCellClick={onCellClick}
                onRowClick={handleRowClick}
                components={{ NoRowsOverlay }}
                sortingOrder={["asc", "desc"]}
                pageSize={props.pageSize ?? pageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                hideFooterPagination={data.length < PAGINATION.PAGINATE_PER_PAGE}
                rowsPerPageOptions={props.rowsPerPageOptions ?? PAGINATION.PAGINATE_PAGE_SIZES}
                {...props}
            />

            {Boolean(disablePagination) && <div className="pagination-disabler"></div>}
        </div>
    );
}

export default DataGridTable;
