import { Modal } from "react-bootstrap";
import { TextareaAutosize } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select, WithLoader, AlertComponent, AdvancedEditor, ContractorCard, PositionSnippet } from "@app/components";
import {
    getMatches,
    moveToPhase,
    declineMatch,
    addMatchNote,
    bulkDeclineMatch,
    getEmailTemplates,
} from "@app/actions/Admin/Matches";

import "./index.scss";

const MAX_CHAR_COUNT = 5000;

const MoveAction = ({
    state,
    rowData,
    handleChange,
    sendEmailClient,
    sendEmailCandidate,
    setSendEmailClient,
    setSendEmailCandidate,
}) => {
    const dispatch = useDispatch();
    const {
        authentication: { userProfile },
        admin: {
            matches: { action, filters, movePositionData },
        },
    } = useSelector((state) => state);

    // Phases options and remove phase without id
    const phases = filters
        .map((el) => {
            return {
                value: el.id,
                label: el.name,
            };
        })
        .filter((el) => el.value);
    const [users, setUsers] = useState([]);
    const checkIsOnborded = ["bY43L03VrX", "rvXpGb9y8G"].includes(state.status_id);

    useEffect(() => {
        if (movePositionData) {
            const users = movePositionData.users.map((el) => {
                return {
                    value: el.id,
                    label: el.full_name,
                };
            });
            setUsers(users);
        }
    }, [movePositionData]);

    useEffect(() => {
        dispatch(getEmailTemplates(rowData.id, state.status_id, state.user_id));
    }, [state.status_id, state.user_id, dispatch, rowData.id]);

    const renderClientEmailPrev = () => {
        if (sendEmailClient)
            return (
                <div className="mt-3">
                    <label>Client Email Preview</label>
                    {!movePositionData.accountManager && (
                        <div className="mb-1">
                            <AlertComponent
                                width={100}
                                infoMessage={` There’s no account manager specified for this client, so email can not be sent automatically.`}
                            />
                        </div>
                    )}
                    {!movePositionData.hiringManager && (
                        <div className="mb-1">
                            <AlertComponent
                                width={100}
                                infoMessage={`There’s no hiring manager specified for this position, so email can not be sent automatically.
            `}
                            />
                        </div>
                    )}
                    {movePositionData?.client && (
                        <div
                            className="email-content"
                            dangerouslySetInnerHTML={{ __html: movePositionData?.client }}
                        />
                    )}

                    {/* <iframe
              title="client-email-content"
              style={{ width: "100%", height: "650px" }}
              srcDoc={movePositionData?.client}
            ></iframe> */}
                </div>
            );
    };

    return (
        <div className="move">
            <Select
                options={phases}
                name="status_id"
                value={state.status_id}
                onChange={handleChange}
            >
                Please select the phase you want to move this candidate to.
            </Select>
            <WithLoader isLoading={action.isLoading}>
                {movePositionData && (
                    <>
                        <label
                            className="d-flex align-center"
                            style={{ cursor: "pointer", maxWidth: "200px" }}
                        >
                            <input
                                type="checkbox"
                                checked={sendEmailCandidate}
                                style={{ cursor: "pointer", marginRight: "10px" }}
                                onChange={() => setSendEmailCandidate(!sendEmailCandidate)}
                            />
                            Send email to candidate
                        </label>
                        {/* If the admin selects Phase: “Contract Preps“ or “Onboarded“ show the section below*/}
                        {checkIsOnborded && (
                            <label
                                className="d-flex align-center"
                                style={{ cursor: "pointer", maxWidth: "200px" }}
                            >
                                <input
                                    type="checkbox"
                                    checked={sendEmailClient}
                                    style={{ cursor: "pointer", marginRight: "10px" }}
                                    onChange={() => setSendEmailClient(!sendEmailClient)}
                                />
                                Send email to client
                            </label>
                        )}

                        {sendEmailCandidate && (
                            <div className="mt-2">
                                {/* Show the dropdown only if Admin has role "Super Admin" */}
                                {/* ************************** */}
                                {movePositionData.is_super_admin && (
                                    <div className="form-group">
                                        <label
                                            className="mb-2"
                                            htmlFor="user_id"
                                        >
                                            Send candidate email on behalf of:
                                        </label>
                                        <select
                                            name="user_id"
                                            value={state.user_id}
                                            className="form-control dropdown-form"
                                            onChange={(e) => handleChange("user_id", e.target.value)}
                                        >
                                            <option value={userProfile.id}>Myself</option>
                                            {users?.map((el, idx) => (
                                                <option
                                                    key={idx}
                                                    value={el.value}
                                                >
                                                    {el.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {/* ************************** */}
                                <div className="mt-4">
                                    <label>Candidate Email Preview</label>
                                    <div
                                        className="email-content"
                                        dangerouslySetInnerHTML={{ __html: movePositionData?.candidate }}
                                    />
                                </div>
                            </div>
                        )}
                        {/* If the admin selects Phase: “Contract Preps“ or “Onboarded“ show the section below*/}
                        {checkIsOnborded && renderClientEmailPrev()}
                    </>
                )}
            </WithLoader>
            <div className="mt-3">
                <label className="mb-2">Note</label>
                <AdvancedEditor
                    name="note"
                    value={state.content}
                    onChange={(e, editor) => {
                        handleChange("note", editor.getData());
                    }}
                />
            </div>
        </div>
    );
};

/** @deprecated Use `MoveMatchModal` instead */
export default function MoveDeclineModal({ rowData, decline, addNote, selected, handleClose, setSelectedPropsIds }) {
    const dispatch = useDispatch();

    const [selectedForMultiplePositions, setSelectedForMultiplePositions] = useState(false);

    useEffect(() => {
        if (selected && selected.length > 1) {
            const firstPositionId = selected[0].positionId;
            setSelectedForMultiplePositions(selected.some((item) => item.positionId !== firstPositionId));
        } else {
            setSelectedForMultiplePositions(false);
        }
    }, [selected]);

    const {
        admin: {
            matches: { filters, paginationMatches },
        },
        authentication: {
            userProfile: { id },
            commonData: { decline_reasons },
        },
    } = useSelector((state) => state);
    const [sendEmail, setSendEmail] = useState(false);
    const [state, setState] = useState({
        note: "",
        reason_id: "",
        email_content: "",
    });

    const [addNoteState, setAddNoteState] = useState("");

    const selectedFilterIndex = filters.findIndex((el) => el.name === paginationMatches.selectedFilter);
    const [moveState, setMoveState] = useState({
        note: "",
        user_id: id,
        status_id: filters.length - 1 === selectedFilterIndex ? 0 : filters[selectedFilterIndex + 1].id,
    });
    const [sendEmailCandidate, setSendEmailCandidate] = useState(false);
    const [sendEmailClient, setSendEmailClient] = useState(false);
    const [, setSelectedIds] = useState(selected || []);

    const handleSubmit = () => {
        if (selected && selected.length > 0) {
            const itemIdsArray = selected.map((item) => item.itemId);

            const data = { ...state, "selected_ids[]": itemIdsArray, send_email: sendEmail ? "1" : "0" };

            !sendEmail && delete data.email_content;

            dispatch(bulkDeclineMatch(itemIdsArray, data))
                .then(() => {
                    dispatch(getMatches());
                    setSelectedIds([]);
                    setSelectedPropsIds([]);
                })
                .catch(() => {
                    setSelectedIds([]);
                    setSelectedPropsIds([]);
                });
        } else {
            if (decline) {
                const data = { ...state, send_email: sendEmail ? "1" : "0" };
                !sendEmail && delete data.email_content;
                dispatch(declineMatch(rowData.id, data)).then(() => dispatch(getMatches()));
            } else if (addNote) {
                const data = {
                    note: addNoteState,
                    match_id: rowData?.id,
                };
                dispatch(addMatchNote(rowData.id, data));
                handleClose();
            } else {
                const data = {
                    ...moveState,
                    match_id: rowData?.id,
                    send_email_to_client: sendEmailClient ? "1" : "0",
                    send_email_to_candidate: sendEmailCandidate ? "1" : "0",
                };
                if (!sendEmailCandidate) {
                    data.user_id = "";
                }
                dispatch(moveToPhase(rowData.id, data)).then(() => dispatch(getMatches()));
            }
        }

        handleClose();
    };

    const handleMoveState = (name, value) => {
        const key = name;
        const val = value;
        setMoveState((prev) => ({ ...prev, [key]: val }));
    };

    const handleChangeByKey = (key, value) => {
        setState((prev) => ({ ...prev, [key]: value }));
    };

    const handleReasonChange = (name, value) => {
        const email_content = decline_reasons.filter((el) => el.value === parseInt(value))?.[0]?.email_content;
        const result_email_content = email_content?.replace("[position_name]", rowData?.position?.name);
        setState((prev) => ({
            ...prev,
            [name]: value,
            email_content: result_email_content,
        }));
    };

    return (
        <Modal
            size="lg"
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">
                        {addNote ? "Add a Note" : "Are you sure you want to continue with this action?"}
                    </p>
                </Modal.Title>
            </Modal.Header>
            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    {selectedForMultiplePositions ? (
                        <div
                            className="row mx-0"
                            style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                        >
                            <AlertComponent
                                width={100}
                                errorMessage={` You can’t decline matches for multiple jobs at once. Please select matches for one job at a time.`}
                            />
                        </div>
                    ) : (
                        <>
                            <div
                                className="row mx-0"
                                style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                            >
                                {selected && selected.length > 0 ? (
                                    <div
                                        className="col-md-6"
                                        style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                                    >
                                        <p>{selected.length} users selected to be declined</p>
                                    </div>
                                ) : (
                                    <div className="col-md-6">
                                        <ContractorCard contractor={rowData?.candidate} />
                                    </div>
                                )}
                                <div className="col-md-6">
                                    <PositionSnippet position={rowData?.position} />
                                </div>
                            </div>
                            {rowData?.candidate?.preferred_communication && (
                                <div className="col-md-12 d-flex">
                                    <label className="pb-2">
                                        Preferred communication channel
                                        <span
                                            style={{ fontSize: "12px" }}
                                            className="preferred-communication"
                                        >
                                            {" "}
                                            : {rowData.candidate.preferred_communication}
                                        </span>
                                    </label>
                                </div>
                            )}
                            <div className="devider mb-3 pb-0" />
                            {decline ? (
                                <div className="decline">
                                    <div className="mb-3">
                                        <AlertComponent
                                            width={100}
                                            infoMessage={` Proceeding will mark this candidate as unqualified for the selected position. They will be considered as failed at the current stage.`}
                                        />
                                    </div>
                                    <Select
                                        required
                                        disabledValue
                                        name="reason_id"
                                        value={state.reason_id}
                                        options={decline_reasons}
                                        onChange={handleReasonChange}
                                    >
                                        Reason for declining
                                    </Select>
                                    <label
                                        className="d-flex align-center"
                                        style={{ cursor: "pointer", maxWidth: "100px" }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={sendEmail}
                                            onChange={() => setSendEmail(!sendEmail)}
                                            style={{ cursor: "pointer", marginRight: "10px" }}
                                        />
                                        Send Email
                                    </label>
                                    {sendEmail && (
                                        <div className="mt-2">
                                            <label>Email Content</label>
                                            <i>
                                                Greeting and salutation are automatically added to the email, don't add
                                                them to the content below.
                                            </i>
                                            <TextareaAutosize
                                                minRows={1}
                                                className="mt-2"
                                                name="email_content"
                                                maxLength={MAX_CHAR_COUNT}
                                                value={state.email_content}
                                                onChange={(e) => {
                                                    handleChangeByKey(e.target.name, e.target.value);
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="mt-2">
                                        <label className="mb-2">Note</label>
                                        <AdvancedEditor
                                            name="note"
                                            value={state.content}
                                            onChange={(e, editor) => {
                                                handleChangeByKey("note", editor.getData());
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : addNote ? (
                                <div className="add-note">
                                    <div className="mt-2">
                                        <label className="mb-2">
                                            Note <span className="required">*</span>
                                        </label>
                                        <AdvancedEditor
                                            name="note"
                                            value={addNoteState}
                                            onChange={(e, editor) => {
                                                setAddNoteState(editor.getData());
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <MoveAction
                                    rowData={rowData}
                                    state={moveState}
                                    sendEmailClient={sendEmailClient}
                                    sendEmailCandidate={sendEmailCandidate}
                                    setSendEmailClient={setSendEmailClient}
                                    setSendEmailCandidate={setSendEmailCandidate}
                                    handleChange={(key, value) => handleMoveState(key, value)}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className={`btn-action btn-text btn-green mr-0 ${selectedForMultiplePositions ? "disabled" : ""}`}
                    >
                        Proceed
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
