import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { matchRoutes, useLocation, useNavigationType, createRoutesFromChildren } from "react-router-dom";

import { APP_ENV } from "@app/enums";

/**
 * Define which URLs Sentry should trace/watch/monitor.
 *
 * Sentry will ignore any AJAX requests sent to any other URLS not matching any URL in the list below.
 */
const TRACE_TARGETS = [
    process.env.REACT_APP_API_HOST, // Local
    "https://app.platform.planup.io/api", // Testing
    "https://app.preprod.planup.io/api", // Staging
    "https://app.adevait.com/api", // Production
];

/**
 * Define which environments Sentry should be enabled in.
 */
const ENABLED_ENVS = [APP_ENV.TESTING, APP_ENV.STAGING, APP_ENV.PRODUCTION];

export const configureSentry = () => {
    // Control which environments Sentry should be enabled on
    if (ENABLED_ENVS?.includes(process.env.REACT_APP_ENV) === false) {
        return;
    }

    Sentry.init({
        // Performance Monitoring
        tracesSampleRate: 0.1, // Capture 10% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.

        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        dsn: process.env.REACT_APP_SENTRY_DSN,

        environment: process.env.REACT_APP_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: TRACE_TARGETS.map((target) => new RegExp(`^${target.replace(".", "\\.")}`)),
            }),
            new Sentry.Replay(),
        ],
    });
};
