import { Alert } from "@mui/material";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";

import { clearErrors, connectToCalendar, CALENDAR_CONNECT_SUCCESS } from "@app/actions/Talent/Interviews";

import { Input } from "..";

const CalendarIntegrationModal = ({ handleClose }) => {
    const dispatch = useDispatch();
    const [calendar, setCalendar] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { action, selectedCalendar } = useSelector((state) => state.talent.interviews);

    const isApiKeyRequired = React.useMemo(() => {
        // Calendar API key has been already set
        if (selectedCalendar?.token) return false;

        return !apiKey;
    }, [apiKey, selectedCalendar?.token]);

    const handleAction = () => {
        setIsSubmitted(true);

        dispatch(clearErrors());

        dispatch(
            connectToCalendar(selectedCalendar?.id, {
                calendar,
                api_key: apiKey,
            }),
        );
    };

    const triggerClose = useCallback(() => {
        dispatch(clearErrors());

        handleClose?.();
    }, [dispatch, handleClose]);

    useEffect(() => {
        if (isSubmitted && action?.actionName === CALENDAR_CONNECT_SUCCESS) {
            triggerClose();
        }
    }, [action?.actionName, triggerClose, isSubmitted]);

    useEffect(() => {
        setCalendar(selectedCalendar?.calendar || "");
    }, [selectedCalendar?.calendar]);

    return (
        <Modal
            show={true}
            onHide={triggerClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Calendar Integration</p>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="mb-0">Here you can integrate your calendar with Adeva.</p>

                <Input
                    required
                    value={calendar}
                    onChange={({ target }) => setCalendar(target?.value || "")}
                    placeholder="Ex: https://example.com/user-name/adeva-interview"
                >
                    Calendar Link
                </Input>

                <Input
                    required
                    value={apiKey}
                    placeholder={selectedCalendar?.token || "*".repeat(14)}
                    onChange={({ target }) => setApiKey(target?.value || "")}
                >
                    API Key
                </Input>

                {action?.error && (
                    <Alert
                        severity="error"
                        className="mt-3 mb-1"
                    >
                        {action?.error}
                    </Alert>
                )}
            </Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    onClick={triggerClose}
                    className="btn-action btn-text btn-transparent"
                >
                    Close
                </button>

                <button
                    onClick={handleAction}
                    disabled={isApiKeyRequired || action?.isLoading}
                    className={`btn-action btn-text btn-green mr-0 ${isApiKeyRequired || action?.isLoading ? "disabled" : ""}`}
                >
                    {action?.isLoading ? "Connecting..." : "Connect"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
export default CalendarIntegrationModal;
