import React from "react";
import { useSelector } from "react-redux";

import SidebarItem from "../SidebarItem";

const MainMenu = ({ items, onClick }) => {
    const { permissions } = useSelector((state) => state.authentication);

    const shouldRender = (item) => {
        if (!item?.permissionKey) return true;

        return permissions?.includes(String(item?.permissionKey)) || false;
    };

    return (
        <ul
            data-submenu-title=""
            style={{ marginTop: "45px" }}
        >
            {items.map(
                (item) =>
                    shouldRender(item) && (
                        <SidebarItem
                            item={item}
                            key={item?.key}
                            onClick={onClick}
                        />
                    ),
            )}
        </ul>
    );
};

export default MainMenu;
