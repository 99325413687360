import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    optionItemHeader: {
        width: "204px",
    },
    avatarGroup: {
        zIndex: "initial !important",
    },
    barColorPrimary: { backgroundColor: "#fdb4363d !important" },
    progresBarColor: {
        backgroundColor: "#fdb4363d !important",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#fdb436",
        },
    },
    progresBarColorGreen: {
        backgroundColor: "#E1E3EB !important",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#24B768",
        },
    },
    optionItem: {
        minHeight: "40px !important",
        whiteSpace: "initial !important",
        "&:hover": {
            background: "#198CCA0D !important",
        },
    },
    centerImage: {
        textAlign: "center !important",
        display: "inline-block !important",
        // "&:hover": {
        //   display: "block !important",
        // },
    },
    birthdayAlert: {
        fontSize: "14px",
        marginBottom: "10px",
        color: "#1A284D !important",
        alignItems: "center !important",
        backgroundColor: "#576AC714 !important",
    },
    avatarNumber: {
        color: "#546285 !important",
        fontSize: "12px !important",
        fontWeight: "500 !important",
        fontFamily: "Inter !important",
        background: "#E1E3EB !important",
    },
    menuHeight: {
        maxHeight: "350px !important",
        "& .MuiMenu-paper": {
            marginTop: "5px",
            borderRadius: "5px !important",
            border: "1px solid #EBECF2 !important",
            boxShadow: "0px 8px 18px #1A284D1A !important",
        },
    },
    listItem: {
        height: "auto !important",
        "& .css-10hburv-MuiTypography-root": {
            fontFamily: "Inter",
            fontSize: "14px !important",
            color: "#546285 !important",
            fontWeight: "500 !important",
            // textAlign: "center",
            // marginLeft: "13px",
        },
    },
    noRowsDataGrid: {
        alignItems: "center",
        justifyContent: "center",
        height: "100% !important",
        // marginTop: " 100px",
        "& p": {
            color: "#546285",
            paddingTop: "20px",
            marginRight: "15px !important",
            font: "normal normal 600 14px/18px Poppins !important",
        },
    },
    filterMenuItem: {
        width: "204px",
        height: "40px",
        display: "flex !important",
        color: "#1a284d !important",
        padding: "0 10px !important",
        justifyContent: "space-between !important",
        "&:hover": {
            background: "#198CCA0D !important",
        },
        "& label": {
            height: "19px",
            font: "normal normal normal 14px/17px Inter !important",
        },
    },
    menuItem: {
        width: "204px",
        height: "40px",
        padding: "12px !important",
        display: "block !important",
        color: "#1a284d !important",
        font: "normal normal normal 14px/17px Inter !important",
        "&:hover": {
            background: "#198CCA0D !important",
        },
        "& a": {
            color: "#1a284d",
            "&:hover": {
                color: "#198CCA",
            },
        },
    },
    childFilterMenuItem: {
        width: "204px",
        height: "40px",
        // cursor: "auto !important",
        display: "flex !important",
        color: "#1a284d !important",
        padding: "0 10px !important",
        paddingLeft: "20px !important",
        justifyContent: "left!important",
        "&:hover": {
            background: "#198CCA0D !important",
        },
        "& label": {
            height: "19px",
            font: "normal normal normal 14px/17px Inter !important",
        },
    },
    collapsibleTable: {
        "& .MuiTableCell-root": {
            borderColor: "#E1E3EB",
        },
        "& .MuiTableCell-body": {
            padding: "0px",
            color: "#1A284D",
            letterSpacing: "0px",
            font: "normal normal normal 14px/17px Inter",
        },
        "& .MuiTableCell-head": {
            opacity: 1,
            color: "#546285",
            letterSpacing: "0.6px",
            textTransform: "uppercase",
            font: "normal normal 600 12px/18px Poppins !important",
        },
    },
    dropdownMenu: (props) => ({
        "& .MuiMenuItem-root": {
            display: "flex !important",
        },
        "& .MuiList-root": {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
        "& .MuiMenu-paper": {
            width: "217px",
            marginTop: "5px",
            left: "11px !important",
            padding: "0px !important",
            borderRadius: "5px !important",
            border: "1px solid #EBECF2 !important",
            boxShadow: "0px 8px 18px #1A284D1A !important",
            [props?.breakpoints?.down(1024)]: {
                width: "86%",
                left: "23px !important",
            },
        },
    }),
    root: {
        opacity: "1 !important",
        color: "#1A284D !important",
        letterSpacing: "0px !important",
        font: "normal normal normal 14px/17px Inter !important",
        "& input[type='checkbox']": {
            opacity: 0,
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        // "&.MuiDataGrid-root .MuiDataGrid-main": {
        //   // minHeight: "auto",
        "& p": {
            textAlign: "left",
            marginTop: "0 !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none",
        },
        // },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-iconSeparator": {
            color: "transparent",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnsContainer": {
            flexDirection: "initial",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
            cursor: "auto !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            padding: "0px !important",
        },
        "&.MuiDataGrid-root .MuiCheckbox-colorPrimary.Mui-checked": {
            color: "#198cca !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#F9FAFC !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            "&:hover": {
                backgroundColor: "#F9FAFC",
            },
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
            opacity: 1,
            color: "#546285",
            letterSpacing: "0.6px",
            textTransform: "uppercase",
            font: "normal normal 600 12px/18px Poppins !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer, .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing":
            {
                wordBreak: "break-all",
                lineHeight: "18px !important",
                whiteSpace: "normal !important",
            },
    },
}));
