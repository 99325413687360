import React from "react";
import moment from "moment";
import { Popover } from "@mui/material";
import { DateRangePicker } from "react-date-range";

import { useWindowResize } from "@app/hooks";

export default function DateRange({ style, dateToFilter, setDateToFilter }) {
    const [direction, setDirection] = React.useState("horizontal");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const onChange = (ranges) => {
        if (moment(ranges.startDate).format("MM-DD-YYYY") !== moment(ranges.endDate).format("MM-DD-YYYY")) {
            setAnchorEl(false);
        } else if (ranges.startDate === "" && ranges.endDate === "") {
            setAnchorEl(false);
        }
    };

    const handleOnChange = (ranges) => {
        const { selection } = ranges;
        onChange(selection);
        setDateToFilter([selection]);
    };

    const handlePopper = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const resetCalendarDirection = () => {
        if (window.innerWidth <= 850) {
            setDirection("vertical");
        } else {
            setDirection("horizontal");
        }
    };

    useWindowResize(() => {
        resetCalendarDirection();
    });

    React.useEffect(() => {
        resetCalendarDirection();
    }, []);

    return (
        <>
            <button
                style={style}
                onClick={handlePopper}
                className="btn-date-range dropdown-toggle"
            >
                <i className="fa fa-calendar" />
                {dateToFilter[0]?.startDate.toDateString()} - {dateToFilter[0]?.endDate.toDateString()}
                &ensp;
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div style={{ overflow: "auto" }}>
                    <DateRangePicker
                        months={2}
                        ranges={dateToFilter}
                        direction={direction}
                        onChange={handleOnChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                    />
                </div>
            </Popover>
        </>
    );
}
