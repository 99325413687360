import React from "react";

import { ModalsContext } from "@app/contexts";

export const useModal = (modalId) => {
    const context = React.useContext(ModalsContext);

    // if context is undefined this means it was used outside of its provider
    // you can throw an error telling that to your fellow developers
    if (!context) {
        throw new Error("useModal must be used under <ModalsContextProvider />");
    }

    const { openModal, modalState, closeModal, registerModal } = context;

    const state = React.useMemo(() => modalState(modalId), [modalId, modalState]);

    const open = React.useCallback((payload = null) => openModal(modalId, payload), [modalId, openModal]);

    const close = React.useCallback((payload = null) => closeModal(modalId, payload), [modalId, closeModal]);

    const toggle = React.useCallback(() => {
        if (open) {
            closeModal(modalId);
        } else {
            openModal(modalId);
        }
    }, [modalId, open, openModal, closeModal]);

    React.useEffect(() => {
        registerModal(modalId);
    }, [modalId, registerModal]);

    return {
        state,
        openModal: open,
        closeModal: close,
        toggleModal: toggle,
    };
};
