import api from "@app/services/api";

export const GET_CONTRACTORS_BEGIN = "GET_CONTRACTORS_BEGIN";
export const getContractorsBegin = () => ({
    type: GET_CONTRACTORS_BEGIN,
});

export const GET_CONTRACTORS_SUCCESS = "GET_CONTRACTORS_SUCCESS";
export const getContractorsSuccess = (data) => ({
    payload: data,
    type: GET_CONTRACTORS_SUCCESS,
});

export const GET_CONTRACTORS_FAILED = "GET_CONTRACTORS_FAILED";
export const getContractorsFailed = (error) => ({
    payload: error,
    type: GET_CONTRACTORS_FAILED,
});

export const getContractors = () => {
    return async (dispatch) => {
        try {
            dispatch(getContractorsBegin());
            const response = await api.get(`admin/contractors`);
            dispatch(getContractorsSuccess(response.data.data));
        } catch (error) {
            dispatch(getContractorsFailed(error));
        }
    };
};
