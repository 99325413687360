import { search } from "@app/actions";
import { formatDate } from "@app/helpers";

function getPastDate(days) {
    const now = new Date();
    return formatDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() - days));
}

const initialState = {
    data: [],
    sortBy: 4,
    keywords: [],
    topFilter: 1,
    pagination: [],
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
    config: {
        loading: true,
        maxExperience: 15,
        hrScoreWeight: 30,
        isSuperAdmin: false,
        technicalScoreWeight: 70,
    },
    dateOptions: [
        // { value: "", label: "Select..." },
        { value: getPastDate(7), label: "In the last week" },
        { value: getPastDate(30), label: "In the last month" },
        { value: getPastDate(60), label: "More than a month ago" },
        { value: getPastDate(365), label: "More than a year ago" },
    ],
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case search.GET_SEARCH_RESULTS_BEGIN:
        case search.DELETE_APPLICANT_BEGIN:
        case search.CREATE_A_MATCH_BEGIN:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case search.GET_SEARCH_RESULTS_FAILED:
        case search.DELETE_APPLICANT_FAILED:
        case search.CREATE_A_MATCH_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case search.DELETE_APPLICANT_SUCCESS:
            const filteredData = state.users.filter((el) => el.id !== action.payload.id);
            return {
                ...state,
                users: [...filteredData],
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.success,
                },
            };
        case search.GET_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                pagination: action.payload.data.pagination,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        case search.GET_SEARCH_CONFIG_BEGIN:
            return {
                ...state,
                config: {
                    ...state.config,
                    loading: true,
                },
            };
        case search.GET_SEARCH_CONFIG_FAILED:
            return {
                ...state,
                config: {
                    ...state.config,
                    loading: false,
                },
            };
        case search.GET_SEARCH_CONFIG_SUCCESS:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload.data,
                    loading: false,
                },
            };
        case search.CREATE_A_MATCH_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case search.SET_SEARCH_KEYWORDS:
            const sanitized = action.payload?.toLowerCase()?.split(" ")?.filter(Boolean) || [];

            return {
                ...state,
                keywords: sanitized,
            };
        case search.CLEAR_ACTION_SEARCH:
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        default: {
            return state;
        }
    }
}
