import React from "react";
import MainMenu from "./MainMenu";
import AdminMenu from "./AdminMenu";
import CompanySwitch from "./CompanySwitch";

import { generate } from "@app/routes/SidebarLinks/Client";

function ClientSidebar({ toggleResponsiveMenu }) {
    const links = React.useMemo(() => generate(), []);

    return (
        <>
            <CompanySwitch />

            <MainMenu
                items={links}
                onClick={toggleResponsiveMenu}
            />

            <AdminMenu onClick={toggleResponsiveMenu} />
        </>
    );
}

export default ClientSidebar;
