// eslint-disable-next-line unused-imports/no-unused-imports
import React from "react";

export default function Feature({ children, flag = false }) {
    if (!flag) {
        return null;
    }

    return children;
}
