import React from "react";

const CardRowContainer = ({ children }) => {
    return (
        <div className="card">
            <ul className="list-group list-group-flush">{children}</ul>
        </div>
    );
};

export default CardRowContainer;
