import { SORTING } from "@app/enums";
import * as actions from "@app/actions/Admin/Positions";

const initialState = {
    data: [],

    statuses: [],

    pagination: {
        page: 1,
        total: 0,
        pageSize: 10,
    },

    sort: {
        column: "id",
        direction: SORTING.DIRECTION.DESC,
    },

    filters: {
        search: "",
        companyId: null,
        selectedFilter: null,
    },

    action: {
        error: null,
        message: "",
        isLoading: true,
        actionName: null,
        isSuccess: false,
    },
};

export default function PositionsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.CLEAR_ADMIN_POSITIONS_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                },
            };
        }
        case actions.CLEAR_ADMIN_POSITIONS_STATE: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
            };
        }
        case actions.SET_ADMIN_POSITIONS_FILTERS: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: 1,
                },
                action: {
                    ...state.action,
                    actionName: action.type,
                },
                filters: {
                    ...state.filters,
                    [action.key]: action.value,
                },
            };
        }
        case actions.SET_ADMIN_POSITIONS_PAGINATION: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }
        case actions.SET_ADMIN_POSITIONS_SORTING: {
            return {
                ...state,
                action: { ...initialState.action, isLoading: false },
                sort: {
                    column: action.column,
                    direction: action.direction,
                },
            };
        }
        case actions.UPDATE_ADMIN_POSITION_BEGIN:
        case actions.DELETE_ADMIN_POSITIONS_BEGIN:
        case actions.GET_ADMIN_POSITIONS_STATUSES_BEGIN:
        case actions.GET_ADMIN_POSITIONS_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case actions.UPDATE_ADMIN_POSITION_FAILED:
        case actions.DELETE_ADMIN_POSITIONS_FAILED:
        case actions.GET_ADMIN_POSITIONS_STATUSES_FAILED:
        case actions.GET_ADMIN_POSITIONS_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case actions.UPDATE_ADMIN_POSITION_SUCCESS:
        case actions.DELETE_ADMIN_POSITIONS_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.message,
                },
            };
        }
        case actions.GET_ADMIN_POSITIONS_SUCCESS: {
            return {
                ...state,
                data: action.payload?.data || [],
                pagination: {
                    ...state.pagination,
                    total: action.payload?.meta?.total || 0,
                },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case actions.GET_ADMIN_POSITIONS_STATUSES_SUCCESS: {
            return {
                ...state,
                statuses: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
