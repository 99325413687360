import { ERROR } from "@app/enums";
import { interviews } from "@app/actions";

const initialState = {
    // Interview Details
    rating: null,

    statuses: [],
    noteTypes: [],
    interviews: [],
    interview: null,

    noteTypeId: null,
    interviewers: [],
    calendarLogo: null,
    // Interviews
    selectedCalendar: null,
    filters: {
        status: "upcoming",
    },
    sorting: {
        sort: "asc",
        field: "start_at",
    },
    pagination: {
        page: 0,
        total: 0,
        pageSize: 10,
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function interviewsReducer(state = initialState, action) {
    switch (action.type) {
        /**
         * BEGIN ACTIONS
         */
        case interviews.GET_RATING_BEGIN:
        case interviews.GET_STATUSES_BEGIN:
        case interviews.GET_INTERVIEW_BEGIN:
        case interviews.GET_INTERVIEWS_BEGIN:
        case interviews.GET_NOTE_TYPES_BEGIN:
        case interviews.GET_INTERVIEWER_BEGIN:
        case interviews.CALENDAR_CONNECT_BEGIN:
        case interviews.SUBMIT_INTERVIEW_FEEDBACK_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                },
            };
        }

        /**
         * FAILED ACTIONS
         */
        case interviews.GET_RATING_FAILED:
        case interviews.GET_STATUSES_FAILED:
        case interviews.GET_INTERVIEW_FAILED:
        case interviews.GET_INTERVIEWS_FAILED:
        case interviews.GET_NOTE_TYPES_FAILED:
        case interviews.GET_INTERVIEWER_FAILED:
        case interviews.CALENDAR_CONNECT_FAILED:
        case interviews.SUBMIT_INTERVIEW_FEEDBACK_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    message: "",
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : ERROR.DEFAULT_MESSAGE,
                },
            };
        }

        /**
         * SUCCESS ACTIONS
         */
        case interviews.GET_INTERVIEW_SUCCESS: {
            return {
                ...state,
                interview: action.payload?.interview,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.GET_INTERVIEWER_SUCCESS: {
            return {
                ...state,
                interviewers: action.payload?.interviewers,
                calendarLogo: action.payload?.calendar?.logo || null,
                selectedCalendar: action.payload?.interviewers?.[0] || null,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.SUBMIT_INTERVIEW_FEEDBACK_SUCCESS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload?.success || "",
                },
            };
        }
        case interviews.GET_INTERVIEWS_SUCCESS: {
            return {
                ...state,
                interviews: action?.payload?.data || [],
                pagination: {
                    ...state.pagination,
                    total: Number(action?.payload?.meta?.total) || 0,
                },
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.GET_STATUSES_SUCCESS: {
            return {
                ...state,
                statuses: action?.payload?.countByStatus || [],
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.GET_NOTE_TYPES_SUCCESS: {
            return {
                ...state,
                noteTypes: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.GET_RATING_SUCCESS: {
            return {
                ...state,
                rating: action.payload,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        case interviews.CALENDAR_CONNECT_SUCCESS: {
            return {
                ...state,
                interviewers: action.payload?.interviewers,
                selectedCalendar: action.payload?.interviewers?.[0] || null,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }

        /**
         * MUTATOR ACTIONS
         */
        case interviews.UPDATE_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
                // Reset paginator on status change
                pagination: {
                    ...state.pagination,
                    page: 0,
                    total: 0,
                },
            };
        }
        case interviews.UPDATE_SORTING: {
            return {
                ...state,
                // Reset paginator on sorting change
                // pagination: {
                //   ...state.pagination,
                //   page: 0,
                //   total: 0,
                // },
                sorting: {
                    ...state.sorting,
                    ...action.payload,
                },
            };
        }
        case interviews.UPDATE_PAGINATION: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }
        case interviews.NOTE_TYPE_SELECTED: {
            return {
                ...state,
                noteTypeId: action.payload,
            };
        }
        case interviews.CLEAR_ERRORS: {
            return {
                ...state,
                action: {
                    ...state.action,
                    message: "",
                    error: null,
                    isLoading: false,
                    actionName: null,
                    isSuccess: false,
                },
            };
        }
        case interviews.RESET_INTERVIEW_DETAILS: {
            return {
                ...state,
                rating: null,
                noteTypes: [],
                interview: null,
                noteTypeId: null,
                action: {
                    ...state.action,
                    error: null,
                    message: "",
                    isLoading: false,
                    actionName: null,
                    isSuccess: false,
                },
            };
        }
        case interviews.SET_SELECTED_INTERVIEWER_CALENDAR: {
            const currentCalendar = state?.interviewers?.find(
                (interviewer) => String(interviewer.id) === String(action.payload),
            );

            return {
                ...state,
                selectedCalendar: currentCalendar,
            };
        }

        /**
         * DEFAULT ACTION
         */
        default: {
            return state;
        }
    }
}
