import api from "@app/services/api";
import { STATUS } from "@app/enums";
import QueryBuilder from "@app/services/query";
import { generateFormdata } from "@app/helpers";

export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const setSearchKeyword = (keyword) => {
    return {
        payload: keyword,
        type: SET_SEARCH_KEYWORD,
    };
};

export const SET_PAGINATION_MATCHES = "SET_PAGINATION_MATCHES";
export const setPaginationMatches = (key, value) => ({
    key,
    value,
    type: SET_PAGINATION_MATCHES,
});

export const SET_SORT_MATCHES = "SET_SORT_MATCHES";
export const setSortMatches = (orderColumn, asc) => ({
    asc,
    orderColumn,
    type: SET_SORT_MATCHES,
});

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const CLEAR_STATE = "CLEAR_STATE";
export const clearState = () => ({
    type: CLEAR_STATE,
});

export const CLEAR_MATCH_DETAILS_STATE = "CLEAR_MATCH_DETAILS_STATE";
export const clearMatchDetailsState = () => ({
    type: CLEAR_MATCH_DETAILS_STATE,
});

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_MATCHES_BEGIN = "GET_MATCHES_BEGIN";
export const getMatchesBegin = () => ({
    type: GET_MATCHES_BEGIN,
});

export const GET_MATCHES_SUCCESS = "GET_MATCHES_SUCCESS";
export const getMatchesSuccess = (data) => ({
    payload: data,
    type: GET_MATCHES_SUCCESS,
});

export const GET_MATCHES_FAILED = "GET_MATCHES_FAILED";
export const getMatchesFailed = (error) => ({
    payload: error,
    type: GET_MATCHES_FAILED,
});

export const GET_MATCHES = "GET_MATCHES";
export const getMatches = () => {
    return async (dispatch, getState) => {
        try {
            const { sort, searchKeyword, paginationMatches } = getState()?.admin?.matches;

            const { direction, orderColumn } = sort;
            const { page, pageSize, selectedFilter, userStatusesFilter, positionStatusesFilter } = paginationMatches;

            dispatch(getMatchesBegin());

            const response = await api.get(
                `admin/talent/matches` +
                    `?status_name=${selectedFilter}` +
                    `&position_statuses=${positionStatusesFilter}` +
                    `&user_statuses=${userStatusesFilter}` +
                    `&page=${page}` +
                    `&num_items=${pageSize}` +
                    `&search=${searchKeyword}` +
                    `&order_column=${orderColumn}` +
                    `&order_direction=${direction || ""}`,
            );
            dispatch(getMatchesSuccess(response.data.data));
        } catch (error) {
            dispatch(getMatchesFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const DELETE_MATCH_BEGIN = "DELETE_MATCH_BEGIN";
export const deleteMatchBegin = () => ({
    type: DELETE_MATCH_BEGIN,
});

export const DELETE_MATCH_SUCCESS = "DELETE_MATCH_SUCCESS";
export const deleteMatchSuccess = (data) => ({
    payload: data,
    type: DELETE_MATCH_SUCCESS,
});

export const DELETE_MATCH_FAILED = "DELETE_MATCH_FAILED";
export const deleteMatchFailed = (error) => ({
    payload: error,
    type: DELETE_MATCH_FAILED,
});

export const DELETE_MATCH = "DELETE_MATCH";
export const deleteMatch = (matchId) => {
    return async (dispatch) => {
        try {
            dispatch(deleteMatchBegin());
            const response = await api.delete(`admin/talent/matches/${matchId}/delete`);
            dispatch(deleteMatchSuccess(response.data.data));
            dispatch(getMatches());
        } catch (error) {
            dispatch(deleteMatchFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const REOPEN_MATCH_BEGIN = "REOPEN_MATCH_BEGIN";
export const reopenMatchBegin = () => ({
    type: REOPEN_MATCH_BEGIN,
});

export const REOPEN_MATCH_SUCCESS = "REOPEN_MATCH_SUCCESS";
export const reopenMatchSuccess = (data) => ({
    payload: data,
    type: REOPEN_MATCH_SUCCESS,
});

export const REOPEN_MATCH_FAILED = "REOPEN_MATCH_FAILED";
export const reopenMatchFailed = (error) => ({
    payload: error,
    type: REOPEN_MATCH_FAILED,
});

export const REOPEN_MATCH = "REOPEN_MATCH";
export const reopenMatch = (matchId) => {
    return async (dispatch) => {
        try {
            dispatch(reopenMatchBegin());

            const response = await api.post(`admin/talent/matches/reopen/${matchId}`);

            dispatch(reopenMatchSuccess(response.data.data));
        } catch (error) {
            dispatch(reopenMatchFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const DECLINE_MATCH_BEGIN = "DECLINE_MATCH_BEGIN";
export const declineMatchBegin = () => ({
    type: DECLINE_MATCH_BEGIN,
});

export const DECLINE_MATCH_SUCCESS = "DECLINE_MATCH_SUCCESS";
export const declineMatchSuccess = (data) => ({
    payload: data,
    type: DECLINE_MATCH_SUCCESS,
});

export const DECLINE_MATCH_FAILED = "DECLINE_MATCH_FAILED";
export const declineMatchFailed = (error) => ({
    payload: error,
    type: DECLINE_MATCH_FAILED,
});

export const DECLINE_MATCH = "DECLINE_MATCH";
export const declineMatch = (matchId, data) => {
    return async (dispatch) => {
        try {
            dispatch(declineMatchBegin());

            const formData = generateFormdata(data);

            const response = await api.post(`admin/talent/matches/decline/${matchId}`, formData);

            dispatch(declineMatchSuccess(response.data.data));
        } catch (error) {
            dispatch(declineMatchFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const BULK_DECLINE_MATCH_BEGIN = "BULK_DECLINE_MATCH_BEGIN";
export const bulkDeclineMatchBegin = () => ({
    type: BULK_DECLINE_MATCH_BEGIN,
});

export const BULK_DECLINE_MATCH_SUCCESS = "BULK_DECLINE_MATCH_SUCCESS";
export const bulkDeclineMatchSuccess = (data) => ({
    payload: data,
    type: BULK_DECLINE_MATCH_SUCCESS,
});

export const BULK_DECLINE_MATCH_FAILED = "BULK_DECLINE_MATCH_FAILED";
export const bulkDeclineMatchFailed = (error) => ({
    payload: error,
    type: BULK_DECLINE_MATCH_FAILED,
});

export const BULK_DECLINE_MATCH = "BULK_DECLINE_MATCH";
export const bulkDeclineMatch = (matchIds, data) => {
    return async (dispatch) => {
        try {
            dispatch(bulkDeclineMatchBegin());

            const formData = generateFormdata(data);

            const response = await api.post(`admin/talent/matches/bulk-decline`, formData);

            dispatch(bulkDeclineMatchSuccess(response.data.data));
        } catch (error) {
            dispatch(bulkDeclineMatchFailed(error));
            throw error;
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const ADD_NOTE_MATCH_BEGIN = "ADD_NOTE_MATCH_BEGIN";
export const addMatchNoteBegin = () => ({
    type: MOVE_TO_PHASE_BEGIN,
});

export const ADD_NOTE_MATCH_SUCCESS = "ADD_NOTE_MATCH_SUCCESS";
export const addNoteMatchSuccess = (data) => ({
    payload: data,
    type: ADD_NOTE_MATCH_SUCCESS,
});

export const ADD_MATCH_NOTE_FAILED = "ADD_MATCH_NOTE_FAILED";
export const addMatchNoteFailed = (error) => ({
    payload: error,
    type: ADD_MATCH_NOTE_FAILED,
});

export const ADD_NOTE_MATCH = "ADD_NOTE_MATCH";
export const addMatchNote = (matchId, data) => {
    return async (dispatch) => {
        try {
            dispatch(addMatchNoteBegin());
            const formData = generateFormdata(data);
            const response = await api.post(`admin/talent/matches/add-note/${matchId}`, formData);
            dispatch(addNoteMatchSuccess(response.data.data));
        } catch (error) {
            dispatch(addMatchNoteFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const MOVE_TO_PHASE_BEGIN = "MOVE_TO_PHASE_BEGIN";
export const moveToPhaseBegin = () => ({
    type: MOVE_TO_PHASE_BEGIN,
});

export const MOVE_TO_PHASE_SUCCESS = "MOVE_TO_PHASE_SUCCESS";
export const moveToPhaseSuccess = (data) => ({
    payload: data,
    type: MOVE_TO_PHASE_SUCCESS,
});

export const MOVE_TO_PHASE_FAILED = "MOVE_TO_PHASE_FAILED";
export const moveToPhaseFailed = (error) => ({
    payload: error,
    type: MOVE_TO_PHASE_FAILED,
});

export const MOVE_TO_PHASE = "MOVE_TO_PHASE";
export const moveToPhase = (matchId, data) => {
    return async (dispatch) => {
        try {
            dispatch(moveToPhaseBegin());

            const formData = generateFormdata(data);

            const response = await api.post(`admin/talent/matches/move-to-phase/${matchId}`, formData);

            dispatch(moveToPhaseSuccess(response.data.data));
        } catch (error) {
            dispatch(moveToPhaseFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_BENCHMARK_BEGIN = "GET_BENCHMARK_BEGIN";
export const getBenchmarkBegin = () => ({
    type: GET_BENCHMARK_BEGIN,
});

export const GET_BENCHMARK_SUCCESS = "GET_BENCHMARK_SUCCESS";
export const getBenchmarkSuccess = (data) => ({
    payload: data,
    type: GET_BENCHMARK_SUCCESS,
});

export const GET_BENCHMARK_FAILED = "GET_BENCHMARK_FAILED";
export const getBenchmarkFailed = (error) => ({
    payload: error,
    type: GET_BENCHMARK_FAILED,
});

export const GET_BENCHMARK = "GET_BENCHMARK";
export const getBenchmark = (page, items, search, orderColumn, direction) => {
    return async (dispatch) => {
        try {
            dispatch(getBenchmarkBegin());
            const response = await api.get(
                `admin/benchmark-positions?page=${page}&num_items=${items}&search=${search}&order_column=${orderColumn}&order_direction=${
                    direction || ""
                }`,
            );
            dispatch(getBenchmarkSuccess(response.data));
        } catch (error) {
            dispatch(getBenchmarkFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const DELETE_BENCHMARK_BEGIN = "DELETE_BENCHMARK_BEGIN";
export const deleteBenchmarkBegin = () => ({
    type: DELETE_BENCHMARK_BEGIN,
});

export const DELETE_BENCHMARK_SUCCESS = "DELETE_BENCHMARK_SUCCESS";
export const deleteBenchmarkSuccess = (data) => ({
    payload: data,
    type: DELETE_BENCHMARK_SUCCESS,
});

export const DELETE_BENCHMARK_FAILED = "DELETE_BENCHMARK_FAILED";
export const deleteBenchmarkFailed = (error) => ({
    payload: error,
    type: DELETE_BENCHMARK_FAILED,
});

export const DELETE_BENCHMARK = "DELETE_BENCHMARK";
export const deleteBenchmark = (benchmarkId, userId, page, items, search, orderColumn, asc) => {
    return async (dispatch) => {
        try {
            dispatch(deleteBenchmarkBegin());
            const response = await api.delete(`/admin/benchmark-positions/${benchmarkId}/users/${userId}`);
            dispatch(deleteBenchmarkSuccess(response.data.data));
            dispatch(getBenchmark(page, items, search, orderColumn, asc));
        } catch (error) {
            dispatch(deleteBenchmarkFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_EMAIL_TEMPLATES_BEGIN = "GET_EMAIL_TEMPLATES_BEGIN";
export const getEmailTemplatesBegin = () => ({
    type: GET_EMAIL_TEMPLATES_BEGIN,
});

export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const getEmailTemplatesSuccess = (data) => ({
    payload: data,
    type: GET_EMAIL_TEMPLATES_SUCCESS,
});

export const GET_EMAIL_TEMPLATES_FAILED = "GET_EMAIL_TEMPLATES_FAILED";
export const getEmailTemplatesFailed = (error) => ({
    payload: error,
    type: GET_EMAIL_TEMPLATES_FAILED,
});

export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES";
export const getEmailTemplates = (matchId, phaseId, userId) => {
    return async (dispatch) => {
        try {
            dispatch(getEmailTemplatesBegin());

            const response = await api.get(`/admin/talent/matches/${matchId}/get-email-templates/${phaseId}/${userId}`);

            dispatch(getEmailTemplatesSuccess(response.data));
        } catch (error) {
            dispatch(getEmailTemplatesFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_ADMIN_POSITIONS_STATUSES_FILTER = "GET_ADMIN_POSITIONS_STATUSES_FILTER";
export const getPositionsStatusesFilter = () => {
    return async (dispatch) => {
        try {
            const params = QueryBuilder.for("statuses")
                .select(["id", "name"])
                .where("group", STATUS.GROUP.POSITION)
                .sort("+order")
                .get();

            const response = await api.get(`admin/statuses${params}`);

            dispatch(setPositionStatusesFilterData(response.data.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const SET_ADMIN_POSITION_STATUSES_FILTER_DATA = "SET_ADMIN_POSITION_STATUSES_FILTER_DATA";
export const setPositionStatusesFilterData = (statuses) => ({
    payload: statuses,
    type: SET_ADMIN_POSITION_STATUSES_FILTER_DATA,
});

export const SET_ADMIN_POSITION_STATUSES_FILTER = "SET_ADMIN_POSITION_STATUSES_FILTER";
export const setPositionStatusesFilter = (statuses) => ({
    payload: statuses,
    type: SET_ADMIN_POSITION_STATUSES_FILTER,
});

export const CLEAR_ADMIN_POSITION_STATUSES_FILTER = "CLEAR_ADMIN_POSITION_STATUSES_FILTER";
export const clearPositionStatusesFilter = () => ({
    type: CLEAR_ADMIN_POSITION_STATUSES_FILTER,
});

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_MATCH_DETAILS_BEGIN = "GET_MATCH_DETAILS_BEGIN";
export const getMatchDetailsBegin = () => ({
    type: GET_MATCH_DETAILS_BEGIN,
});

export const GET_MATCH_DETAILS_SUCCESS = "GET_MATCH_DETAILS_SUCCESS";
export const getMatchDetailsSuccess = (data) => ({
    payload: data,
    type: GET_MATCH_DETAILS_SUCCESS,
});

export const GET_MATCH_DETAILS_FAILED = "GET_MATCH_DETAILS_FAILED";
export const getMatchDetailsFailed = (error) => ({
    payload: error,
    type: GET_MATCH_DETAILS_FAILED,
});

export const GET_MATCH_DETAILS = "GET_MATCH_DETAILS";
export const getMatchDetails = (matchId) => {
    return async (dispatch) => {
        try {
            dispatch(getMatchDetailsBegin());

            const params = QueryBuilder.for("user_positions")
                .transforms(["match_details"])
                .select({
                    "position.companies": ["id", "name"],
                    "user.contacts": ["id", "user_id", "country"],
                    "user.owners": ["id", "first_name", "last_name"],
                    "position.internal_owners": ["id", "first_name", "last_name"],
                    users: ["id", "email", "first_name", "last_name", "owner_id", "profile_ready"],
                    positions: ["id", "name", "due_date", "location", "company_id", "internal_owner_id"],
                    position_x_users: [
                        "id",
                        "user_id",
                        "matcher_id",
                        "position_id",
                        "client_rate_type",
                        "client_rate_amount",
                        "client_rate_currency",
                        "talent_rate_type",
                        "talent_rate_amount",
                        "talent_rate_currency",
                    ],
                })
                .where("id", matchId)
                .get();

            const response = await api.get(`admin/talent/new/matches${params}`);

            dispatch(getMatchDetailsSuccess(response.data?.data?.[0]));
        } catch (error) {
            dispatch(getMatchDetailsFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_MATCH_NOTES_BEGIN = "GET_MATCH_NOTES_BEGIN";
export const getMatchNotesBegin = () => ({
    type: GET_MATCH_NOTES_BEGIN,
});

export const GET_MATCH_NOTES_SUCCESS = "GET_MATCH_NOTES_SUCCESS";
export const getMatchNotesSuccess = (data) => ({
    payload: data,
    type: GET_MATCH_NOTES_SUCCESS,
});

export const GET_MATCH_NOTES_FAILED = "GET_MATCH_NOTES_FAILED";
export const getMatchNotesFailed = (error) => ({
    payload: error,
    type: GET_MATCH_NOTES_FAILED,
});

export const GET_MATCH_NOTES = "GET_MATCH_NOTES";
export const getMatchNotes = (matchId) => {
    return async (dispatch) => {
        try {
            dispatch(getMatchNotesBegin());

            const response = await api.get(`admin/notes?entity_id=${matchId}&entity_type=match`);

            dispatch(getMatchNotesSuccess(response.data?.data?.notes ?? []));
        } catch (error) {
            dispatch(getMatchNotesFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const UPDATE_MATCH_DETAILS_BEGIN = "UPDATE_MATCH_DETAILS_BEGIN";
export const updateMatchDetailsBegin = () => ({
    type: UPDATE_MATCH_DETAILS_BEGIN,
});

export const UPDATE_MATCH_DETAILS_SUCCESS = "UPDATE_MATCH_DETAILS_SUCCESS";
export const updateMatchDetailsSuccess = (data) => ({
    payload: data,
    type: UPDATE_MATCH_DETAILS_SUCCESS,
});

export const UPDATE_MATCH_DETAILS_FAILED = "UPDATE_MATCH_DETAILS_FAILED";
export const updateMatchDetailsFailed = (error) => ({
    payload: error,
    type: UPDATE_MATCH_DETAILS_FAILED,
});

export const UPDATE_MATCH_DETAILS = "UPDATE_MATCH_DETAILS";
export const updateMatchDetails = (matchId, data) => {
    return async (dispatch) => {
        try {
            dispatch(updateMatchDetailsBegin());

            const response = await api.patch(`admin/talent/matches/${matchId}`, data);

            dispatch(updateMatchDetailsSuccess(response.data?.data?.notes ?? []));
        } catch (error) {
            dispatch(updateMatchDetailsFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_MATCH_RECOMMENDATIONS_BEGIN = "GET_MATCH_RECOMMENDATIONS_BEGIN";
export const getMatchRecommendationsBegin = () => ({
    type: GET_MATCH_RECOMMENDATIONS_BEGIN,
});

export const GET_MATCH_RECOMMENDATIONS_SUCCESS = "GET_MATCH_RECOMMENDATIONS_SUCCESS";
export const getMatchRecommendationsSuccess = (data) => ({
    payload: data,
    type: GET_MATCH_RECOMMENDATIONS_SUCCESS,
});

export const GET_MATCH_RECOMMENDATIONS_FAILED = "GET_MATCH_RECOMMENDATIONS_FAILED";
export const getMatchRecommendationsFailed = (error) => ({
    payload: error,
    type: GET_MATCH_RECOMMENDATIONS_FAILED,
});

export const GET_MATCH_RECOMMENDATIONS = "GET_MATCH_RECOMMENDATIONS";
export const getMatchRecommendations = (matchId) => {
    return async (dispatch) => {
        try {
            dispatch(getMatchRecommendationsBegin());

            const params = QueryBuilder.for("match_recommendations")
                .select({
                    match_recommendations: ["id", "match_id", "ai_recommendations"],
                })
                .where("match_id", matchId)
                .get();

            const response = await api.get(`admin/talent/matches/recommendations${params}`);

            dispatch(getMatchRecommendationsSuccess(response.data?.data?.[0]?.ai_recommendations ?? []));
        } catch (error) {
            dispatch(getMatchRecommendationsFailed(error));
        }
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const GET_MATCHES_STATUSES_BEGIN = "GET_MATCHES_STATUSES_BEGIN";
export const getMatchesStatusesBegin = () => ({
    type: GET_MATCHES_STATUSES_BEGIN,
});

export const GET_MATCHES_STATUSES_SUCCESS = "GET_MATCHES_STATUSES_SUCCESS";
export const getMatchesStatusesSuccess = (data) => ({
    payload: data,
    type: GET_MATCHES_STATUSES_SUCCESS,
});

export const GET_MATCHES_STATUSES_FAILED = "GET_MATCHES_STATUSES_FAILED";
export const getMatchesStatusesFailed = (error) => ({
    payload: error,
    type: GET_MATCHES_STATUSES_FAILED,
});

export const GET_MATCHES_STATUSES = "GET_MATCHES_STATUSES";
export const getMatchesStatuses = () => {
    return async (dispatch) => {
        try {
            dispatch(getMatchesStatusesBegin());

            const params = QueryBuilder.for("statuses")
                .transforms("status")
                .select(["id", "name", "order"])
                .where("group", STATUS.GROUP.CONTRACT)
                .sort("+order")
                .get();

            const response = await api.get(`admin/statuses${params}`);

            dispatch(getMatchesStatusesSuccess(response.data.data));
        } catch (error) {
            dispatch(getMatchesStatusesFailed(error));
        }
    };
};
