import React from "react";

import "./index.scss";

const variants = {
    info: "info-message",
    error: "error-message",
    success: "success-message",
};

export default function HelperText({ text, always = false, variant = "message" }) {
    if (!text && !always) {
        return null;
    }

    return (
        <div className="helper-text">
            <small className={`message ${variants[variant] ?? ""}`}>{text}</small>
        </div>
    );
}
