import { TALENT } from "@app/routes";
import { websiteUrl, mainHostUrl } from "@app/helpers";
import { TALENT_PERKS, TALENT_LEARNING } from "@app/routes/talent";
import { FEAT_NEW_TALENT_DASHBOARD_ENABLED } from "@app/config/features";

const generateDashboardLinks = () => {
    return [
        {
            key: "overview",
            display: "Overview",
            icon: "icon-overview",
            target: FEAT_NEW_TALENT_DASHBOARD_ENABLED ? TALENT.DASHBOARD_PATH : mainHostUrl("account"),
        },
    ];
};

const generateCommunityLinks = () => {
    return FEAT_NEW_TALENT_DASHBOARD_ENABLED
        ? [
              {
                  key: "community",
                  display: "Community",
                  icon: "icon-community",
                  children: [
                      {
                          key: "programs",
                          display: "Programs",
                          target: TALENT.TALENT_PROGRAMS,
                      },
                      {
                          target: TALENT_LEARNING,
                          key: "learning-and-certification",
                          display: "Learning & Certification",
                      },
                      {
                          target: TALENT_PERKS,
                          key: "perks-and-benefits",
                          display: "Perks & Benefits",
                      },
                      {
                          display: "Interviews",
                          key: "community-interviews",
                          target: TALENT.INTERVIEWS_PATH,
                      },
                  ],
              },
          ]
        : [
              {
                  key: "community",
                  display: "Community",
                  icon: "icon-community",
                  children: [
                      {
                          display: "Write",
                          key: "community-writter",
                          target: mainHostUrl("community/writer"),
                      },
                      {
                          display: "Speak",
                          key: "community-speaker",
                          target: mainHostUrl("community/speaker"),
                      },
                      {
                          display: "Interviews",
                          key: "community-interviews",
                          target: TALENT.INTERVIEWS_PATH,
                      },
                  ],
              },
          ];
};

export const generate = (config = {}) => {
    const isCoreEmployee = config?.isCoreEmployee || false;
    const dashboardAccessOnly = config?.dashboardAccessOnly || false;
    const applicantAccessOnly = config?.applicantAccessOnly || false;

    const dashboardLinks = generateDashboardLinks();

    // Talent has no access to the portal except for the dashboard
    if (dashboardAccessOnly) return dashboardLinks;

    return [
        ...dashboardLinks,
        !applicantAccessOnly && {
            key: "jobs",
            display: "Jobs",
            icon: "icon-user-search",
            children: [
                {
                    key: "positions",
                    display: "Job Openings",
                    target: TALENT.POSITIONS,
                },
                {
                    key: "contracts",
                    display: "Contracts",
                    target: mainHostUrl("contracts"),
                },
                {
                    key: "timelogs",
                    display: "Time Management",
                    target: mainHostUrl("logs/time-logs"),
                },
            ],
        },
        !applicantAccessOnly && {
            key: "payments",
            display: "Payments",
            icon: "icon-payments-nav",
            target: mainHostUrl("payments"),
        },
        ...generateCommunityLinks(),
        {
            key: "referrals",
            display: "Referrals",
            icon: "icon-referrals",
            target: TALENT.REFERRALS,
        },
        {
            key: "support",
            icon: "icon-faq",
            display: "Support",
            target: mainHostUrl("support"),
        },
        FEAT_NEW_TALENT_DASHBOARD_ENABLED &&
            !applicantAccessOnly && {
                hasMarginTop: true,
                renderTitleFirst: true,
                key: "business-conduct",
                display: "Business Conduct",
                icon: "icon-arrow-right-up-grey",
                target: websiteUrl("guides/business-conduct"),
            },
        FEAT_NEW_TALENT_DASHBOARD_ENABLED &&
            !isCoreEmployee &&
            !applicantAccessOnly && {
                renderTitleFirst: true,
                key: "contractor-policy",
                display: "Contractor Policy",
                icon: "icon-arrow-right-up-grey",
                target: websiteUrl("guides/contractor-handbook"),
            },
    ]?.filter(Boolean);
};
