import format from "date-fns/format";

export function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

export const formatDateFns = (date, formatStr = "dd MMM, y") => {
    const dateObj = new Date(date);

    return format(dateObj, formatStr);
};
