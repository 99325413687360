import React, { useState, useCallback } from "react";
import { Pie, Cell, Sector, PieChart, ResponsiveContainer } from "recharts";

export default function PieChartComponent({
    data,
    colors,
    SmallChart,
    totalScore,
    AvatarChart,
    CardViewChart,
    totalPositions,
    DoubleBorderChart,
    StraightAngleChart,
}) {
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, fill, value, payload, percent, midAngle, endAngle, startAngle, innerRadius, outerRadius } =
            props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        const renderChartType = () => {
            if (SmallChart) {
                return (
                    <text
                        x={cx}
                        y={cy}
                        dy={5}
                        fontSize={16}
                        fill="#1A284D"
                        textAnchor="middle"
                        className="bold-text"
                    >
                        {`${Math.round(percent * 100)}%`}
                    </text>
                );
            }
            if (CardViewChart) {
                return (
                    <text
                        x={cx}
                        y={cy}
                        dy={5}
                        fontSize={13}
                        fill="#1A284D"
                        textAnchor="middle"
                        className="bold-text"
                    >
                        {totalScore}
                    </text>
                );
            }
            if (AvatarChart) {
                return (
                    <text
                        x={cx}
                        y={cy}
                        dy={4}
                        fontSize={10}
                        fill="#1A284D"
                        textAnchor="middle"
                        className="bold-text"
                        style={{ background: "#fff" }}
                    >
                        {totalScore === 0 ? "-" : totalScore}
                    </text>
                );
            }
            if (DoubleBorderChart) {
                return (
                    <>
                        <text
                            x={cx}
                            y={cy}
                            dy={-5}
                            fill={fill}
                            textAnchor="middle"
                            className="bold-text"
                            style={{ fill: "#1A284D", fontSize: "30px" }}
                        >
                            {totalPositions}
                        </text>
                        <text
                            x={cx}
                            y={cy}
                            dy={15}
                            fill="#546285"
                            textAnchor="middle"
                            style={{ fontSize: "14px" }}
                        >
                            Total Positions
                        </text>
                    </>
                );
            }
            return (
                <>
                    <text
                        x={cx}
                        y={cy}
                        dy={-5}
                        fill={fill}
                        textAnchor="middle"
                        className="bold-text"
                        style={{ fill: "#1A284D", fontSize: "30px" }}
                    >
                        {value}
                    </text>
                    <text
                        x={cx}
                        y={cy}
                        dy={15}
                        fill="#546285"
                        textAnchor="middle"
                        style={{ fontSize: "14px" }}
                    >
                        {payload.name}
                    </text>
                </>
            );
        };

        return (
            <g>
                {renderChartType()}
                <Sector
                    cx={cx}
                    cy={cy}
                    fill={fill}
                    endAngle={endAngle}
                    startAngle={startAngle}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                />
                {DoubleBorderChart && (
                    <>
                        <Sector
                            cx={cx}
                            cy={cy}
                            fill={fill}
                            endAngle={endAngle}
                            startAngle={startAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                        />
                        <path
                            fill="none"
                            stroke={fill}
                            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                        />
                        <circle
                            r={2}
                            cx={ex}
                            cy={ey}
                            fill={fill}
                            stroke="none"
                        />
                        <text
                            y={ey}
                            fill="#333"
                            textAnchor={textAnchor}
                            x={ex + (cos >= 0 ? 1 : -1) * 12}
                        >{`${value}`}</text>
                    </>
                )}
            </g>
        );
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );

    if (CardViewChart) {
        return (
            <ResponsiveContainer
                width={50}
                height={50}
            >
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        innerRadius={14}
                        outerRadius={20}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        // paddingAngle={0}
                        // onMouseEnter={onPieEnter}
                    >
                        {data.map((entry, index) => {
                            return (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            );
                        })}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
    if (AvatarChart) {
        return (
            <ResponsiveContainer className="avatar-chart">
                <PieChart style={{ borderRadius: "50%", background: "#FFFFFF", border: "1px solid #FFFFFF" }}>
                    <Pie
                        data={data}
                        dataKey="value"
                        innerRadius={8}
                        startAngle={90}
                        endAngle={-270}
                        outerRadius={12}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        // paddingAngle={0}
                        // onMouseEnter={onPieEnter}
                    >
                        {data.map((entry, index) => {
                            return (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            );
                        })}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
    if (StraightAngleChart) {
        return (
            <ResponsiveContainer className="straight-angle-chart">
                <PieChart>
                    <Pie
                        data={data}
                        endAngle={0}
                        dataKey="value"
                        innerRadius={95}
                        startAngle={180}
                        outerRadius={105}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        // onMouseEnter={onPieEnter}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
    if (SmallChart) {
        return (
            <ResponsiveContainer
                width={80}
                height={80}
            >
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        innerRadius={32}
                        outerRadius={37}
                        paddingAngle={4}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        // onMouseEnter={onPieEnter}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }

    return (
        <ResponsiveContainer className="pie-chart">
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    startAngle={90}
                    endAngle={-270}
                    innerRadius={85}
                    outerRadius={95}
                    paddingAngle={3}
                    activeIndex={activeIndex}
                    onMouseEnter={onPieEnter}
                    activeShape={renderActiveShape}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
