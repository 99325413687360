import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateProspect } from "@app/actions/Admin/Prospects";
import { Select, AdvancedEditor, ContractorCard } from "@app/components";

const MoveAction = ({ state, handleChange, close = false }) => {
    const { statuses } = useSelector((state) => state.admin.prospects);

    const phases = React.useMemo(
        () =>
            statuses
                .map((el) => ({
                    value: el.id,
                    label: el.name,
                }))
                .filter((el) => el.value),
        [],
    );

    return (
        <div className="move">
            <Select
                required
                name="status_id"
                options={phases}
                disabled={close}
                onChange={handleChange}
                value={state.status_id || ""}
            >
                Please select the phase you want to move this prospect to.
            </Select>

            <div className="mt-3">
                <label className="mb-2">Note</label>
                <AdvancedEditor
                    name="note"
                    value={state.note}
                    onChange={(_, editor) => {
                        handleChange("note", editor.getData());
                    }}
                />
            </div>
        </div>
    );
};

export default React.memo(function MoveProspectModal({ prospect, handleClose }) {
    const dispatch = useDispatch();
    const { filters } = useSelector((state) => state.admin.prospects);

    const [state, setState] = useState({
        note: "",
        status_id: filters.selectedFilter,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            status_id: filters.selectedFilter,
        }));
    }, [filters.selectedFilter]);

    const handleStateChange = (name, value) => {
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateProspect(prospect.id, state));

        handleClose();
    };

    return (
        <Modal
            size="lg"
            show={true}
            onHide={() => handleClose(true)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="modal-title">Are you sure you want to continue with this action?</p>
                </Modal.Title>
            </Modal.Header>

            <form
                name="formSubmit"
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <div
                        className="col-md-8 d-flex"
                        style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                    >
                        <ContractorCard contractor={prospect} />
                    </div>

                    <div className="devider mb-3 pb-0" />

                    <MoveAction
                        state={state}
                        handleChange={handleStateChange}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        onClick={() => handleClose(true)}
                        className="btn-action btn-text btn-transparent"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className="btn-action btn-text btn-green mr-0"
                    >
                        Proceed
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});
