import api from "@app/services/api";
import properties from "@app/properties";
import request from "@app/services/request/main";
import { mainHostUrl, handleErrors, generateFormdata } from "@app/helpers";

/************************************************
 *                  ACTION TYPES
 ************************************************/
export const CLEAR_ACTION = "CLEAR_ACTION";

export const SET_COMPANY = "SET_COMPANY";

export const CURRENT_ROLE_SWITCHED = "CURRENT_ROLE_SWITCHED";

export const PUBLIC_ROUTE_REQUESTED = "PUBLIC_ROUTE_REQUESTED";

export const ESTABLISH_CONNECTION_BEGIN = "ESTABLISH_CONNECTION_BEGIN";
export const ESTABLISH_CONNECTION_SUCCESS = "ESTABLISH_CONNECTION_SUCCESS";
export const ESTABLISH_CONNECTION_FAILED = "ESTABLISH_CONNECTION_FAILED";

export const FETCH_USER_BEGIN = "FETCH_USER_BEGIN";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const GET_COMMON_DATA_SUCCESS = "GET_COMMON_DATA_SUCCESS";
export const GET_COMMON_DATA_FAILED = "GET_COMMON_DATA_FAILED";

export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const SET_PERMISSION = "SET_PERMISSION";

/************************************************
 *                    ACTIONS
 ************************************************/
export const establishConnectionBegin = () => ({
    type: ESTABLISH_CONNECTION_BEGIN,
});

export const establishConnectionSuccess = (response) => ({
    payload: response,
    type: ESTABLISH_CONNECTION_SUCCESS,
});

export const establishConnectionFailed = (error) => ({
    payload: error,
    type: ESTABLISH_CONNECTION_FAILED,
});

export const establishConnection = () => {
    return async (dispatch) => {
        dispatch(establishConnectionBegin());

        const { data } = await api.get("connect").catch((e) => dispatch(establishConnectionFailed(e)));

        dispatch(establishConnectionSuccess(data));
    };
};

// =============================================
// =============================================
// =============================================

export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const fetchUserBegin = () => ({
    type: FETCH_USER_BEGIN,
});

export const fetchUserSuccess = (data) => ({
    payload: data,
    type: FETCH_USER_SUCCESS,
});

export const fetchUserFailed = (error) => ({
    payload: error,
    type: FETCH_USER_FAILED,
});

export const fetchUserProfile = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchUserBegin());

            const response = await api.get("me");

            dispatch(fetchUserSuccess(response?.data?.data?.user || null));
        } catch (error) {
            dispatch(fetchUserFailed(error));
        }
    };
};

export const updateUserBegin = () => ({
    type: UPDATE_USER_BEGIN,
});

export const updateUserSuccess = (data) => ({
    payload: data,
    type: UPDATE_USER_SUCCESS,
});

export const updateUserFailed = (error) => ({
    payload: error,
    type: UPDATE_USER_FAILED,
});

export const updateUserProfile = (body) => {
    return async (dispatch) => {
        try {
            dispatch(updateUserBegin());
            const formData = generateFormdata(body);
            const response = await api.post("me", formData);
            dispatch(updateUserSuccess(response.data));
            dispatch(fetchUserProfile());
        } catch (error) {
            dispatch(updateUserFailed(error));
        }
    };
};

export const logOutBegin = () => ({
    type: LOGOUT_BEGIN,
});

export const logOutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logOutFailed = (error) => ({
    payload: error,
    type: LOGOUT_FAILED,
});

export const logOut = () => {
    return async (dispatch) => {
        try {
            dispatch(logOutBegin());
            await request.post("logout").catch(handleErrors);
            dispatch(logOutSuccess());
        } catch (error) {
            dispatch(logOutFailed(error.message));
        }
    };
};

export const leaveImpersonate = () => {
    return async (dispatch) => {
        try {
            await request.get("admin/impersonate/leave");
            dispatch(logOutSuccess());
            window.location.replace(`${properties.MAIN_HOST}admin`);
        } catch (error) {
            dispatch(logOutFailed(error));
            dispatch(logOutSuccess());
            window.location.replace(`${properties.MAIN_HOST}admin`);
        }
    };
};

export const getCommonDataSuccess = (data) => ({
    payload: data,
    type: GET_COMMON_DATA_SUCCESS,
});

export const getCommonDataFailed = (error) => ({
    payload: error,
    type: GET_COMMON_DATA_FAILED,
});

export const getCommonData = () => {
    return async (dispatch) => {
        try {
            const response = await api.get("common-data");
            dispatch(getCommonDataSuccess(response.data));
        } catch (error) {
            dispatch(getCommonDataFailed(error));
        }
    };
};

export const setCompany = (company) => ({
    payload: company,
    type: SET_COMPANY,
});

export const publicRouteRequested = () => ({
    payload: null,
    type: PUBLIC_ROUTE_REQUESTED,
});

export const currentRoleSwitched = (newRole) => ({
    payload: newRole,
    type: CURRENT_ROLE_SWITCHED,
});

export const requestPublicRouteAccess = () => {
    return async (dispatch) => {
        try {
            // Call this endpoint to let the server set the CSRF-token cookie
            await api.get(mainHostUrl("sanctum/csrf-cookie"));

            dispatch(publicRouteRequested());
        } catch (error) {}
    };
};

export const hasPermission = (authorized) => ({
    payload: authorized,
    type: SET_PERMISSION,
});
