import React from "react";

export default function Error() {
    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content-inner generic-table mt-4 d-flex justify-content-center">
                <div className="text-center">
                    <h1 className="mb-3">Whoops!</h1>

                    <p className="mb-4">Something went wrong. We're working on it.</p>

                    <a
                        href="/"
                        className="btn btn-success"
                    >
                        Go Home
                    </a>
                </div>
            </div>
        </div>
    );
}
