import React from "react";

export default function PerksCard({ href, image, heading, subtext, subHeader }) {
    return (
        <div className="community-panel">
            <div>
                <div className="community-panel-image">
                    <img
                        alt=""
                        src={image}
                        style={{ width: "60px" }}
                    />
                </div>
                <div className="community-panel-header mt-2">{heading}</div>
                <div className="community-panel-subheader mt-1">{subHeader}</div>
                <div
                    style={{ height: "120px" }}
                    className="community-panel-subtext mt-3"
                >
                    {subtext}
                </div>
            </div>
            <div>
                <a
                    href={href}
                    target="_blank"
                    className="link"
                    rel="noreferrer"
                    style={{ padding: "0", paddingTop: "30px" }}
                >
                    Learn More
                </a>
            </div>
        </div>
    );
}
