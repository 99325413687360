import moment from "moment";
import React, { useMemo } from "react";

import "./index.scss";

const validateDate = (date) => (moment(date).isValid() ? moment(date) : null);

const DateTime = ({ interview }) => {
    const startDate = useMemo(() => validateDate(interview?.start_at), [interview?.start_at]);
    const endDate = useMemo(() => validateDate(interview?.end_at), [interview?.end_at]);

    const isSameDay = useMemo(() => startDate?.isSame(endDate, "day") || false, [startDate, endDate]);

    if (!startDate || !endDate) {
        return <p className="text-center">-</p>;
    }

    return (
        <div className="interview-date d-flex">
            <div className="start-date">
                <p className="local-date">{startDate?.format("ll")}</p>

                <p className="dot">&nbsp;&nbsp; &bull; &nbsp;&nbsp;</p>

                <p className="local-timezone time">{startDate?.format("h:mmA")}</p>
            </div>

            <p className="time">&nbsp;-&nbsp;</p>

            <div className="end-date">
                {!isSameDay && (
                    <>
                        <p className="local-date">{endDate?.format("ll")}</p>
                        <p className="dot">&nbsp;&nbsp; &bull; &nbsp;&nbsp;</p>
                    </>
                )}

                <p className="local-timezone time">{endDate?.format("h:mmA")}</p>
            </div>
        </div>
    );
};

export default DateTime;
