import api from "@app/services/api";
import QueryBuilder from "@app/services/query";

const refreshPositionsState = () => {
    return async (dispatch) => {
        dispatch(getPositionsStatuses());

        dispatch(getPositions());
    };
};

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//

export const SET_ADMIN_POSITIONS_PAGINATION = "SET_ADMIN_POSITIONS_PAGINATION";
export const setPagination = (payload) => ({
    payload,
    type: SET_ADMIN_POSITIONS_PAGINATION,
});

export const SET_ADMIN_POSITIONS_FILTERS = "SET_ADMIN_POSITIONS_FILTERS";
export const setFilters = (key, value) => {
    return {
        key,
        value,
        type: SET_ADMIN_POSITIONS_FILTERS,
    };
};

export const SET_ADMIN_POSITIONS_SORTING = "SET_ADMIN_POSITIONS_SORTING";
export const setSorting = (column, direction) => ({
    column,
    direction,
    type: SET_ADMIN_POSITIONS_SORTING,
});

export const CLEAR_ADMIN_POSITIONS_ACTION = "CLEAR_ADMIN_POSITIONS_ACTION";
export const clearAction = () => ({
    type: CLEAR_ADMIN_POSITIONS_ACTION,
});

export const CLEAR_ADMIN_POSITIONS_STATE = "CLEAR_ADMIN_POSITIONS_STATE";
export const clearState = () => ({
    type: CLEAR_ADMIN_POSITIONS_STATE,
});
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const GET_ADMIN_POSITIONS_STATUSES_BEGIN = "GET_ADMIN_POSITIONS_STATUSES_BEGIN";
export const getPositionsStatusesBegin = () => ({
    type: GET_ADMIN_POSITIONS_STATUSES_BEGIN,
});

export const GET_ADMIN_POSITIONS_STATUSES = "GET_ADMIN_POSITIONS_STATUSES";
export const getPositionsStatuses = () => {
    return async (dispatch, getState) => {
        try {
            const { filters } = getState()?.admin?.positions;

            dispatch(getPositionsStatusesBegin());

            const params = QueryBuilder.for("positions")
                .when(filters?.search, (q) => q.where("admin_searchable", filters?.search)) // Filter by position name or company name if search keyword is found
                .when(filters?.companyId, (q) => q.where("company_id", filters?.companyId)) // Allow filter position by client
                .when(filters?.companyId === null, (q) => q.whereNotNull("company_id")) // Fetch all positions
                .get();

            const response = await api.get(`admin/statuses/positions${params}`);

            dispatch(getPositionsStatusesSuccess(response.data.data));
        } catch (error) {
            dispatch(getPositionsStatusesFailed(error));
        }
    };
};

export const GET_ADMIN_POSITIONS_STATUSES_SUCCESS = "GET_ADMIN_POSITIONS_STATUSES_SUCCESS";
export const getPositionsStatusesSuccess = (data) => ({
    payload: data,
    type: GET_ADMIN_POSITIONS_STATUSES_SUCCESS,
});

export const GET_ADMIN_POSITIONS_STATUSES_FAILED = "GET_ADMIN_POSITIONS_STATUSES_FAILED";
export const getPositionsStatusesFailed = (error) => ({
    payload: error,
    type: GET_ADMIN_POSITIONS_STATUSES_FAILED,
});
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const GET_ADMIN_POSITIONS_BEGIN = "GET_ADMIN_POSITIONS_BEGIN";
export const getPositionsBegin = () => ({
    type: GET_ADMIN_POSITIONS_BEGIN,
});

export const GET_ADMIN_POSITIONS = "GET_ADMIN_POSITIONS";
export const getPositions = () => {
    return async (dispatch, getState) => {
        const { sort, filters, pagination } = getState()?.admin?.positions;

        try {
            dispatch(getPositionsBegin());

            const params = QueryBuilder.for("positions")
                .includes([
                    "contractsCount",
                    "activeMatchesCount",
                    "status",
                    "company",
                    "company.users",
                    "internalOwner",
                ])
                .transforms("position_details")
                .select({
                    statuses: ["id", "name"],
                    companies: ["id", "name"],
                    positions: [
                        "id",
                        "type",
                        "published",
                        "commitment_id",
                        "number_openings",
                        "company_id",
                        "internal_owner_id",
                        "status_id",
                        "name",
                        "location",
                        "due_date",
                    ],
                })
                .where("admin_searchable", filters.search)
                .where("status_id", filters?.selectedFilter)
                .when(filters?.companyId, (q) => q.where("company_id", filters?.companyId)) // Allow filter position by client
                .when(filters?.companyId === null, (q) => q.whereNotNull("company_id")) // Fetch all positions
                .limit(pagination?.pageSize)
                .page(pagination?.page)
                .sort(`${sort?.direction}${sort?.column}`)
                .get();

            const response = await api.get(`admin/positions${params}`);

            dispatch(getPositionsSuccess(response.data));
        } catch (error) {
            dispatch(getPositionsFailed(error));
        }
    };
};

export const GET_ADMIN_POSITIONS_SUCCESS = "GET_ADMIN_POSITIONS_SUCCESS";
export const getPositionsSuccess = (data) => ({
    payload: data,
    type: GET_ADMIN_POSITIONS_SUCCESS,
});

export const GET_ADMIN_POSITIONS_FAILED = "GET_ADMIN_POSITIONS_FAILED";
export const getPositionsFailed = (error) => ({
    payload: error,
    type: GET_ADMIN_POSITIONS_FAILED,
});
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const UPDATE_ADMIN_POSITION_BEGIN = "UPDATE_ADMIN_POSITION_BEGIN";
export const updatePositionBegin = () => ({
    type: UPDATE_ADMIN_POSITION_BEGIN,
});

export const UPDATE_ADMIN_POSITION_SUCCESS = "UPDATE_ADMIN_POSITION_SUCCESS";
export const updatePositionSuccess = (data) => ({
    payload: data,
    type: UPDATE_ADMIN_POSITION_SUCCESS,
});

export const UPDATE_ADMIN_POSITION_FAILED = "UPDATE_ADMIN_POSITION_FAILED";
export const updatePositionFailed = (error) => ({
    payload: error,
    type: UPDATE_ADMIN_POSITION_FAILED,
});

export const UPDATE_ADMIN_POSITION = "UPDATE_ADMIN_POSITION";
export const updatePosition = (positionId, data) => {
    return async (dispatch) => {
        try {
            dispatch(updatePositionBegin());

            const response = await api.patch(`admin/positions/${positionId}`, data);

            dispatch(updatePositionSuccess(response.data));

            dispatch(refreshPositionsState());
        } catch (error) {
            dispatch(updatePositionFailed(error));
        }
    };
};
//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const DELETE_ADMIN_POSITIONS_BEGIN = "DELETE_ADMIN_POSITIONS_BEGIN";
export const deletePositionBegin = () => ({
    type: DELETE_ADMIN_POSITIONS_BEGIN,
});

export const DELETE_ADMIN_POSITIONS = "DELETE_ADMIN_POSITIONS";
export const deletePosition = (positionId) => {
    return async (dispatch) => {
        try {
            dispatch(deletePositionBegin());

            const response = await api.delete(`admin/positions/${positionId}`);

            dispatch(deletePositionSuccess(response.data));

            dispatch(refreshPositionsState());
        } catch (error) {
            dispatch(deletePositionFailed(error));
        }
    };
};

export const DELETE_ADMIN_POSITIONS_SUCCESS = "DELETE_ADMIN_POSITIONS_SUCCESS";
export const deletePositionSuccess = (data) => ({
    payload: data,
    type: DELETE_ADMIN_POSITIONS_SUCCESS,
});

export const DELETE_ADMIN_POSITIONS_FAILED = "DELETE_ADMIN_POSITIONS_FAILED";
export const deletePositionFailed = (error) => ({
    payload: error,
    type: DELETE_ADMIN_POSITIONS_FAILED,
});
