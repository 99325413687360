import React from "react";

import { getGPSCoordinates } from "@app/helpers";
import GeoLocationService from "@app/services/GeoLocationService";

export const useGeoLocation = () => {
    const getCurrentCountryByGPS = React.useCallback(() => {
        return getGPSCoordinates().then(({ coords }) =>
            GeoLocationService.getCountryFromLatLong(coords.latitude, coords.longitude),
        );
    }, []);

    return {
        getCurrentCountryByGPS,
    };
};
