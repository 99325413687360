import { Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";

import { mainHostUrl } from "@app/helpers";
import DealIcon from "@app/styles/assets/svg/icons/deal.svg";
import ClockIcon from "@app/styles/assets/svg/icons/clock.svg";
import GlobeIcon from "@app/styles/assets/svg/icons/globe-location.svg";
import CalendarIcon from "@app/styles/assets/svg/icons/calendar-rounded.svg";

import "./index.scss";

export default function PositionCard({ position, handleClick, hideActions = false }) {
    const [openTooltip, setOpenTooltip] = useState(false);

    const currentStatusDescription = useMemo(() => {
        return position?.current_status_name === "Client Interviewing"
            ? "Client interviewing applicants."
            : "Matcher reviewing applications.";
    }, [position?.current_status_name]);

    const skills = useMemo(() => {
        const positionSkills = position?.required_skills || [];

        positionSkills?.sort((a, b) => b.is_matched - a.is_matched);

        return positionSkills;
    }, [position?.required_skills]);

    const resetTooltip = (delay = 250) => setTimeout(() => setOpenTooltip(false), delay);

    const handleCopy = async (e) => {
        e.preventDefault();

        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(e?.target?.dataset?.link || "");

            setOpenTooltip(true);

            resetTooltip(2000);
        } catch (err) {}
    };

    const handleRaiseInterest = (e) => {
        e.preventDefault();

        e.stopPropagation();

        handleClick?.(position);
    };

    if (!position) return null;

    return (
        <a
            className="position-card"
            href={mainHostUrl(`positions/${position?.slug}`)}
        >
            <div className="description">
                <div className="header">
                    <h4 className="title">{position?.name}</h4>

                    {position?.publish_date && (
                        <p className="date">
                            <img
                                alt=""
                                className="icon"
                                src={CalendarIcon}
                            />

                            {position?.publish_date}
                        </p>
                    )}
                </div>

                <div className="body">{position?.excerpt}</div>

                <div className="skills">
                    <label>Required Skills</label>

                    <ul>
                        {skills?.map((skill) => (
                            <li
                                key={skill.id}
                                className={skill?.is_matched ? "active" : ""}
                            >
                                {skill.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="details">
                <div className={`status ${position?.current_status_name === "Hiring Now" ? "hiring" : ""}`}>
                    <p className="name">{position?.current_status_name}</p>
                    <small className="desc">{currentStatusDescription}</small>
                </div>

                <div className="terms">
                    <div className="term">
                        <img
                            alt=""
                            src={ClockIcon}
                            className="icon"
                        />
                        <span>{position?.commitment}</span>
                    </div>
                    <div className="term">
                        <img
                            alt=""
                            src={DealIcon}
                            className="icon"
                        />
                        <span>{position?.project_length}</span>
                    </div>
                    <div className="term">
                        <img
                            alt=""
                            src={GlobeIcon}
                            className="icon"
                        />
                        <span>{position?.location || "Worldwide"}</span>
                    </div>
                </div>

                {!hideActions && (
                    <div
                        className="actions"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <button
                            onClick={handleRaiseInterest}
                            disabled={!position?.can_raise_interest}
                            className={`btn-action btn-text btn-green mr-0 ${position?.can_raise_interest ? "" : "disabled"}`}
                        >
                            Raise Interest
                        </button>

                        <Tooltip
                            arrow
                            placement="top"
                            onClose={() => resetTooltip()}
                            title={openTooltip ? "Link copied" : "Click to copy your unique link"}
                        >
                            <p>
                                or{" "}
                                <span
                                    onClick={handleCopy}
                                    className="referral-link"
                                    data-link={position?.referral_link}
                                >
                                    Invite a friend
                                </span>
                            </p>
                        </Tooltip>
                    </div>
                )}
            </div>
        </a>
    );
}
