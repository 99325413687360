// eslint-disable-next-line unused-imports/no-unused-vars
export const generate = (config = {}) => {
    return [
        {
            target: "/",
            key: "overview",
            display: "Dashboard",
            icon: "icon-overview",
        },
        {
            key: "positions",
            display: "Positions",
            target: "/positions",
            permissionKey: "jobs",
            icon: "icon-positions",
        },
        {
            key: "candidates",
            display: "Candidates",
            target: "/candidates",
            permissionKey: "jobs",
            icon: "icon-open-positions",
        },
        {
            key: "contracts",
            display: "Contracts",
            target: "/contracts",
            icon: "icon-contracts",
            permissionKey: "contracts",
        },
        {
            key: "timelogs",
            display: "Timelogs",
            target: "/timelogs",
            icon: "icon-timelogs",
            permissionKey: "time-logs",
        },
        {
            key: "time_off",
            display: "Time Off",
            icon: "icon-timeoff",
            target: "/time-offs",
            permissionKey: "time-offs",
        },
        {
            key: "invoices",
            display: "Invoices",
            target: "/invoices",
            icon: "icon-invoices",
            permissionKey: "invoices",
        },
        {
            key: "documents",
            display: "Documents",
            target: "/documents",
            icon: "icon-documents",
            permissionKey: "company-documents",
        },
        // {
        //     key: "referrals",
        //     display: "Referrals",
        //     target: "/referrals",
        //     icon: "icon-referrals",
        //     permissionKey: "referrals",
        // },
    ]?.filter(Boolean);
};
