import api from "@app/services/api";

/***************
 * ACTION TYPES
 ***************/
export const GET_INTERVIEWS_BEGIN = "GET_INTERVIEWS_BEGIN";
export const GET_INTERVIEWS_SUCCESS = "GET_INTERVIEWS_SUCCESS";
export const GET_INTERVIEWS_FAILED = "GET_INTERVIEWS_FAILED";

export const GET_INTERVIEW_BEGIN = "GET_INTERVIEW_BEGIN";
export const GET_INTERVIEW_SUCCESS = "GET_INTERVIEW_SUCCESS";
export const GET_INTERVIEW_FAILED = "GET_INTERVIEW_FAILED";

export const GET_INTERVIEWER_BEGIN = "GET_INTERVIEWER_BEGIN";
export const GET_INTERVIEWER_SUCCESS = "GET_INTERVIEWER_SUCCESS";
export const GET_INTERVIEWER_FAILED = "GET_INTERVIEWER_FAILED";

export const GET_STATUSES_BEGIN = "GET_STATUSES_BEGIN";
export const GET_STATUSES_SUCCESS = "GET_STATUSES_SUCCESS";
export const GET_STATUSES_FAILED = "GET_STATUSES_FAILED";

export const GET_NOTE_TYPES_BEGIN = "GET_NOTE_TYPES_BEGIN";
export const GET_NOTE_TYPES_SUCCESS = "GET_NOTE_TYPES_SUCCESS";
export const GET_NOTE_TYPES_FAILED = "GET_NOTE_TYPES_FAILED";

export const GET_RATING_BEGIN = "GET_RATING_BEGIN";
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
export const GET_RATING_FAILED = "GET_RATING_FAILED";

export const SUBMIT_INTERVIEW_FEEDBACK_BEGIN = "SUBMIT_INTERVIEW_FEEDBACK_BEGIN";
export const SUBMIT_INTERVIEW_FEEDBACK_SUCCESS = "SUBMIT_INTERVIEW_FEEDBACK_SUCCESS";
export const SUBMIT_INTERVIEW_FEEDBACK_FAILED = "SUBMIT_INTERVIEW_FEEDBACK_FAILED";

export const CALENDAR_CONNECT_BEGIN = "CALENDAR_CONNECT_BEGIN";
export const CALENDAR_CONNECT_SUCCESS = "CALENDAR_CONNECT_SUCCESS";
export const CALENDAR_CONNECT_FAILED = "CALENDAR_CONNECT_FAILED";

export const UPDATE_FILTERS = "UPDATE_FILTERS";

export const UPDATE_SORTING = "UPDATE_SORTING";

export const UPDATE_PAGINATION = "UPDATE_PAGINATION";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const NOTE_TYPE_SELECTED = "NOTE_TYPE_SELECTED";

export const RESET_INTERVIEW_DETAILS = "RESET_INTERVIEW_DETAILS";

export const SET_SELECTED_INTERVIEWER_CALENDAR = "SET_SELECTED_INTERVIEWER_CALENDAR";

/***************
 * ACTION TYPES
 ***************/
export const getInterviewsBegin = () => ({
    type: GET_INTERVIEWS_BEGIN,
});

export const getInterviewsSuccess = (data) => ({
    payload: data,
    type: GET_INTERVIEWS_SUCCESS,
});

export const getInterviewsFailed = (error) => ({
    payload: error,
    type: GET_INTERVIEWS_FAILED,
});

export const getInterviewBegin = () => ({
    type: GET_INTERVIEW_BEGIN,
});

export const getInterviewSuccess = (data) => ({
    payload: data,
    type: GET_INTERVIEW_SUCCESS,
});

export const getInterviewFailed = (error) => ({
    payload: error,
    type: GET_INTERVIEW_FAILED,
});

export const getInterviewerBegin = () => ({
    type: GET_INTERVIEWER_BEGIN,
});

export const getInterviewerSuccess = (data) => ({
    payload: data,
    type: GET_INTERVIEWER_SUCCESS,
});

export const getInterviewerFailed = (error) => ({
    payload: error,
    type: GET_INTERVIEWER_FAILED,
});

export const getStatusesBegin = () => ({
    type: GET_STATUSES_BEGIN,
});

export const getStatusesSuccess = (data) => ({
    payload: data,
    type: GET_STATUSES_SUCCESS,
});

export const getStatusesFailed = (error) => ({
    payload: error,
    type: GET_STATUSES_FAILED,
});

export const getNoteTypesBegin = () => ({
    type: GET_NOTE_TYPES_BEGIN,
});

export const getNoteTypesSuccess = (data) => ({
    payload: data,
    type: GET_NOTE_TYPES_SUCCESS,
});

export const getNoteTypesFailed = (error) => ({
    payload: error,
    type: GET_NOTE_TYPES_FAILED,
});

export const getRatingBegin = () => ({
    type: GET_RATING_BEGIN,
});

export const getRatingSuccess = (data) => ({
    payload: data,
    type: GET_RATING_SUCCESS,
});

export const getRatingFailed = (error) => ({
    payload: error,
    type: GET_RATING_FAILED,
});

export const submitInterviewBegin = () => ({
    type: SUBMIT_INTERVIEW_FEEDBACK_BEGIN,
});

export const submitInterviewSuccess = (data) => ({
    payload: data,
    type: SUBMIT_INTERVIEW_FEEDBACK_SUCCESS,
});

export const submitInterviewFailed = (error) => ({
    payload: error,
    type: SUBMIT_INTERVIEW_FEEDBACK_FAILED,
});

export const calendarConnectBegin = () => ({
    type: CALENDAR_CONNECT_BEGIN,
});

export const calendarConnectSuccess = (data) => ({
    payload: data,
    type: CALENDAR_CONNECT_SUCCESS,
});

export const calendarConnectFailed = (error) => ({
    payload: error,
    type: CALENDAR_CONNECT_FAILED,
});

export const setFilters = (data) => ({
    payload: data,
    type: UPDATE_FILTERS,
});

export const setPagination = (data) => ({
    payload: data,
    type: UPDATE_PAGINATION,
});

export const setSorting = (data) => ({
    payload: data,
    type: UPDATE_SORTING,
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});

export const setSelectedNote = (noteTypeId) => ({
    payload: noteTypeId,
    type: NOTE_TYPE_SELECTED,
});

export const resetInterviewDetails = () => ({
    type: RESET_INTERVIEW_DETAILS,
});

export const setSelectedCalendar = (calendarId) => ({
    payload: calendarId,
    type: SET_SELECTED_INTERVIEWER_CALENDAR,
});

/**********
 * ACTIONS
 **********/
export const getInterviews = (filters) => {
    return async (dispatch) => {
        try {
            dispatch(getInterviewsBegin());

            const params = new URLSearchParams({
                ...filters,
                transforms: "interview_details",
            });

            const response = await api.get(`talent/interviews?${params?.toString()}`);

            dispatch(getInterviewsSuccess(response?.data));
        } catch (error) {
            dispatch(getInterviewsFailed(error));
        }
    };
};

export const getInterviewDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getInterviewBegin());

            const response = await api.get(`talent/interviews/${id}`);

            dispatch(getInterviewSuccess(response?.data?.data));
        } catch (error) {
            dispatch(getInterviewFailed(error));

            throw error;
        }
    };
};

export const getNoteTypes = (filters = {}) => {
    return async (dispatch) => {
        try {
            dispatch(getNoteTypesBegin());

            const params = new URLSearchParams(filters);

            const response = await api.get(`talent/notes/types?${params?.toString()}`);

            dispatch(getNoteTypesSuccess(response?.data?.data?.notes));
        } catch (error) {
            dispatch(getNoteTypesFailed(error));
        }
    };
};

export const getRatingPerNote = (applicantId, noteTypeId) => {
    return async (dispatch) => {
        try {
            dispatch(getRatingBegin());

            dispatch(setSelectedNote(noteTypeId));

            const response = await api.get(`talent/notes/details?applicant_id=${applicantId}&category=${noteTypeId}`);

            dispatch(getRatingSuccess(response?.data?.data));
        } catch (error) {
            dispatch(getRatingFailed(error));
        }
    };
};

export const connectToCalendar = (interviewerId, data) => {
    return async (dispatch) => {
        try {
            dispatch(calendarConnectBegin());

            const targetUrl = interviewerId ? `talent/calendar/connect/${interviewerId}` : "talent/calendar/connect";

            const response = await api.post(targetUrl, data);

            dispatch(calendarConnectSuccess(response?.data?.data));
        } catch (error) {
            dispatch(calendarConnectFailed(error));
        }
    };
};

export const submitInterviewFeedback = (data) => {
    return async (dispatch) => {
        try {
            dispatch(submitInterviewBegin());

            const response = await api.post(`talent/notes`, data);

            dispatch(submitInterviewSuccess(response?.data?.data));
        } catch (error) {
            dispatch(submitInterviewFailed(error));
        }
    };
};

export const fetchInterviewer = () => {
    return async (dispatch) => {
        try {
            dispatch(getInterviewerBegin());

            const response = await api.get(`talent/interviewers/me`);

            dispatch(getInterviewerSuccess(response?.data?.data));
        } catch (error) {
            dispatch(getInterviewerFailed(error));
        }
    };
};

export const fetchStatuses = (filters = {}) => {
    return async (dispatch) => {
        try {
            dispatch(getStatusesBegin());

            const params = new URLSearchParams(filters);

            const response = await api.get(`talent/interviews/statuses?${params?.toString()}`);

            dispatch(getStatusesSuccess(response?.data?.data));
        } catch (error) {
            dispatch(getStatusesFailed(error));
        }
    };
};
