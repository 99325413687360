import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";

import { Loading, NotFound } from "@app/components";
import { FEAT_NEW_TALENT_DASHBOARD_ENABLED } from "@app/config/features";
import LearningAndCertification from "@app/pages/talent/LearningAndCertification";
import {
    AdminMiddleware,
    PublicMiddleware,
    TalentMiddleware,
    ClientMiddleware,
    LayoutMiddleware,
    PartnerMiddleware,
    FeatureMiddleware,
    RedirectUrlMiddleware,
    IframeLayoutMiddleware,
    ClientProfileMiddleware,
    AuthenticationMiddleware,
    PotentialCustomerMiddleware,
} from "@app/middlewares";
import {
    Lobby,
    Search,
    TimeOff,
    PostJob,
    Matches,
    Invoices,
    Timelogs,
    Overview,
    Documents,
    Prospects,
    Dashboard,
    Contracts,
    Positions,
    Resources,
    Candidates,
    Interviews,
    SubmitForm,
    InviteUsers,
    AddEquipment,
    InviteFriend,
    PartnerTalents,
    AdminPositions,
    TalentPositions,
    TalentReferrals,
    HealthInsurance,
    CompanySettings,
    AccountSettings,
    PositionDetails,
    PartnerInvoices,
    PartnerBenefits,
    ContractsCreate,
    AcceptInvitation,
    InterviewDetails,
    InterviewerGuide,
    PartnerPositions,
    IntroduceCompany,
    PartnerCustomers,
    PerksAndBenefits,
    ContractorTimeOff,
    ContractsDetailes,
    PartnersDashboard,
    PartnerGetInTouch,
    ContractorTimelogs,
    SuggestedCandidates,
    AdminShowMatchDetails,
    CommunityProgramsIndex,
    PerksAndBenefitsDetail,
    PartnerProgramMessaging,
    CommunityProgramsDetail,
    MovePositionPhaseActions,
    PartnerProgramTrainingGuide,
    CommunityProgramsDetailForm,
} from "@app/pages";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    // useEffect(() => {
    //   TagManager.initialize({ gtmId: properties.GTM_ID });
    // }, []);

    return (
        <Suspense
            fallback={
                <Loading
                    fullScreen
                    withoutLabel
                />
            }
        >
            <SentryRoutes>
                {/**********************
                 *    Public Routes    *
                 ***********************/}
                <Route element={<PublicMiddleware />}>
                    <Route
                        path="accept-invite"
                        element={<AcceptInvitation />}
                    />
                </Route>

                {/**********************
                 *    Protected Routes    *
                 ***********************/}
                <Route element={<AuthenticationMiddleware />}>
                    <Route element={<RedirectUrlMiddleware />}>
                        {/* Define Potential Customer Related Routes */}
                        <Route element={<PotentialCustomerMiddleware />}>
                            <Route element={<LayoutMiddleware />}>
                                <Route
                                    path="lobby"
                                    element={<Lobby />}
                                />
                            </Route>
                        </Route>

                        {/* Define Talent Related Routes */}
                        <Route
                            path="talent"
                            element={<TalentMiddleware />}
                        >
                            <Route element={<LayoutMiddleware />}>
                                <Route element={<FeatureMiddleware flag={FEAT_NEW_TALENT_DASHBOARD_ENABLED} />}>
                                    <Route
                                        path="overview"
                                        element={<Overview />}
                                    />

                                    <Route
                                        path="positions"
                                        element={<TalentPositions />}
                                    />

                                    <Route
                                        path="community/perks/"
                                        element={<PerksAndBenefits />}
                                    />
                                    <Route
                                        path="community/perks/:perk"
                                        element={<PerksAndBenefitsDetail />}
                                    />
                                    <Route
                                        path="community/learning/"
                                        element={<LearningAndCertification />}
                                    />
                                    <Route
                                        path="community/programs"
                                        element={<CommunityProgramsIndex />}
                                    />
                                    <Route
                                        path="community/programs/:program"
                                        element={<CommunityProgramsDetail />}
                                    />
                                    <Route
                                        path="community/programs/:program/form"
                                        element={<CommunityProgramsDetailForm />}
                                    />
                                </Route>

                                <Route
                                    path="health-insurance"
                                    element={<HealthInsurance />}
                                />
                                <Route
                                    path="referrals"
                                    element={<TalentReferrals />}
                                />
                                <Route
                                    path="interviews"
                                    element={<Interviews />}
                                />
                                <Route
                                    path="interviews/guide"
                                    element={<InterviewerGuide />}
                                />
                                <Route
                                    path="interviews/:id"
                                    element={<InterviewDetails />}
                                />
                                <Route
                                    path="not-found"
                                    element={<NotFound />}
                                />
                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                            </Route>
                        </Route>

                        {/* Define Client Related Routes */}
                        <Route element={<ClientMiddleware />}>
                            <Route element={<ClientProfileMiddleware />}>
                                <Route element={<LayoutMiddleware />}>
                                    <Route
                                        path="/timelogs/:userId"
                                        element={<ContractorTimelogs />}
                                    />
                                    <Route
                                        path="/timelogs"
                                        element={<Timelogs />}
                                    />
                                    <Route
                                        path="/time-offs/:userId"
                                        element={<ContractorTimeOff />}
                                    />
                                    <Route
                                        exact
                                        path="/time-offs"
                                        element={<TimeOff />}
                                    />
                                    <Route
                                        path="/invoices"
                                        element={<Invoices />}
                                    />
                                    <Route
                                        path="/candidates"
                                        element={<Candidates />}
                                    />
                                    <Route
                                        element={<PostJob />}
                                        path="/positions/edit/:id"
                                    />
                                    <Route
                                        element={<PostJob />}
                                        path="/positions/create"
                                    />
                                    <Route
                                        path="/positions/:id"
                                        element={<PositionDetails />}
                                    />
                                    <Route
                                        exact
                                        path="/positions"
                                        element={<Positions />}
                                    />
                                    <Route
                                        path="/company-settings"
                                        element={<CompanySettings />}
                                    />
                                    <Route
                                        element={<SubmitForm />}
                                        path="/contracts/:id/feedback/:feedbackId"
                                    />
                                    <Route
                                        element={<SubmitForm />}
                                        path="/contracts/:id/feedback"
                                    />
                                    <Route
                                        element={<AddEquipment />}
                                        path="/contracts/:id/equipment/:equipmentId"
                                    />
                                    <Route
                                        element={<AddEquipment />}
                                        path="/contracts/:id/equipment"
                                    />
                                    <Route
                                        path="/contracts/:id"
                                        element={<ContractsDetailes />}
                                    />
                                    <Route
                                        exact
                                        path="/contracts"
                                        element={<Contracts />}
                                    />
                                    <Route
                                        element={<InviteUsers />}
                                        path="/invite-user/edit/:id"
                                    />
                                    <Route
                                        exact
                                        path="/invite-user"
                                        element={<InviteUsers />}
                                    />
                                    <Route
                                        path="/documents"
                                        element={<Documents />}
                                    />
                                    <Route
                                        path="/account-settings"
                                        element={<AccountSettings />}
                                    />
                                    <Route
                                        exact
                                        path="/"
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path="*"
                                        element={<NotFound />}
                                    />
                                </Route>
                            </Route>
                        </Route>

                        {/* Define Admin Related Routes */}
                        <Route
                            path="admin"
                            element={<AdminMiddleware />}
                        >
                            <Route element={<IframeLayoutMiddleware />}>
                                <Route
                                    path="suggested-candidates"
                                    element={<SuggestedCandidates />}
                                ></Route>
                                <Route
                                    path="position/:id/:operation"
                                    element={<MovePositionPhaseActions />}
                                ></Route>
                            </Route>

                            <Route element={<LayoutMiddleware />}>
                                <Route path="matches">
                                    <Route
                                        index
                                        element={<Matches />}
                                    />
                                    <Route
                                        path=":matchId"
                                        element={<AdminShowMatchDetails />}
                                    />
                                </Route>

                                <Route
                                    path="positions"
                                    element={<AdminPositions />}
                                ></Route>
                                <Route
                                    path="search"
                                    element={<Search />}
                                ></Route>
                                <Route
                                    exact
                                    path="contracts/create"
                                    element={<ContractsCreate />}
                                />
                                <Route
                                    exact
                                    path="contracts/:id"
                                    element={<ContractsCreate />}
                                />
                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                                <Route
                                    exact
                                    path="prospects"
                                    element={<Prospects />}
                                />
                            </Route>
                        </Route>

                        {/* Define Partner Related Routes */}
                        <Route
                            path="partner"
                            element={<PartnerMiddleware />}
                        >
                            <Route element={<LayoutMiddleware />}>
                                <Route
                                    path="customers"
                                    element={<PartnerCustomers />}
                                />
                                <Route
                                    path="introduce-company"
                                    element={<IntroduceCompany />}
                                />
                                <Route
                                    element={<InviteFriend />}
                                    path="talents/invite-friend"
                                />
                                <Route
                                    exact
                                    path="talents"
                                    element={<PartnerTalents />}
                                />
                                <Route
                                    path="invoices"
                                    element={<PartnerInvoices />}
                                />
                                <Route
                                    path="positions"
                                    element={<PartnerPositions />}
                                />
                                <Route
                                    path="resources/benefits"
                                    element={<PartnerBenefits />}
                                />
                                <Route
                                    path="resources/get-in-touch"
                                    element={<PartnerGetInTouch />}
                                />
                                <Route
                                    path="resources/adevavp"
                                    element={<PartnerProgramTrainingGuide />}
                                />
                                <Route
                                    path="resources/partnerpm"
                                    element={<PartnerProgramMessaging />}
                                />
                                <Route
                                    exact
                                    path="resources"
                                    element={<Resources />}
                                />
                                <Route
                                    exact
                                    path=""
                                    element={<PartnersDashboard />}
                                />
                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </SentryRoutes>
        </Suspense>
    );
}

export default App;
