import * as referralsHistory from "@app/actions/Talent/ReferralsHistory";

const initialState = {
    data: null,
    searchKeyword: "",
    referralsData: null,
    sort: {
        orderDirection: "desc",
        orderColumn: "created_at",
    },
    paginationReferrals: {
        page: 1,
        pageSize: 10,
        selectedFilter: "",
    },
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
};

export default function referralsReducer(state = initialState, action) {
    switch (action.type) {
        case referralsHistory.CLEAR_ACTION: {
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: false,
                },
            };
        }
        case referralsHistory.RESET_TABLE_ACTIONS: {
            return {
                ...initialState,
            };
        }
        case referralsHistory.SET_SEARCH_KEYWORD_REFERRALS: {
            return {
                ...state,
                searchKeyword: action.payload,
                paginationReferrals: {
                    ...state.paginationReferrals,
                    page: action.payload === "" ? 1 : "",
                },
            };
        }
        case referralsHistory.SET_PAGINATION: {
            return {
                ...state,
                paginationReferrals: {
                    ...state.paginationReferrals,
                    [action.key]: action.value,
                },
            };
        }
        case referralsHistory.SET_SORT: {
            return {
                ...state,
                sort: {
                    orderColumn: action.orderColumn,
                    orderDirection: action.orderDirection,
                },
            };
        }
        case referralsHistory.GET_REFERRALS_HISTORY_BEGIN: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        }
        case referralsHistory.GET_REFERRALS_HISTORY_FAILED: {
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        }
        case referralsHistory.GET_REFERRALS_HISTORY_SUCCESS: {
            return {
                ...state,
                filters: action.payload.filters,
                referralsData: { ...action.payload, data: action.payload.data },

                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
