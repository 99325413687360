import React from "react";

import { TalentWorkPreferences } from "@app/contexts";

export const useTalentWorkPreferences = () => {
    const context = React.useContext(TalentWorkPreferences);

    // if context is undefined this means it was used outside of its provider
    // you can throw an error telling that to your fellow developers
    if (!context) {
        throw new Error("useTalentWorkPreferences must be used under <TalentWorkPreferences />");
    }

    return context;
};
