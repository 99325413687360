export const generate = (config = {}) => {
    const isSalesPartner = config?.isSales || false;
    const isTalentPartner = config?.isTalent || false;

    if (!isTalentPartner && !isSalesPartner) return [];

    return [
        {
            key: "dashboard",
            target: "/partner",
            display: "Dashboard",
            icon: "icon-overview",
        },
        isTalentPartner && {
            key: "positions",
            display: "Openings",
            icon: "icon-positions",
            target: "/partner/positions",
        },
        isTalentPartner && {
            key: "talents",
            display: "Consultants",
            target: "/partner/talents",
            icon: "icon-introduced-talent",
        },
        isSalesPartner && {
            key: "customers",
            display: "Customers",
            icon: "icon-customer",
            target: "/partner/customers",
        },
        {
            key: "invoices",
            display: "Payouts",
            icon: "icon-invoices",
            target: "/partner/invoices",
        },
        isSalesPartner && {
            key: "resources",
            display: "Resources",
            icon: "icon-contracts",
            target: "/partner/resources",
        },
    ]?.filter(Boolean);
};
