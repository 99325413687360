export const SET_INVITE_MEMBER_ATTRIBUTE = "SET_INVITE_MEMBER_ATTRIBUTE";
export const setInviteMemberAttribute = (key, value) => {
    return {
        key,
        value,
        type: SET_INVITE_MEMBER_ATTRIBUTE,
    };
};

export const CLEAR_INVITE_MEMBER = "CLEAR_INVITE_MEMBER";
export const clearInviteMember = () => {
    return {
        type: CLEAR_INVITE_MEMBER,
    };
};

export const SEARCH_DATA = "SEARCH_DATA";
export const searchData = (keyword) => {
    return {
        keyword,
        type: SEARCH_DATA,
    };
};
