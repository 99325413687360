import React from "react";
import { Outlet } from "react-router-dom";

import "./index.scss";

const IframeLayoutMiddleware = () => {
    return (
        <div className="dashboard-container iframe-wrapper">
            <Outlet />
        </div>
    );
};

export default IframeLayoutMiddleware;
