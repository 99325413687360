import React from "react";

import "./index.scss";

function RadioInput({
    label,
    onChange,
    inputProps,
    inline = false,
    checked = false,
    readOnly = false,
    disabled = false,
    description = null,
    additionalStyle = {},
}) {
    return (
        <label
            style={additionalStyle}
            className={`adeva-radio-input ${checked ? "light-blue" : ""} ${disabled ? "disabled" : ""} ${
                inline ? "inline" : ""
            }`}
        >
            <div className="adeva-radio-input-wrapper">
                <div className="adeva-radio-input-button-wrapper">
                    <input
                        type="radio"
                        checked={checked}
                        readOnly={readOnly}
                        onChange={onChange}
                        disabled={disabled}
                        className="adeva-radio-input-button"
                        {...inputProps}
                    />
                </div>

                <div className="adeva-radio-input-text">
                    <p className="adeva-radio-input-title">{label}</p>
                    {description && <p className="adeva-radio-input-description">{description}</p>}
                </div>
            </div>
        </label>
    );
}

export default RadioInput;
