export const ReferralInvitationType = {
    EMAIL: "email",
    PROGRAM: "program",
};

export const ReferralInvitationReason = {
    APPLY: "apply",
    POSITION: "position",
};

export const RefereeType = {
    TALENT: "talent",
    CLIENT: "client",
};

export const ReferrerType = {
    TALENT: "talent",
    CLIENT: "client",
    PARTNER: "partner",
    AMBASSADOR: "ambassador",
};

export const ReferralStatus = {
    EARNING: "earning",
    INVITED: "invited",
    ACCEPTED: "accepted",
    DECLINED: "declined",
    SIGNED_UP: "signed_up",
};

export const CompensationType = {
    AMOUNT: "amount",
    PERCENTAGE: "percentage",
};
