import api from "@app/services/api";
import { generateFormdata, searchGetEndpoint } from "@app/helpers";

export const SET_SEARCH_KEYWORDS = "SET_SEARCH_KEYWORDS";
export const setSearchKeywords = (value) => ({
    payload: value,
    type: SET_SEARCH_KEYWORDS,
});

export const GET_SEARCH_RESULTS_BEGIN = "GET_SEARCH_RESULTS_BEGIN";
export const getSearchResultsBegin = () => ({
    type: GET_SEARCH_RESULTS_BEGIN,
});

export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const getSearchResultsSuccess = (data) => ({
    payload: data,
    type: GET_SEARCH_RESULTS_SUCCESS,
});

export const GET_SEARCH_RESULTS_FAILED = "GET_SEARCH_RESULTS_FAILED";
export const getSearchResultsFailed = (error) => ({
    payload: error,
    type: GET_SEARCH_RESULTS_FAILED,
});

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const getSearchResults = () => {
    return async (dispatch) => {
        try {
            const params = new URLSearchParams(window.location.search);

            const data = Object.fromEntries(params);

            dispatch(getSearchResultsBegin());

            const response = await api.get(searchGetEndpoint(data));

            dispatch(getSearchResultsSuccess(response));
        } catch (error) {
            dispatch(getSearchResultsFailed(error));
        }
    };
};

export const GET_SEARCH_CONFIG_BEGIN = "GET_SEARCH_CONFIG_BEGIN";
export const getSearchConfigBegin = (data) => ({
    payload: data,
    type: GET_SEARCH_CONFIG_BEGIN,
});

export const GET_SEARCH_CONFIG_SUCCESS = "GET_SEARCH_CONFIG_SUCCESS";
export const getSearchConfigSuccess = (data) => ({
    payload: data,
    type: GET_SEARCH_CONFIG_SUCCESS,
});

export const GET_SEARCH_CONFIG_FAILED = "GET_SEARCH_CONFIG_FAILED";
export const getSearchConfigFailed = (error) => ({
    payload: error,
    type: GET_SEARCH_CONFIG_FAILED,
});

export const GET_SEARCH_CONFIG = "GET_SEARCH_CONFIG";
export const getSearchConfig = () => {
    return async (dispatch) => {
        try {
            dispatch(getSearchConfigBegin());

            const response = await api.get("/admin/talent/search-config");

            dispatch(getSearchConfigSuccess(response));
        } catch (error) {
            dispatch(getSearchConfigFailed(error));
        }
    };
};

export const DELETE_APPLICANT_BEGIN = "DELETE_APPLICANT_BEGIN";
export const deleteApplicantBegin = () => ({
    type: DELETE_APPLICANT_BEGIN,
});

export const DELETE_APPLICANT_SUCCESS = "DELETE_APPLICANT_SUCCESS";
export const deleteApplicantSuccess = (data) => ({
    payload: data,
    type: DELETE_APPLICANT_SUCCESS,
});

export const DELETE_APPLICANT_FAILED = "DELETE_APPLICANT_FAILED";
export const deleteApplicantFailed = (error) => ({
    payload: error,
    type: DELETE_APPLICANT_FAILED,
});

export const DELETE_APPLICANT = "DELETE_APPLICANT";
export const deleteApplicant = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteApplicantBegin());
            const formData = generateFormdata({ id });
            const response = await api.post("admin/talent/deleteCandidate", formData);
            dispatch(deleteApplicantSuccess({ id: id, success: response.data.success }));
        } catch (error) {
            dispatch(deleteApplicantFailed(error));
        }
    };
};

export const CREATE_A_MATCH_BEGIN = "CREATE_A_MATCH_BEGIN";
export const createMatchBegin = () => ({
    type: CREATE_A_MATCH_BEGIN,
});

export const CREATE_A_MATCH_SUCCESS = "CREATE_A_MATCH_SUCCESS";
export const createMatchSuccess = (data) => ({
    payload: data,
    type: CREATE_A_MATCH_SUCCESS,
});

export const CREATE_A_MATCH_FAILED = "CREATE_A_MATCH_FAILED";
export const createMatchFailed = (error) => ({
    payload: error,
    type: CREATE_A_MATCH_FAILED,
});

export const CREATE_A_MATCH = "CREATE_A_MATCH";
export const createMatch = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch(createMatchBegin());

            const formData = generateFormdata({
                user_id: id,
                position_id: data.position,
                talent_rate_amount: data.rate,
                talent_rate_currency: data.currency,
                talent_rate_type: data.compensationType,
            });

            const response = await api.post("admin/talent/createMatch", formData);

            dispatch(createMatchSuccess(response.data));
        } catch (error) {
            dispatch(createMatchFailed(error));
        }
    };
};

export const CLEAR_ACTION_SEARCH = "CLEAR_ACTION_SEARCH";
export const clearAction = () => ({
    type: CLEAR_ACTION_SEARCH,
});

export const DECLINE_A_MATCH_FAILED = "DECLINE_A_MATCH_FAILED";
export const declineMatchFailed = (error) => ({
    payload: error,
    type: DECLINE_A_MATCH_FAILED,
});

export const DECLINE_A_MATCH_SUCCESS = "DECLINE_A_MATCH_SUCCESS";
export const declineMatchSuccess = (data) => ({
    payload: data,
    type: DECLINE_A_MATCH_SUCCESS,
});

export const DECLINE_SUGGESTED_MATCH = "DECLINE_SUGGESTED_MATCH";
export const declineSuggestedMatch = (userId, positionId) => {
    return async (dispatch) => {
        try {
            const formData = generateFormdata({
                user_id: userId,
                position_id: positionId,
            });
            const response = await api.post("admin/position-suggested-matches/decline", formData);
            dispatch(declineMatchSuccess(response.data));
        } catch (error) {
            dispatch(declineMatchFailed(error));
        }
    };
};
