import React from "react";

export default function Input({
    name,
    type,
    value,
    children,
    required,
    onChange,
    className,
    icon = null,
    labelClassName = "",
    ...props
}) {
    return (
        <div className={`form-group mb-3 ${className ? className : ""}`}>
            {children && (
                <label
                    htmlFor={name}
                    className={`col-form-label ${labelClassName}`}
                >
                    {children} {required && <span className="required">*</span>}
                </label>
            )}

            <div className="d-flex">
                {icon && <span className="input-icon-start">{icon}</span>}

                <input
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="form-control"
                    type={type ? type : "text"}
                    required={required ? true : false}
                    {...props}
                />
            </div>
        </div>
    );
}
