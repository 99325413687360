import React from "react";

import FallbackAvatar from "@app/components/FallbackAvatar";

import "./index.scss";

const ApplicantName = ({ interview }) => {
    return (
        <div className="d-flex align-items-center">
            <FallbackAvatar
                className="applicant-img"
                alt={interview?.applicant?.fullName}
                src={interview?.applicant?.custom_avatar_thumbnail}
            />

            <div>
                <p className="applicant-name">{interview?.applicant?.fullName}</p>
                <p className="applicant-country">{interview?.applicant?.contact?.country}</p>
            </div>
        </div>
    );
};

export default ApplicantName;
