import React from "react";

import FallbackAvatar from "@app/components/FallbackAvatar";

import "./index.scss";

function arePropsEqual(oldProps, newProps) {
    return (
        oldProps?.item?.email === newProps?.item?.email &&
        oldProps?.item?.fullName === newProps?.item?.fullName &&
        oldProps?.item?.full_name === newProps?.item?.full_name &&
        oldProps?.item?.custom_avatar_thumbnail === newProps?.item?.custom_avatar_thumbnail
    );
}

const MentionItem = React.memo(({ item }) => {
    return (
        <>
            <div className="ck-reset_all-excluded">
                <FallbackAvatar
                    size="sm"
                    src={item?.custom_avatar_thumbnail}
                    alt={item?.fullName || item?.full_name}
                    sx={{ width: 32, height: 32, marginRight: 1.75 }}
                />
            </div>

            <span className="text-content">
                <p>{item?.fullName || item?.full_name}</p>
                <small>{item?.email}</small>
            </span>
        </>
    );
}, arePropsEqual);

export default MentionItem;
