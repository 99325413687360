import AdminSidebar from "./Admin";
import ClientSidebar from "./Client";
import TalentSidebar from "./Talent";
import RoleSwitch from "./RoleSwitch";
import PartnerSidebar from "./Partner";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useMemo, useState } from "react";
import PotentialCustomer from "./PotentialCustomer";
import ResponsiveMenuButton from "./ResponsiveMenuButton";

import { AUTH } from "@app/enums";

import "./index.scss";

const Sidebar = (props) => {
    const { userProfile, currentRole } = useSelector((state) => state.authentication);
    const { pathname } = useLocation();
    const isAdmin = Boolean(pathname.includes("/admin"));

    const [responsiveMenuCollapsed, setResponsiveMenuCollapsed] = useState(true);

    const toggleResponsiveMenu = () => setResponsiveMenuCollapsed(!responsiveMenuCollapsed);

    const shouldRenderForBoth = useMemo(
        () => userProfile?.is_talent && userProfile?.is_client,
        [userProfile?.is_client, userProfile?.is_talent],
    );

    const shouldRenderSidebarOnMobileOnly = useMemo(() => currentRole === AUTH.ROLE.ADMIN, [currentRole]);

    return (
        <div
            style={{ backgroundColor: isAdmin ? "#F9FAFC" : "#FFFFFF" }}
            className={`dashboard-sidebar ${shouldRenderSidebarOnMobileOnly ? "mobile-only" : ""}`}
        >
            <div
                data-simplebar
                className="dashboard-sidebar-inner"
            >
                <div
                    style={{ height: "95%" }}
                    className="dashboard-nav-container"
                >
                    <ResponsiveMenuButton
                        collapsed={responsiveMenuCollapsed}
                        toggleCollapsed={setResponsiveMenuCollapsed}
                    />

                    <div
                        style={{ height: "unset" }}
                        className={responsiveMenuCollapsed ? "dashboard-nav" : "dashboard-nav is-active"}
                    >
                        <div className="dashboard-nav-inner">
                            {currentRole === AUTH.ROLE.ADMIN && (
                                <AdminSidebar
                                    {...props}
                                    toggleResponsiveMenu={toggleResponsiveMenu}
                                />
                            )}

                            {currentRole === AUTH.ROLE.CLIENT && (
                                <ClientSidebar
                                    {...props}
                                    toggleResponsiveMenu={toggleResponsiveMenu}
                                />
                            )}

                            {currentRole === AUTH.ROLE.POTENTIAL_CUSTOMER && (
                                <PotentialCustomer
                                    {...props}
                                    toggleResponsiveMenu={toggleResponsiveMenu}
                                />
                            )}

                            {currentRole === AUTH.ROLE.TALENT && (
                                <TalentSidebar
                                    {...props}
                                    toggleResponsiveMenu={toggleResponsiveMenu}
                                />
                            )}

                            {shouldRenderForBoth && <RoleSwitch onClick={toggleResponsiveMenu} />}

                            {currentRole === AUTH.ROLE.PARTNER && (
                                <PartnerSidebar
                                    {...props}
                                    toggleResponsiveMenu={toggleResponsiveMenu}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
