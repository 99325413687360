import React from "react";
import MainMenu from "./MainMenu";
import { useSelector } from "react-redux";

import { generate } from "@app/routes/SidebarLinks/Partner";

function PartnerSidebar({ toggleResponsiveMenu }) {
    const { is_partner } = useSelector((state) => state.authentication.userProfile);

    const links = React.useMemo(
        () =>
            generate({
                isSales: is_partner?.is_partner_sales,
                isTalent: is_partner?.is_partner_talent,
            }),
        [is_partner?.is_partner_sales, is_partner?.is_partner_talent],
    );

    return (
        <MainMenu
            items={links}
            onClick={toggleResponsiveMenu}
        />
    );
}

export default PartnerSidebar;
