import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { MODALS } from "@app/enums";
import { useModal, useTalentWorkPreferences } from "@app/hooks";
import { clearErrors, setAccountPreferences, getAccountPreferences } from "@app/actions/Talent/Account";

import AvailabilityPreference from "../AvailabilityPreference";

import "./index.scss";

const AvailabilityModal = ({ onClose, modalId = MODALS.TALENT_AVAILABILITY }) => {
    const dispatch = useDispatch();
    const { state, closeModal } = useModal(modalId);
    const {
        state: contextData,
        hasError: hasContextError,
        resetState: resetContextData,
        clearErrors: clearContextErrors,
        updateErrors: updateContextErrors,
    } = useTalentWorkPreferences();

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const onHide = React.useCallback(() => {
        if (isSubmitting) return;

        dispatch(clearErrors());

        closeModal();

        onClose?.();

        resetContextData();

        clearContextErrors();
    }, [clearContextErrors, closeModal, dispatch, isSubmitting, onClose, resetContextData]);

    const onSubmit = () => {
        if (hasContextError) return;

        setIsSubmitting(true);

        dispatch(clearErrors());

        clearContextErrors?.();

        dispatch(setAccountPreferences(contextData))
            .then(() => {
                dispatch(getAccountPreferences());

                onHide();
            })
            .catch((e) => {
                Object.entries(e || {})?.forEach(([path, error]) => {
                    updateContextErrors(path, error);
                });
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <Modal
            scrollable
            onHide={onHide}
            id="AvailabilityModal"
            show={state?.show || false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Availability</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <AvailabilityPreference />
            </Modal.Body>

            <Modal.Footer>
                <button
                    onClick={onHide}
                    disabled={isSubmitting}
                    className="btn-action btn-text btn-transparent"
                >
                    Close
                </button>

                <button
                    onClick={onSubmit}
                    disabled={!!hasContextError || isSubmitting}
                    className="btn-action btn-text btn-green mr-0"
                >
                    {isSubmitting ? "Saving..." : "Submit"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AvailabilityModal;
