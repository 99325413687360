import { getGroups } from "./groups";

import api from "@app/services/api";
import properties from "@app/properties";
import { generateFormdata, generateFormDataPatch } from "@app/helpers";

export const USER_MANAGEMENT_SEARCH_DATA = "USER_MANAGEMENT_SEARCH_DATA";
export const searchData = (keyword) => ({
    keyword,
    type: USER_MANAGEMENT_SEARCH_DATA,
});

export const DATA_TO_EDIT = "DATA_TO_EDIT";
export const dataToEdit = (selectedRow) => ({
    selectedRow,
    type: DATA_TO_EDIT,
});

export const REMOVE_ACCESS = "REMOVE_ACCESS";
export const removeAccess = () => ({
    type: REMOVE_ACCESS,
});

export const RESEND_EMAIL = "RESEND_EMAIL";
export const resendEmail = () => ({
    type: RESEND_EMAIL,
});

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => ({
    type: CLEAR_ACTION,
});

export const SORT_DATA = "SORT_DATA";
export const sortDataByDate = () => ({
    type: SORT_DATA,
});

export const FILTER_DATA_USER_MANAGEMENT = "FILTER_DATA_USER_MANAGEMENT";
export const filterDataUserManagement = (data) => {
    return {
        payload: data,
        type: FILTER_DATA_USER_MANAGEMENT,
    };
};

export const SET_COMPANY_DETAILS_ATTRIBUTE = "SET_COMPANY_DETAILS_ATTRIBUTE";
export const setComanyDetailsAttribute = (key, value) => {
    return {
        key,
        value,
        type: SET_COMPANY_DETAILS_ATTRIBUTE,
    };
};

// =======>>>> API ENDPOINTS <<<<=======

export const getUserCompanyData = (clientId) => {
    return async (dispatch) => {
        await dispatch(getClientRoles());
        await dispatch(getUsers(clientId));
        await dispatch(getGroups(clientId));
    };
};

export const GET_USERS_BEGIN = "GET_USERS_BEGIN";
export const getUsersBegin = () => ({
    type: GET_USERS_BEGIN,
});

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const getUsersSuccess = (data) => ({
    payload: data,
    type: GET_USERS_SUCCESS,
});

export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const getUsersFailed = (error) => ({
    payload: error,
    type: GET_USERS_FAILED,
});

export const getUsers = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getUsersBegin());
            const response = await api.get(`clients/${clientId}/users`);
            dispatch(getUsersSuccess(response.data));
            dispatch(sortDataByDate());
        } catch (error) {
            dispatch(getUsersFailed(error));
        }
    };
};

export const GET_CLIENT_ROLES_BEGIN = "GET_CLIENT_ROLES_BEGIN";
export const getClientRolesBegin = () => ({
    type: GET_CLIENT_ROLES_BEGIN,
});

export const GET_CLIENT_ROLES_SUCCESS = "GET_CLIENT_ROLES_SUCCESS";
export const getClientRolesSuccess = (data) => ({
    payload: data,
    type: GET_CLIENT_ROLES_SUCCESS,
});

export const GET_CLIENT_ROLES_FAILED = "GET_CLIENT_ROLES_FAILED";
export const getClientRolesFailed = (error) => ({
    payload: error,
    type: GET_CLIENT_ROLES_FAILED,
});

export const getClientRoles = () => {
    return async (dispatch) => {
        try {
            dispatch(getClientRolesBegin());
            const response = await api.get(`clients/roles`);
            dispatch(getClientRolesSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(getClientRolesFailed(error));
        }
    };
};

export const REMOVE_USER_BEGIN = "REMOVE_USER_BEGIN";
export const removeUserBegin = () => ({
    type: REMOVE_USER_BEGIN,
});

export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const removeUserSuccess = (data) => ({
    payload: data,
    type: REMOVE_USER_SUCCESS,
});

export const REMOVE_USER_FAILED = "REMOVE_USER_FAILED";
export const removeUserFailed = (error) => ({
    payload: error,
    type: REMOVE_USER_FAILED,
});

export const removeUser = (clientId, userId) => {
    return async (dispatch) => {
        try {
            dispatch(removeUserBegin());
            const response = await api.delete(`clients/${clientId}/users/${userId}`);
            response.data.data["removed_id"] = userId;
            dispatch(removeUserSuccess(response.data));
        } catch (error) {
            dispatch(removeUserFailed(error));
        }
    };
};

export const REMOVE_INVITATION_BEGIN = "REMOVE_INVITATION_BEGIN";
export const removeInvitationBegin = () => ({
    type: REMOVE_INVITATION_BEGIN,
});

export const REMOVE_INVITATION_SUCCESS = "REMOVE_INVITATION_SUCCESS";
export const removeInvitationSuccess = (data) => ({
    payload: data,
    type: REMOVE_INVITATION_SUCCESS,
});

export const REMOVE_INVITATION_FAILED = "REMOVE_INVITATION_FAILED";
export const removeInvitationFailed = (error) => ({
    payload: error,
    type: REMOVE_INVITATION_FAILED,
});

export const removeInvitation = (clientId, email) => {
    return async (dispatch) => {
        try {
            dispatch(removeInvitationBegin());
            const body = {
                email: email,
            };
            const response = await api.delete(`clients/${clientId}/users/invite`, {
                data: generateFormDataPatch(body),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
            });
            response.data.data["removed_email"] = email;
            dispatch(removeInvitationSuccess(response.data));
        } catch (error) {
            dispatch(removeInvitationFailed(error));
        }
    };
};

export const INVITE_USER_BEGIN = "INVITE_USER_BEGIN";
export const inviteUserBegin = () => ({
    type: INVITE_USER_BEGIN,
});

export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const inviteUserSuccess = (data) => ({
    payload: data,
    type: INVITE_USER_SUCCESS,
});

export const INVITE_USER_FAILED = "INVITE_USER_FAILED";
export const inviteUserFailed = (error) => ({
    payload: error,
    type: INVITE_USER_FAILED,
});

export const inviteUser = (clientId, data) => {
    return async (dispatch) => {
        try {
            dispatch(inviteUserBegin());
            const body = {
                "email[]": data.email,
                "group_id[]": data.groups,
                client_role_id: data.role[0].id,
            };
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/users/invite`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(inviteUserSuccess(response.data));
        } catch (error) {
            dispatch(inviteUserFailed(error));
        }
    };
};

export const modifyUser = (clientId, userId, inviteMemberBody) => {
    return async (dispatch) => {
        await dispatch(updateClientRole(clientId, userId, inviteMemberBody));
        inviteMemberBody.groups.length > 0
            ? await dispatch(updateGroup(clientId, userId, inviteMemberBody.role[0].id, inviteMemberBody.groups))
            : await dispatch(removeGroups(clientId, userId, inviteMemberBody.role[0].id, inviteMemberBody.groups));
    };
};

export const UPDATE_CLIENT_ROLE_BEGIN = "UPDATE_CLIENT_ROLE_BEGIN";
export const updateClientRoleBegin = () => ({
    type: UPDATE_CLIENT_ROLE_BEGIN,
});

export const UPDATE_CLIENT_ROLE_SUCCESS = "UPDATE_CLIENT_ROLE_SUCCESS";
export const updateClientRoleSuccess = (data) => ({
    payload: data,
    type: UPDATE_CLIENT_ROLE_SUCCESS,
});

export const UPDATE_CLIENT_ROLE_FAILED = "UPDATE_CLIENT_ROLE_FAILED";
export const updateClientRoleFailed = (error) => ({
    payload: error,
    type: UPDATE_CLIENT_ROLE_FAILED,
});

export const updateClientRole = (clientId, userId, inviteMemberBody) => {
    return async (dispatch) => {
        try {
            dispatch(updateClientRoleBegin());
            const body = {
                "client_role_id[]": inviteMemberBody.role,
            };
            const formData = new FormData();
            for (const key in body) {
                body[key].forEach((el) => formData.append(key, el.id));
            }
            const response = await api.post(`clients/${clientId}/users/${userId}/client-roles`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(updateClientRoleSuccess(response.data));
        } catch (error) {
            dispatch(updateClientRoleFailed(error));
        }
    };
};

export const UPDATE_GROUP_FOR_CLIENT_ROLE_BEGIN = "UPDATE_GROUP_FOR_CLIENT_ROLE_BEGIN";
export const updateGroupBegin = () => ({
    type: UPDATE_GROUP_FOR_CLIENT_ROLE_BEGIN,
});

export const UPDATE_GROUP_FOR_CLIENT_ROLE_SUCCESS = "UPDATE_GROUP_FOR_CLIENT_ROLE_SUCCESS";
export const updateGroupSuccess = (data) => ({
    payload: data,
    type: UPDATE_GROUP_FOR_CLIENT_ROLE_SUCCESS,
});

export const UPDATE_GROUP_FOR_CLIENT_ROLE_FAILED = "UPDATE_GROUP_FOR_CLIENT_ROLE_FAILED";
export const updateGroupFailed = (error) => ({
    payload: error,
    type: UPDATE_GROUP_FOR_CLIENT_ROLE_FAILED,
});

export const updateGroup = (clientId, userId, clientRoleId, groups) => {
    return async (dispatch) => {
        try {
            dispatch(updateGroupBegin());
            const formData = generateFormdata({
                "group_id[]": groups,
            });
            const response = await api.post(
                `clients/${clientId}/users/${userId}/client-role/${clientRoleId}/groups`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            dispatch(updateGroupSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(updateGroupFailed(error));
        }
    };
};

export const RESEND_INVITATION_BEGIN = "RESEND_INVITATION_BEGIN";
export const resendInvitationBegin = () => ({
    type: RESEND_INVITATION_BEGIN,
});

export const RESEND_INVITATION_SUCCESS = "RESEND_INVITATION_SUCCESS";
export const resendInvitationSuccess = (data) => ({
    payload: data,
    type: RESEND_INVITATION_SUCCESS,
});

export const RESEND_INVITATION_FAILED = "RESEND_INVITATION_FAILED";
export const resendInvitationFailed = (error) => ({
    payload: error,
    type: RESEND_INVITATION_FAILED,
});

export const resendInvitation = (clientId, email) => {
    return async (dispatch) => {
        try {
            dispatch(resendInvitationBegin());
            const formData = generateFormdata({
                "email[]": email,
            });
            const response = await api.post(`clients/${clientId}/users/resend-invite`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(resendInvitationSuccess(response.data));
        } catch (error) {
            dispatch(resendInvitationFailed(error));
        }
    };
};

export const ACCEPT_INVITATION_BEGIN = "ACCEPT_INVITATION_BEGIN";
export const acceptInvitationBegin = () => ({
    type: ACCEPT_INVITATION_BEGIN,
});

export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS";
export const acceptInvitationSuccess = (data) => {
    window.location.replace(`${properties.MAIN_HOST}login?success=true`);
    return {
        payload: data,
        type: ACCEPT_INVITATION_SUCCESS,
    };
};

export const ACCEPT_INVITATION_FAILED = "ACCEPT_INVITATION_FAILED";
export const acceptInvitationFailed = (error) => ({
    payload: error,
    type: ACCEPT_INVITATION_FAILED,
});

export const acceptInvitation = (body) => {
    return async (dispatch) => {
        try {
            dispatch(acceptInvitationBegin());
            const formData = generateFormdata(body);
            const response = await api.post("accept-invite", formData);
            dispatch(acceptInvitationSuccess(response.data));
        } catch (error) {
            dispatch(acceptInvitationFailed(error));
        }
    };
};

export const GET_DASHBOARD_BEGIN = "GET_DASHBOARD_BEGIN";
export const getDashboardBegin = () => ({
    type: GET_DASHBOARD_BEGIN,
});

export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const getDashboardSuccess = (data) => ({
    payload: data,
    type: GET_DASHBOARD_SUCCESS,
});

export const GET_DASHBOARD_FAILED = "GET_DASHBOARD_FAILED";
export const getDashboardFailed = (error) => ({
    payload: error,
    type: GET_DASHBOARD_FAILED,
});

export const getDashboard = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getDashboardBegin());
            const response = await api.get(`clients/${clientId}/dashboard`);
            dispatch(getDashboardSuccess(response.data.data));
        } catch (error) {
            dispatch(getDashboardFailed(error));
        }
    };
};

export const REMOVE_GROUP_FOR_CLIENT_ROLE_BEGIN = "REMOVE_GROUP_FOR_CLIENT_ROLE_BEGIN";
export const removeGroupsBegin = () => ({
    type: REMOVE_GROUP_FOR_CLIENT_ROLE_BEGIN,
});

export const REMOVE_GROUP_FOR_CLIENT_ROLE_SUCCESS = "REMOVE_GROUP_FOR_CLIENT_ROLE_SUCCESS";
export const removeGroupsSuccess = (data) => ({
    payload: data,
    type: REMOVE_GROUP_FOR_CLIENT_ROLE_SUCCESS,
});

export const REMOVE_GROUP_FOR_CLIENT_ROLE_FAILED = "REMOVE_GROUP_FOR_CLIENT_ROLE_FAILED";
export const removeGroupsFailed = (error) => ({
    payload: error,
    type: REMOVE_GROUP_FOR_CLIENT_ROLE_FAILED,
});

export const removeGroups = (clientId, userId, clientRoleId) => {
    return async (dispatch) => {
        try {
            dispatch(removeGroupsBegin());
            const response = await api.delete(
                `clients/${clientId}/users/${userId}/client-role/${clientRoleId}/groups`,
                {
                    headers: {},
                },
            );
            dispatch(removeGroupsSuccess(response.data.data));
        } catch (error) {
            dispatch(removeGroupsFailed(error));
        }
    };
};

export const GET_COMPANY_DETAILS_BEGIN = "GET_COMPANY_DETAILS_BEGIN";
export const getCompanyDetailsBegin = () => ({
    type: GET_COMPANY_DETAILS_BEGIN,
});

export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS";
export const getCompanyDetailsSuccess = (data) => ({
    payload: data,
    type: GET_COMPANY_DETAILS_SUCCESS,
});

export const GET_COMPANY_DETAILS_FAILED = "GET_COMPANY_DETAILS_FAILED";
export const getCompanyDetailsFailed = (error) => ({
    payload: error,
    type: GET_COMPANY_DETAILS_FAILED,
});

export const getCompanyDetails = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getCompanyDetailsBegin());
            const response = await api.get(`clients/${clientId}/details`);
            dispatch(getCompanyDetailsSuccess(response.data.data));
        } catch (error) {
            dispatch(getCompanyDetailsFailed(error));
        }
    };
};

export const UPDATE_COMPANY_DETAILS_BEGIN = "UPDATE_COMPANY_DETAILS_BEGIN";
export const updateCompanyDetailsBegin = () => ({
    type: UPDATE_COMPANY_DETAILS_BEGIN,
});

export const UPDATE_COMPANY_DETAILS_SUCCESS = "UPDATE_COMPANY_DETAILS_SUCCESS";
export const updateCompanyDetailsSuccess = (data) => ({
    payload: data,
    type: UPDATE_COMPANY_DETAILS_SUCCESS,
});

export const UPDATE_COMPANY_DETAILS_FAILED = "UPDATE_COMPANY_DETAILS_FAILED";
export const updateCompanyDetailsFailed = (error) => ({
    payload: error,
    type: UPDATE_COMPANY_DETAILS_FAILED,
});

export const updateCompanyDetails = (clientId, body) => {
    return async (dispatch) => {
        try {
            dispatch(updateCompanyDetailsBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/details`, formData);
            dispatch(updateCompanyDetailsSuccess(response.data.data));
        } catch (error) {
            dispatch(updateCompanyDetailsFailed(error));
        }
    };
};
