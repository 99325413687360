import api from "@app/services/api";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const GET_ACCOUNT_PREFERENCES_BEGIN = "GET_ACCOUNT_PREFERENCES_BEGIN";
export const getAccountPreferencesBegin = () => ({
    type: GET_ACCOUNT_PREFERENCES_BEGIN,
});

export const GET_ACCOUNT_PREFERENCES = "GET_ACCOUNT_PREFERENCES";
export const getAccountPreferences = () => {
    return async (dispatch) => {
        try {
            dispatch(getAccountPreferencesBegin());

            const response = await api.get("talent/account");

            dispatch(getAccountPreferencesSuccess(response.data.data));
        } catch (error) {
            dispatch(getAccountPreferencesFailed(error));
        }
    };
};

export const GET_ACCOUNT_PREFERENCES_SUCCESS = "GET_ACCOUNT_PREFERENCES_SUCCESS";
export const getAccountPreferencesSuccess = (data) => ({
    payload: data,
    type: GET_ACCOUNT_PREFERENCES_SUCCESS,
});

export const GET_ACCOUNT_PREFERENCES_FAILED = "GET_ACCOUNT_PREFERENCES_FAILED";
export const getAccountPreferencesFailed = (error) => ({
    payload: error,
    type: GET_ACCOUNT_PREFERENCES_FAILED,
});

//==============================================================================================================//
//==============================================================================================================//
//==============================================================================================================//
export const SET_ACCOUNT_PREFERENCES_BEGIN = "SET_ACCOUNT_PREFERENCES_BEGIN";
export const setAccountPreferencesBegin = () => ({
    type: SET_ACCOUNT_PREFERENCES_BEGIN,
});

export const SET_ACCOUNT_PREFERENCES = "SET_ACCOUNT_PREFERENCES";
export const setAccountPreferences = (data) => {
    return async (dispatch) => {
        try {
            dispatch(setAccountPreferencesBegin());

            const response = await api.put("talent/account", data);

            dispatch(setAccountPreferencesSuccess(response.data.data));
        } catch (error) {
            dispatch(setAccountPreferencesFailed(error));

            throw error;
        }
    };
};

export const SET_ACCOUNT_PREFERENCES_SUCCESS = "SET_ACCOUNT_PREFERENCES_SUCCESS";
export const setAccountPreferencesSuccess = (data) => ({
    payload: data,
    type: SET_ACCOUNT_PREFERENCES_SUCCESS,
});

export const SET_ACCOUNT_PREFERENCES_FAILED = "SET_ACCOUNT_PREFERENCES_FAILED";
export const setAccountPreferencesFailed = (error) => ({
    payload: error,
    type: SET_ACCOUNT_PREFERENCES_FAILED,
});
