import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackButton = ({ to, label, onClick }) => {
    const navigate = useNavigate();

    const goBack = () => {
        // Allow total override of onClick behaviour
        if (onClick) {
            return onClick();
        }

        if (to) {
            navigate(to);
        } else {
            navigate(-1);
        }
    };

    return (
        <button
            type="button"
            onClick={goBack}
            className="blue-link arrow back"
        >
            {label || "Back"}
        </button>
    );
};

export default GoBackButton;
