import api from "@app/services/api";
import { generateFormdata, generateFormDataPatch } from "@app/helpers";
import { groupEditSuccess, groupDeleteSuccess, getContractDetails } from "@app/actions/Client/Contracts/contracts";

export const GROUP_SEARCH_DATA = "GROUP_SEARCH_DATA";
export const groupSearchData = (keyword) => {
    return {
        keyword,
        type: GROUP_SEARCH_DATA,
    };
};

export const DATA_TO_EDIT = "DATA_TO_EDIT";
export const dataToEdit = (selectedRow) => {
    return {
        selectedRow,
        type: DATA_TO_EDIT,
    };
};

export const CREATE_POPPER = "CREATE_POPPER";
export const createPopper = () => {
    return {
        type: CREATE_POPPER,
    };
};

export const EDIT_POPPER = "EDIT_POPPER";
export const editPopper = () => {
    return {
        type: EDIT_POPPER,
    };
};

export const DELETE_POPPER = "DELETE_POPPER";
export const deletePopper = () => {
    return {
        type: DELETE_POPPER,
    };
};

export const ARCHIVE_POPPER = "ARCHIVE_POPPER";
export const archivePopper = () => ({
    type: ARCHIVE_POPPER,
});

export const CLEAR_ACTION = "CLEAR_ACTION";
export const clearAction = () => {
    return {
        type: CLEAR_ACTION,
    };
};

export const SORT_DATA_GROUP = "SORT_DATA_GROUP";
export const sortDataByDate = () => ({
    type: SORT_DATA_GROUP,
});

export const FILTER_DATA_GROUPS = "FILTER_DATA_GROUPS";
export const filterDataGroups = (selectedStatus) => {
    return {
        payload: selectedStatus,
        type: FILTER_DATA_GROUPS,
    };
};

// =======>>>> API ENDPOINTS <<<<=======

export const GET_GROUPS_BEGIN = "GET_GROUPS_BEGIN";
export const getGroupsBegin = () => ({
    type: GET_GROUPS_BEGIN,
});

export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const getGroupsSuccess = (data) => ({
    payload: data,
    type: GET_GROUPS_SUCCESS,
});

export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";
export const getGroupsFailed = (error) => ({
    payload: error,
    type: GET_GROUPS_FAILED,
});

export const getGroups = (clientId) => {
    return async (dispatch) => {
        try {
            dispatch(getGroupsBegin());
            const response = await api.get(`clients/${clientId}/groups`);
            dispatch(getGroupsSuccess(response.data));
            dispatch(sortDataByDate());
        } catch (error) {
            dispatch(getGroupsFailed(error));
        }
    };
};

export const REMOVE_GROUP_BEGIN = "REMOVE_GROUP_BEGIN";
export const removeGroupBegin = () => ({
    type: REMOVE_GROUP_BEGIN,
});

export const REMOVE_GROUP_SUCCESS = "REMOVE_GROUP_SUCCESS";
export const removeGroupSuccess = (json) => ({
    payload: json,
    type: REMOVE_GROUP_SUCCESS,
});

export const REMOVE_GROUP_FAILED = "REMOVE_GROUP_FAILED";
export const removeGroupFailed = (error) => ({
    payload: error,
    type: REMOVE_GROUP_FAILED,
});

export const removeGroup = (clientId, groupId, contractsComponent = false) => {
    return async (dispatch) => {
        try {
            dispatch(removeGroupBegin());
            const response = await api.delete(`clients/${clientId}/groups/${groupId}`);
            response.data.data["removed_id"] = groupId;
            dispatch(removeGroupSuccess(response.data));
            if (contractsComponent) {
                dispatch(groupDeleteSuccess(response.data));
            }
        } catch (error) {
            dispatch(removeGroupFailed(error));
        }
    };
};

export const ARCHIVE_GROUP_BEGIN = "ARCHIVE_GROUP_BEGIN";
export const archiveGroupBegin = () => ({
    type: ARCHIVE_GROUP_BEGIN,
});

export const ARCHIVE_GROUP_SUCCESS = "ARCHIVE_GROUP_SUCCESS";
export const archiveGroupSuccess = (json) => ({
    payload: json,
    type: ARCHIVE_GROUP_SUCCESS,
});

export const ARCHIVE_GROUP_FAILED = "ARCHIVE_GROUP_FAILED";
export const archiveGroupFailed = (error) => ({
    payload: error,
    type: ARCHIVE_GROUP_FAILED,
});

export const archiveGroup = (clientId, groupId, contractId = null) => {
    return async (dispatch) => {
        try {
            dispatch(archiveGroupBegin());
            const response = await api.get(`clients/${clientId}/groups/${groupId}/archive`);
            dispatch(archiveGroupSuccess(response.data));
            dispatch(getGroups(clientId));
            if (contractId) {
                dispatch(getContractDetails(clientId, contractId));
            }
        } catch (error) {
            dispatch(archiveGroupFailed(error));
        }
    };
};

export const UPDATE_GROUP_BEGIN = "UPDATE_GROUP_BEGIN";
export const updateGroupBegin = () => ({
    type: UPDATE_GROUP_BEGIN,
});

export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const updateGroupSuccess = (json) => ({
    payload: json,
    type: UPDATE_GROUP_SUCCESS,
});

export const UPDATE_GROUP_FAILED = "UPDATE_GROUP_FAILED";
export const updateGroupFailed = (error) => ({
    payload: error,
    type: UPDATE_GROUP_FAILED,
});

export const updateGroup = (clientId, groupId, body, contractsComponent = false) => {
    return async (dispatch) => {
        try {
            dispatch(updateGroupBegin());
            const formData = generateFormDataPatch(body);
            const response = await api.patch(`clients/${clientId}/groups/${groupId}`, formData, {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            });
            dispatch(updateGroupSuccess(response.data));
            dispatch(editPopper());
            if (contractsComponent) {
                dispatch(groupEditSuccess(response.data));
            }
        } catch (error) {
            dispatch(updateGroupFailed(error));
        }
    };
};

export const ADD_GROUP_BEGIN = "ADD_GROUP_BEGIN";
export const addGroupBegin = () => ({
    type: ADD_GROUP_BEGIN,
});

export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const addGroupSuccess = (json) => ({
    payload: json,
    type: ADD_GROUP_SUCCESS,
});

export const ADD_GROUP_FAILED = "ADD_GROUP_FAILED";
export const addGroupFailed = (error) => ({
    payload: error,
    type: ADD_GROUP_FAILED,
});

export const addGroup = (clientId, body) => {
    return async (dispatch) => {
        try {
            dispatch(addGroupBegin());
            const formData = generateFormdata(body);
            const response = await api.post(`clients/${clientId}/groups`, formData);
            dispatch(addGroupSuccess(response.data));
            dispatch(createPopper());
        } catch (error) {
            dispatch(addGroupFailed(error));
        }
    };
};
