import { contract } from "@app/actions";
import { setSelectedProp } from "@app/helpers";

const initialState = {
    action: {
        error: null,
        message: "",
        isLoading: false,
        actionName: null,
        isSuccess: false,
    },
    contract: {
        title: "",
        filled: 0,
        user_id: "",
        end_date: "",
        extension: 0,
        company_id: 0,
        parent_id: "",
        rate_type: "",
        skip_brysk: 1,
        xero_id: null,
        start_date: "",
        position_id: "",
        rate_amount: "",
        preset_id: null,
        paid_time_off: 0,
        daily_logging: 0,
        rate_currency: "",
        monthly_hours: "",
        notice_period: "",
        approved_at: null,
        preset_data: null,
        assigned_groups: [],
        client_rate_type: "",
        client_rate_amount: "",
        owner_entity_id: "null",
        client_rate_currency: "",
        time_management_enabled: 1,
    },
};

export default function contractsReducer(state = initialState, action) {
    switch (action.type) {
        case contract.CLEAR_ACTIONS:
            return {
                ...state,
                action: {
                    ...initialState.action,
                },
            };
        case contract.CLEAR_CONTRACT_ACTION:
            return {
                ...state,
                contract: setSelectedProp(state.data, false),
                action: {
                    ...initialState.action,
                },
            };
        case contract.SET_CONTRACT_ATTRIBUTE: {
            return {
                ...state,
                contract: {
                    ...state.contract,
                    [action.key]: action.value,
                },
            };
        }
        case contract.CREATE_CONTRACT_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case contract.CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: initialState,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case contract.CREATE_CONTRACT_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case contract.UPDATE_CONTRACT_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case contract.UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.data.message,
                },
            };
        case contract.UPDATE_CONTRACT_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };
        case contract.GET_CONTRACT_BEGIN:
            return {
                ...state,
                action: {
                    ...initialState.action,
                    isLoading: true,
                    actionName: action.type,
                },
            };
        case contract.GET_CONTRACT_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.message,
                },
                contract: {
                    title: action.payload.title,
                    user_id: action.payload.user_id,
                    xero_id: action.payload.xero_id,
                    end_date: action.payload.end_date,
                    rate_type: action.payload.rate_type,
                    parent_id: action.payload.parent_id,
                    start_date: action.payload.start_date,
                    skip_brysk: +action.payload.skip_brysk,
                    position_id: action.payload.position_id,
                    rate_amount: action.payload.rate_amount,
                    approved_at: action.payload.approved_at,
                    extension: +(action.payload.parent_id > 0),
                    notice_period: action.payload.notice_period,
                    monthly_hours: action.payload.monthly_hours,
                    rate_currency: action.payload.rate_currency,
                    paid_time_off: +action.payload.paid_time_off,
                    daily_logging: +action.payload.daily_logging,
                    company_id: action.payload.position.company_id,
                    owner_entity_id: action.payload.owner_entity_id,
                    client_rate_type: action.payload.client_rate_type,
                    filled: +(action.payload.position.status_id === 9),
                    client_rate_amount: action.payload.client_rate_amount,
                    client_rate_currency: action.payload.client_rate_currency,
                    assigned_groups: action.payload.groups.map((item) => item.id),
                    time_management_enabled: action.payload.time_management_enabled,
                    preset_data: action.payload?.brysk_preset?.custom_placeholders || null,
                    preset_id: Number(action.payload?.brysk_preset?.brysk_preset_id) || null,
                    original_preset_data: action.payload?.brysk_preset?.custom_placeholders || null,
                },
            };
        case contract.DUPLICATE_CONTRACT_SUCCESS:
            return {
                ...state,
                action: {
                    ...state.action,
                    isSuccess: true,
                    isLoading: false,
                    actionName: action.type,
                    message: action.payload.message,
                },
                contract: {
                    end_date: "",
                    start_date: "",
                    title: action.payload.title,
                    user_id: action.payload.user_id,
                    xero_id: action.payload.xero_id,
                    rate_type: action.payload.rate_type,
                    parent_id: action.payload.parent_id,
                    skip_brysk: +action.payload.skip_brysk,
                    position_id: action.payload.position_id,
                    rate_amount: action.payload.rate_amount,
                    approved_at: action.payload.approved_at,
                    extension: +(action.payload.parent_id > 0),
                    notice_period: action.payload.notice_period,
                    monthly_hours: action.payload.monthly_hours,
                    rate_currency: action.payload.rate_currency,
                    paid_time_off: +action.payload.paid_time_off,
                    daily_logging: +action.payload.daily_logging,
                    company_id: action.payload.position.company_id,
                    owner_entity_id: action.payload.owner_entity_id,
                    client_rate_type: action.payload.client_rate_type,
                    filled: +(action.payload.position.status_id === 9),
                    client_rate_amount: action.payload.client_rate_amount,
                    client_rate_currency: action.payload.client_rate_currency,
                    assigned_groups: action.payload.groups.map((item) => item.id),
                    time_management_enabled: action.payload.time_management_enabled,
                    preset_data: action.payload?.brysk_preset?.custom_placeholders || null,
                    preset_id: Number(action.payload?.brysk_preset?.brysk_preset_id) || null,
                    original_preset_data: action.payload?.brysk_preset?.custom_placeholders || null,
                },
            };
        case contract.GET_CONTRACT_FAILED:
            return {
                ...state,
                action: {
                    ...state.action,
                    isLoading: false,
                    actionName: action.type,
                    error: action.payload ? action.payload : "Unknown error",
                },
            };

        default: {
            return state;
        }
    }
}
