import React from "react";

import "./index.scss";

const ReferralStatsCard = ({ totalRewards, totalRewardsTitle, successfulReferrals, successfulReferralsTitle }) => {
    return (
        <div className="referral-stats-card">
            <div className="successful-referrals">
                <p className="title">{successfulReferralsTitle}</p>
                <p className="value-number">{successfulReferrals}</p>
            </div>
            <div>
                <p className="rewards-title">{totalRewardsTitle}</p>
                <p className="total-rewards">{totalRewards}</p>
            </div>
        </div>
    );
};

export default ReferralStatsCard;
