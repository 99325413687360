import React from "react";
import startCase from "lodash/startCase";
import { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { FormSelect } from "@app/components";
import { SupportedCalendars } from "@app/config";
import { INTERVIEWER_TYPE } from "@app/enums/interview";
import { setSelectedCalendar } from "@app/actions/Talent/Interviews";

import "./index.scss";

const urlAsLabel = (url) => {
    return url?.replace(/http(s)?:\/\/(app.)?cal\.com(\/)?/, "");
};

const transformInterviewerAsOption = (interviewer) => {
    if (!interviewer) return "";

    const label =
        interviewer?.calendar_title ||
        interviewer?.event_type_title ||
        urlAsLabel(interviewer?.calendar) ||
        interviewer?.type;

    return {
        label: label,
        value: interviewer?.id,
        metadata: { ...interviewer },
    };
};

const CalendarOption = ({ label, metadata }) => {
    const type = startCase(metadata?.type);
    const skill = startCase(metadata?.type === INTERVIEWER_TYPE.TECHNICAL ? metadata?.skill : "");
    const tag = skill ? `${type} - ${skill}` : type;

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="mr-2">
                <p>{label}</p>
            </div>

            {Boolean(tag) && <small className="badge rounded-pill bg-secondary">{tag}</small>}
        </div>
    );
};

const CustomSelectedValue = (props) => {
    const optionData = props?.options?.find((op) => op.value === props.data.value);

    const metadata = optionData?.metadata;

    return (
        <components.SingleValue {...props}>
            <CalendarOption
                metadata={metadata}
                label={optionData?.label}
            />
        </components.SingleValue>
    );
};

const CustomOption = (props) => {
    const metadata = props?.data?.metadata;

    return (
        <components.Option {...props}>
            <CalendarOption
                metadata={metadata}
                label={props?.label}
            />
        </components.Option>
    );
};

const IntegratedCalendars = () => {
    const dispatch = useDispatch();
    const { interviewers, selectedCalendar } = useSelector((state) => state.talent.interviews);

    const options = React.useMemo(
        () =>
            interviewers
                ?.filter((cal) => SupportedCalendars.includes(cal?.integration))
                ?.map((interviewer) => transformInterviewerAsOption(interviewer)),
        [interviewers],
    );

    const changeSelectedCalendar = (newVal) => dispatch(setSelectedCalendar(newVal?.value));

    // Hide the dropdown if no supported calendars are added
    if (!options?.length) {
        return null;
    }

    return (
        <div className="current-calendar-selector">
            <FormSelect
                isClearable={false}
                isSearchable={false}
                optionsData={options}
                onChange={changeSelectedCalendar}
                value={selectedCalendar?.id || ""}
                isDisabled={options?.length === 1}
                components={{ Option: CustomOption, SingleValue: CustomSelectedValue }}
            />
        </div>
    );
};

export default IntegratedCalendars;
