import React from "react";
import isNil from "lodash/isNil";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import api from "@app/services/api";
import { COMPANY_STATUS } from "@app/enums/status";
import { PASSING_MEETING_STATUSES } from "@app/static";
import { IS_HUBSPOT_MEETING_DISABLED } from "@app/config/app";

import "./index.scss";

export default function CalendarModal() {
    const [searchParams] = useSearchParams();
    const [calendarUrl, setCalendarUrl] = React.useState(null);

    const {
        authentication: { company, userProfile },
    } = useSelector((state) => state);

    const meetingUrl = React.useMemo(
        () =>
            `${calendarUrl}?embed=true&firstName=${userProfile?.first_name}&lastName=${userProfile?.last_name}&email=${
                userProfile?.work_email || userProfile?.email
            }&company=${company?.name}`,
        [
            calendarUrl,
            company?.name,
            userProfile?.email,
            userProfile?.first_name,
            userProfile?.last_name,
            userProfile?.work_email,
        ],
    );

    // Consider the status after a webhook of the meeting is received
    const isPermanentlySubmitted = React.useMemo(
        () => PASSING_MEETING_STATUSES.includes(company?.hubspot_meeting_status),
        [company?.hubspot_meeting_status],
    );

    // Consider the status after redirect on the frontend
    const isTemporarilySubmitted = React.useMemo(() => {
        const queryStatus = searchParams.get("introCall") || null;
        const cachedStatus = localStorage.getItem(`${company.id}_introCall`) || null;

        return queryStatus === "success" || cachedStatus === "success";
    }, [company.id, searchParams]);

    const shouldRender = React.useMemo(() => {
        const isPending = !isNil(company?.status) && company?.status === COMPANY_STATUS.PENDING;

        if (isTemporarilySubmitted) {
            localStorage.setItem(`${company.id}_introCall`, "success");
        }

        const callScheduled = isPermanentlySubmitted || isTemporarilySubmitted || false;

        return isPending && !callScheduled;
    }, [company.id, company?.status, isPermanentlySubmitted, isTemporarilySubmitted]);

    React.useEffect(() => {
        if (!IS_HUBSPOT_MEETING_DISABLED) {
            api.get("config/hubspot-calendar")
                .then(({ data }) => setCalendarUrl(data?.url?.replace("?embed=true", "")))
                .catch(() => {});
        }
    }, []);

    if (IS_HUBSPOT_MEETING_DISABLED || !Boolean(calendarUrl) || !shouldRender) {
        return null;
    }

    return (
        <>
            {/* Push the embed code script to the page head */}
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                />
            </Helmet>

            <Modal
                open
                keepMounted
                disableEscapeKeyDown
            >
                <div className="calendar-wrapper">
                    <div className="calendar-wrapper-inner">
                        <div className="meetings-header">
                            <p className="heading">Welcome!</p>
                            <p className="description">Let's schedule a call to introduce Adeva</p>
                        </div>

                        <div className="meetings-container">
                            <div
                                data-src={meetingUrl}
                                className="meetings-iframe-container"
                            >
                                {/* Hubspot iFrame will be injected here */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
