import React from "react";
import isNil from "lodash/isNil";
import { useSelector } from "react-redux";

import { MODALS } from "@app/enums";
import { useModal } from "@app/hooks";
import { UNAVAILABLE } from "@app/static";

import "./index.scss";

const AvailabilityIndicator = ({ className = null, modalId = MODALS.TALENT_AVAILABILITY }) => {
    const { user_availability } = useSelector((state) => state.talent.account);

    const available = React.useMemo(
        () => (isNil(user_availability?.type) ? null : user_availability?.type !== "unavailable"),
        [user_availability?.type],
    );

    const { openModal } = useModal(modalId);

    const handleClick = () => openModal();

    if (isNil(available)) {
        return null;
    }

    return (
        <div
            onClick={handleClick}
            className={`availability-indicator ${available ? "available" : "unavailable"} ${className || ""}`}
        >
            <span className="dot">&nbsp;</span>

            <p className="text">{available ? "Available" : UNAVAILABLE}</p>
        </div>
    );
};

export default AvailabilityIndicator;
