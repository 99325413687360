import React from "react";

import Label from "../atoms/label";
import HelperText from "../../HelperText";

export default function Input({ name, type, value, error, children, required, onChange, className, ...props }) {
    return (
        <div className={`form-group mb-4 ${className ? className : ""}`}>
            {children && (
                <Label
                    label={children}
                    elementName={name}
                    required={required}
                />
            )}

            <input
                name={name}
                value={value}
                onChange={onChange}
                style={{ height: "42px" }}
                type={type ? type : "text"}
                className={`form-control ${error ? "border-error" : ""}`}
                {...props}
            />

            <HelperText
                text={error}
                variant="error"
            />
        </div>
    );
}
