import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import "./index.scss";

const MAX_CHAR_COUNT = 5000;

const InterviewNote = ({ onChange }) => {
    const { rating } = useSelector((state) => state.talent.interviews);
    const [content, setContent] = useState(rating?.note?.note || "");
    const [charCounter, setCharCounter] = useState(0);

    const handleChange = (e) => {
        const value = e?.target?.value;

        setContent(value || "");

        onChange(value);
    };

    const isMaxCountReached = () => charCounter === MAX_CHAR_COUNT;

    useEffect(() => {
        const noteMessage = rating?.note?.note || "";

        setContent(noteMessage);

        onChange(noteMessage);
    }, [onChange, rating?.note?.note]);

    useEffect(() => {
        setCharCounter(content?.length || 0);
    }, [content]);

    return (
        <div className="interview-note-wrapper">
            <label className="mb-2">
                Note
                <span className="required">*</span>
            </label>

            <TextareaAutosize
                minRows={1}
                value={content}
                onChange={handleChange}
                maxLength={MAX_CHAR_COUNT}
            />

            <small className={`char-counter ${isMaxCountReached() ? `text-danger` : ""}`}>
                {charCounter}/{MAX_CHAR_COUNT}
            </small>
        </div>
    );
};

export default InterviewNote;
